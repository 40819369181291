import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { deleteLogin } from '../../redux/actions/login';

const ErrorCase = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const { userDetails } = useSelector((state) => state.loginReducer);

  const source = searchParams.get('source');
  return (
    <div className="nointernet">
      <h1>{source}</h1>
      <h1>Domain doesn't exist</h1>
      <a
        href="/create-account/seller-registration"
        onClick={() => {
          if (userDetails?.token) {
            dispatch(deleteLogin());
            nav('/create-account/seller-registration');
          }
        }}
      >
        Do you want to register this Domain?
      </a>
      {/* <Lightbtn title="Retry" /> */}
    </div>
  );
};

export default ErrorCase;
