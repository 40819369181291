import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Paper from '@mui/material/Paper';

import {
  Dialog,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import BannerCard from '../../components/universal/WelcomeCard';
import { BlueBtn } from '../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import { AxiosAll } from '../../services/axiosAll';
import { ImgCard } from '../../components/SiteEditorTable/imgCard';
import CardPagination from '../../components/universal/CardPagination';
import { notify, truncateText } from '../../core/constants';
import { TableActionBtn } from '../../components/SiteEditorTable/TableActionBtn';
import { images } from '../../assets';
import { Loader } from '../../components/Skeleton/Loader';

export const ProductBadgesList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [collectionList, setCollectionList] = useState([]);
  const [collectionData, setCollectionData] = useState({});
  const [collectionId, setCollectionId] = useState(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getCollectionList = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/badges?per_page=${limit}&page=${page}`, null);
    if (response?.statusCode === 200) {
      setCollectionList(response?.data?.data);
      setCollectionData(response?.data);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const DeleteBadge = async (badgeId) => {
    const response = await AxiosAll('delete', `seller/badges/${badgeId}`);
    if (response?.statusCode === 200) {
      getCollectionList();
      handleClose();
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setCollectionId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setCollectionId(null);
  };

  useEffect(() => {
    if (page > collectionData?.last_page) {
      setPage(collectionData?.last_page);
    }
  }, [collectionData?.last_page, page]);

  useEffect(() => {
    getCollectionList();
  }, [page, limit]);

  return (
    <div className="product-collection">
      <Loader open={loading} />
      <BannerCard title="Product Badges" para={para} smallCard backgroundImageURL={images.dynamicProductsImg} />
      <div className="container-27inch">
        <BlueBtn
          title="Add New"
          customClass="collection-btn"
          onClick={() => navigate('/onboarding/site-editor/add-product-badges')}
        />
        <div className="site-table">
          <div className="site-table">
            <TableContainer component={Paper} className="main-table">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collectionList?.map((row, index) => {
                    return (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left">{truncateText(row?.name, 12)}</TableCell>
                        <TableCell align="left">
                          <ImgCard tableImg={row?.image || images.placeholder} isImageTrue />
                        </TableCell>
                        <TableCell align="left">
                          <TableActionBtn
                            onEdit={() => navigate(`/onboarding/site-editor/add-product-badges/${row?.id}`)}
                            onDelete={() => handleClickOpen(row?.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>{' '}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="delete-pop-up"
            >
              <div className="delete-container">
                <Typography variant="h2">Delete</Typography>
                <Typography paragraph>Do you want to delete this badge?</Typography>
              </div>
              <DialogActions>
                <BlueBtn title="Yes" onClick={() => DeleteBadge(collectionId)} />
                <LightBtn title="No" onClick={handleClose} />
              </DialogActions>
            </Dialog>
          </div>

          {collectionData?.total >= 12 && (
            <CardPagination
              noOfPages={collectionData?.last_page}
              page={page}
              handleChange={handlePagination}
              rowsPerPage={limit}
            />
          )}
        </div>
      </div>
    </div>
  );
};
