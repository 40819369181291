import { Grid } from '@mui/material';
import React from 'react';

const BannerCard = ({ title, para, backgroundImageURL, color, smallCard }) => {
  const colorConfig = {
    width: '100%',
    backgroundColor: color,
  };

  const imageConfig = {
    width: '100%',
    background: `url(${backgroundImageURL})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div
      className={smallCard ? 'welcominnercard' : 'welcomecard'}
      style={backgroundImageURL ? imageConfig : colorConfig}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <h1 className="welcome-new-text">{title} </h1>
          <p className="welcome-para">{para}</p>
        </Grid>
        <Grid item xs={12} sm={3} />
      </Grid>
    </div>
  );
};

export default BannerCard;
