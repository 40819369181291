/* eslint-disable no-shadow */
import { Dialog, DialogActions, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { images } from '../../../assets';
import { ProductSectionCard } from '../../../components/ProductSection/ProductSectionCard';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import DropDown from '../../../components/universal/DropDown';
import TextArea from '../../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import ProductsCard from '../../../components/universal/ProductsCard';
import { UploadSingleImage } from '../../../components/universal/UploadImage';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { Loader } from '../../../components/Skeleton/Loader';

export const AddPopularProducts = () => {
  const route = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [metaImage, setMetaImage] = useState('');
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [existingProducts, setExistingProducts] = useState([]);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  const [product, setProduct] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [openDelete, setOpenDelete] = useState(false);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    description: '',
    filter: 0,
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    filter: Yup.number().min(1, 'Filter is required'),
    metaTitle: Yup.string(),
    metaDescription: Yup.string(),
    metaKeywords: Yup.string(),
  });

  const onSubmit = async (values) => {
    if (productList.length === 0) {
      notify('Please select atleast one product', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('title', values?.title);
    formData.append('description', values?.description);
    formData.append('filter', values?.filter);
    formData.append('seo_meta_title', values?.metaTitle);
    formData.append('seo_meta_description', values?.metaDescription);
    formData.append('seo_meta_keywords', values?.metaKeywords);
    formData.append('seo_meta_image', metaImage);
    if (productList.length > 0) {
      for (let i = 0; i < productList.length; i++) {
        formData.append(`product_ids[${i}]`, productList[i]?.id);
      }
    } else {
      formData.append(`product_ids`, '');
    }

    setLoading(true);

    const endPoint = id ? `seller/popular-products/${id}` : 'seller/popular-products';

    const response = await AxiosAll('post', endPoint, formData);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      // route('/onboarding/site-editor');
      getProductDetails();
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const getProductDetails = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/popular-products`, null);
    if (response.statusCode === 200) {
      const productData = response?.data?.data?.[0];
      formik.setFieldValue('title', productData?.title || '');
      formik.setFieldValue('description', productData?.description || '');
      formik.setFieldValue('filter', productData?.filter || 0);
      formik.setFieldValue('metaTitle', productData?.seo_meta_title || '');
      formik.setFieldValue('metaDescription', productData?.seo_meta_description || '');
      formik.setFieldValue('metaKeywords', productData?.seo_meta_keywords || '');
      setMetaImage(productData?.seo_meta_image || '');
      const selectedId = productData?.products?.map((item) => item?.id) || [];
      setExistingProducts(selectedId);
      setSelectedProduct((prev) => [...prev, ...selectedId]);
      setProductList(productData?.products || []);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...productList];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    const updateImage = { newSections };
    setProductList(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  const getProducts = async () => {
    const response = await AxiosAll('get', `seller/products?&search=${search || ''}`, null);
    if (response?.statusCode === 200) {
      const responseData = response?.data?.data;
      // notify(response.message, 'success');

      setProduct(responseData);
    } else {
      // notify(response.message, 'error');
    }
  };

  const handleDelete = (id) => {
    setProductList(productList?.filter((e) => e.id !== id));
    setOpenDelete(false);
  };

  useEffect(() => {
    if (search !== '') {
      getProducts();
    }
  }, [search]);

  const handleSearch = (e) => {
    if (e?.target?.value) {
      setSearchText(e?.target?.value);
    } else {
      setSearchText('');
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    getProductDetails();
  }, []);

  useEffect(() => {
    if (productList?.length) {
      setProduct([]);
    }
  }, [productList]);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  useEffect(() => {
    if (productList?.length) {
      setProduct([]);
    }
  }, [productList]);

  return (
    <div className="site-Add-popular-products">
      <Loader open={loading} />
      <BannerCard title="Popular Product" para={para} backgroundImageURL={images.dynamicProductsImg} />
      {/* <form onSubmit={formik.handleSubmit}> */}
      <div className="main-section container-27inch">
        <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
        <div className="trending-detail">
          <div className="search-engine-section">
            <Typography variant="h4">Section Details</Typography>
            <Typography paragraph className="description">
              These details will populate the featured products section on your website.
            </Typography>
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Enter"
                    name="title"
                    value={formik?.values?.title}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.title && formik?.errors?.title}
                  />
                </div>
                <div className="meta-des">
                  <Typography>Description</Typography>
                  <TextArea
                    placeholder="Enter"
                    name="description"
                    value={formik?.values?.description}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.description && formik?.errors?.description}
                  />
                </div>
                <div className="meta-des">
                  <Typography>Filter</Typography>
                  <DropDown
                    option={[
                      { id: 1, name: 'Trending' },
                      { id: 2, name: 'Most Selling' },
                      { id: 3, name: 'Large Rating' },
                      { id: 4, name: 'Most Viewed' },
                      { id: 5, name: 'Custom' },
                    ]}
                    name="filter"
                    placholder="Select"
                    value={formik?.values?.filter}
                    handleChange={formik?.handleChange}
                    blur={formik?.handleBlur}
                    touched={formik?.touched?.filter}
                    error={formik?.touched?.filter && formik?.errors?.filter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="padding-left">
          <div className="add-product">
            <Typography>Add Products</Typography>
          </div>
          <div className="product-gallery">
            <Autocomplete
              options={product?.map((prediction) => prediction?.name)}
              noOptionsText="No Products Found"
              inputValue={searchText}
              onInputChange={handleSearch}
              renderInput={(params) => <TextField {...params} label="Add Custom Products" variant="outlined" />}
              renderOption={(option) => <div>{option}</div>}
              getOptionSelected={(option, value) => option === value}
              filterSelectedOptions={true}
              onChange={(event, value) => {
                if (value) {
                  const selectedPrediction = product?.find((prediction) => prediction?.name === value);
                  if (selectedPrediction) {
                    const isDuplicate = productList?.some((product) => product?.id === selectedPrediction.id);
                    if (isDuplicate) {
                      notify('This product has already been added', 'error');
                      return;
                    }
                    const updatedTopPicks = [...productList, selectedPrediction];
                    setProductList(updatedTopPicks);
                  }
                }
              }}
            />{' '}
            {productList?.map((productImg, index) => {
              return (
                <div className="img-container" key={productImg?.id}>
                  <label className="label-file" htmlFor={`myCheckbox${index}`}>
                    <ProductsCard
                      dragStart={(e) => handleDragStart(e, index)}
                      dragOver={(e) => handleDragOver(e, index)}
                      dragEnd={() => setDraggedOverIndex(null)}
                      dropCard={(e) => handleDrop(e, index)}
                      image={productImg?.cover_image?.image}
                      name={productImg?.name}
                      copyUrl={productImg?.banner_image}
                      id={productImg?.id}
                      isImages
                    />
                  </label>
                  {/* <Checkbox
                        className="checkbox-style"
                        {...Label}
                        id={`myCheckbox${index}`}
                        checkedIcon={<CheckCircleIcon />}
                        checked={!!selectedProductIds?.ids?.includes(productImg?.id)}
                        value={productImg?.id}
                        onClick={(e) => handleCheckbox(productImg?.id, e)}
                        icon={<RadioButtonUncheckedIcon />}
                      /> */}

                  {productList?.length > 0 ? (
                    <DeleteIcon
                      onClick={() => {
                        setOpenDelete(true);
                        setDeleteId(productImg?.id);
                      }}
                    />
                  ) : null}
                </div>
              );
            })}
            <Dialog
              open={openDelete}
              onClose={() => {
                setOpenDelete(false);
                setDeleteId(null);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="delete-pop-up"
            >
              <div className="delete-container">
                <Typography variant="h2">Delete</Typography>
                <Typography paragraph>Are you sure you want to delete this product?</Typography>
              </div>
              <DialogActions>
                <BlueBtn title="Yes" onClick={() => handleDelete(deleteId)} />
                <LightBtn
                  title="No"
                  onClick={() => {
                    setOpenDelete(false);
                    setDeleteId(null);
                  }}
                />
              </DialogActions>
            </Dialog>
            {/* <CardPagination
                page={page}
                noOfPages={productImages?.last_page}
                handleChange={(e, value) => setPage(value)}
                rowsPerPage={productImages?.per_page}
              /> */}
          </div>
          {formik?.touched?.product && formik?.errors?.product && (
            <div className="input-error-message">{formik?.errors?.product}</div>
          )}
        </div>

        <div className="search-engine-section">
          <Typography variant="h4">Search Engine Optimisation</Typography>
          <Typography paragraph className="description">
            Product SEO is the practice of optimising elements of product to provide search engines with enough relevant
            content to get the product found in search engines.
          </Typography>

          <div className="input-title">
            <div className="meta-info">
              <div className="meta-title">
                <Typography>Meta Title</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="metaTitle"
                  value={formik?.values?.metaTitle}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.metaTitle && formik?.errors?.metaTitle}
                />
              </div>
              <div className="meta-des">
                <Typography>Meta Description</Typography>
                <TextArea
                  placeholder="Enter"
                  name="metaDescription"
                  value={formik?.values?.metaDescription}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.metaDescription && formik?.errors?.metaDescription}
                />
              </div>
              <div className="meta-keywords">
                <Typography>Meta Keywords</Typography>
                <TextArea
                  placeholder="Enter"
                  name="metaKeywords"
                  value={formik?.values?.metaKeywords}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.metaKeywords && formik?.errors?.metaKeywords}
                />
              </div>
              <div className="upload-single-image-com">
                <UploadSingleImage
                  title="Meta Image"
                  description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                  image={metaImage}
                  setImage={setMetaImage}
                  imageSize={2}
                />
              </div>
            </div>
          </div>
        </div>

        {/* {productList?.map((productImg, index) => {
            return (
              <div className="img-container" key={index}>
                <ProductsCard
                  image={productImg?.cover_image?.image}
                  name={productImg?.name}
                  copyUrl={productImg?.cover_image?.image}
                  handleDelete={() => {
                    handleDelete(productImg?.id);
                  }}
                  id={productImg?.id}
                  isImages
                  hideEdit
                />
              </div>
            );
          })} */}

        <div className="buttons">
          <div className="buttons-position">
            <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor')} />
            <BlueBtn title="Save" type="submit" onClick={formik.handleSubmit} loading={loading} disabled={loading} />
          </div>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
};
