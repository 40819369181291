/* eslint-disable no-debugger */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import { notify } from '../../../core/constants';
import { AxiosAll, axiosAll } from '../../../services/axiosAll';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import SubHeading from '../../universal/Headings/SubHeading';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import DropDown from '../../universal/DropDown';

const DispatchOrderDialog = ({ order_id, OrdersList, open, setOpen, courier, CourierClass }) => {
  const [trackingNo, setTrackingNo] = useState('');
  const [OrdersIds, setOrdersIds] = useState(order_id);
  const [loading, setloading] = useState(false);
  const [status, setStatus] = useState(0);
  const [curier, setCurier] = useState();
  const [curierClass, setCurierClass] = useState();

  const handleDispatch = async (Status) => {
    if (status === 0) {
      notify('Please select a product', 'error');
      return;
    }
    if (trackingNo === '') {
      notify('Please enter tracking number', 'error');
      return;
    }
    setloading(true);
    const formData = new FormData();
    formData.append('tracking_no', trackingNo);

    const response = await AxiosAll('post', `seller/orders-dispatch/${status}`, formData);
    if (response?.statusCode === 200) {
      // setOrder(response?.data);
      notify(response?.message, 'success');
      setloading(false);
      setOpen(false);
    } else {
      setloading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setTrackingNo(([e.target.name] = e.target.value));
  };

  const handleStatus = (e) => {
    const { value } = e.target;
    const object = OrdersList.find((a) => a.value === value);
    setStatus(value);
  };

  useEffect(() => {
    if (OrdersList?.length === 1) {
      setStatus(OrdersList[0]?.value);
    }
  }, [OrdersList]);

  return (
    <div className="last-btn-section-container">
      <Button variant="contained" className="darkbtn" onClick={handleClickOpen}>
        Mark as Dispatch
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dispatchorderdialogs"
      >
        <DialogContent>
          <SubHeading title="Dispatch order" />
          <div className="heading-para">
            {' '}
            Your customer will get shipping updates via email. Please provide the tracking number which we will share
            with them.{' '}
          </div>
          <div className="label-text">Product list</div>
          <DropDown
            option={OrdersList}
            handleChange={handleStatus}
            value={status}
            placholder="Select"
            customClass="dispatch-dropdown"
          />
          <div className="heading-id">
            Courier : <span style={{ fontWeight: 'bold' }}>{OrdersList[0]?.shipping_name}</span>
          </div>
          <div className="heading-id">
            Courier Class: <span style={{ fontWeight: 'bold' }}>{OrdersList[0]?.shipping_class}</span>
          </div>
          <div className="label-text">Tracking number</div>
          <InputTypeOutline name="trackingno" value={trackingNo} onChange={handleChange} type="text" />
          <div className="btn-section">
            <LightBtn onClick={handleClose} autoFocus title="Cancel" />
            <span className="disable-add-btn">
              <DarkBtn title="Add" onClick={handleDispatch} loading={loading} />
            </span>
            {/* ADD Condition  */}

            {/* <DarkBtn
              title={loading ? 'Loading...' : 'Add'}
              disabled={loading}
              onClick={() => {
                if (trackingno === '') {
                  notify('Please enter tracking number', 'error');
                } else {
                  handleDispatchItem(OrdersIds, trackingno);
                }
              }}
            /> */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DispatchOrderDialog;
