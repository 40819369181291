import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import EditMultiSelect from '../../../components/universal/EditProduct/EditMultiSelect';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { Loader } from '../../../components/Skeleton/Loader';

export const AddBlogSection = () => {
  const route = useNavigate();

  const [loading, setLoading] = useState(false);
  const [blogsList, setBlogsList] = useState([]);
  const [selectedBlogs, setSelectedBlogs] = useState([]);
  const [selectedDesktopBlogs, setSelectedDesktopBlogs] = useState([]);
  const [selectedTabletBlogs, setSelectedTabletBlogs] = useState([]);
  const [selectedMobileBlogs, setSelectedMobileBlogs] = useState([]);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const { id } = useParams();
  const { userDetails } = useSelector((state) => state.loginReducer);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    link: '',
    desktopTitle: '',
    desktopLink: '',
    tabletTitle: '',
    tabletLink: '',
    mobileTitle: '',
    mobileLink: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    link: Yup.string().url('Link is not valid').required('Link is required'),
    // desktopTitle: Yup.string().required('Title is required'),
    // desktopLink: Yup.string().url('Link is not valid').required('Link is required'),
    // tabletTitle: Yup.string().required('Title is required'),
    // tabletLink: Yup.string().url('Link is not valid').required('Link is required'),
    // mobileTitle: Yup.string().required('Title is required'),
    // mobileLink: Yup.string().url('Link is not valid').required('Link is required'),
  });

  const getBlogDetails = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/sellerSections/${id}?section_type=blogs`, null);
    if (response.statusCode === 200) {
      const blogData = response.data?.blog;
      formik.setFieldValue('title', blogData?.title || '');
      formik.setFieldValue('link', blogData?.link || '');
      formik.setFieldValue('desktopTitle', blogData?.title_web || '');
      formik.setFieldValue('desktopLink', blogData?.link_web || '');
      formik.setFieldValue('tabletTitle', blogData?.title_tablet || '');
      formik.setFieldValue('tabletLink', blogData?.link_tablet || '');
      formik.setFieldValue('mobileTitle', blogData?.title_mobile || '');
      formik.setFieldValue('mobileLink', blogData?.link_mobile || '');
      const blogList = blogData?.blogs?.map((item) => ({ ...item, name: item.title }));
      setSelectedBlogs(blogList || []);
      const desktopBlogList = blogData?.web_blogs?.map((item) => ({ ...item, name: item.title }));
      setSelectedDesktopBlogs(desktopBlogList || []);
      const tabletBlogList = blogData?.tablet_blogs?.map((item) => ({ ...item, name: item.title }));
      setSelectedTabletBlogs(tabletBlogList || []);
      const mobileBlogList = blogData?.mobile_blogs?.map((item) => ({ ...item, name: item.title }));
      setSelectedMobileBlogs(mobileBlogList || []);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    if (selectedBlogs.length === 0) {
      notify('Please select atleast one blog', 'error');
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append('title', values?.title);
      formData.append('link', values?.link);
      for (let i = 0; i < selectedBlogs.length; i++) {
        formData.append(`blog_ids[${i}]`, selectedBlogs[i]?.id);
      }
      formData.append('title_web', values?.desktopTitle);
      formData.append('link_web', values?.desktopLink);
      for (let i = 0; i < selectedDesktopBlogs.length; i++) {
        formData.append(`web_blog_ids[${i}]`, selectedDesktopBlogs[i]?.id);
      }
      formData.append('title_tablet', values?.tabletTitle);
      formData.append('link_tablet', values?.tabletLink);
      for (let i = 0; i < selectedTabletBlogs.length; i++) {
        formData.append(`tablet_blog_ids[${i}]`, selectedTabletBlogs[i]?.id);
      }
      formData.append('title_mobile', values?.mobileTitle);
      formData.append('link_mobile', values?.mobileLink);
      for (let i = 0; i < selectedMobileBlogs.length; i++) {
        formData.append(`mobile_blog_ids[${i}]`, selectedMobileBlogs[i]?.id);
      }

      const endPoint = id
        ? `seller/sellerSections/${id}?section_type=blogs&_method=PUT`
        : 'seller/sellerSections?section_type=blogs';

      const response = await AxiosAll('post', endPoint, formData);
      if (response.statusCode === 200) {
        notify(response.message, 'success');
        route('/onboarding/site-editor');
        setLoading(false);
      } else {
        notify(response.message, 'error');
        setLoading(false);
      }
    }
  };

  const getDropdowns = async () => {
    const response = await AxiosAll('get', `dropdowns?type=blogs&seller_id=${userDetails?.user?.id}`);
    if (response.statusCode === 200) {
      const dropdownData = response.data;
      setBlogsList(dropdownData);
    }
  };

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedBlogs];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedBlogs(newSections);
    setDraggedOverIndex(null);
  };

  const handleDropTablet = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedTabletBlogs];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedTabletBlogs(newSections);
    setDraggedOverIndex(null);
  };

  const handleDropDesktop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedDesktopBlogs];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedDesktopBlogs(newSections);
    setDraggedOverIndex(null);
  };

  const handleDropMobile = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedMobileBlogs];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedMobileBlogs(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getBlogDetails();
    }
  }, [blogsList]);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  return (
    <div className="site-blog">
      <Loader open={loading} />
      <BannerCard title="Blog Section" para={para} backgroundImageURL={images.latestArrivalImg} smallCard />
      <div className="main-section container-27inch">
        <div className="section-editor">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="search-engine-section">
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && formik.errors.title}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Link</Typography>
                  <InputTypeOutline
                    placeholder="Link"
                    name="link"
                    value={formik.values.link}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.link && formik.errors.link}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Blogs</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={blogsList}
                      selectedBadges={selectedBlogs}
                      setSelectedBadges={setSelectedBlogs}
                      limit={100}
                    />
                  </div>
                </div>
                {selectedBlogs?.length > 0 && (
                  <div className="meta-title wrapper">
                    {selectedBlogs?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDrop(e, index)}
                        draggable
                      >
                        <img src={item.image} alt="blog" height={100} width={100} />
                        <Typography>{item?.name}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedBlogs = selectedBlogs.filter((badge) => badge !== item);
                            setSelectedBlogs(newSelectedBlogs);
                          }}
                        >
                          <img src={images.trashBtn} alt="close" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* desktop section */}
          <div className="search-engine-section">
            <Typography variant="h4">Desktop</Typography>
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="desktopTitle"
                    value={formik.values.desktopTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.desktopTitle && formik.errors.desktopTitle}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Link</Typography>
                  <InputTypeOutline
                    placeholder="Link"
                    name="desktopLink"
                    value={formik.values.desktopLink}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.desktopLink && formik.errors.desktopLink}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Blogs</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={blogsList}
                      selectedBadges={selectedDesktopBlogs}
                      setSelectedBadges={setSelectedDesktopBlogs}
                      limit={100}
                    />
                  </div>
                </div>
                {selectedDesktopBlogs?.length > 0 && (
                  <div className="meta-title wrapper">
                    {selectedDesktopBlogs?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDropDesktop(e, index)}
                        draggable
                      >
                        <img src={item.image} alt="blog" height={100} width={100} />
                        <Typography>{item?.name}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedBlogs = selectedDesktopBlogs.filter((badge) => badge !== item);
                            setSelectedDesktopBlogs(newSelectedBlogs);
                          }}
                        >
                          <img src={images.trashBtn} alt="close" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* tablet section */}
          <div className="search-engine-section">
            <Typography variant="h4">Tablet</Typography>
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="tabletTitle"
                    value={formik.values.tabletTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tabletTitle && formik.errors.tabletTitle}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Link</Typography>
                  <InputTypeOutline
                    placeholder="Link"
                    name="tabletLink"
                    value={formik.values.tabletLink}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tabletLink && formik.errors.tabletLink}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Blogs</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={blogsList}
                      selectedBadges={selectedTabletBlogs}
                      setSelectedBadges={setSelectedTabletBlogs}
                      limit={100}
                    />
                  </div>
                </div>
                {selectedTabletBlogs?.length > 0 && (
                  <div className="meta-title wrapper">
                    {selectedTabletBlogs?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDropTablet(e, index)}
                        draggable
                      >
                        <img src={item.image} alt="blog" height={100} width={100} />
                        <Typography>{item?.name}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedBlogs = selectedTabletBlogs.filter((badge) => badge !== item);
                            setSelectedTabletBlogs(newSelectedBlogs);
                          }}
                        >
                          <img src={images.trashBtn} alt="close" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* mobile section */}
          <div className="search-engine-section">
            <Typography variant="h4">Mobile</Typography>
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="mobileTitle"
                    value={formik.values.mobileTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.mobileTitle && formik.errors.mobileTitle}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Link</Typography>
                  <InputTypeOutline
                    placeholder="Link"
                    name="mobileLink"
                    value={formik.values.mobileLink}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.mobileLink && formik.errors.mobileLink}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Blogs</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={blogsList}
                      selectedBadges={selectedMobileBlogs}
                      setSelectedBadges={setSelectedMobileBlogs}
                      limit={100}
                    />
                  </div>
                </div>
                {selectedMobileBlogs?.length > 0 && (
                  <div className="meta-title wrapper">
                    {selectedMobileBlogs?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDropMobile(e, index)}
                        draggable
                      >
                        <img src={item.image} alt="blog" height={100} width={100} />
                        <Typography>{item?.name}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedBlogs = selectedMobileBlogs.filter((badge) => badge !== item);
                            setSelectedMobileBlogs(newSelectedBlogs);
                          }}
                        >
                          <img src={images.trashBtn} alt="close" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor')} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
