/* eslint-disable no-debugger */
import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OrderSkeleton from '../../../components/Skeleton/OrderSkeleton';
import DropDown from '../../../components/universal/DropDown';
import MainHeading from '../../../components/universal/Headings/MainHeading';
import BasicPagination from '../../../components/universal/Pagination';
import SearchBar from '../../../components/universal/SearchBar';
import { OrderOption } from '../../../core/constants';
import { BasicTable as OrderTable } from './OrderTable';
import { AxiosAll, axiosAll } from '../../../services/axiosAll';
import BannerCard from '../../../components/universal/WelcomeCard';
import { CustomerOrderTable } from '../../CustomeroderTable';

const Orders = () => {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const TableColumn = ['Order no', 'Status', 'Order date', 'Gross sales (£)', ' No. of items'];
  const [order, setOrder] = useState();
  const [page, setPage] = useState(0);
  const [paginationPage, setPaginationPage] = React.useState(0);
  const [searchProduct, setSearch] = useState();
  const [searchText, setSearchText] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [Loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [statusList, setStatusList] = useState([]);

  const getOrder = async (Status) => {
    setLoading(true);
    // setPage(page + 1);
    const params = {
      search: searchProduct || '',
      per_page: 10,
      searchByOrderStatus: 2,
    };

    const response = await AxiosAll(
      'get',
      `seller/orders?search=${searchProduct || ''}&page=${page + 1}&per_page=10&searchByOrderStatus=${status}`,
      null,
    );
    if (response?.statusCode === 200) {
      setOrder(response?.data);
    }
    setLoading(false);
  };

  const getDropdowns = async () => {
    const response = await AxiosAll('get', `dropdowns?seller_id=${userDetails?.user?.id}&type[]=order_statuses`);
    if (response.statusCode === 200) {
      const filterdata = response?.data?.order_statuses.filter((e, index) => e.id !== 1);
      setStatusList(filterdata);
    }
  };

  const handleSearch = (e) => {
    if (page > 0) {
      setPage(0);
    }
    const { value } = e.target;
    setSearchText(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleStatus = (e) => {
    if (page > 0) {
      setPage(0);
    }
    const { value } = e.target;
    setStatus(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPaginationPage(0);
    setPage(page + 1);
    getOrder(rowsPerPage);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
      setPage(0);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    getOrder(status);
  }, [searchProduct, status, page]);

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <div className="sellerorder">
      <BannerCard
        title="Order Management"
        para="This is where you can view and upload photos of your products. When bulk uploading products you need to upload your images here and reference the link in your CSV."
        smallCard
      />
      <Container className="all-container-27inch">
        <div className="sellerordercontainer">
          {/* <MainHeading title="Orders" /> */}
          <div className="order-row">
            <SearchBar handleChange={handleSearch} value={searchText} />
            <DropDown
              option={statusList}
              handleChange={handleStatus}
              value={status}
              placholder="all-statuses"
              enablePlaceholder
            />
          </div>
          {Loading ? (
            <OrderSkeleton />
          ) : (
            <>
              {' '}
              <CustomerOrderTable
                TableColumn={TableColumn}
                Row={order?.data}
                page={paginationPage}
                rowsPerPage={rowsPerPage}
                path="/onboarding/order-details"
                name="Order"
              />
            </>
          )}

          {/* eslint-disable-next-line no-unsafe-optional-chaining */}
          {Math.floor(order?.total / 10) > 1 ? (
            <BasicPagination
              page={page}
              setPage={setPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              // Row={order?.data}
              total={order?.total}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          ) : (
            ''
          )}
        </div>
      </Container>
    </div>
  );
};

export default Orders;
