import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';
import { useNetwork } from './core/network';
import NoInternet from './pages/NoInternet';
import { persistor, store } from './redux/store';
import Routes from './routing';

const App = () => {
  return useNetwork() ? (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  ) : (
    <NoInternet />
  );
};

export default App;
