import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';
import { notify } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';
import PendingBtn from '../../universal/Buttons/PendingBtn';
import DropDown from '../../universal/DropDown';

const AddToDialog = ({
  subsectionValue,
  AddToOptionValue,
  handleChange,
  handleProductDetail,
  id,
  sectionId,
  setSubsectionValue,
  setToOptionValue,
  setToOption,
  AddToOption,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (id) {
      handleProductDetail(id);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setToOptionValue('');
    setToOption([]);
  };

  const handleAddTO = async () => {
    const AddTo = {
      sectionId,
      subsectionId: AddToOptionValue,
    };
    const response = await axiosAll({
      method: 'put',
      url: `v2/products/${id}/addSubsection`,
      data: AddTo,
    });
    if (response?.statusCode === 200) {
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }
  };

  const handleSubmit = () => {
    handleAddTO();
    handleClose();
  };

  // useEffect(() => {
  //   if (open) {
  //     handleProductDetail(id);
  //   }
  // }, [open]);

  return (
    <div className="AddTo">
      <Button className="pendingbtn" onClick={handleClickOpen}>
        AddTo
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Add-To"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="input-heading">
              <h2 className="upload-text">Choose subsection to add product to</h2>
              {AddToOption && AddToOptionValue ? (
                <div className="heading">
                  <DropDown option={AddToOption} value={AddToOptionValue} handleChange={handleChange} />
                </div>
              ) : (
                'subsection Loading...'
              )}
              <PendingBtn title="Submit" onClick={handleSubmit} />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddToDialog;
