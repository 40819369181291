import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { images } from '../../../../assets';

const CategoryDropDown = ({ options, defaultSelect, onChange, value, name, disabled, onClick }) => {
  return (
    <div className="selectbox">
      <FormControl className="selectboxwidth">
        <Select
          defaultValue={defaultSelect?.value}
          style={{
            backgroundImage: `url(${images.errowDown})`,
          }}
          value={`${value}`}
          className="select-field"
          onChange={onChange}
          name={name}
          disabled={disabled}
          onClick={onClick}
        >
          <MenuItem disabled value={defaultSelect?.value}>
            {defaultSelect?.name}
          </MenuItem>
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.id}>
              {option?.name ? option?.name : option?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CategoryDropDown;
