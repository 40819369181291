/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CategoryCard } from '../../components/CategoriesHeader/categoryCard';
import { BlueBtn } from '../../components/universal/Buttons/BlueBtn';
import BannerCard from '../../components/universal/WelcomeCard';
import { AxiosAll } from '../../services/axiosAll';

import { ProductSectionCard } from '../../components/ProductSection/ProductSectionCard';
import CategoryAdd from '../../components/dialogs/CategoryAdd';
import { notify } from '../../core/constants';
import Editor from '../../components/universal/InputType/Editor';
import { Loader } from '../../components/Skeleton/Loader';

export const CategoriesHeader = () => {
  const [MainCategory, setMainCategory] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [step, setStep] = useState(false);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const para =
    'Here you can configure your website using our preset website MainCategory, at any time you can view your work by clicking on the ‘view website’.';
  const { userDetails } = useSelector((state) => state.loginReducer);
  const AddCategory = async (value, id) => {
    setisLoading(true);
    const response = await AxiosAll(
      id === 0 ? 'post' : 'put',
      id === 0 ? 'seller/categories' : `seller/categories/${id}`,
      {
        parent_id: 0,
        name: value,
        status: 1,
      },
      userDetails?.token,
    );
    if (response?.statusCode === 200) {
      setisLoading(false);
      notify(response.message, 'success');
      getMainCategories();
    } else {
      setisLoading(false);

      notify(response.message, 'error');
    }
  };

  const getMainCategories = async () => {
    setisLoading(true);
    const response = await AxiosAll('get', 'seller/categories?parent_id=0', null, userDetails?.token);
    setStep(false);
    setMainCategory(response.data?.data);
    setisLoading(false);
  };

  const deleteCategory = async (id) => {
    setisLoading(true);
    const response = await AxiosAll('delete', `seller/categories/${id}`, null, userDetails?.token);
    if (response?.statusCode === 200) {
      setisLoading(false);
      notify(response.message, 'success');
      getMainCategories();
    } else {
      setisLoading(false);

      notify(response.message, 'error');
    }
  };

  const SaveOrder = async (ReorderedSections) => {
    setisLoading(true);
    const formData = new FormData();
    if (ReorderedSections?.length > 0) {
      for (let i = 0; i < ReorderedSections?.length; i++) {
        formData.append(`category_ids[${i}]`, ReorderedSections[i]?.id);
      }
    } else {
      formData.append(`category_ids`, '');
    }
    const response = await AxiosAll('post', 'seller/categories-order-by', formData);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      getMainCategories();
      setisLoading(false);
    } else {
      notify(response.message, 'error');
      setisLoading(false);
    }
  };

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...MainCategory];

    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);

    setMainCategory(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };
  useEffect(() => {
    if (step) {
      getMainCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    getMainCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="categories-header">
      <Loader open={isLoading} />
      <BannerCard title="Categories" para={para} smallCard />
      <div className="main-section container-27inch">
        <div className="categorybtn">
          <div className="save-create">
            <CategoryAdd AddCategory={AddCategory} titleName="  Menu " />
            {MainCategory?.length > 0 ? (
              <BlueBtn title="Save Order" onClick={() => SaveOrder(MainCategory)} customClass="save-order" />
            ) : null}
          </div>
        </div>

        {/* <Editor /> */}

        <div className="menu-list">
          {isLoading ? (
            ''
          ) : MainCategory?.length > 0 ? (
            MainCategory?.map((item, index) => {
              return (
                <CategoryCard
                  dragStart={(e) => handleDragStart(e, index)}
                  dragOver={(e) => handleDragOver(e, index)}
                  dragEnd={() => setDraggedOverIndex(null)}
                  dropCard={(e) => handleDrop(e, index)}
                  setStep={setStep}
                  key={item?.id}
                  data={item}
                  getMainCategories={getMainCategories}
                  AddCategory={AddCategory}
                  deleteCategory={deleteCategory}
                />
              );
            })
          ) : (
            <div
              style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Typography style={{ fontSize: 28 }}>No Categories have been created yet.</Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
