import { useNavigate } from 'react-router';
import { images } from '../../assets';
import { BlueBtn } from '../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import { UploadArrowDraggableContainer } from '../../components/universal/UploadArrowDraggableContainer';
import BannerCard from '../../components/universal/WelcomeCard';

export const ThemeBanner = () => {
  const route = useNavigate();
  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';
  return (
    <div className="theme-banner">
      <BannerCard title="Site Editor" para={para} smallCard />
      <div className="main-section container-27inch">
        <BlueBtn title="Return" customClass="return-btn" onClick={() => route(-1)} />
        <BlueBtn title="Add more" customClass="return-btn" />

        <UploadArrowDraggableContainer dec={para} imagePreview={images.sitePreview} imageTrash={images.trashBtn} />

        <div className="buttons">
          <div className="buttons-position">
            <LightBtn title="Cancel" />
            <BlueBtn title="Save" />
          </div>
        </div>
      </div>
    </div>
  );
};
