import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { useNavigate } from 'react-router';
import { images } from '../../../assets';
// eslint-disable-next-line import/order
import { Link } from 'react-router-dom';
import { AccordionMenu } from '../../universal/Accordian';

export const buttonDatas = [
  {
    Section: 'Category',
    Link: '/onboarding/site-editor/categories-header',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Size',
    Link: '/onboarding/site-editor/sizes',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Colour',
    Link: '/onboarding/site-editor/colors',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Product Badges',
    Link: '/onboarding/site-editor/product-badges',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Home Screen',
    Link: '/onboarding/site-editor',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Shop Collection',
    Link: '/onboarding/site-editor/product-collection',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Feature Product',
    Link: '/onboarding/site-editor/feature-products',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Popular Product',
    Link: '/onboarding/site-editor/popular-products',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Common Banner',
    Link: '/onboarding/site-editor/banners',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
    dropdown: [
      {
        Section: 'seller banners',
        Link: '/onboarding/site-editor/seller_banners',
        NameStatusAction: '',
        Active: true,
        Inactive: false,
      },
      // {
      //   Section: 'add banner',
      //   Link: '/onboarding/site-editor/add_seller_banners',
      //   NameStatusAction: '',
      //   Active: true,
      //   Inactive: false,
      // },
      {
        Section: 'feature product',
        Link: '/onboarding/site-editor/feature-product-banners',
        NameStatusAction: '',
        Active: true,
        Inactive: false,
      },
      {
        Section: 'theme base contant',
        Link: '/onboarding/site-editor/theme-based-content-banners',
        NameStatusAction: '',
        Active: true,
        Inactive: false,
      },
      {
        Section: 'latest arrival',
        Link: '/onboarding/site-editor/latest-arrival-banners',
        NameStatusAction: '',
        Active: true,
        Inactive: false,
      },
      {
        Section: 'dynamic block',
        Link: '/onboarding/site-editor/dynamic-block-banners',
        NameStatusAction: '',
        Active: true,
        Inactive: false,
      },
      // {
      //   Section: 'All Product',
      //   Link: '/onboarding/site-editor/product-banners',
      //   NameStatusAction: '',
      //   Active: true,
      //   Inactive: false,
      // },
      {
        Section: 'trending',
        Link: '/onboarding/site-editor/trending-banners',
        NameStatusAction: '',
        Active: true,
        Inactive: false,
      },
    ],
  },
  {
    Section: 'Latest Blog',
    Link: '/onboarding/site-editor/blogs-list',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },

  {
    Section: 'Feedback',
    Link: '/onboarding/site-editor/feedbacks',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Testimonials',
    Link: '/onboarding/site-editor/testimonial-list',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  // {
  //   Section: 'Instagram',
  //   Link: '/onboarding/site-editor/instagram',
  //   NameStatusAction: '',
  //   Active: true,
  //   Inactive: false,
  // },
  {
    Section: 'Shipping',
    Link: '/onboarding/site-editor/shippings',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Processing Time',
    Link: '/onboarding/site-editor/shippings/processing-time',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Available Countries',
    Link: '/onboarding/site-editor/shippings/countries',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
  {
    Section: 'Links',
    Link: '/onboarding/site-editor/links',
    NameStatusAction: '',
    Active: true,
    Inactive: false,
  },
];
export const BasicAccordion = ({ menuName, toggleDrawer, anchor, handleChange }) => {
  const route = useNavigate();

  return (
    <div className="main-accordion item2">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <span className="sidebar-new-img">
            <img alt="" src={images.siteEditor} className="new-sidebar" />
          </span>
          <Typography>{menuName}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <ul>
            {buttonDatas.map((buttonData) => {
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li key={buttonData.Section} className="item2">
                  {handleChange ? (
                    <Link to={buttonData?.Link} onClick={() => handleChange(anchor, false)}>
                      {buttonData.Section}
                    </Link>
                  ) : (
                    <div className="test">
                      {buttonData.dropdown && buttonData.dropdown?.length > 0 ? (
                        <AccordionMenu menuName={buttonData.Section} options={buttonData.dropdown} />
                      ) : (
                        <Link to={buttonData?.Link}>{buttonData.Section}</Link>
                      )}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
