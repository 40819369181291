/* eslint-disable react/no-unknown-property */
/* eslint-disable no-debugger */
/* eslint-disable no-shadow */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import 'react-quill/dist/quill.snow.css';
import { images } from '../../../assets';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import { Empty } from '../../../components/universal/Empty';
import TextArea from '../../../components/universal/InputType/TextArea';
import { UploadSingleImage } from '../../../components/universal/UploadImage';
import BannerCard from '../../../components/universal/WelcomeCard';
import { formats, modules, notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import Editor from '../../../components/universal/InputType/Editor';
import { Loader } from '../../../components/Skeleton/Loader';
import LightBtn from '../../../components/universal/Buttons/LightBtn';

export const ShopByValue = () => {
  const route = useNavigate();
  const { id } = useParams();
  const [existingProducts, setExistingProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedShopByValueId, setSelectedShopByValueId] = useState([]);
  const [AllShopByValue, setShopByValue] = useState();
  const [selected, setSelected] = useState(0);
  const [selectedProductIds, setSelectedProductsIds] = useState({
    ids: [],
  });
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  const [imageMain, setImage] = useState('');
  const [imageMobile, setImageMobile] = useState('');
  const [imageTablet, setImageTablet] = useState('');
  const [imageWeb, setImageWeb] = useState('');

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    description: '',
    description_mobile: '',
    description_tablet: '',
    description_web: '',
    image: '',
  };

  const validationSchema = Yup.object({
    description: Yup.string().required('Description is required'),
    image: Yup.string().required('Image is required '),
  });

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values?.title);
    formData.append('description', values?.description);
    formData.append('description_mobile', values?.description_mobile);
    formData.append('description_tablet', values?.description_tablet);
    formData.append('description_web', values?.description_web);
    formData.append('image', imageMain);
    formData.append('image_mobile', imageMobile);
    formData.append('image_tablet', imageTablet);
    formData.append('image_web', imageWeb);
    if (AllShopByValue.length > 0) {
      for (let i = 0; i < AllShopByValue.length; i++) {
        formData.append(`shop_by_value_ids[${i}]`, AllShopByValue[i]?.id);
      }
    } else {
      formData.append(`shop_by_value_ids`, '');
    }

    setLoading(true);
    try {
      const endPoint = id
        ? `seller/sellerSections/${id}?section_type=shop_by_value&_method=PUT`
        : `seller/sellerSections?section_type=shop_by_value`;

      const response = await AxiosAll('post', endPoint, formData);
      if (response.statusCode === 200) {
        notify(response.message, 'success');
        setLoading(false);
        route('/onboarding/site-editor');
      } else {
        notify(response.message, 'error');
      }
    } catch (err) {
      // seterror(true);
      // notify(err?.message, 'error');

      console.log(err);
      setLoading(false);
    }
  };

  const getAllShopByValue = async () => {
    const response = await AxiosAll('get', `seller/badges`, null);
    if (response.statusCode === 200) {
      const productData = response?.data?.data;

      setShopByValue(productData);
    } else {
      notify(response.message, 'error');
    }
  };

  const GetShopByValueDetail = async () => {
    setLoading(true);
    const res = await AxiosAll('get', `seller/sellerSections/${id}?section_type=shop_by_value`, null);

    if (res.statusCode === 200) {
      const ShopbyValue = res?.data?.shop_by_value;
      formik.setFieldValue('title', ShopbyValue?.title || '');
      formik.setFieldValue('description', ShopbyValue?.description || '');
      formik.setFieldValue('description_mobile', ShopbyValue?.description_mobile || '');
      formik.setFieldValue('description_tablet', ShopbyValue?.description_tablet || '');
      formik.setFieldValue('description_web', ShopbyValue?.description_web || '');
      formik.setFieldValue('image', ShopbyValue?.image || '');
      setImage(ShopbyValue?.image);
      setImageMobile(ShopbyValue?.image_mobile);
      setImageTablet(ShopbyValue?.image_tablet);
      setImageWeb(ShopbyValue?.image_web);
      setSelectedProductsIds({
        ids: [...selectedProductIds.ids, ...(ShopbyValue?.shop_by_values?.map((e) => e?.id) || [])],
      });
      setSelectedShopByValueId(ShopbyValue?.shop_by_values?.map((e) => e?.id));
      setShopByValue(ShopbyValue?.shop_by_values);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      GetShopByValueDetail();
    }
  }, [id]);

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...AllShopByValue];

    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setDraggedOverIndex(null);

    setShopByValue(newSections);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };
  const handleCheckbox = (id, e) => {
    const { checked } = e.target;

    if (checked) {
      setSelected((prevState) => {
        return prevState + 1;
      });
      setSelectedProductsIds({
        ids: [...selectedProductIds.ids, id],
      });
      setSelectedShopByValueId((prevState) => [...prevState, id]);
      // formik?.setFieldValue("coverImages", [...selectedProductIds.ids, id]);
    } else {
      setSelected((prevState) => {
        return prevState - 1;
      });
      setSelectedProductsIds({
        ids: selectedProductIds.ids.filter((item) => item !== id),
      });
      setSelectedShopByValueId((prevState) => prevState.filter((item) => item !== id));
      // formik?.setFieldValue(
      //   "coverImages",
      //   selectedProductIds.ids.filter((item) => item !== id)
      // );
    }

    // getProductImage(searchProduct);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!id) {
      getAllShopByValue();
    }
  }, [id]);

  useEffect(() => {
    formik.setFieldValue('image', imageMain);
  }, [imageMain]);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);
  // console.log('formik', formik);
  const [copyText, setCopyText] = useState('Copy image link to clipboard');
  return (
    <div className="shop-by-value">
      <Loader open={loading} />
      <BannerCard title="Shop By Value" para={para} backgroundImageURL={images.bestSellerImg} smallCard />
      <form onSubmit={formik.handleSubmit}>
        <div className="main-section container-27inch">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route(-1)} />
          <div className="trending-detail">
            <div className="search-engine-section">
              <div className="input-title">
                <div className="meta-info">
                  <div className="single-upload">
                    <UploadSingleImage
                      title="Image"
                      description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={imageMain}
                      setImage={setImage}
                      imageSize={2}
                    />
                    {formik?.touched?.image && formik?.errors?.image && (
                      <div className="input-error-message">{formik?.errors?.image}</div>
                    )}
                  </div>

                  <div className="meta-des">
                    <Typography>Description</Typography>
                    <TextArea
                      placeholder="Enter"
                      name="description"
                      value={formik?.values?.description}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.description && formik?.errors?.description}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="main-badge-box">
              {AllShopByValue?.length > 0 ? (
                AllShopByValue?.map((productImg, index) => {
                  return (
                    <div
                      className="badges"
                      key={index}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOver(e, index)}
                      onDragEnd={() => setDraggedOverIndex(null)}
                      onDrop={(e) => handleDrop(e, index)}
                    >
                      {/* <label className="label-file" htmlFor={`myCheckbox${index}`}>
                          <ProductsCard
                            image={productImg?.image}
                            name={productImg?.name}
                            copyUrl={productImg.image}
                            id={productImg?.id}
                            isImages
                          />
                        </label> */}
                      <div className="shop-product-badges">
                        <div className="badge-image">
                          <img src={productImg?.image} className="img-row img-opacity" alt="product" />
                        </div>
                        <div className="text">
                          <Typography>{productImg?.name}</Typography>
                        </div>
                        {/* <div className="edit-btn">
                          <Tooltip title="Edit Product" placement="right" onClick={() => {}}>
                            <img src={images.edit} className="edit-icon" alt="edit" />
                          </Tooltip>
                        </div> */}
                      </div>

                      {/* <Checkbox
                        className="checkbox-style"
                        id={`myCheckbox${index}`}
                        checkedIcon={<CheckCircleIcon />}
                        checked={!!selectedProductIds?.ids?.includes(productImg?.id)}
                        value={productImg?.id}
                        icon={<RadioButtonUncheckedIcon />}
                        onChange={(e) => handleCheckbox(productImg?.id, e)}
                      /> */}
                    </div>
                  );
                })
              ) : (
                <Empty message="Uploaded products will be displayed here." />
              )}
            </div>

            <div className="search-engine-section">
              <Typography variant="h4">Mobile</Typography>
              <div className="input-title">
                <div className="meta-info">
                  <div className="single-upload">
                    <UploadSingleImage
                      title="Mobile Image"
                      description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={imageMobile}
                      setImage={setImageMobile}
                      imageSize={2}
                    />
                  </div>

                  <div className="meta-des">
                    <Typography>Description</Typography>

                    <TextArea
                      placeholder="Enter"
                      name="description_mobile"
                      value={formik?.values?.description_mobile}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.description_mobile && formik?.errors?.description_mobile}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="search-engine-section">
              <Typography variant="h4">Tablet</Typography>

              <div className="input-title">
                <div className="meta-info">
                  <div className="single-upload">
                    <UploadSingleImage
                      title="Tablet Image"
                      description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={imageTablet}
                      setImage={setImageTablet}
                      imageSize={2}
                    />
                  </div>

                  <div className="meta-des">
                    <Typography>Description</Typography>

                    <TextArea
                      placeholder="Enter"
                      name="description_tablet"
                      value={formik?.values?.description_tablet}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.description_tablet && formik?.errors?.description_tablet}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="search-engine-section">
              <Typography variant="h4">Web</Typography>
              <div className="input-title">
                <div className="meta-info">
                  <div className="single-upload">
                    <UploadSingleImage
                      title="Web Image"
                      description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={imageWeb}
                      setImage={setImageWeb}
                      imageSize={2}
                    />
                  </div>

                  <div className="meta-des">
                    <Typography>Description</Typography>
                    <TextArea
                      placeholder="Enter"
                      name="description_web"
                      value={formik?.values?.description_web}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.description_web && formik?.errors?.description_web}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route(-1)} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
