import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { notify } from '../../core/constants';
import { AxiosAll } from '../../services/axiosAll';
import { BlueBtn } from '../universal/Buttons/BlueBtn';
import LightBtn from '../universal/Buttons/LightBtn';
import InputTypeOutline from '../universal/InputType/inputTypeOutline';
// import { TextArea } from "gestalt";
import TextArea from '../universal/InputType/TextArea';
import { UploadSingleImage } from '../universal/UploadImage';

const SearchMetaSection = ({ type, name, id, data, setStep }) => {
  const [loading, setLoading] = useState(false);
  const [Open, setOpen] = useState(false);
  const [title, settitle] = useState('');
  const [metaImage, setMetaImage] = useState('');

  const handleClose = () => {
    setOpen(false);
    settitle('');
    setStep(true);
  };
  const handleCloseAndAdd = () => {
    setOpen(false);
    settitle('');
  };

  const initialValues = {
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
  };

  const validationSchema = Yup.object({
    metaTitle: Yup.string(),
    metaDescription: Yup.string(),
    metaKeywords: Yup.string(),
  });

  const onSubmit = async (values) => {
    if (values?.metaTitle || values?.metaDescription) {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', name);
      formData.append('seo_meta_title', values?.metaTitle);
      formData.append('seo_meta_description', values?.metaDescription);
      formData.append('seo_meta_keywords', values?.metaKeywords);
      formData.append('seo_meta_image', metaImage);

      const response = await AxiosAll('post', `seller/categories/${id}?_method=PUT`, formData);
      if (response.statusCode === 200) {
        notify(response.message, 'success');
        setStep(true);
        setOpen(false);
        setLoading(false);
      } else {
        notify(response.message, 'error');
        setOpen(true);
        setLoading(false);
      }
    } else {
      setOpen(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  useEffect(() => {
    if (data?.seo_meta_keywords !== '' || data?.seo_meta_title !== '' || data?.seo_meta_description !== '') {
      formik.setFieldValue('metaTitle', data?.seo_meta_title);
      formik.setFieldValue('metaDescription', data?.seo_meta_description);
      formik.setFieldValue('metaKeywords', data?.seo_meta_keywords);
      setMetaImage(data?.seo_meta_image);
    }
  }, [data, id]);

  return (
    <div className="btn-width">
      {data?.seo_meta_keywords !== '' || data?.seo_meta_title !== '' || data?.seo_meta_description !== '' ? (
        <BlueBtn
          onClick={() => {
            setOpen(true);
          }}
          title="Edit Meta data"
          customClass="create-menu"
        />
      ) : (
        <BlueBtn
          onClick={() => {
            setOpen(true);
          }}
          title=" Add Meta data"
          customClass="create-menu"
        />
      )}
      <Dialog
        open={Open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="top-picks"
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <div className="search-pop-up">
              <Typography variant="h4">Search Engine Optimisation</Typography>
              <Typography paragraph className="description">
                Product SEO is the practice of optimising elements of product to provide search engines with enough
                relevant content to get the product found in search engines.
              </Typography>

              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Meta Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="metaTitle"
                      value={formik?.values?.metaTitle}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.metaTitle && formik?.errors?.metaTitle}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Meta Description</Typography>
                    <TextArea
                      topClass="textarea-box"
                      placeholder="Enter"
                      name="metaDescription"
                      value={formik?.values?.metaDescription}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.metaDescription && formik?.errors?.metaDescription}
                    />
                  </div>
                  <div className="meta-keywords">
                    <Typography>Meta Keywords</Typography>
                    <TextArea
                      topClass="textarea-box"
                      placeholder="Enter"
                      name="metaKeywords"
                      value={formik?.values?.metaKeywords}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.metaKeywords && formik?.errors?.metaKeywords}
                    />
                  </div>

                  <div className="single-upload">
                    {/* <Typography>Meta Image</Typography> */}
                    <UploadSingleImage
                      title="Meta Image"
                      description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={metaImage}
                      setImage={setMetaImage}
                      imageSize={2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-section" style={{ display: 'flex', justifyContent: 'end' }}>
              <LightBtn title="Cancel" onClick={handleClose} />
              <BlueBtn title="Save" loading={loading} type="submit" />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SearchMetaSection;
