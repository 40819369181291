import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DashboardSkeleton from '../../../components/Skeleton/DashboardSkeleton';
import FinancalReportSkeleton from '../../../components/Skeleton/FinancalReportSkeleton';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import DatePicker from '../../../components/universal/DatePicker';
import BasicPagination from '../../../components/universal/Pagination';
import BannerCard from '../../../components/universal/WelcomeCard';
import { store } from '../../../redux/store';
import { AxiosAll, axiosAll } from '../../../services/axiosAll';
import { BasicTable } from '../Orders/OrderTable';

const FinancialReports = () => {
  const CurrentDate = new Date();
  const { user } = useSelector((state) => state.userReducer);
  const PreviousDate = new Date().setFullYear(new Date().getFullYear() - 1);
  const [finalReport, setFinalReport] = useState();
  const [startDate, setStartDate] = React.useState(PreviousDate);
  const [endDate, setEndDate] = React.useState(CurrentDate);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paginationCount, setCount] = useState([]);

  const endDateInput = React.useRef();
  const startDateInput = React.useRef();
  const { loginReducer } = store.getState();
  const [Loading, setLoading] = useState(true);

  const TableColumn = [
    'Order number',
    'Gross amount (£)',
    'Total fees charged (£)',
    'Net payout (£)',
    ' Payout date',
    ' Refund',
  ];

  const getFinancialReport = async (startDateData, endDateData) => {
    const Date1 = moment(startDateData).format('DD-MM-YYYY');
    const Date2 = moment(endDateData).format('DD-MM-YYYY');
    const response = await AxiosAll('get', `seller/financial-reports/${Date1}/${Date2}`, null);
    if (response?.statusCode === 200) {
      setFinalReport(response?.data);
      setCount(response?.data?.ordersList);
      console.log('response', response);
      setPage(0);
    }
    setLoading(false);
  };

  const handlefilter = () => {
    getFinancialReport(startDate, endDate);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getFinancialReport(startDate, endDate);
  }, []);
  return (
    <div className="financialreports">
      <BannerCard
        title="Financial Reports"
        para="This is where you can view and upload photos of your products. When bulk uploading products you need to upload your images here and reference the link in your CSV."
        smallCard
      />
      <Container className="all-container-27inch">
        {Loading ? (
          <DashboardSkeleton type={1} page="finance" />
        ) : (
          <>
            <div className="complete-section">
              {/* <div className="financial-heading">Financial Reports</div> */}
              <div className="store-row">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading">
                        £ {finalReport?.total_gross_amount ? finalReport?.total_gross_amount?.toFixed(2) : 0}
                      </div>
                      <div className="box-title marginbott">Total gross amount</div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading">
                        £ {finalReport?.total_commission ? finalReport?.total_commission?.toFixed(2) : 0}
                      </div>
                      <div className="box-title marginbott">Total GoEthical commission</div>
                    </div>
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading">
                        £ {finalReport?.total_processing_fee ? finalReport?.total_processing_fee?.toFixed(2) : 0}
                      </div>
                      <div className="box-title marginbott">Total card processing fees</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading">
                        £ {finalReport?.total_net_payable ? finalReport?.total_net_payable?.toFixed(2) : 0}
                      </div>
                      <div className="box-title marginbott">Total net payout</div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="filters">
              <div className="store-heading">Filters</div>
              <div className="box-title search-title">Search by payout date range</div>
              <div className="datefilter-btn">
                <div className="datepickerpart">
                  <DatePicker
                    setStartDate={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    endDateInput={endDateInput}
                    startDateInput={startDateInput}
                  />
                  <LightBtn title=" Filter " onClick={() => handlefilter()} />
                </div>

                {paginationCount?.length > 0 && (
                  <div className="exportbtn">
                    {finalReport?.ordersList.length ? (
                      <DarkBtn
                        onClick={() => window.open(finalReport?.report_dwonload, '_blank')}
                        title="Export to CSV"
                      />
                    ) : (
                      <DarkBtn disable title="Export To CSV" />
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="tabledata">
          {Loading ? (
            <FinancalReportSkeleton />
          ) : (
            <>
              {' '}
              <BasicTable
                TableColumn={TableColumn}
                Row={finalReport?.ordersList}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              {finalReport?.ordersList?.length > 10 ? (
                <BasicPagination
                  page={page}
                  setPage={setPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  Row={paginationCount}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              ) : (
                ''
              )}
            </>
          )}
          {/* <div className="empty-table">
            <img src={images.shopImg} className="storeimg" />
            <div className="box-title">Your orders will show in this table</div>
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default FinancialReports;
