import React, { useRef } from 'react';
import { images } from '../../../assets';
import { DraggableCardDelete } from '../../dialogs/DraggableCardDelete';
import { DraggableUploadCard } from '../../topBanner/DraggableUploadCard';

export const UploadSingleImage = ({ title, description, image, setImage, imageSize }) => {
  const imageRef = useRef();

  return (
    <div className="drag-container">
      <DraggableUploadCard
        cardImg={image}
        headerTitle={title}
        descriptionPara={description}
        setImage={(img) => {
          setImage(img);
        }}
        imageRef={imageRef}
        imageSize={imageSize}
      />
      {image && (
        <div className="upload-arrow-card">
          <DraggableCardDelete
            iconImage={images.trashBtn}
            textTitle="Delete"
            textDescription="Are you sure you want to delete this image?"
            onPress={(setOpen) => {
              setImage('');
              if (imageRef?.current) {
                imageRef.current.value = '';
              }
              setOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
};
