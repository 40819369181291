import { LOGIN, LOGOUT, UPDATE_INFO } from '../constant';

const initialState = {
  userDetails: {},
};

// eslint-disable-next-line default-param-last
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, userDetails: action.data };
    case LOGOUT:
      return { ...state, userDetails: {} };
    case UPDATE_INFO:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.data,
        },
      };
    default:
      return state;
  }
};

export default loginReducer;
