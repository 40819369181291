import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import * as React from 'react';
import { CircleBtnIcon } from '../../siteEitor/circleBtnIcon';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import LightBtn from '../../universal/Buttons/LightBtn';

export const DraggableCardDelete = ({ iconImage, onPress, textTitle, textDescription, isDummy, section }) => {
  const route = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (isDummy) {
      route(
        section?.section_type?.section_slug === 'banner_with_multiple_image'
          ? `/onboarding/site-editor/banner-with-multiple-image`
          : `/onboarding/site-editor/${section?.section_type?.section_slug?.replaceAll('_', '-')}`,
      );
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <CircleBtnIcon iconImg={iconImage} iconColor="#D75D4C" onPress={handleClickOpen}>
        <DeleteIcon />
      </CircleBtnIcon>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="delete-pop-up"
      >
        <div className="delete-container">
          <Typography variant="h2">{textTitle}</Typography>
          <Typography paragraph>{textDescription}</Typography>
        </div>
        <DialogActions>
          <BlueBtn
            title="Yes"
            onClick={() => {
              onPress(setOpen);
              handleClose();
            }}
          />
          <LightBtn title="No" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
