import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { Typography } from '@mui/material';
import { images } from '../../assets';
import { DraggableCardDelete } from '../../components/dialogs/DraggableCardDelete';
import { CircleBtnIcon } from '../../components/siteEitor/circleBtnIcon';

import { BlueBtn } from '../../components/universal/Buttons/BlueBtn';
import BannerCard from '../../components/universal/WelcomeCard';
import { DraggableCard } from '../../components/siteEitor/draggableComponent/draggableCard';
import { AddNewSectionModel } from '../../components/siteEitor/addNewSectionModel';
import { notify } from '../../core/constants';
import { AxiosAll } from '../../services/axiosAll';
import { Loader } from '../../components/Skeleton/Loader';
import SEOMetaSection from '../../components/PopUpContainer/SEOMetaSection';

export const SiteEditor = () => {
  const route = useNavigate();

  const [sections, setSections] = useState([]);
  const [isDummy, setIsDummy] = useState(false);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const getSectionList = async () => {
    setLoading(true);
    const response = await AxiosAll('get', 'seller/sellerSections?per_page=100', null);
    if (response.statusCode === 200) {
      setSections(response.data.data);
      setIsDummy(response.data.is_dummy === 1);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };
  const SaveOrder = async (ReorderedSections) => {
    setLoading(true);
    const ReOrderList = ReorderedSections?.map((section) => {
      return section?.id;
    });
    const response = await AxiosAll('put', 'seller/sellerSectionSorting', { seller_section_ids: ReOrderList });
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      getSectionList();
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const getHomeScreensOptions = async () => {
    const response = await AxiosAll('get', 'seller/sectionType');
    if (response.statusCode === 200) {
      setSectionOptions(response?.data);
    } else {
      console.log('error');
    }
  };

  useEffect(() => {
    getHomeScreensOptions();
    getSectionList();
  }, []);
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...sections];

    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);

    setSections(newSections);
    setDraggedOverIndex(null);
  };

  const moveCardUp = (index) => {
    if (index > 0) {
      // setLoading(true);
      setTimeout(() => {
        const newSections = [...sections];
        const draggedsection = newSections[index];
        newSections.splice(index, 1);
        newSections.splice(index - 1, 0, draggedsection);
        setSections(newSections);
        setLoading(false);
      }, 1000); // Simulating an asynchronous operation with a timeout
    }
  };

  const moveCardDown = (index) => {
    if (index < sections.length - 1) {
      // setLoading(true);
      setTimeout(() => {
        const newSections = [...sections];
        const draggedSection = newSections[index];
        newSections.splice(index, 1);
        newSections.splice(index + 1, 0, draggedSection);
        setSections(newSections);
        // setLoading(false);
      }, 1000); // Simulating an asynchronous operation with a timeout
    }
  };

  const handleDelete = async (section) => {
    const response = await AxiosAll('delete', `seller/sellerSections/${section?.id}`);
    if (response?.statusCode === 200) {
      if (section?.section_type?.section_slug === 'footer') {
        getHomeScreensOptions();
      }
      notify(response?.message, 'success');
      getSectionList();
    }
  };

  const dec =
    'Color is used to express style and communicate meaning. With dynamic<br />color, Material puts personal color preferences and individual needs at the<br />forefront of systematic color application.';

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  return (
    <div className="site-editor">
      <Loader open={loading} />
      <BannerCard title="Site Editor" para={para} smallCard />
      <div className="container-27inch">
        <div className="add-section">
          <AddNewSectionModel sectionOptions={sectionOptions} />
          {sections?.length > 0 ? (
            <BlueBtn title="Save Order" onClick={() => SaveOrder(sections)} customClass="save-order-btn" />
          ) : null}{' '}
          <SEOMetaSection />
        </div>
        {loading ? (
          <Loader open={loading} />
        ) : sections?.length > 0 ? (
          sections?.map((section, index) => (
            <div className="drag-container" key={section?.id}>
              {/* {console.log("section",section)} */}
              <DraggableCard
                keyID={section.id}
                dragStart={(e) => handleDragStart(e, index)}
                dragOver={(e) => handleDragOver(e, index)}
                dragEnd={() => setDraggedOverIndex(null)}
                dropCard={(e) => handleDrop(e, index)}
                cardImg={images.sitePreview}
                headerTitle={
                  section?.section_name && section?.section_type?.section_slug !== 'banner_with_multiple_image'
                    ? `${section?.section_type?.section_name} (${section?.section_name})`
                    : `${section?.section_type?.section_name} `
                }
                descriptionPara={section?.section_type?.section_description}
              />
              <div className="draggable-arrow-card">
                <div className="all-btn">
                  <div className="up-down-btn">
                    <CircleBtnIcon iconColor="#444746" onPress={() => moveCardUp(index)}>
                      <ArrowUpwardIcon />
                    </CircleBtnIcon>
                    <CircleBtnIcon iconColor="#444746" onPress={() => moveCardDown(index)}>
                      <ArrowDownwardIcon />
                    </CircleBtnIcon>
                  </div>

                  <div className="edit-del-btn">
                    <CircleBtnIcon
                      iconColor="#444746"
                      onPress={() => {
                        if (isDummy) {
                          route(
                            section?.section_type?.section_slug === 'banner_with_multiple_image'
                              ? `/onboarding/site-editor/banner-with-multiple-image`
                              : `/onboarding/site-editor/${section?.section_type?.section_slug?.replaceAll('_', '-')}`,
                          );
                          return;
                        }
                        route(
                          section?.section_type?.section_slug === 'banner_with_multiple_image'
                            ? `/onboarding/site-editor/banners/${section.id}`
                            : `/onboarding/site-editor/${section?.section_type?.section_slug?.replaceAll('_', '-')}/${
                                section.id
                              }`,
                        );
                      }}
                    >
                      <ModeEditOutlineIcon />
                    </CircleBtnIcon>

                    <DraggableCardDelete
                      iconImage={images.trashBtn}
                      isDummy={isDummy}
                      section={section}
                      textTitle="Delete"
                      textDescription={`Are you sure you want to delete ${section?.section_type?.section_name.toLowerCase()} section?`}
                      onPress={() => handleDelete(section)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Typography style={{ fontSize: '1.5rem', textAlign: 'center' }}>
              No Sections Created at this moments
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
