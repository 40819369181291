import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { deviceToken, notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import BlackBtn from '../Buttons/BlackBtn';
import InputType from '../InputType';

const ExclusiveBlogs = () => {
  const newsLetterEntry = async (data) => {
    try {
      const res = await AxiosAll('post', `newsLetter-Entries?deviceType=3&deviceToken=${deviceToken}`, data);
      notify(res.data.message, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  // formik start
  const initialValues = {
    email: '',
  };
  const validationSchema = Yup.object({
    email: Yup.string().required('E-mail address is required.').email('Enter valid email address'),
  });
  const onSubmit = (values) => {
    formik.handleReset();
    newsLetterEntry({ email: values?.email, type: 2 });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="exclusiveblogs">
      <div className="email-img-row">
        <img src={images.blog} alt="goEthical logo" className="blogbgimg" />
      </div>

      <div className="blog-row">
        <div className="blog-container">
          <h2 className="blog-text">
            Yes! Send me exclusive blogs, unique gift ideas, and personalised tips for shopping and selling on
            <br />
            <span className="goethical-text"> Sell Stream</span>
          </h2>

          <form onSubmit={formik.handleSubmit}>
            <div>
              <InputType
                name="email"
                id="email"
                type="email"
                placeholder="Email Address"
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
              />
            </div>

            <div>
              <BlackBtn type="submit" title="Submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveBlogs;
