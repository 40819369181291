import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import { CircleBtnIcon } from '../../siteEitor/circleBtnIcon';
import { images } from '../../../assets';
import LightBtn from '../../universal/Buttons/LightBtn';
// eslint-disable-next-line no-shadow
const AddCourier = ({ type, AddNewAddCourier, data, id, customClass }) => {
  const [Open, setOpen] = useState(false);
  const [title, settitle] = useState('');
  const handleClose = () => {
    setOpen(false);
    settitle('');
  };
  const handleCloseAndAdd = () => {
    if (title) {
      setOpen(false);
      AddNewAddCourier(title, id || 0);
      settitle('');
    }
  };

  return (
    <div className="AddTo">
      {type === 'edit' ? (
        <CircleBtnIcon
          iconClass="edit"
          iconColor="transparent"
          iconImg={images.editBlack}
          onPress={() => {
            setOpen(true);
            settitle(data?.name);
          }}
        >
          <ModeEditOutlineIcon />
        </CircleBtnIcon>
      ) : (
        <BlueBtn
          onClick={() => {
            setOpen(true);
          }}
          title="Create  menu"
          customClass="create-menu"
        />
      )}
      <Dialog
        open={Open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Add-To"
      >
        <DialogContent className={customClass}>
          <p style={{ fontSize: '18px' }}>{type === 'edit' ? 'Edit' : 'Add'} Courier</p>
          <div className="add-courier">
            <div>
              <InputTypeOutline
                placeholder="name"
                value={title}
                onChange={(e) => {
                  settitle(e.target.value);
                }}
                type="text"
              />
            </div>

            <div style={{ marginRight: '10px' }}>
              <BlueBtn
                title="Yes"
                onClick={() => {
                  handleCloseAndAdd();
                }}
              />
              <LightBtn
                title="No"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddCourier;
