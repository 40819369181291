import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Blackbtn from '../../components/universal/Buttons/BlackBtn';
import { updateGuestUser } from '../../redux/actions/guestUser';
import { addLogin } from '../../redux/actions/login';
import { AxiosAll } from '../../services/axiosAll';
import { notify } from '../../core/constants';

export const Status = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const status = searchParams.get('status');
  const token = localStorage.getItem('user_token');

  const loginUser = async () => {
    const response = await AxiosAll('get', 'seller-profile', null, token);
    if (response.statusCode === 200) {
      token && localStorage.removeItem('user_token');
      const userDetails = {
        token: response?.data?.user_token,
        user: response?.data?.user,
      };
      dispatch(addLogin(userDetails));
      const user = response?.data?.user;
      dispatch(
        updateGuestUser({
          user,
          userToken: userDetails.token,
        }),
      );
      if (response?.data?.user?.type === 1) {
        nav('/admin/categories');
      } else {
        nav('/onboarding/dashboard?first=1');
        console.log('came here ');
      }
    } else {
      notify(response.message, 'error');
    }
  };

  useEffect(() => {
    if (status === 'success' && token) {
      loginUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, token]);

  return status === 'success' && token ? (
    <div className="notfound">
      <h1>Your account has been successfully created.</h1>
      <h3>Thank you for registering with us.</h3>
      <h4>You will be logged in momentarily.</h4>
    </div>
  ) : status === 'failure' ? (
    <div className="notfound">
      <h1>
        Oops, something went wrong. <br /> Please try again.
      </h1>
      <div className="btn-section">
        <Blackbtn
          title="BACK TO HOMEPAGE"
          onClick={() => {
            nav('/login');
          }}
        />
      </div>
    </div>
  ) : (
    <div className="notfound">
      <h1>Oops, page not found</h1>
      <p>We are very sorry for the inconvenience. It looks like you're trying to</p>
      <p>access a page the has been deleted or never even existed.</p>
      <div className="btn-section">
        <Blackbtn
          onClick={() => {
            nav('/login');
          }}
          title="BACK TO HOMEPAGE"
        />
      </div>
    </div>
  );
};
