/* eslint-disable no-debugger */
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { images } from '../../../assets';
import { notify } from '../../../core/constants';
import { AddIconBtn } from '../../CategoriesHeader/AddBtn';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';

const CategoryAdd = ({ type, AddCategory, name, id, titleName }) => {
  const navigate = useNavigate();
  const query = window.location.search;
  const urlParam = new URLSearchParams(query);
  const child = urlParam.get('child');
  const [Open, setOpen] = useState(false);
  const [title, settitle] = useState('');
  const handleClose = () => {
    setOpen(false);
    settitle('');
  };
  const handleCloseAndAdd = () => {
    if (title !== '') {
      AddCategory(title, id || 0);
      settitle('');
      setOpen(false);
    } else {
      notify('THE NAME FIELD IS REQUIRED', 'error');
    }
    // setOpen(false);
  };

  const Title = child === '1' ? 'Create Sub Category' : child === '2' ? 'Create Sub Category ' : 'Create Category';
  const EditTitle = child === '1' ? 'Edit Sub Category' : child === '2' ? 'Edit Sub Category ' : 'Edit Category';

  return (
    <div className="AddTo">
      {type === 'edit' ? (
        <AddIconBtn
          onPress={() => {
            setOpen(true);
            name && settitle(name);
          }}
          title="edit"
          customClass="create-menu"
          iconColor="transparent"
          iconImg={images.editBlack}
        />
      ) : (
        <BlueBtn
          onClick={() => {
            setOpen(true);
          }}
          title={Title}
          customClass="create-menu"
        />
      )}
      <Dialog
        open={Open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Add-To categories-create-menu"
      >
        <DialogContent>
          <p className="create-menu-title">{type === 'edit' ? EditTitle : Title}</p>
          <div className="edit-menu-box">
            <div>
              <InputTypeOutline
                placeholder="name"
                value={title}
                onChange={(e) => {
                  settitle(e.target.value);
                }}
                type="text"
              />
            </div>

            <div className="edit-menu-btn">
              <BlueBtn
                title="Save"
                onClick={() => {
                  handleCloseAndAdd();
                }}
              />
              <LightBtn
                title="Cancel"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CategoryAdd;
