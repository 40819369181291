import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const sweatAlertBoxEdit = async (title, text, confirmButtonText, cancelButtonText, handleEdit, productSlug, id) => {
  const result = await MySwal.fire({
    title,
    html: text,
    text,
    type: 'warning',
    cancelButtonText: cancelButtonText || 'Cancel',
    confirmButtonColor: '#ff6600',
    confirmButtonText: confirmButtonText || 'Confirm',
    showCancelButton: true,
    allowOutsideClick: false,
  });
  if (result.isConfirmed) {
    handleEdit(productSlug, id);
  }
};

export default sweatAlertBoxEdit;

export const sweatAlert = async (title) => {
  const result = await MySwal.fire(title);
};
