export const images = {
  mainLogo: require('./images/sellerSide/goethical_logo.png'),
  facebook1: require('./images/sellerSide/blackfacebook.webp'),
  instagram1: require('./images/sellerSide/instagram.webp'),
  linkedin1: require('./images/sellerSide/linkedin.webp'),
  twitter1: require('./images/sellerSide/twitter.webp'),
  twitter2: require('./images/sellerSide/twitter.png'),
  blog: require('./images/sellerSide/email-text.png'),
  verify: require('./images/sellerSide/verify.png'),
  upload: require('./images/sellerSide/upload.png'),
  shop: require('./images/sellerSide/shop.png'),
  welcome: require('./images/sellerSide/welcome.png'),
  register: require('./images/sellerSide/register-header-image.png'),
  dashboard: require('./images/sidebar/dashboard_1.png'),
  book: require('./images/sidebar/book_1.png'),
  basket: require('./images/sidebar/basket_1.png'),
  financials: require('./images/sidebar/financials_1.png'),
  gallery: require('./images/sidebar/gallery_1.png'),
  siteEditor: require('./images/sidebar/site_editor.png'),
  help: require('./images/sidebar/help.png'),
  signOut: require('./images/sidebar/signout_1.png'),
  user: require('./images/sidebar/user_1.png'),
  product: require('./images/sellerSide/product.jpg'),
  delete: require('./images/sellerSide/delete.png'),
  copy: require('./images/sellerSide/copy.png'),
  edit: require('./images/sellerSide/edit.jpg'),
  profile: require('./images/sellerSide/profile.png'),
  tree: require('./images/sellerSide/tree.png'),
  userTable: require('./images/sellerSide/userTable.png'),
  moti: require('./images/sellerSide/moti1.png'),
  userIconSaller: require('./images/sellerSide/saller-icon.png'),
  completeIcon: require('./images/sellerSide/complete-icon.png'),
  errowDown: require('./images/sellerSide/dropdown.webp'),
  shopImg: require('./images/sellerSide/shop.png'),
  menuIcon: require('./images/menu-icon.webp'),
  sideMenuImg: require('./images/sellerSide/sidebar.png'),
  dashboardSide: require('./images/sellerSide/dashboard.png'),
  contact: require('./images/staticpage/contact.png'),
  emptyBasket: require('./images/staticpage/basket.png'),
  placeholder: require('./images/staticpage/placeholder.jpg'),
  defaultUser: require('./images/user-1.png'),
  sitePreview: require('./images/siteEditor/site-preview.png'),
  upArrow: require('./images/siteEditor/up-arrow.png'),
  downArrow: require('./images/siteEditor/down-arrow.png'),
  editBtn: require('./images/siteEditor/edit.png'),
  editBlack: require('./images/siteEditor/edit-black.png'),
  trashBtn: require('./images/siteEditor/trash.png'),
  uploadIcon: require('./images/siteEditor/upload.png'),
  uploadIconSVG: require('./images/siteEditor/upload.svg'),
  backgroundBg: require('./images/sellerSide/background-img.png'),
  plusIcon: require('./images/siteEditor/plus.png'),
  blueBgImg: require('./images/sellerSide/bluebg-img.png'),
  mastercard: require('./images/sellerSide/MasterCard.png'),
  visa: require('./images/sellerSide/Visa.png'),
  americanExpress: require('./images/sellerSide/AmericanExpress_new.png'),
  unionPay: require('./images/sellerSide/unionpay.png'),
  addNewSectionTemp: require('./images/siteEditor/addnewsection.png'),
  topBannerImg: require('./images/siteEditor/top-banner.png'),
  productSectionImg: require('./images/siteEditor/product-section.png'),
  latestArrivalImg: require('./images/siteEditor/latest-arrival.png'),
  bestSellerImg: require('./images/siteEditor/best-seller.png'),
  dynamicProductsImg: require('./images/siteEditor/dynamic-products.png'),
  tellYourStoryImg: require('./images/siteEditor/tell-your-story.png'),
  newsLetterImg: require('./images/siteEditor/news-letter.png'),
  signupImg: require('./images/siteEditor/signup.png'),
  websiteFooterImg: require('./images/siteEditor/website-footer.png'),
  lock: require('./images/sidebar/lock.png'),
  arrowBack: require('./images/sellerSide/arrow_back.png'),
};
