import React, { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../../../components/universal/SearchBar';
import { axiosAll } from '../../../services/axiosAll';
import { addLogin, deleteLogin } from '../../../redux/actions/login';
import { updateGuestUser } from '../../../redux/actions/guestUser';
import { deleteUser } from '../../../redux/actions/user';
import CustomTablePagination from '../../../components/universal/TablePagination';
import SellerSkeleton from '../../../components/Skeleton/SellerSkeleton';
import { images } from '../../../assets';

const SellerAdmin = () => {
  const [sellerList, setSellerList] = useState([]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const { userDetails: oldUser } = useSelector((state) => state.loginReducer);
  const [isLoading, setIsLoading] = useState(false);

  const nav = useNavigate();

  const dispatch = useDispatch();

  const getSellerList = async () => {
    setIsLoading(true);
    const res = await axiosAll({
      method: 'get',
      url: `v2/user/getSellers?page=${page + 1}&search=${search}`,
    });
    setSellerList(res.result);
    setIsLoading(false);
  };

  useEffect(() => {
    getSellerList();
  }, [page, search]);

  const loginSellerAsAdmin = async (id) => {
    const response = await axiosAll({
      method: 'post',
      url: `v2/user/loginSellerAsAdmin`,
      data: {
        user_id: id,
      },
    });

    if (response.statusCode === 200) {
      localStorage.setItem('oldData', JSON.stringify(oldUser));
      dispatch(deleteLogin());
      dispatch(deleteUser());
      const userDetails = {
        token: response?.result?.userToken,
        user: response?.result?.user,
      };
      dispatch(addLogin(userDetails));
      const user = response?.result?.user;
      dispatch(
        updateGuestUser({
          user,
          userToken: userDetails.token,
        }),
      );
      nav('/onboarding/dashboard');
    }
  };

  return (
    <div className="seller-admin">
      <h1 className="product">Sellers</h1>
      <div className="table-section-seller">
        <SearchBar
          value={search}
          handleChange={(e) => {
            setSearch(e.target.value);
            setPage(0);
          }}
        />
        {isLoading ? (
          <SellerSkeleton />
        ) : (
          <table>
            <tr className="heading">
              <th>Email</th>
              <th>Name</th>
              <th>User Name</th>
              <th>Phone Number</th>
              <th>Profile Picture</th>
              <th>Active Status</th>
              <th>Action</th>
            </tr>
            {sellerList?.data?.map((seller, index) => {
              return (
                <tr key={index} className="product-name">
                  <td>{seller?.email}</td>
                  <td>{seller?.name}</td>
                  <td>{seller?.nameSlug}</td>
                  <td>{seller?.phoneNumber}</td>
                  <td>
                    <img
                      src={seller?.profilePicture ? seller?.profilePicture : images?.defaultUser}
                      alt=""
                      className="tree-img"
                    />
                  </td>
                  <td> </td>
                  <td>
                    <VisibilityIcon className="iconvisibility" onClick={() => loginSellerAsAdmin(seller?.id)} />
                  </td>
                </tr>
              );
            })}
          </table>
        )}

        {!isLoading && (
          <CustomTablePagination
            page={page}
            count={sellerList?.total}
            onChangePage={(e, newPage) => setPage(newPage)}
          />
        )}
      </div>
    </div>
  );
};

export default SellerAdmin;
