import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import BannerCard from '../../../components/universal/WelcomeCard';
import { AxiosAll } from '../../../services/axiosAll';
import { notify } from '../../../core/constants';
import { CountryTable } from '../../../components/SiteEditorTable/ComonTable/CountryTable';
import AddCountry from '../../../components/dialogs/AddCountry';
import { Loader } from '../../../components/Skeleton/Loader';

export const CountryList = () => {
  const route = useNavigate();
  const [CountriesList, setCountriesList] = useState([]);
  const [loading, setloading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);

  const [total, setTotal] = useState(0);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getCountryList = async () => {
    setloading(true);
    const response = await AxiosAll('get', `seller/shippingCountries?per_page=20`, null);
    if (response.statusCode === 200) {
      setCountriesList(response.data.data);
      setTotal(response.data.total);

      setloading(false);
    } else {
      notify(response.message, 'error');
      setloading(false);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const AddNewCountry = async (value) => {
    // setisLoading(true);
    const response = await AxiosAll(
      value.id === 0 ? 'post' : 'put',
      value.id === 0 ? 'seller/shippingCountries' : `seller/shippingCountries/${value.id}`,
      value,
      null,
    );
    if (response.statusCode === 200) {
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }

    getCountryList();
  };

  const EditStatus = async (value, data) => {
    // setisLoading(true);
    const response = await AxiosAll(
      'put',
      `seller/shippingCountries/${data.id}`,
      {
        name: data?.name,
        currency_symbol: data?.currency_symbol,
        currency_iso_code: data?.currency_iso_code,
        status: value,
      },
      null,
    );

    getCountryList();
  };
  return (
    <div className="country-list">
      <Loader open={loading} />
      <BannerCard title="Shippings Country" para={para} smallCard />
      <div className="container-27inch">
        <div className="country-list-buttons">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route(-1)} />
          <AddCountry AddNewCountry={AddNewCountry} />
        </div>
        {loading ? (
          'Loading...'
        ) : (
          <div className="site-table">
            <CountryTable
              EditStatus={EditStatus}
              CountriesList={CountriesList}
              getCountriesList={getCountryList}
              AddNewCountry={AddNewCountry}
            />
          </div>
        )}
      </div>
    </div>
  );
};
