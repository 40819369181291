/* eslint-disable no-lone-blocks */
/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-debugger */
/* eslint-disable no-unsafe-optional-chaining */
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DialogActions from '@mui/material/DialogActions';
import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Label } from 'gestalt';

import { useFormik } from 'formik';

import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import LightBtn from '../../universal/Buttons/LightBtn';

import { AxiosAll } from '../../../services/axiosAll';
import { Empty } from '../../universal/Empty';

import ProductsCard from '../../universal/ProductsCard';

import { notify } from '../../../core/constants';
import ToolTip from '../../universal/ToolTip';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import { UploadSingleImage } from '../../universal/UploadImage';

export const Position = ({ id, data, setStep, AddCategory, getMainCategories }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState('');
  const [positionName, setPositionName] = useState('1');
  const [name, setName] = useState('');
  const [display, setDisplay] = useState('0');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStep(true);
  };

  const formik = useFormik({
    // initialValues,
    // validationSchema,
    // onSubmit,
  });

  const SavePosition = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('position', positionName);
    formData.append('is_show_extra_info', display);
    formData.append('extra_info_title', name);
    {
      positionName === '2' ? formData.append('extra_info_image', image) : null;
    }

    const response = await AxiosAll('post', `seller/category-extra-info/${id}`, formData);
    if (response?.statusCode === 200) {
      const responseData = response?.data;
      handleClose();
      setLoading(false);
    } else {
      // setOpen(false);
      setLoading(false);

      notify(response.message, 'success');
    }
  };

  const handleChange = (event) => {
    setPositionName(event.target.value);
  };

  const handleChangeDisplay = (event) => {
    setDisplay(event.target.value);
  };

  useEffect(() => {
    if (data?.extra_info?.position) {
      setPositionName(`${data?.extra_info?.position}`);
      setImage(data?.extra_info?.extra_info_image);
      setDisplay(`${data?.extra_info?.is_show_extra_info}`);
      setName(data?.extra_info?.extra_info_title);
    }
  }, [id]);
  return (
    <div className="btn-width">
      {data?.extra_info?.id ? (
        <BlueBtn title="Edit Position" customClass="save-order" onClick={handleClickOpen} />
      ) : (
        <BlueBtn title="Add Position" customClass="save-order" onClick={handleClickOpen} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="edit-position"
      >
        <div className="main-picks-container">
          <div className="add-product">
            <Typography>Position </Typography>
            {/* <SearchBar /> */}
          </div>
          <div className="edit-position-radio">
            <FormControl>
              <div className="sub-position">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  onChange={handleChange}
                  value={positionName}
                >
                  <div className="position-radio-btn">
                    <FormLabel id="demo-row-radio-buttons-group-label">Position Sub menu : </FormLabel>
                    <div className="radio-btn-container">
                      <FormControlLabel value="1" control={<Radio />} label="Left To Right " />
                      <FormControlLabel value="2" control={<Radio />} label="Right To Left" />
                    </div>
                  </div>
                </RadioGroup>
              </div>
            </FormControl>
            <FormControl>
              <div className="display-extra">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  onChange={handleChangeDisplay}
                  value={display}
                >
                  <div className="position-radio-btn">
                    <FormLabel id="demo-row-radio-buttons-group-label">Display Extra Information :</FormLabel>
                    <div className="radio-btn-container">
                      <FormControlLabel value="0" control={<Radio />} label="Hide" />
                      <FormControlLabel value="1" control={<Radio />} label="Show" />
                    </div>
                  </div>
                </RadioGroup>
              </div>
            </FormControl>
          </div>
          <div className="meta-title">
            <Typography className="title-meta">Title</Typography>
            <InputTypeOutline placeholder="Enter" name="title" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          {positionName === '2' ? (
            <div className="single-img-upload">
              <Typography variant="h6">image </Typography>

              <UploadSingleImage
                title="Image"
                description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                image={image}
                setImage={setImage}
                imageSize={2}
              />
            </div>
          ) : null}
        </div>

        <DialogActions className="bottom-btn">
          <LightBtn title="Cancel" onClick={handleClose} />
          <BlueBtn title="Save" loading={loading} onClick={SavePosition} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
