import { DELETE_USER, SET_USER, UPDATE_Id } from '../constant';

const initialState = {
  user: {},
};

// eslint-disable-next-line default-param-last
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.data };
    case DELETE_USER:
      return { ...state, user: action.data };
    case UPDATE_Id:
      return {
        ...state,
        user: {
          ...state.user,
          id: action.data,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
