import { Typography } from '@mui/material';
import uploadIconSVG from '../../../assets/images/siteEditor/upload.svg';
import { notify } from '../../../core/constants';
import DropDown from '../../universal/DropDown';

export const DraggableUploadCard = ({
  keyID,
  headerTitle,
  cardImg,
  descriptionPara,
  dropDownReq,
  setImage,
  imageRef,
  required,
  imageSize,
}) => {
  const handleProfilePicURL = (item) => {
    if (item instanceof File) {
      return URL.createObjectURL(item);
    }
    return item;
  };

  return (
    <div className="draggable-update-card" key={keyID}>
      {cardImg ? (
        <div className="update-image-card no-padding">
          <img src={handleProfilePicURL(cardImg)} alt="" className="upload-img" />
        </div>
      ) : (
        <div
          className="update-image-card"
          onDrag={(e) => {
            e.preventDefault();
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            if (file) {
              if (imageSize && file.size > imageSize * 1024 * 1024) {
                notify(`file size should be less than ${imageSize} MB`, 'error');
                return;
              }
              setImage(file);
            }
          }}
        >
          <img src={uploadIconSVG} alt="" className="upload-icon" />
          <Typography paragraph>
            Drag and drop photos or files or
            <br />
            <span
              tabIndex={0}
              role="button"
              style={{ textDecoration: 'underline' }}
              onClick={() => {
                imageRef?.current?.click();
              }}
            >
              click here
            </span>{' '}
            to select
            <input
              type="file"
              required={required}
              accept="image/x-png ,image/jpeg"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  if (imageSize && file.size > imageSize * 1024 * 1024) {
                    notify(`file size should be less than ${imageSize} MB`, 'error');
                    return;
                  }
                  setImage(file);
                }
              }}
              hidden
              ref={imageRef}
            />
          </Typography>
          <Typography paragraph>Maximum file size upload-{imageSize || 50}MB</Typography>
        </div>
      )}
      <div className="upload-description-card">
        <Typography variant="h3" dangerouslySetInnerHTML={{ __html: headerTitle }} />
        <Typography paragraph dangerouslySetInnerHTML={{ __html: descriptionPara }} />
        {dropDownReq && <DropDown />}
      </div>
    </div>
  );
};
