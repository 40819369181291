/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */
import LockIcon from '@mui/icons-material/Lock';
import WestIcon from '@mui/icons-material/West';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import AddCardDialogue from '../../components/AddCard';
import PaymentMethodStripForm from '../../components/PaymentStripForm';
import { CardContainer } from '../../components/universal/CardContainer';
import { Loader } from '../../components/universal/Loader';
import { notify } from '../../core/constants';
import { cardTypeImage } from '../../hooks/cardtypes';
import { openWindow } from '../../hooks/popupFrame';
import { updateGuestUser } from '../../redux/actions/guestUser';
import { addLogin } from '../../redux/actions/login';
import { AxiosAll } from '../../services/axiosAll';
import SubscriptionDisableBtn from '../../components/universal/Buttons/SubscriptionDisableBtn';

const Subscription = () => {
  const [planDetails, setPlanDetails] = useState('');
  const [cardList, setCardList] = useState([]);
  const [addingCard, setAddingCard] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userPaymentToken, setUserPaymentToken] = useState('');

  const nav = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { token } = useParams();
  const searchParams = new URLSearchParams(window.location.search);

  const isNew = searchParams.get('isNew');

  const initialValues = {
    address: '',
    city: '',
    postalCode: '',
    country: '',
    phoneNumber: '',
    companyName: '',
    VATNumber: '',
  };

  const validationSchema = Yup.object({
    address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    postalCode: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    phoneNumber: Yup.string().required('Required'),
    companyName: Yup.string(),
    VATNumber: Yup.string(),
  });

  const getPlanData = async () => {
    try {
      const res = await AxiosAll('get', 'seller/subscription-plans', null, token);
      const plan = res?.data[0];
      setPlanDetails(plan);
    } catch (error) {
      console.log(error);
    }
  };

  const loginUser = async () => {
    try {
      const response = await AxiosAll('get', 'seller-profile', null, token);
      const userDetails = {
        token: response?.data?.user_token,
        user: response?.data?.user,
      };
      dispatch(addLogin(userDetails));
      const user = response?.data?.user;
      dispatch(
        updateGuestUser({
          user,
          userToken: userDetails.token,
        }),
      );
      if (response?.data?.user?.type === 1) {
        nav('/admin/categories');
      } else {
        nav('/onboarding/dashboard?first=1');
        console.log('came here ');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addCard = async (data) => {
    setAddingCard(true);
    const res = await AxiosAll('post', 'seller/payment-methods', data, token);
    if (res?.statusCode === 200) {
      getCards();
      notify(res?.message, 'success');
      setAddingCard(false);
    } else {
      notify(res?.message, 'error');
      setAddingCard(false);
    }
  };

  const getCards = async () => {
    const res = await AxiosAll('get', 'seller/payment-methods', '', token);
    if (res?.statusCode === 200) {
      const data = res?.data;
      setCardList(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const makeDefault = async (cardId) => {
    const res = await AxiosAll('put', `seller/payment-methods/${cardId}`, { is_default: 1 }, token);
    if (res?.statusCode === 200) {
      notify(res.message, 'success');
      getCards();
    } else {
      notify(res.message, 'error');
      getCards();
    }
  };

  const handelPopupLoad = () => {
    getCurrentSubscription();
    // navigate('/my-account/subscription-history');
  };

  const getCurrentSubscription = async () => {
    const res = await AxiosAll('GET', 'seller/subscriptions', null, token);
    if (res?.statusCode === 200) {
      // dispatch(setSubscription(res?.data));
      // navigate('/my-account/subscription-history');
      setLoadingDetails(false);
    }
  };
  console.log(window.location.origin);

  const PurchaseSubscription = async (Id) => {
    setLoadingDetails(true);

    try {
      const res = await AxiosAll(
        'post',
        'seller/subscriptions',
        {
          plan_id: planDetails?.id,
          is_trail: isNew == 2 ? 0 : 1,
          web_redirect_url: window.location.origin,
          // coupen_code: formik?.values?.coupen_code
        },
        token,
      );
      if (res?.statusCode === 200) {
        notify(res?.message, 'success');
        setLoadingDetails(false);
        if (res?.data?.is3DSecure) {
          // window?.open(res?.data['3d_data'].use_stripe_sdk?.stripe_js);
          window.localStorage.setItem('user_token', token);
          window.open(res?.data?.is3DSecureURL, '_self');
          // setTimeout(() => {
          handelPopupLoad();
          // }, 50000);
        } else {
          // handelPopupLoad();
          loginUser();
        }
      } else {
        notify(res?.message, 'error');
        setLoadingDetails(false);
        // navigate("/my-subscription");
      }
    } catch (error) {
      console.log(error);
      setLoadingDetails(false);
    }
  };

  const onSubmit = async (values) => {
    setLoadingDetails(true);
    try {
      const res = await AxiosAll('post', 'seller/subscription-plans', values, token);
      notify(res?.message, 'success');
      setLoadingDetails(false);
      getPlanData();
    } catch (error) {
      console.log(error);
      notify(error?.message, 'error');
      setLoadingDetails(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    getPlanData();
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userPaymentToken && userPaymentToken !== '') {
      addCard({ payment_method_id: userPaymentToken });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPaymentToken]);

  return (
    <div className="subscription">
      <div className="login-full-container">
        <div style={{ justifyContent: isNew != 1 ? 'center' : '' }} className="left-arrow-new">
          {isNew == 1 && <WestIcon onClick={() => nav(`/business-product-details/${id}/${token}`)} />}
          <div className="main-heading">{isNew == 2 ? 'Subscription' : 'Start Your Free Trial'}</div>
          <div />
        </div>
        <Loader
          loading={loadingDetails || addingCard}
          message={
            loadingDetails ? 'Confirming your Payment <br> Please do no close or refresh the window' : 'Loading...'
          }
        />

        {isNew == 2 ? (
          <div className="login-para" />
        ) : (
          <div className="login-para">{planDetails?.trial_period_days}-day Free Trial</div>
        )}
      </div>
      <div className="form-box">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7} className="inner-box">
            <div className="payment-text-new">Payment method</div>
            {loading ? (
              'Loading...'
            ) : cardList?.length > 0 ? (
              <>
                {cardList?.map((card, index) => {
                  return (
                    <CardContainer bottomMargin="0" topMargin="0" key={index} className="bottomMargin">
                      <div className="address-details">
                        <div className="address-text">
                          <FormControl>
                            <RadioGroup>
                              <FormControlLabel
                                onClick={() => {
                                  makeDefault(card?.id);
                                  const newCardList = cardList.map((item) => {
                                    if (item.id === card?.id) {
                                      item.is_default = 1;
                                    } else {
                                      item.is_default = 0;
                                    }
                                    return item;
                                  });
                                  setCardList(newCardList);
                                }}
                                value={card?.id}
                                control={<Radio />}
                                checked={card?.is_default}
                              />
                            </RadioGroup>
                          </FormControl>
                          {cardTypeImage(card?.card_brand, 30, 60)}
                          <Typography variant="body1" className="font14px">
                            **** **** **** {card?.card_last4}
                          </Typography>
                        </div>
                      </div>
                    </CardContainer>
                  );
                })}
                <AddCardDialogue buttonType="dark" getCards={getCards} setAddingCard={setAddingCard} token={token} />
              </>
            ) : (
              <div style={{ width: '90%', minHeight: 150 }}>
                <PaymentMethodStripForm setPayCardID={setUserPaymentToken} buttonText="Add Card and Continue" />
              </div>
            )}
          </Grid>
          {/* <PaymentMethodStripForm buttonText={"Start 7-day Free Trial"} setPayCardID={setUserPaymentToken} /> */}
          <Grid item xs={12} sm={12} md={4.5} lg={4} className="inner-box second-inner">
            <div className="summery-text" style={{ marginTop: '0.5vw' }}>
              Summary
            </div>
            <div className="summery-text" style={{ marginBottom: '5px' }}>
              {planDetails?.plan_name}
            </div>
            <div className="summery-text-flex">
              <div className="summery-text" style={{ fontFamily: 'Inter-Medium' }}>
                £14 / every {planDetails?.recurring_interval}
              </div>
              <div className="summery-text">£{planDetails?.amount?.toFixed(2)}</div>
            </div>
            <div className="summery-text margin-bottom" style={{ color: '#FF6D2C', fontFamily: 'Inter-Medium' }}>
              Starting from: {moment().format('DD/MM/YY')}
            </div>
            <div className="summery-text-flex" style={{ borderBottom: '1px solid #E0E5E9', marginTop: '17px' }}>
              <div className="summery-text" style={{ fontFamily: 'Inter-Medium' }}>
                Subtotal
              </div>
              <div className="summery-text">£{planDetails?.amount?.toFixed(2)}</div>
            </div>
            <div className="summery-text-flex" style={{ borderBottom: '1px solid #E0E5E9', marginTop: '16px' }}>
              <div className="summery-text">Total</div>
              <div className="summery-text">£{planDetails?.amount?.toFixed(2)}</div>
            </div>
            <div className="text-center">
              {cardList?.length > 0 ? (
                <Button className="trial-btn" onClick={() => PurchaseSubscription(planDetails?.id)}>
                  <LockIcon />
                  {loadingDetails ? 'loading' : isNew == 2 ? 'Pay Now' : 'Start 7-day Free Trial'}
                </Button>
              ) : (
                // <Button className="disabled-btn" disabled>
                //   <LockIcon />
                //   {loadingDetails ? 'loading' : isNew == 2 ? 'Pay Now' : 'Start 7-day Free Trial'}
                // </Button>
                <SubscriptionDisableBtn
                  isNewBtn={isNew}
                  isLoading={loadingDetails}
                  firstTitle="Pay Now"
                  secTitle="Start 7-day Free Trial"
                />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Subscription;
