import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../assets';
import { SignupLetterInput } from '../../components/AllInputBox/SignLetter';
import { BlueBtn } from '../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import BannerCard from '../../components/universal/WelcomeCard';
import { notify } from '../../core/constants';
import { AxiosAll } from '../../services/axiosAll';
import { Loader } from '../../components/Skeleton/Loader';

export const SignupLetter = () => {
  const [loading, setLoading] = useState(false);

  const route = useNavigate();
  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    description: '',
    action: '',
    label: '',
  };

  const { id } = useParams();

  const validationSchema = Yup.object({
    title: Yup.string().required('Title field is required'),
    description: Yup.string().required('Description field is required'),
    action: Yup.string().required('action field is required'),
    label: Yup.string().required('Label field is required'),
  });

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values?.title);
    formData.append('description', values?.description);
    formData.append('place_holder', values?.action);
    formData.append('button_label', values?.label);

    const endPoint = id
      ? `seller/sellerSections/${id}?section_type=signup_to_newsletter&_method=PUT`
      : `seller/sellerSections?section_type=signup_to_newsletter`;

    const response = await AxiosAll('post', endPoint, formData);
    if (response?.statusCode === 200) {
      notify(response?.message, 'success');
      route('/onboarding/site-editor');
    } else {
      notify(response?.message, 'error');
    }
  };

  const getNewsLetterDetails = async () => {
    setLoading(true);

    const response = await AxiosAll('get', `seller/sellerSections/${id}?section_type=signup_to_newsletter`, null);
    if (response?.statusCode === 200) {
      formik.setFieldValue('title', response?.data?.signup_to_newsletter?.title || '');
      formik.setFieldValue('description', response?.data?.signup_to_newsletter?.description || '');
      formik.setFieldValue('action', response?.data?.signup_to_newsletter?.place_holder || '');
      formik.setFieldValue('label', response?.data?.signup_to_newsletter?.button_label || '');
      setLoading(false);
    } else {
      notify(response?.message, 'error');
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getNewsLetterDetails();
    }
  }, []);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  return (
    <div className="signup-letter">
      <Loader open={loading} />
      <BannerCard title="Sign-up pop up" para={para} backgroundImageURL={images.signupImg} />
      <div className="container-27inch">
        <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />

        <SignupLetterInput
          newsTitle="Newsletter"
          newsDes="The Newsletter gives you the opportunity to build a community by collecting some information from them."
          inputTitle="Title"
          inputPlaceholder="E.g. Yes! Send me exclusive blogs"
          desTitle="Description"
          desPlaceholder="E.g. Enter your email"
          callActionTitle="Call to action "
          callActionPlaceholder="E.g. Enter your email"
          buttonLabel="Button label"
          buttonLabelPlaceholder="E.g. Submit"
          formik={formik}
        />

        <div className="buttons">
          <div className="buttons-position">
            <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor')} />
            <BlueBtn title="Save" onClick={formik.handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};
