import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { images } from '../../../assets';
import DarkBtn from '../../universal/Buttons/DarkBtn';

const WelcomeAdminDialog = ({ dialogOpen }) => {
  const [open, setOpen] = React.useState(false);
  const nav = useNavigate();
  const handleClickOpen = () => {
    dialogOpen && setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    nav('/onboarding/dashboard');
  };
  React.useEffect(() => {
    handleClickOpen();
  }, [dialogOpen]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="welcomeadmindialogs"
      >
        <DialogContent>
          <img src={images.shop} alt="goEthical logo" className="shop-img" />
          {/* <div className="welcome-heading">Welcome to your admin dashboard</div> */}
          <div className="welcome-heading">Welcome to your Sellstream dashboard</div>
          <div className="heading-para">
            {' '}
            You’re now set up to start selling things! Go to the ‘Products’ section in the right hand navigation to get
            started!{' '}
          </div>
          <div className="btn-section">
            <DarkBtn onClick={handleClose} autoFocus title="Let's get started!" />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WelcomeAdminDialog;
