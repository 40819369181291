/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import { formats, HEADINGS, modules } from '../../../../core/constants';
import 'react-quill/dist/quill.snow.css';

/*
 * Custom toolbar component including insertStar button and dropdowns
 */
export const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-header" defaultValue="">
      {HEADINGS.map((heading, index) => (
        <option key={heading} value={index + 1}>
          {heading}
        </option>
      ))}
      <option value="">Normal</option>
    </select>
    <div className="ql-formats">
      <button type="button" className="ql-bold" />
      <button type="button" className="ql-italic" />
      <button type="button" className="ql-underline" />
      <button type="button" className="ql-strike" />
    </div>
    <div className="ql-formats">
      <button type="button" className="ql-list" value="ordered" />
      <button type="button" className="ql-list" value="bullet" />
      <button type="button" className="ql-indent" value="-1" />
      <button type="button" className="ql-indent" value="+1" />
    </div>
    <div className="ql-formats">
      <button type="button" className="ql-link" />
    </div>
    <div className="ql-formats">
      <button type="button" className="ql-clean" />
    </div>
  </div>
);

/*
 * Editor component with custom toolbar and content containers
 */
const Editor = ({ placeholder, name, value, onChange, props, error }) => {
  const handleChange = (text) => {
    return onChange(text);
  };

  const quillRef = useRef(null);

  return (
    <div className="text-editor">
      <CustomToolbar />
      <ReactQuill
        ref={quillRef}
        modules={modules}
        formats={formats}
        theme="snow" // pass false to use minimal theme
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => {
          handleChange(quillRef?.current?.editingArea?.innerText?.trim()?.length ? e : '');
        }}
        {...props}
      />
      {error && <div className="input-error-message">{error}</div>}
    </div>
  );
};

export default Editor;
