import { Button } from '@mui/material';

export const BlueBtn = ({ title, onClick, props, type, disabled, loading, customClass }) => {
  return (
    <Button
      style={{ textTransform: 'none' }}
      onClick={onClick}
      variant="contained"
      type={type}
      className={`bluebtn ${customClass}`}
      disabled={disabled}
      {...props}
    >
      {loading ? 'Loading...' : title}
    </Button>
  );
};
