import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import TextArea from '../../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { Loader } from '../../../components/Skeleton/Loader';

export const AddSizes = () => {
  const route = useNavigate();

  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    name: '',
    description: '',
    status: '1',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    status: Yup.string().required('Status is required'),
  });

  const getSizeDetails = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/sizes/${id}`, null);
    if (response.statusCode === 200) {
      const responseData = response.data;
      formik.setFieldValue('name', responseData?.name || '');
      formik.setFieldValue('description', responseData?.description || '');
      formik.setFieldValue('status', responseData?.status);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('description', values?.description);
    formData.append('status', values?.status);

    const endPoint = id ? `seller/sizes/${id}?_method=PUT` : 'seller/sizes';

    const response = await AxiosAll('post', endPoint, formData);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      route('/onboarding/site-editor/sizes');
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getSizeDetails();
    }
  }, []);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  return (
    <div className="latest-arrivals 2">
      <Loader open={loading} />
      <BannerCard title="Size" para={para} backgroundImageURL={images.latestArrivalImg} smallCard />
      <div className="main-section container-27inch">
        <div className="section-editor">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor/sizes')} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="search-engine-section">
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Name</Typography>
                  <InputTypeOutline
                    placeholder="Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && formik.errors.name}
                  />
                </div>
                <div className="meta-des">
                  <Typography>Description</Typography>
                  <TextArea
                    placeholder="Description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && formik.errors.description}
                  />
                </div>
                {/* <div className="meta-des">
                  <Typography>Status</Typography>
                  <div className="radiobtn-section">
                    <RadioGroup
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.status && formik.errors.status}
                    >
                      <FormControlLabel value="1" label="Active" control={<Radio />} />
                      <FormControlLabel value="0" label="Inactive" control={<Radio />} />
                    </RadioGroup>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor/sizes')} />
              <BlueBtn title="Save" type="submit" loading={loading} disaqed={loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
