import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { images } from '../../../../../assets';
import { deleteLogin } from '../../../../../redux/actions/login';
import { deleteUser } from '../../../../../redux/actions/user';

export default function AdminSidebarMobile() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton
            onClick={() => {
              nav('/admin/user-registration');
            }}
          >
            {/* <ListItemIcon>
                icon
              </ListItemIcon> */}
            <ListItemText>Pro Seller Requests</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton
            onClick={() => {
              nav('/admin/trending');
            }}
          >
            {/* <ListItemIcon>
                icon
              </ListItemIcon> */}
            <ListItemText>Trending Products</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton
            onClick={() => {
              nav('/admin/featured-section');
            }}
          >
            {/* <ListItemIcon>
                icon
              </ListItemIcon> */}
            <ListItemText>Featured Section</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton
            onClick={() => {
              nav('/admin/brand-with-cause');
            }}
          >
            {/* <ListItemIcon>
                icon
              </ListItemIcon> */}
            <ListItemText>Brands with Cause</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton
            onClick={() => {
              nav('/admin/seller');
            }}
          >
            {/* <ListItemIcon>
                icon
              </ListItemIcon> */}
            <ListItemText>Sellers</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton
            onClick={() => {
              dispatch(deleteLogin());
              dispatch(deleteUser());
              nav('/login');
            }}
          >
            {/* <ListItemIcon>
                icon
              </ListItemIcon> */}
            <ListItemText>Logout</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {/* <img src={images.menuIcon} /> */}
            <DehazeIcon />
          </Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
