import axios from 'axios';
import currentURL from '../env';
import { deleteLogin } from '../redux/actions/login';
import { store } from '../redux/store';

const client = axios.create({
  baseURL: currentURL.currentEnv?.replace('v2', ''),
});

export const axiosAll = ({ ...options }) => {
  const state = store.getState();
  const { guestDetails } = state.guestReducer;
  const { loginReducer } = store.getState();
  client.defaults.headers.common.userToken = loginReducer.userDetails.token ? loginReducer.userDetails.token : ``;
  client.defaults.params = {
    deviceType: 3,
    deviceToken: guestDetails?.token,
  };

  const onSuccess = (response) => {
    if (response.data.statusCode === 401) {
      // store.dispatch(deleteLogin());
      // store.dispatch(deleteUser());
    }
    return response.data;
  };

  const onError = (error) => {
    return error;
  };

  return client(options).then(onSuccess).catch(onError);
};

export const AxiosAll = async (method, url, params, token) => {
  const state = store.getState();
  const { userData } = state.guestUserReducer;
  const baseURL = currentURL.currentEnv;
  return axios({
    method,
    url: `${baseURL}${url}`,
    data: params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : userData?.userToken ? `Bearer ${userData?.userToken}` : '',
    },
  })
    .then((res) => {
      if (res.data.statusCode === 401) {
        store.dispatch(deleteLogin());
      }
      return res?.data;
    })
    .catch((error) => {
      return error?.response?.data;
    });
};
