import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import TextArea from '../../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import { UploadSingleImage } from '../../../components/universal/UploadImage';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import DropDown from '../../../components/universal/DropDown';
import { Loader } from '../../../components/Skeleton/Loader';

export const RightImageLeftContent = () => {
  const route = useNavigate();

  const [contentImage, setContentImage] = useState('');
  const [desktopImage, setDesktopImage] = useState('');
  const [tabletImage, setTabletImage] = useState('');
  const [mobileImage, setMobileImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);

  const { id } = useParams();
  const { userDetails } = useSelector((state) => state.loginReducer);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    description: '',
    buttonLabel: '',
    buttonLink: '0',
    desktopTitle: '',
    desktopDescription: '',
    desktopButtonLabel: '',
    desktopButtonLink: '0',
    tabletTitle: '',
    tabletDescription: '',
    tabletButtonLabel: '',
    tabletButtonLink: '0',
    mobileTitle: '',
    mobileDescription: '',
    mobileButtonLabel: '',
    mobileButtonLink: '0',
    image: '',
  };

  const getDropdowns = async () => {
    const types = ['links'];

    const response = await AxiosAll(
      'get',
      `dropdowns?type[]=${types.join('&type[]=')}&seller_id=${userDetails?.user?.id}`,
    );
    if (response.statusCode === 200) {
      setLinks(response?.data?.links);
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    buttonLabel: Yup.string().required('Button Label is required'),
    image: Yup.string().required('Left Section Image is required'),
  });

  const getContentDetails = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/sellerSections/${id}?section_type=right_image_left_content`, null);
    if (response.statusCode === 200) {
      const content = response?.data?.right_image_left_content;
      formik.setFieldValue('title', content?.left_section_title || '');
      formik.setFieldValue('description', content?.left_section_description || '');
      formik.setFieldValue('buttonLabel', content?.left_section_label || '');
      formik.setFieldValue('buttonLink', content?.left_section_button_link || '');
      formik.setFieldValue('desktopTitle', content?.left_section_title_web || '');
      formik.setFieldValue('desktopDescription', content?.left_section_description_web || '');
      formik.setFieldValue('desktopButtonLabel', content?.left_section_label_web || '');
      formik.setFieldValue('desktopButtonLink', content?.left_section_button_link_web || '');
      formik.setFieldValue('tabletTitle', content?.left_section_title_tablet || '');
      formik.setFieldValue('tabletDescription', content?.left_section_description_tablet || '');
      formik.setFieldValue('tabletButtonLabel', content?.left_section_label_tablet || '');
      formik.setFieldValue('tabletButtonLink', content?.left_section_button_link_tablet || '');
      formik.setFieldValue('mobileTitle', content?.left_section_title_mobile || '');
      formik.setFieldValue('mobileDescription', content?.left_section_description_mobile || '');
      formik.setFieldValue('mobileButtonLabel', content?.left_section_label_mobile || '');
      formik.setFieldValue('mobileButtonLink', content?.left_section_button_link_mobile || '');
      formik.setFieldValue('image', content?.right_section_image || '');

      setContentImage(content?.right_section_image || '');
      setDesktopImage(content?.right_section_image_web || '');
      setTabletImage(content?.right_section_image_tablet || '');
      setMobileImage(content?.right_section_image_mobile || '');
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    if (contentImage.length === 0) {
      notify('Please upload image', 'error');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('left_section_title', values?.title);
    formData.append('left_section_description', values?.description);
    formData.append('left_section_label', values?.buttonLabel);
    formData.append('left_section_button_link', values?.buttonLink);
    formData.append('right_section_image', contentImage);
    formData.append('left_section_title_web', values?.desktopTitle);
    formData.append('left_section_description_web', values?.desktopDescription);
    formData.append('left_section_label_web', values?.desktopButtonLabel);
    formData.append('left_section_button_link_web', values?.desktopButtonLink);
    formData.append('right_section_image_web', desktopImage);
    formData.append('left_section_title_tablet', values?.tabletTitle);
    formData.append('left_section_description_tablet', values?.tabletDescription);
    formData.append('left_section_label_tablet', values?.tabletButtonLabel);
    formData.append('left_section_button_link_tablet', values?.tabletButtonLink);
    formData.append('right_section_image_tablet', tabletImage);
    formData.append('left_section_title_mobile', values?.mobileTitle);
    formData.append('left_section_description_mobile', values?.mobileDescription);
    formData.append('left_section_label_mobile', values?.mobileButtonLabel);
    formData.append('left_section_button_link_mobile', values?.mobileButtonLink);
    formData.append('right_section_image_mobile', mobileImage);

    const endPoint = id
      ? `seller/sellerSections/${id}?section_type=right_image_left_content&_method=PUT`
      : 'seller/sellerSections?section_type=right_image_left_content';

    const response = await AxiosAll('post', endPoint, formData);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      route('/onboarding/site-editor');
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getContentDetails();
    }
    getDropdowns();
  }, []);
  useEffect(() => {
    formik.setFieldValue('image', contentImage);
  }, [contentImage]);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  return (
    <div className="right-img-left-content">
      <Loader open={loading} />
      <BannerCard title="Right Image Left Content" para={para} backgroundImageURL={images.tellYourStoryImg} smallCard />
      <div className="main-section container-27inch">
        <div className="section-editor">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="search-engine-section">
            <div className="left-section">
              <Typography variant="h4">Right Section</Typography>
              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Title"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.title && formik.errors.title}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Description</Typography>
                    <TextArea
                      placeholder="Description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.description && formik.errors.description}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Label</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="buttonLabel"
                      value={formik?.values?.buttonLabel}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.buttonLabel && formik?.errors?.buttonLabel}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Link</Typography>
                    <DropDown
                      placholder="Select"
                      name="buttonLink"
                      handleChange={(e) => formik.setFieldValue('buttonLink', e.target.value)}
                      value={formik.values.buttonLink}
                      option={links}
                      touched={formik.touched.buttonLink}
                      error={formik.errors.buttonLink}
                      blur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Typography variant="h4">Left Section</Typography>
              <div className="input-title">
                <div className="meta-info">
                  <UploadSingleImage
                    title="Left Section Image"
                    description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={contentImage}
                    setImage={setContentImage}
                    imageSize={2}
                  />
                </div>
                {formik?.touched?.image && formik?.errors?.image && (
                  <div className="input-error-message">{formik?.errors?.image}</div>
                )}
              </div>
            </div>
          </div>

          {/* Desktop Section */}
          <Typography variant="h4" className="heading-meta">
            Desktop
          </Typography>
          <div className="search-engine-section">
            <div>
              <Typography variant="h4">Right Section</Typography>
              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Title"
                      name="desktopTitle"
                      value={formik.values.desktopTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.desktopTitle && formik.errors.desktopTitle}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Description</Typography>
                    <TextArea
                      placeholder="Description"
                      name="desktopDescription"
                      value={formik.values.desktopDescription}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.desktopDescription && formik.errors.desktopDescription}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Label</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="desktopButtonLabel"
                      value={formik?.values?.desktopButtonLabel}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.desktopButtonLabel && formik?.errors?.desktopButtonLabel}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Link</Typography>
                    <div className="left-section">
                      <div className="btn-section-new">
                        <DropDown
                          placholder="Select"
                          name="desktopButtonLink"
                          handleChange={(e) => formik.setFieldValue('desktopButtonLink', e.target.value)}
                          value={formik.values.desktopButtonLink}
                          option={links}
                          touched={formik.touched.desktopButtonLink}
                          error={formik.errors.desktopButtonLink}
                          blur={formik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Typography variant="h4">Left Section</Typography>
              <div className="input-title">
                <div className="meta-info">
                  <UploadSingleImage
                    title="Left Section Image"
                    description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={desktopImage}
                    setImage={setDesktopImage}
                    imageSize={2}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Tablet Section */}
          <Typography variant="h4" className="heading-meta">
            Tablet
          </Typography>
          <div className="search-engine-section">
            <div>
              <Typography variant="h4">Right Section</Typography>
              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Title"
                      name="tabletTitle"
                      value={formik.values.tabletTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.tabletTitle && formik.errors.tabletTitle}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Description</Typography>
                    <TextArea
                      placeholder="Description"
                      name="tabletDescription"
                      value={formik.values.tabletDescription}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.tabletDescription && formik.errors.tabletDescription}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Label</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="tabletButtonLabel"
                      value={formik?.values?.tabletButtonLabel}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.tabletButtonLabel && formik?.errors?.tabletButtonLabel}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Link</Typography>
                    <DropDown
                      placholder="Select"
                      name="tabletButtonLink"
                      handleChange={(e) => formik.setFieldValue('tabletButtonLink', e.target.value)}
                      value={formik.values.tabletButtonLink}
                      option={links}
                      touched={formik.touched.tabletButtonLink}
                      error={formik.errors.tabletButtonLink}
                      blur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Typography variant="h4">Left Section</Typography>
              <div className="input-title">
                <div className="meta-info">
                  <UploadSingleImage
                    title="Left Section Image"
                    description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={tabletImage}
                    setImage={setTabletImage}
                    imageSize={2}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Mobile Section */}
          <Typography variant="h4" className="heading-meta">
            Mobile
          </Typography>
          <div className="search-engine-section">
            <div>
              <Typography variant="h4">Right Section</Typography>
              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Title"
                      name="mobileTitle"
                      value={formik.values.mobileTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.mobileTitle && formik.errors.mobileTitle}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Description</Typography>
                    <TextArea
                      placeholder="Description"
                      name="mobileDescription"
                      value={formik.values.mobileDescription}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.mobileDescription && formik.errors.mobileDescription}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Label</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="mobileButtonLabel"
                      value={formik?.values?.mobileButtonLabel}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.mobileButtonLabel && formik?.errors?.mobileButtonLabel}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Link</Typography>
                    <DropDown
                      placholder="Select"
                      name="mobileButtonLink"
                      handleChange={(e) => formik.setFieldValue('mobileButtonLink', e.target.value)}
                      value={formik.values.mobileButtonLink}
                      option={links}
                      touched={formik.touched.mobileButtonLink}
                      error={formik.errors.mobileButtonLink}
                      blur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Typography variant="h4">Left Section</Typography>
              <div className="input-title">
                <div className="meta-info">
                  <UploadSingleImage
                    title="Left Section Image"
                    description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={mobileImage}
                    setImage={setMobileImage}
                    imageSize={2}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor')} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
