/* eslint-disable no-shadow */
import { Typography, Dialog, DialogActions, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { images } from '../../../assets';
import { ProductSectionCard } from '../../../components/ProductSection/ProductSectionCard';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import ProductsCard from '../../../components/universal/ProductsCard';
import { ToggleButtons } from '../../../components/universal/Toggle';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { CustomProductFilterSection } from './filterSection';
import { UploadSingleImage } from '../../../components/universal/UploadImage';
import TextArea from '../../../components/universal/InputType/TextArea';
import { Loader } from '../../../components/Skeleton/Loader';

export const AddCustomProducts = () => {
  const route = useNavigate();

  const [loading, setLoading] = useState(false);
  const [sectionType, setSectionType] = useState(2);
  const [productList, setProductList] = useState([]);
  const [existingProducts, setExistingProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [validFilter, setValidFilter] = useState(false);
  const [metaImage, setMetaImage] = useState('');
  const [errors, setErrors] = useState({});
  const [deleteId, setDeleteId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [product, setProduct] = useState([]);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  const { id } = useParams();
  const filterBtnRef = useRef();

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    metaTitle: Yup.string(),
    metaDescription: Yup.string(),
    metaKeywords: Yup.string(),
  });

  const getCustomProductDetails = async () => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `seller/sellerSections/${id}?section_type=custom_products&_method=PUT`,
      null,
    );
    if (response.statusCode === 200) {
      const customData = response.data?.custom_product;
      formik.setFieldValue('title', customData?.title || '');
      formik.setFieldValue('metaTitle', customData?.seo_meta_title || '');
      formik.setFieldValue('metaDescription', customData?.seo_meta_description || '');
      formik.setFieldValue('metaKeywords', customData?.seo_meta_keywords || '');
      setMetaImage(customData?.seo_meta_image || '');
      // setSectionType(customData?.prod_sec_type || 2);
      if (customData?.prod_sec_type === 1) {
        setFilterData(customData?.filter_section_data);
      }
      // if (customData?.prod_sec_type === 2) {
      const productIds = customData?.products?.map((item) => item?.id);
      setSelectedProduct(productIds);
      setExistingProducts(productIds);
      setProductList(customData?.products);
      setLoading(false);
      // }
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const handleDelete = (productId) => {
    const filteredProduct = productList.filter((item) => item?.id !== productId);
    const filteredProductIds = selectedProduct.filter((item) => item !== productId);
    setSelectedProduct(filteredProductIds);
    setExistingProducts(filteredProductIds);
    setProductList(filteredProduct);
    setOpenDelete(false);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('prod_sec_type', sectionType);
    formData.append('seo_meta_title', values?.metaTitle);
    formData.append('seo_meta_description', values?.metaDescription);
    formData.append('seo_meta_keywords', values?.metaKeywords);
    formData.append('seo_meta_image', metaImage);

    if (sectionType === 1) {
      formData.append('filter_section_data[]', filterData);
    }
    if (sectionType === 2) {
      if (productList.length > 0) {
        for (let i = 0; i < productList.length; i++) {
          formData.append(`product_ids[${i}]`, productList[i]?.id);
        }
      } else {
        formData.append(`product_ids`, '');
      }
    }

    const data =
      sectionType === 1
        ? {
            title: values.title,
            prod_sec_type: sectionType,
            filter_section_data: filterData,
            seo_meta_title: values?.metaTitle,
            seo_meta_description: values?.metaDescription,
            seo_meta_keywords: values?.metaKeywords,
            seo_meta_image: metaImage,
          }
        : formData;

    const endPoint = id
      ? `seller/sellerSections/${id}?section_type=custom_products&_method=PUT`
      : 'seller/sellerSections?section_type=custom_products';

    const response = await AxiosAll('post', endPoint, data);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      route('/onboarding/site-editor');
    } else {
      notify(response.message, 'error');
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...productList];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    const updateImage = { newSections };
    setProductList(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };
  const getProducts = async () => {
    const response = await AxiosAll('get', `seller/products?&search=${search || ''}`, null);
    if (response?.statusCode === 200) {
      const responseData = response?.data?.data;
      // notify(response.message, 'success');

      setProduct(responseData);
    } else {
      // notify(response.message, 'error');
    }
  };
  useEffect(() => {
    if (search !== '') {
      getProducts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleSearch = (e) => {
    if (e?.target?.value) {
      setSearchText(e?.target?.value);
    } else {
      setSearchText('');
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    if (id) {
      getCustomProductDetails();
    }
  }, []);

  useEffect(() => {
    if (validFilter && sectionType === 1) {
      formik.handleSubmit();
      setValidFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionType, validFilter]);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  useEffect(() => {
    if (productList?.length) {
      setProduct([]);
    }
  }, [productList]);
  return (
    <div className="customer-product">
      <Loader open={loading} />
      <BannerCard title="Custom Product" para={para} backgroundImageURL={images.latestArrivalImg} smallCard />
      <div className="main-section container-27inch">
        <div className="section-editor">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
        </div>
        <div className="search-engine-section">
          <Typography variant="h4">Custom Product</Typography>
          <div className="input-title">
            <div className="meta-info">
              <div className="meta-title">
                <Typography>Title</Typography>
                <InputTypeOutline
                  placeholder="Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && formik.errors.title}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="search-engine-section">
          <Typography variant="h4">Search Engine Optimisation</Typography>
          <Typography paragraph className="description">
            Product SEO is the practice of optimising elements of product to provide search engines with enough relevant
            content to get the product found in search engines.
          </Typography>

          <div className="input-title">
            <div className="meta-info">
              <div className="meta-title">
                <Typography>Meta Title</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="metaTitle"
                  value={formik?.values?.metaTitle}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.metaTitle && formik?.errors?.metaTitle}
                />
              </div>
              <div className="meta-des">
                <Typography>Meta Description</Typography>
                <TextArea
                  placeholder="Enter"
                  name="metaDescription"
                  value={formik?.values?.metaDescription}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.metaDescription && formik?.errors?.metaDescription}
                />
              </div>
              <div className="meta-keywords">
                <Typography>Meta Keywords</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="metaKeywords"
                  value={formik?.values?.metaKeywords}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.metaKeywords && formik?.errors?.metaKeywords}
                />
              </div>
              <div className="single-upload">
                <UploadSingleImage
                  title="Meta Image"
                  description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                  image={metaImage}
                  setImage={setMetaImage}
                  imageSize={2}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="add-custom-product-toggle-btn">
          <ToggleButtons type={sectionType} setType={setSectionType} hideExternalLinkSection />
        </div> */}

        <div className="filter-table">
          {sectionType === 1 && (
            <CustomProductFilterSection
              filterData={filterData}
              setFilterData={setFilterData}
              filterBtnRef={filterBtnRef}
              setValidFilter={setValidFilter}
              errors={errors}
              setErrors={setErrors}
            />
          )}
          {sectionType === 2 && (
            <div className="product-list">
              <Typography variant="h4">Add Custom Products</Typography>
              <div className="product-gallery">
                <Autocomplete
                  options={product?.map((prediction) => prediction?.name)}
                  noOptionsText="No Products Found"
                  inputValue={searchText}
                  onInputChange={handleSearch}
                  renderInput={(params) => <TextField {...params} label="Add Custom Products" variant="outlined" />}
                  renderOption={(option) => <div>{option}</div>}
                  getOptionSelected={(option, value) => option === value}
                  filterSelectedOptions={true}
                  onChange={(event, value) => {
                    if (value) {
                      const selectedPrediction = product?.find((prediction) => prediction?.name === value);

                      if (selectedPrediction) {
                        const isDuplicate = productList?.some((product) => product?.id === selectedPrediction.id);

                        if (isDuplicate) {
                          notify('This product has already been added', 'error');
                          return;
                        }

                        const updatedTopPicks = [...productList, selectedPrediction];

                        setProductList(updatedTopPicks);
                      }
                    }
                  }}
                />{' '}
                {productList?.map((productImg, index) => {
                  return (
                    <div className="img-container" key={productImg?.id}>
                      <label className="label-file" htmlFor={`myCheckbox${index}`}>
                        <ProductsCard
                          dragStart={(e) => handleDragStart(e, index)}
                          dragOver={(e) => handleDragOver(e, index)}
                          dragEnd={() => setDraggedOverIndex(null)}
                          dropCard={(e) => handleDrop(e, index)}
                          image={productImg?.cover_image?.image}
                          name={productImg?.name}
                          copyUrl={productImg?.banner_image}
                          id={productImg?.id}
                          isImages
                        />
                      </label>
                      {/* <Checkbox
                        className="checkbox-style"
                        {...Label}
                        id={`myCheckbox${index}`}
                        checkedIcon={<CheckCircleIcon />}
                        checked={!!selectedProductIds?.ids?.includes(productImg?.id)}
                        value={productImg?.id}
                        onClick={(e) => handleCheckbox(productImg?.id, e)}
                        icon={<RadioButtonUncheckedIcon />}
                      /> */}

                      {productList?.length > 0 ? (
                        <DeleteIcon
                          onClick={() => {
                            setOpenDelete(true);
                            setDeleteId(productImg?.id);
                          }}
                        />
                      ) : null}
                    </div>
                  );
                })}
                <Dialog
                  open={openDelete}
                  onClose={() => {
                    setOpenDelete(false);
                    setDeleteId(null);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className="delete-pop-up"
                >
                  <div className="delete-container">
                    <Typography variant="h2">Delete</Typography>
                    <Typography paragraph>Are you sure you want to delete this product?</Typography>
                  </div>
                  <DialogActions>
                    <BlueBtn title="Yes" onClick={() => handleDelete(deleteId)} />
                    <LightBtn
                      title="No"
                      onClick={() => {
                        setOpenDelete(false);
                        setDeleteId(null);
                      }}
                    />
                  </DialogActions>
                </Dialog>
                {/* <CardPagination
                page={page}
                noOfPages={productImages?.last_page}
                handleChange={(e, value) => setPage(value)}
                rowsPerPage={productImages?.per_page}
              /> */}
              </div>
            </div>
          )}
        </div>

        <div className="buttons">
          <div className="buttons-position">
            <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor')} />
            <BlueBtn
              title="Save"
              // type="submit"
              onClick={() => {
                if (sectionType === 1) {
                  filterBtnRef?.current?.click();
                } else {
                  formik.handleSubmit();
                }
              }}
              loading={loading}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
