/* eslint-disable no-debugger */
import InfoIcon from '@mui/icons-material/Info';
import { Container, Grid, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DispatchOrderDialog from '../../../components/dialogs/DispatchOrder';
import RefundDialog from '../../../components/dialogs/Refund';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import MainHeading from '../../../components/universal/Headings/MainHeading';
import SubHeading from '../../../components/universal/Headings/SubHeading';
import { capitalizeFirstLetter, toPascalCase } from '../../../core/constants';
import { AxiosAll, axiosAll } from '../../../services/axiosAll';
import BannerCard from '../../../components/universal/WelcomeCard';
import CancelOrderDialog from '../../../components/dialogs/CancleOrder';

const SellerOrderDetails = () => {
  const [orderDetail, setOrderDetail] = useState();
  const [refundedItems, setRefundedItems] = useState([]);
  const [makeADispatch, setMakeADispatch] = useState([]);
  const [isProductRefundable, setIsProductRefundable] = useState(false);
  const [showActionColumn, setShowActionColumn] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openDailog, setOpenDailog] = React.useState(false);

  const location = useLocation();
  const Order_id = location?.state?.id;
  const Order = location?.state?.order;

  const path = `/onboarding/invoice?order_id=${Order_id}`;

  const handleOrderDetail = async (OrderId) => {
    const responce = await AxiosAll('get', `seller/orders/${OrderId}`, null);
    setOrderDetail(responce?.data);
    const isPending = responce?.data?.order_items?.filter(
      (item) =>
        item?.order_status?.name === 'PROCESSING' ||
        item?.order_status?.name === 'DISPATCHED' ||
        item?.order_status?.name === 'DISPUTED',
    );
    if (isPending?.length > 0) {
      setShowActionColumn(true);
    } else {
      setShowActionColumn(false);
    }
  };
  const handleNavigate = () => {
    window.open(window.location.origin + path, '_blank');
    // Nav(path, { state: { orderId: Order_id } });
  };

  useEffect(() => {
    handleOrderDetail(Order_id);
  }, [open, openDailog]);

  useEffect(() => {
    const refund = orderDetail?.order_items?.filter(
      (item) => item?.order_status?.name === 'DISPUTED' && item?.transaction_dispute?.dispute_resolved_status === 1,
    );

    if (refund?.length > 0) {
      setIsProductRefundable(true);
      setRefundedItems(refund);
    } else {
      setIsProductRefundable(false);
    }
  }, [orderDetail]);

  useEffect(() => {
    const isPending = orderDetail?.order_items?.filter((item) => item?.order_status?.name === 'PROCESSING');
    setMakeADispatch(isPending);
  }, [orderDetail]); // if any item from orderDetail?.order_items is  item?.order_status?.nameMessage === 'PENDING' or item?.trackingNo then return true else return false

  return (
    <div className="sellerorderdetails">
      <BannerCard
        title="Order Details"
        para="This is where you can view and upload photos of your products. When bulk uploading products you need to upload your images here and reference the link in your CSV."
        smallCard
      />
      <Container className="all-container-27inch">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} className="sellerorderdetails-container">
            {/* <MainHeading title={Headings} />
            <div className="order-para">
              Once you have marked item(s) as dispatched we will release payment
              to your nominated bank account.
            </div> */}
            <div className="data-container">
              <SubHeading title="Details" />
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={6} sm={6} md={3}>
                  <div className="ordernumber"> Order number </div>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <div className="orderid">
                    <span>(#{orderDetail?.generated_order_id})</span>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={6} sm={6} md={3}>
                  <div className="ordernumber"> Order Date </div>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <div className="orderid">{moment(orderDetail?.created_at).format('MMMM D, YYYY [@]h:mm A')}</div>
                </Grid>
              </Grid>

              {!(orderDetail?.order_items[0]?.status === 1 || orderDetail?.order_items[0]?.status === 3) &&
              (orderDetail?.order_items[0]?.orderShippingCharges?.shippingCourier ||
                orderDetail?.order_items[0]?.orderShippingCharges?.shippingClass) ? (
                <>
                  {orderDetail?.order_items[0]?.orderShippingCharges?.shippingCourier ? (
                    <Grid container spacing={0} className="borderadd">
                      <Grid item xs={6} sm={6} md={3}>
                        <div className="ordernumber">Courier</div>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <div className="orderid">
                          {orderDetail?.order_items[0]?.orderShippingCharges?.shippingCourier}
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                  {orderDetail?.order_items[0]?.orderShippingCharges?.shippingClass ? (
                    <Grid container spacing={0} className="borderadd">
                      <Grid item xs={6} sm={6} md={3}>
                        <div className="ordernumber">Courire Class</div>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <div className="orderid">
                          {orderDetail?.order_items[0]?.orderShippingCharges?.shippingClass}
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              ) : null}

              <Grid container spacing={0} className="borderadd">
                <Grid item xs={6} sm={6} md={3}>
                  <div className="ordernumber">Buyer Name</div>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <div className="orderid">{capitalizeFirstLetter(orderDetail?.order_buyer?.name)}</div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={6} sm={6} md={3}>
                  <div className="ordernumber">Buyer Email</div>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <div className="orderid">{orderDetail?.order_buyer?.email}</div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={6} sm={6} md={3}>
                  <div className="ordernumber">Shipping Address</div>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <div className="orderid">
                    {orderDetail?.order_buyer?.shipping_addess_name},{'  '}
                    {orderDetail?.order_buyer?.shipping_addess_line_one},
                    {orderDetail?.order_buyer?.shipping_addess_line_two},
                    {orderDetail?.order_buyer?.shipping_addess_city},{orderDetail?.order_buyer?.shipping_addess_country}
                    ,
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={6} sm={6} md={3}>
                  <div className="ordernumber">Billing Address</div>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <div className="orderid">
                    {orderDetail?.order_buyer?.billing_addess_name},{'  '}
                    {orderDetail?.order_buyer?.billing_addess_line_one},
                    {orderDetail?.order_buyer?.billing_addess_line_two},{orderDetail?.order_buyer?.billing_addess_city},
                    {orderDetail?.order_buyer?.billing_addess_country}
                    {'  '}
                  </div>
                </Grid>
              </Grid>
              {/* <Grid container spacing={0}>
                <Grid item xs={12} sm={2}>
                  <div className="ordernumber">Tracking number</div>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div className="orderid">{orderDetail?.order_items[0]?.tracking_no}</div>
                </Grid>
              </Grid> */}
            </div>
            <SubHeading title="Order Items" />
            <div className="table-container">
              <table className="seller-order-table">
                <tr className="table-heading seller-ordertable">
                  <th>Item</th>
                  <th style={{ textAlign: 'center' }}>Status</th>
                  {showActionColumn && <th style={{ textAlign: 'center' }}>Action</th>}
                  <th style={{ textAlign: 'center' }}>Quantity</th>
                  <th style={{ textAlign: 'end', paddingRight: '40px' }}>Total(£)</th>
                </tr>
                {orderDetail?.order_items?.map((item, index) => {
                  return (
                    <tr key={index} className="table-data">
                      <td>
                        {item?.product?.product_name}
                        {Order === 'order' ? (
                          <div>
                            {item?.personalization ? <p>Personalisation: {item?.personalization}</p> : null}
                            {item?.product?.product_variant_size_name ? (
                              <p> Size : {item?.product?.product_variant_size_name}</p>
                            ) : null}
                            {item?.product?.product_variant_color_name ? (
                              <p> Color : {item?.product?.product_variant_color_name}</p>
                            ) : null}
                            {item?.gift_message ? <p> Gift : {item?.gift_message}</p> : null}
                            {item?.is_gift_wrapping ? (
                              <p> Gift Wrapping : £{item?.gift_wrapping_price?.toFixed(2)}</p>
                            ) : null}
                            {item?.tracking_no && <p> Tracking number : {item?.tracking_no}</p>}
                            {item?.seller_payout_date && (
                              <p> Payout Date : {moment(item?.seller_payout_date).format('MMMM D, YYYY [@]h:mm A')}</p>
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <div
                          className={`orderid ${
                            item?.status === 2
                              ? 'pendingbtn'
                              : item?.status === 3
                              ? 'deliveredbtn'
                              : item?.status === 4
                              ? 'deliveredbtn'
                              : item?.status === 5
                              ? 'cancelbtn'
                              : item?.status === 6
                              ? 'cancelbtn'
                              : item?.status === 7
                              ? 'partialbtn'
                              : 'cancelbtn'
                          }`}
                        >
                          {toPascalCase(item?.order_status?.slug)}
                        </div>
                      </td>
                      {showActionColumn && (
                        <td className="tabel-order-btn">
                          {(item?.order_status?.name === 'DISPUTED' || item?.order_status?.name === 'DISPATCHED') && (
                            <div tabIndex={0} role="button" onClick={handleNavigate}>
                              <LightBtn title="View invoice" />
                            </div>
                          )}
                          {item?.order_status?.name === 'DISPUTED' &&
                            item?.transaction_dispute?.dispute_resolved_status === 1 && (
                              <RefundDialog
                                OrdersList={refundedItems?.map((refundItem) => {
                                  return {
                                    value: refundItem?.id,
                                    title: refundItem?.product?.product_name,
                                    total_amount: refundItem?.total_charge,
                                  };
                                })}
                                open={open}
                                setOpenUp={setOpen}
                              />
                            )}
                          {item?.order_status?.name === 'PROCESSING' && (
                            <div
                              tabIndex={0}
                              role="button"
                              onClick={handleNavigate}
                              className="last-btn-section-container"
                            >
                              <LightBtn title="Generate Invoice" />
                            </div>
                          )}
                          {item?.order_status?.name === 'PROCESSING' && makeADispatch?.length > 0 && (
                            <DispatchOrderDialog
                              OrdersList={makeADispatch?.map((e) => {
                                return {
                                  value: e?.id,
                                  title: e?.product?.product_name,
                                  shipping_class: e?.shipping?.shipping_class,
                                  shipping_name: e?.shipping?.shipping_courier,
                                };
                              })}
                              id={Order_id}
                              open={open}
                              setOpen={setOpen}
                              // courier={makeADispatch?.order_items[0]?.total_shipping_charge?.shippingCourier}
                              // CourierClass={makeADispatch?.order_items[0]?.orderShippingCharges?.shippingClass}
                            />
                          )}
                          {item?.order_status?.name === 'PROCESSING' && makeADispatch?.length > 0 && (
                            <CancelOrderDialog
                              OrdersList={makeADispatch?.map((e) => {
                                return {
                                  value: e?.id,
                                  title: e?.product?.product_name,
                                  shipping_class: e?.shipping?.shipping_class,
                                  shipping_name: e?.shipping?.shipping_courier,
                                };
                              })}
                              id={Order_id}
                              open={openDailog}
                              setOpenin={setOpenDailog}
                              // courier={makeADispatch?.order_items[0]?.total_shipping_charge?.shippingCourier}
                              // CourierClass={makeADispatch?.order_items[0]?.orderShippingCharges?.shippingClass}
                            />
                          )}
                          {!(
                            item?.order_status?.name === 'DISPUTED' ||
                            item?.order_status?.name === 'DISPATCHED' ||
                            item?.order_status?.name === 'PROCESSING'
                          ) && '--'}
                        </td>
                      )}
                      <td style={{ textAlign: 'center' }}>{item?.stock}</td>
                      <td style={{ textAlign: 'end', paddingRight: '40px' }}>£{item?.price_with_no_vat?.toFixed(2)}</td>
                    </tr>
                  );
                })}
              </table>
              <div className="second-table">
                <div className="second-table-bg">
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={8} md={8}>
                      <div />
                    </Grid>
                    <Grid item xs={3.5} sm={2} md={2}>
                      <div className="table-data2 "> Subtotal </div>
                    </Grid>
                    <Grid item xs={3.5} sm={2} md={2}>
                      <div className="table-price">£{orderDetail?.sub_total?.toFixed(2)}</div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={8}>
                      <div />
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-data2 "> Shipping </div>
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-price">£{orderDetail?.shipping_total?.toFixed(2)}</div>
                    </Grid>
                  </Grid>

                  {orderDetail?.gift_price_total ? (
                    <Grid container spacing={2}>
                      <Grid item xs={5} sm={8}>
                        <div />
                      </Grid>
                      <Grid item xs={3.5} sm={2}>
                        <div className="table-data2 "> Gift Wrapping </div>
                      </Grid>
                      <Grid item xs={3.5} sm={2}>
                        <div className="table-price">£{orderDetail?.gift_price_total?.toFixed(2)}</div>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={8}>
                      <div />
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-data2 "> Vat @ 20% </div>
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-price"> £{orderDetail?.vat_total?.toFixed(2)}</div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={8}>
                      <div />
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-data2 "> Gross Total</div>
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-price"> £{orderDetail?.total_amount?.toFixed(2)}</div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>{' '}
            {orderDetail?.order_items[0]?.statusMessage === 'DEFAULT' ? (
              ''
            ) : (
              <div className="data-container">
                <SubHeading title="Fee breakdown" />
                <Grid container spacing={0} className="borderadd">
                  <Grid item xs={6} sm={6} md={3}>
                    <div className="ordernumber"> Gross amount </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <div className="orderid">£{orderDetail?.total_amount?.toFixed(2)}</div>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="borderadd">
                  <Grid item xs={6} sm={6} md={3}>
                    <div className="ordernumber"> Card processing fee </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <div className="orderid">£{orderDetail?.stripe_processing_fees?.toFixed(2)}</div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  // className="borderadd"
                >
                  <Grid item xs={6} sm={6} md={3}>
                    <div className="ordernumber">
                      Net Payout
                      <Tooltip
                        title="This value will be calculated once the product is dispatched and Stripe has charged the buyer."
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <div className="orderid">£{orderDetail?.order_items[0]?.seller_amount?.toFixed(2)}</div>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={0}>
                  <Grid item xs={12} sm={2}>
                    <div className="ordernumber">Payout Date</div>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <div className="orderid">{moment(orderDetail?.created_at).format('MMMM D, YYYY [@]h:mm A')}</div>
                  </Grid>
                </Grid> */}
              </div>
            )}
            {orderDetail?.order_items?.map((item, index) => {
              return (
                item?.transaction_refund?.order_refund_date && (
                  <div key={index} className="data-container">
                    <SubHeading title="Refund" />
                    <Grid container spacing={0} className="borderadd">
                      <Grid item xs={12} sm={2}>
                        <div className="ordernumber"> Refund Amount </div>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div className="orderid">£{item?.transaction_refund?.order_refund_amount}</div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0} className="borderadd">
                      <Grid item xs={12} sm={2}>
                        <div className="ordernumber">
                          Refund Status{' '}
                          <Tooltip
                            title="After you initiate a refund, Stripe submits refund requests to your customer's bank or card issuer. Your customer sees the refund as a credit approximately 5-10 business days later, depending upon the bank."
                            placement="right"
                          >
                            <InfoIcon />
                          </Tooltip>{' '}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div className="orderid">Refunded</div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0} className="borderadd">
                      <Grid item xs={12} sm={2}>
                        <div className="ordernumber">Refund Date </div>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div className="orderid">
                          {' '}
                          {moment(item?.transaction_refund?.order_refund_date).format('DD/MM/YYYY [@]h:mm A')}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )
              );
            })}
            {/* {orderDetail?.order_items[0]?.statusMessage === 'DEFAULT' ? (
              ''
            ) : ( */}
            {/* <>
              <div className="last-btn-section">
                {orderDetail?.order_items[0]?.order_status?.name === 'DISPUTED' ||
                orderDetail?.order_items[0]?.order_status?.name === 'DISPATCHED' ? (
                  <div tabIndex={0} role="button" onClick={handleNavigate}>
                    <LightBtn title="View invoice" />
                  </div>
                ) : (
                  ''
                )}

                {isProductRefundable ? (
                  <RefundDialog
                    OrdersList={refundedItems?.map((item) => {
                      return {
                        value: item?.id,
                        title: item?.product?.product_name,
                        total_amount: item?.total_charge,
                      };
                    })}
                    open={open}
                    setOpenUp={setOpen}
                  />
                ) : (
                  ' '
                )}
              </div>
              <div className="last-btn-section">
                {orderDetail?.order_items[0]?.order_status?.name === 'PROCESSING' && (
                  <div tabIndex={0} role="button" onClick={handleNavigate} className="last-btn-section-container">
                    <LightBtn title="Generate Invoice" />
                  </div>
                )}
                {makeADispatch?.length > 0 && (
                  <DispatchOrderDialog
                    OrdersList={makeADispatch?.map((e) => {
                      return {
                        value: e?.id,
                        title: e?.product?.product_name,
                        shipping_class: e?.shipping?.shipping_class,
                        shipping_name: e?.shipping?.shipping_courier,
                      };
                    })}
                    id={Order_id}
                    open={open}
                    setOpen={setOpen}
                    // courier={makeADispatch?.order_items[0]?.total_shipping_charge?.shippingCourier}
                    // CourierClass={makeADispatch?.order_items[0]?.orderShippingCharges?.shippingClass}
                  />
                )}

                {makeADispatch?.length > 0 && (
                  <CancelOrderDialog
                    OrdersList={makeADispatch?.map((e) => {
                      return {
                        value: e?.id,
                        title: e?.product?.product_name,
                        shipping_class: e?.shipping?.shipping_class,
                        shipping_name: e?.shipping?.shipping_courier,
                      };
                    })}
                    id={Order_id}
                    open={openDailog}
                    setOpenin={setOpenDailog}
                    // courier={makeADispatch?.order_items[0]?.total_shipping_charge?.shippingCourier}
                    // CourierClass={makeADispatch?.order_items[0]?.orderShippingCharges?.shippingClass}
                  />
                )}
              </div>
            </> */}
            {/* )} */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SellerOrderDetails;
