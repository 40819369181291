import { Button } from '@mui/material';
import React from 'react';

export const OrangeBtn = ({ title, onClick, type, loading, disabled, styleClass }) => {
  return (
    <Button
      onClick={onClick}
      type={`${type}`}
      disabled={disabled}
      variant="contained"
      className={`Orangebtn ${styleClass}`}
    >
      {' '}
      {loading ? 'loading...' : title}
    </Button>
  );
};
