import React from 'react';

const MainHeading = ({ title, onClick }) => {
  return (
    <div role="button" tabIndex={0} onClick={onClick} className="main-heading40 trending-heading">
      {title}
    </div>
  );
};

export default MainHeading;
