import { Dialog, DialogActions, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { images } from '../../../assets';
import { notify, truncateText } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import { RadioButtonsGroup } from '../RadioBtnTable';
import { TableActionBtn } from '../TableActionBtn';
import { ImgCard } from '../imgCard';

export const DynamicBlockBannerTable = ({ list, getList }) => {
  const navigate = useNavigate();

  const handleDelete = async (id) => {
    const response = await AxiosAll('delete', `seller/common-dynamic-banners/${id}`, null);
    if (response?.statusCode === 200) {
      notify(response?.message, 'success');
      getList && getList();
      handleClose();
    }
  };

  const [open, setOpen] = React.useState(false);
  const [itemId, setItemId] = React.useState(null);

  const handleClickOpen = (id) => {
    setOpen(true);
    setItemId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setItemId(null);
  };

  const handleChange = async (e, id, key) => {
    const { value } = e.target;

    const response = await AxiosAll('post', `seller/common-dynamic-banners/${id}?_method=PUT`, {
      [key]: parseInt(value, 10),
    });
    if (response?.statusCode === 200) {
      notify(response?.message, 'success');
      getList && getList();
    } else {
      notify(response?.message, 'error');
    }
  };

  return (
    <TableContainer component={Paper} className="main-table">
      {list?.length ? (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Sub Title</TableCell>
              <TableCell align="left">Banner Image</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row, index) => {
              return (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{row?.title}</TableCell>
                  <TableCell align="left">{row?.sub_title}</TableCell>

                  <TableCell align="left">
                    <ImgCard tableImg={row?.banner_image || images.placeholder} isImageTrue />
                  </TableCell>
                  <TableCell align="left">
                    <RadioButtonsGroup
                      name="status"
                      value={row?.status}
                      onChange={(e) => handleChange(e, row?.id, 'status')}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TableActionBtn
                      onEdit={() => navigate(`/onboarding/site-editor/edit-dynamic-block-banners/${row?.id}`)}
                      onDelete={() => handleClickOpen(row?.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="delete-pop-up"
            >
              <div className="delete-container">
                <Typography variant="h2">Delete</Typography>
                <Typography paragraph>Are you sure you want to delete this banner?</Typography>
              </div>
              <DialogActions>
                <BlueBtn title="Yes" onClick={() => handleDelete(itemId)} />
                <LightBtn title="No" onClick={handleClose} />
              </DialogActions>
            </Dialog>
          </TableBody>
        </Table>
      ) : null}
    </TableContainer>
  );
};
