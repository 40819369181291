import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import EditMultiSelect from '../../../components/universal/EditProduct/EditMultiSelect';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { Loader } from '../../../components/Skeleton/Loader';

export const AddProductCollectionSection = () => {
  const route = useNavigate();

  const [loading, setLoading] = useState(false);
  const [collectionsList, setCollectionsList] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedDesktopCollections, setSelectedDesktopCollections] = useState([]);
  const [selectedTabletCollections, setSelectedTabletCollections] = useState([]);
  const [selectedMobileCollections, setSelectedMobileCollections] = useState([]);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const { id } = useParams();
  const { userDetails } = useSelector((state) => state.loginReducer);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    label: '',
    desktopTitle: '',
    desktopLabel: '',
    tabletTitle: '',
    tabletLabel: '',
    mobileTitle: '',
    mobileLabel: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    label: Yup.string().required('Label is required'),
    // desktopTitle: Yup.string().required('Title is required'),
    // desktopLabel: Yup.string().required('Label is required'),
    // tabletTitle: Yup.string().required('Title is required'),
    // tabletLabel: Yup.string().required('Label is required'),
    // mobileTitle: Yup.string().required('Title is required'),
    // mobileLabel: Yup.string().required('Label is required'),
  });

  const getCollectionDetails = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/sellerSections/${id}?section_type=shop_collection`, null);
    if (response.statusCode === 200) {
      const collectionData = response?.data?.shop_collection;
      formik.setFieldValue('title', collectionData?.title || '');
      formik.setFieldValue('label', collectionData?.label || '');
      formik.setFieldValue('desktopTitle', collectionData?.title_web || '');
      formik.setFieldValue('desktopLabel', collectionData?.label_web || '');
      formik.setFieldValue('tabletTitle', collectionData?.title_tablet || '');
      formik.setFieldValue('tabletLabel', collectionData?.label_tablet || '');
      formik.setFieldValue('mobileTitle', collectionData?.title_mobile || '');
      formik.setFieldValue('mobileLabel', collectionData?.label_mobile || '');
      const shopCollections = collectionData?.shop_collections?.map((item) => ({
        ...item,
        name: item.title,
        image: item.cover_image,
      }));
      setSelectedCollections(shopCollections || []);
      const webShopCollections = collectionData?.web_shop_collections?.map((item) => ({
        ...item,
        name: item.title,
        image: item.cover_image,
      }));
      setSelectedDesktopCollections(webShopCollections || []);
      const tabletShopCollections = collectionData?.tablet_shop_collections?.map((item) => ({
        ...item,
        name: item.title,
        image: item.cover_image,
      }));
      setSelectedTabletCollections(tabletShopCollections || []);
      const mobileShopCollections = collectionData?.mobile_shop_collections?.map((item) => ({
        ...item,
        name: item.title,
        image: item.cover_image,
      }));
      setSelectedMobileCollections(mobileShopCollections || []);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    if (selectedCollections.length === 0) {
      notify('Please select atleast one collection', 'error');
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append('title', values?.title);
      formData.append('label', values?.label);
      for (let i = 0; i < selectedCollections.length; i++) {
        formData.append(`shop_collection_ids[${i}]`, selectedCollections[i]?.id);
      }
      formData.append('title_web', values?.desktopTitle);
      formData.append('label_web', values?.desktopLabel);
      for (let i = 0; i < selectedDesktopCollections.length; i++) {
        formData.append(`web_shop_collection_ids[${i}]`, selectedDesktopCollections[i]?.id);
      }
      formData.append('title_tablet', values?.tabletTitle);
      formData.append('label_tablet', values?.tabletLabel);
      for (let i = 0; i < selectedTabletCollections.length; i++) {
        formData.append(`tablet_shop_collection_ids[${i}]`, selectedTabletCollections[i]?.id);
      }
      formData.append('title_mobile', values?.mobileTitle);
      formData.append('label_mobile', values?.mobileLabel);
      for (let i = 0; i < selectedMobileCollections.length; i++) {
        formData.append(`mobile_shop_collection_ids[${i}]`, selectedMobileCollections[i]?.id);
      }

      const endPoint = id
        ? `seller/sellerSections/${id}?section_type=shop_collection&_method=PUT`
        : 'seller/sellerSections?section_type=shop_collection';

      const response = await AxiosAll('post', endPoint, formData);
      if (response.statusCode === 200) {
        notify(response.message, 'success');
        route('/onboarding/site-editor');
        setLoading(false);
      } else {
        notify(response.message, 'error');
        setLoading(false);
      }
    }
  };

  const getDropdowns = async () => {
    const response = await AxiosAll('get', `dropdowns?type=shopCollections&seller_id=${userDetails?.user?.id}`);
    if (response.statusCode === 200) {
      const dropdownData = response.data;
      setCollectionsList(dropdownData);
    }
  };

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedCollections];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedCollections(newSections);
    setDraggedOverIndex(null);
  };

  const handleDropTablet = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedTabletCollections];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedTabletCollections(newSections);
    setDraggedOverIndex(null);
  };

  const handleDropDesktop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedDesktopCollections];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedDesktopCollections(newSections);
    setDraggedOverIndex(null);
  };

  const handleDropMobile = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedMobileCollections];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedMobileCollections(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getCollectionDetails();
    }
  }, [collectionsList]);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  return (
    <div className="shop-collection">
      <Loader open={loading} />
      <BannerCard title="Collection Section" para={para} backgroundImageURL={images.latestArrivalImg} smallCard />
      <div className="main-section container-27inch">
        <div className="section-editor">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="search-engine-section">
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && formik.errors.title}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Label</Typography>
                  <InputTypeOutline
                    placeholder="Label"
                    name="label"
                    value={formik.values.label}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.label && formik.errors.label}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Shop Collections</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={collectionsList}
                      selectedBadges={selectedCollections}
                      setSelectedBadges={setSelectedCollections}
                      limit={100}
                    />
                  </div>
                </div>
                {selectedCollections?.length > 0 && (
                  <div className="meta-title wrapper">
                    {selectedCollections?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDrop(e, index)}
                        draggable
                      >
                        <img src={item.image} alt="collection" height={100} width={100} />
                        <Typography>{item?.name}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedCollections = selectedCollections.filter((badge) => badge !== item);
                            setSelectedCollections(newSelectedCollections);
                          }}
                        >
                          <img src={images.trashBtn} alt="close" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* desktop section */}
          <div className="search-engine-section">
            <Typography variant="h4">Desktop</Typography>
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="desktopTitle"
                    value={formik.values.desktopTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.desktopTitle && formik.errors.desktopTitle}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Label</Typography>
                  <InputTypeOutline
                    placeholder="Label"
                    name="desktopLabel"
                    value={formik.values.desktopLabel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.desktopLabel && formik.errors.desktopLabel}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Shop Collections</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={collectionsList}
                      selectedBadges={selectedDesktopCollections}
                      setSelectedBadges={setSelectedDesktopCollections}
                      limit={100}
                    />
                  </div>
                </div>
                {selectedDesktopCollections?.length > 0 && (
                  <div className="meta-title wrapper">
                    {selectedDesktopCollections?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDropDesktop(e, index)}
                        draggable
                      >
                        <img src={item.image} alt="collection" height={100} width={100} />
                        <Typography>{item?.name}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedCollections = selectedDesktopCollections.filter((badge) => badge !== item);
                            setSelectedDesktopCollections(newSelectedCollections);
                          }}
                        >
                          <img src={images.trashBtn} alt="close" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* tablet section */}
          <div className="search-engine-section">
            <Typography variant="h4">Tablet</Typography>
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="tabletTitle"
                    value={formik.values.tabletTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tabletTitle && formik.errors.tabletTitle}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Label</Typography>
                  <InputTypeOutline
                    placeholder="Label"
                    name="tabletLabel"
                    value={formik.values.tabletLabel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tabletLabel && formik.errors.tabletLabel}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Shop Collections</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={collectionsList}
                      selectedBadges={selectedTabletCollections}
                      setSelectedBadges={setSelectedTabletCollections}
                      limit={100}
                    />
                  </div>
                </div>
                {selectedTabletCollections?.length > 0 && (
                  <div className="meta-title wrapper">
                    {selectedTabletCollections?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDropTablet(e, index)}
                        draggable
                      >
                        <img src={item.image} alt="collection" height={100} width={100} />
                        <Typography>{item?.name}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedCollections = selectedTabletCollections.filter((badge) => badge !== item);
                            setSelectedTabletCollections(newSelectedCollections);
                          }}
                        >
                          <img src={images.trashBtn} alt="close" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* mobile section */}
          <div className="search-engine-section">
            <Typography variant="h4">Mobile</Typography>
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="mobileTitle"
                    value={formik.values.mobileTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.mobileTitle && formik.errors.mobileTitle}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Label</Typography>
                  <InputTypeOutline
                    placeholder="Label"
                    name="mobileLabel"
                    value={formik.values.mobileLabel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.mobileLabel && formik.errors.mobileLabel}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Shop Collections</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={collectionsList}
                      selectedBadges={selectedMobileCollections}
                      setSelectedBadges={setSelectedMobileCollections}
                      limit={100}
                    />
                  </div>
                </div>
                {selectedMobileCollections?.length > 0 && (
                  <div className="meta-title wrapper">
                    {selectedMobileCollections?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDropMobile(e, index)}
                        draggable
                      >
                        <img src={item.image} alt="collection" height={100} width={100} />
                        <Typography>{item?.name}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedCollections = selectedMobileCollections.filter((badge) => badge !== item);
                            setSelectedMobileCollections(newSelectedCollections);
                          }}
                        >
                          <img src={images.trashBtn} alt="close" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor')} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
