import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FeedBackTable } from '../../../components/SiteEditorTable/ComonTable/feedbackTable';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import CardPagination from '../../../components/universal/CardPagination';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import SearchBar from '../../../components/universal/SearchBar';
import { Loader } from '../../../components/Skeleton/Loader';

export const Feedbacks = () => {
  const route = useNavigate();
  const [loading, setLoading] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const [feedbackData, setFeedbackData] = useState({});
  const [searchFeedback, setSearch] = useState();
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getFeedbackList = async () => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `seller/feedbacks?per_page=${limit}&page=${page}&search=${searchFeedback || ''}`,
      null,
    );
    if (response.statusCode === 200) {
      setFeedbackList(response?.data?.data);
      setFeedbackData(response?.data);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
    setPage(1);
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    getFeedbackList();
  }, [page, limit, searchFeedback]);

  return (
    <div className="feedback">
      <Loader open={loading} />

      <BannerCard title="Feedback" para={para} smallCard />
      <div className="container-27inch">
        <div className="feedback-btn">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
          <SearchBar handleChange={handleSearch} value={searchText} />
        </div>
        <div className="site-table">
          <FeedBackTable feedbackList={feedbackList} getFeedbackList={getFeedbackList} />
        </div>
        {feedbackData?.total >= 12 && (
          <CardPagination
            noOfPages={feedbackData?.last_page}
            page={page}
            handleChange={handlePagination}
            rowsPerPage={limit}
          />
        )}
      </div>
    </div>
  );
};
