import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const DashboardCharts = ({ data, categories, type, name, barChartData, color }) => {
  const [options, setOptions] = useState({
    chart: {
      id: 'dashboard-chart',
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    colors: ['#949d8c'],
    legend: {
      show: false,
    },
    xaxis: {
      categories,
    },
  });

  const [series, setSeries] = useState([
    {
      name,
      data,
    },
  ]);

  useEffect(() => {
    if (type === 'bar') {
      setOptions((prevOptions) => ({
        ...prevOptions,
        stroke: {
          ...prevOptions.stroke,
          width: 1,
          colors: ['#fff'],
        },
      }));
    }
  }, [type]);

  return <Chart options={options} series={barChartData ?? series} type={type} height={320} />;
};

export default DashboardCharts;
