import React from 'react';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import { images } from '../../../assets';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import InputType from '../../../components/universal/InputType/TextArea';

const ContactUs = () => {
  return (
    <div className="ContactUs">
      <div className="admin-contact">
        <Container className="all-container-27inch">
          <Grid container spacing={2} className="contact-us-form">
            <Grid item xs={12} sm={6}>
              {/* <div className="container"> */}
              <div className="register-heading">
                {' '}
                Get in <strong>touch</strong>
              </div>
              <div className="register-para">
                It’s important to us that we can support you on your sustainable journey, we hope most queries can be
                answered by viewing our FAQs page. If you would still like to talk to us as a buyer or seller at
                GoEthical, please contact us with the details on the right.
              </div>
              <div className="contact-us">
                <img src={images.contact} className="contact-img" alt="contact-img" />
                <div>
                  <p className="email-text">E: enquiries@GoEthical.com</p>
                  <p className="email-text">T: 020 8337 2175</p>
                </div>
              </div>
              {/* </div> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <InputTypeOutline placeholder="Your Name" />
                <InputTypeOutline placeholder="Your Email Address" />
                <InputTypeOutline placeholder="Your Contact Number" />
                <InputType placeholder="Your Message" />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default ContactUs;
