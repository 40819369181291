import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { TraderTypeOption } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import DropDown from '../../universal/DropDown';

const AboutYou = ({ setStep, step, registration, setRegistration }) => {
  const [productType, setProductType] = useState([]);
  const [badge, setBadge] = useState();
  const [validation, setValidation] = useState(false);
  const initialValues = {
    traderType: '',
    ethicalProductsType: [],
  };

  // formik validation
  const validationSchema = Yup.object({
    traderType: Yup.string().required('This feild is required'),
  });
  const getBadges = async () => {
    try {
      const responce = await axiosAll({
        method: 'get',
        url: 'v1/product/badges?userToken=0',
      });
      setBadge(responce.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBadges();
  }, []);

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    const CheckUser = badge?.map((item) => (item?.id === name ? { ...item, isChecked: checked } : item));
    setBadge(CheckUser);
    // console.log("productType", CheckUser);
    const validationtrue = CheckUser?.some((event) => event?.isChecked === true);
    if (validationtrue) {
      setValidation(false);
    } else {
      setValidation(true);
    }
  };

  const handleProductType = (e) => {
    for (let index = 0; index < badge?.length; index++) {
      if (badge[index]?.isChecked) {
        productType?.push(badge[index]?.name);
      }
    }
    // console.log("productType", productType);
  };

  const onSubmit = (values) => {
    handleProductType();
    formik.setFieldValue('ethicalProductsType', productType);
    if (values.traderType && productType?.length > 0) {
      setRegistration({
        ...registration,
        traderType: values.traderType,
        ethicalProductsType: productType,
      });
      setStep(step + 1);
    } else {
      setValidation(true);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div className="aboutyou">
      <form onSubmit={formik.handleSubmit}>
        <div className="register-text-heading"> About You</div>
        <Grid item xs={12}>
          <DropDown
            label="What Type of trader are you ?*"
            name="traderType"
            handleChange={(e) => formik.setFieldValue('traderType', e.target.value)}
            value={formik.values.traderType}
            option={TraderTypeOption}
            blur={formik.handleBlur}
            touched={formik.touched.traderType}
            error={formik.errors.traderType}
          />
        </Grid>
        <div className="charity-text"> What type of ethical products are you selling? </div>
        <div className="charity-para">
          {' '}
          Please tick the relevant box(s) to confirm your organisation, brand or products meet with the GoEthical
          Criteria. By ticking the box(s) you confirm that your brand and or products meet the values described.{' '}
        </div>

        <Grid container spacing={2} className="mrgbottom">
          {badge &&
            badge?.map((badg, index) => {
              return (
                <Grid item xs={6} sm={3} key={index}>
                  <div className="checkbox-section">
                    <input
                      type="checkbox"
                      name={badg?.id}
                      onChange={handleCheckbox}
                      className="checkbox-trader"
                      checked={badg?.isChecked || false}
                    />
                    <p className="label-trader">{badg.name}</p>
                  </div>
                </Grid>
              );
            })}
        </Grid>
        {validation ? <span className="input-error-message">This feild is required</span> : ''}

        <div className="btn-section">
          <DarkBtn title="Final Step" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default AboutYou;
