import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DropDown from '../../universal/DropDown';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import PendingBtn from '../../universal/Buttons/PendingBtn';
import { axiosAll } from '../../../services/axiosAll';
import { notify } from '../../../core/constants';
import CancelBtn from '../../universal/Buttons/CancelBtn';

const RemoveFromDialog = ({
  sectionId,
  productid,
  subsection,
  removeOptionvalue,
  removeOption,
  setProductDetailRemove,
  handleProductDetailRemove,
  setOptionvalue,
  setOption,
  RemoveToloading,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (productid) {
      handleProductDetailRemove(productid);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOption([]);
  };

  const handleChange = (e) => {
    setOptionvalue(e.target.value);
  };

  const handleRemoveTo = async () => {
    const AddTo = {
      sectionId,
      subsectionId: removeOptionvalue,
    };
    const response = await axiosAll({
      method: 'put',
      url: `v2/products/${productid}/deleteSubsection`,
      data: AddTo,
    });
    if (response?.statusCode === 200) {
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }
  };

  const handleSubmit = () => {
    handleRemoveTo();
    handleClose();
  };

  return (
    <div className="removefrom">
      <Button className="pendingbtn" onClick={handleClickOpen}>
        removeFrom
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="remove-from"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="input-heading">
              <h2 className="upload-text">Choose subsection to remove product from</h2>
              {RemoveToloading === false ? (
                removeOption?.length && removeOptionvalue ? (
                  <>
                    <div className="heading">
                      <DropDown option={removeOption} value={removeOptionvalue} handleChange={handleChange} />
                    </div>
                    <PendingBtn title="Submit" onClick={handleSubmit} />
                  </>
                ) : (
                  'This product has not been added to any subsection'
                )
              ) : (
                'SubSection Loading...'
              )}
              {removeOption?.length && removeOptionvalue ? '' : <CancelBtn onClick={handleClose} />}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RemoveFromDialog;
