import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Android12Switch = styled(Switch)(() => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    width: 48,
    height: 28,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      content: '"on"',
      color: '#fff',
      fontSize: '12px',
      left: 12,
    },
    '&:after': {
      content: '"off"',
      color: '#fff',
      fontSize: '12px',
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 22,
    height: 22,
    margin: 2,
  },
}));

export default function CustomizedSwitches({ onChange, name, value, props }) {
  return (
    <FormGroup className="onoffswitch">
      <FormControlLabel
        control={<Android12Switch value={value} checked={value} onChange={onChange} name={name} {...props} />}
      />
    </FormGroup>
  );
}
