import { Rating, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import TextArea from '../../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import { UploadSingleImage } from '../../../components/universal/UploadImage';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { Loader } from '../../../components/Skeleton/Loader';

export const AddTestimonials = () => {
  const route = useNavigate();

  const [companyLogo, setCompanyLogo] = useState('');
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    name: '',
    companyName: '',
    description: '',
    rating: 5,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    // companyName: Yup.string().required('Company Name is required'),
    description: Yup.string().required('Description is required'),
  });

  const getTestimonialDetails = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/testimonials/${id}`, null);
    if (response.statusCode === 200) {
      const testimonialData = response.data;
      formik.setFieldValue('name', testimonialData?.name || '');
      formik.setFieldValue('companyName', testimonialData?.company_name || '');
      formik.setFieldValue('description', testimonialData?.description || '');
      formik.setFieldValue('rating', testimonialData?.rating || 5);
      setCompanyLogo(testimonialData?.company_logo || '');
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('company_name', values?.companyName);
    formData.append('description', values?.description);
    formData.append('rating', values?.rating);
    formData.append('company_logo', companyLogo);

    const endPoint = id ? `seller/testimonials/${id}?_method=PUT` : 'seller/testimonials';

    const response = await AxiosAll('post', endPoint, formData);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      route('/onboarding/site-editor/testimonial-list');
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getTestimonialDetails();
    }
  }, []);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  return (
    <div className="add-testimonial">
      <Loader open={loading} />
      <BannerCard title="Testimonial" para={para} backgroundImageURL={images.latestArrivalImg} smallCard />
      <div className="main-section container-27inch">
        <div className="section-editor">
          <BlueBtn
            title="Return"
            customClass="return-btn"
            onClick={() => route('/onboarding/site-editor/testimonial-list')}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <UploadSingleImage
            title="Company Logo"
            description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
            image={companyLogo}
            setImage={setCompanyLogo}
            imageSize={2}
          />

          <div className="search-engine-section">
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Name</Typography>
                  <InputTypeOutline
                    placeholder="Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && formik.errors.name}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Company Name</Typography>
                  <InputTypeOutline
                    placeholder="Company Name"
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.companyName && formik.errors.companyName}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Rating</Typography>
                  <div className="rating-star">
                    <Rating
                      name="simple-controlled"
                      value={formik.values.rating}
                      onChange={(event, newValue) => {
                        formik.setFieldValue('rating', newValue);
                      }}
                    />
                  </div>
                </div>
                <div className="meta-des">
                  <Typography>Description</Typography>
                  <TextArea
                    placeholder="Description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && formik.errors.description}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor/testimonial-list')} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
