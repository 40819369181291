/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-debugger */
/* eslint-disable no-unsafe-optional-chaining */
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DialogActions from '@mui/material/DialogActions';
import * as React from 'react';
import { Checkbox, TextField, Typography } from '@mui/material';

import { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Label } from 'gestalt';

import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import LightBtn from '../../universal/Buttons/LightBtn';

import { AxiosAll } from '../../../services/axiosAll';
import { Empty } from '../../universal/Empty';

import ProductsCard from '../../universal/ProductsCard';

import { notify } from '../../../core/constants';
import ToolTip from '../../universal/ToolTip';

export const PopUpBeauty = ({ id, data, setStep, AddCategory, getMainCategories }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [product, setProduct] = useState([]);
  const [productImages, setProductImages] = useState();
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
    setStep(true);
  };

  const handleDeleteModalClose = () => {
    setOpenDelete(false);
    setDeleteId(null);
  };

  const handleSearch = (e) => {
    if (e?.target?.value) {
      setSearchText(e?.target?.value);
    } else {
      setSearchText('');
    }
  };

  const getProductImage = async () => {
    const response = await AxiosAll('get', `seller/categories/${id}`, null);
    if (response?.statusCode === 200) {
      const responseData = response?.data?.top_picks;
      setProductImages(responseData);

      // notify(response.message, "error");
    } else {
      notify(response.message, 'error');
    }
  };

  const AddProduct = async () => {
    setLoading(true);
    const formData = new FormData();
    if (productImages?.length > 0) {
      for (let i = 0; i < productImages?.length; i++) {
        formData.append(`products[${i}]`, productImages[i]?.id);
      }
    } else {
      formData.append(`products`, '');
    }

    const response = await AxiosAll('post', `seller/category-top-picks/${id}`, formData);
    if (response?.statusCode === 200) {
      setProduct([]);
      const responseData = response?.data;
      getProductImage();
      setStep(true);
      handleClose();
      setLoading(false);
    } else {
      // setOpen(false);

      setProduct([]);
      notify(response.message, 'success');
      setLoading(false);
    }
  };

  const handleDelete = (id) => {
    const updatedTopPicks = productImages?.filter((e) => e.id !== id);
    setProductImages(updatedTopPicks);
    setOpenDelete(false);
  };

  const getProducts = async () => {
    const response = await AxiosAll(
      'get',
      `seller/products?category_id=${id}&per_page=10&search=${search || ''}`,
      null,
    );
    if (response?.statusCode === 200) {
      const responseData = response?.data?.data;
      // notify(response.message, 'success');
      setProduct(responseData);
    } else {
      // notify(response.message, 'error');
    }
  };

  const handleDrop = (e, index) => {
    console.log('index', index);
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...productImages];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);

    setProductImages(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  useEffect(() => {
    if (search.trim() !== '') {
      getProducts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    if (open) {
      getProductImage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (productImages?.length) {
      setProduct([]);
    }
  }, [productImages]);

  // console.log('product', product);

  return (
    <div className="btn-width">
      {data?.top_picks?.length ? (
        <BlueBtn title="Edit Top Picks" customClass="save-order" onClick={handleClickOpen} />
      ) : (
        <BlueBtn title="Add Top Picks" customClass="save-order" onClick={handleClickOpen} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="beauty-picks"
      >
        <div className="main-picks-container">
          <div className="add-product">
            <Typography>Add Products</Typography>
          </div>
          <div className="product-list">
            <div className="product-gallery pop-up-gallery">
              <Autocomplete
                options={product?.map((prediction) => prediction?.name)}
                noOptionsText="No Products Found"
                inputValue={searchText}
                onInputChange={handleSearch}
                renderInput={(params) => <TextField {...params} label="Search Products" variant="outlined" />}
                renderOption={(option) => <div>{option}</div>}
                getOptionSelected={(option, value) => option === value}
                filterSelectedOptions={true}
                onChange={(event, value) => {
                  if (value) {
                    const selectedPrediction = product?.find((prediction) => prediction?.name === value);

                    if (selectedPrediction) {
                      const isDuplicate = productImages.some((product) => product?.id === selectedPrediction.id);

                      if (isDuplicate) {
                        notify('This product has already been added', 'error');
                        return;
                      }

                      const updatedTopPicks = [...productImages, selectedPrediction];

                      setProductImages(updatedTopPicks);
                    }
                  }
                }}
              />
              {productImages?.map((productImg, index) => {
                return (
                  <div className="img-container" key={productImg?.id}>
                    <label className="label-file" htmlFor={`myCheckbox${index}`}>
                      <ProductsCard
                        dragStart={(e) => handleDragStart(e, index)}
                        dragOver={(e) => handleDragOver(e, index)}
                        dragEnd={() => setDraggedOverIndex(null)}
                        dropCard={(e) => handleDrop(e, index)}
                        image={productImg?.cover_image?.image}
                        name={productImg?.name}
                        copyUrl={productImg?.banner_image}
                        id={index}
                        isImages
                      />
                    </label>
                    {productImages?.length > 0 ? (
                      <DeleteIcon
                        onClick={() => {
                          setOpenDelete(true);
                          setDeleteId(productImg?.id);
                        }}
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Dialog
          open={openDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="delete-pop-up"
        >
          <div className="delete-container">
            <Typography variant="h2">Delete</Typography>
            <Typography paragraph>Are you sure you want to delete this product?</Typography>
          </div>
          <DialogActions>
            <BlueBtn title="Yes" onClick={() => handleDelete(deleteId)} />
            <LightBtn title="No" onClick={() => handleDeleteModalClose()} />
          </DialogActions>
        </Dialog>
        <DialogActions className="bottom-btn">
          <LightBtn title="Cancel" onClick={handleClose} />
          <BlueBtn title="Save" loading={loading} onClick={AddProduct} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
