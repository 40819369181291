import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InfoIcon from '@mui/icons-material/Info';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { Tooltip } from '@mui/material';

const InputTypeOutline = ({
  placeholder,
  onChange,
  name,
  type,
  error,
  value,
  tooltipTitle,
  onBlur,
  password,
  isPasswordShow,
  setIsPasswordShow,
  disabled,
  max,
  min,
  showTooltip,
  onKeyDown,
  showStaticText,
  staticText,
  ...props
}) => {
  return (
    <div className="input-width">
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        className="input-field-outline"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        onKeyDown={onKeyDown}
        maxLength={max}
        min={min}
        {...props}
      />
      {showStaticText && <span className="static-text">{staticText}</span>}
      {/* {showTooltip && (
        <Tooltip title={tooltipTitle || 'Tooltip'}>
          <InfoIcon />
        </Tooltip>
      )} */}
      {password && (
        <span tabIndex={0} role="button" className="visibility-icon" onClick={() => setIsPasswordShow(!isPasswordShow)}>
          {isPasswordShow ? <VisibilitySharpIcon /> : <VisibilityOffIcon />}
        </span>
      )}
      <div>{error && <div className="input-error-message">{error}</div>}</div>
    </div>
  );
};

export default InputTypeOutline;
