/* eslint-disable no-debugger */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BannerTable } from '../../../../components/SiteEditorTable/ComonTable/bannerTable';
import { BlueBtn } from '../../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../../components/universal/Buttons/LightBtn';
import CardPagination from '../../../../components/universal/CardPagination';
import BannerCard from '../../../../components/universal/WelcomeCard';
import { notify } from '../../../../core/constants';
import { AxiosAll } from '../../../../services/axiosAll';
import { Loader } from '../../../../components/Skeleton/Loader';

export const BannerList = () => {
  const route = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [bannerData, setBannerData] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const { id } = useParams();
  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getBannerList = async () => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `seller/sellerSections/${id}?section_type=banner_with_multiple_image&per_page=${limit}&page=${page}`,
      null,
    );
    if (response.statusCode === 200) {
      setBannerList(response?.data?.banner?.banners);
      setBannerData(response?.data);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (page > bannerData?.last_page) {
      setPage(bannerData?.last_page);
    }
  }, [bannerData?.last_page, page]);

  const SaveOrder = async (bannerListRows) => {
    const fromdata = new FormData();
    setLoading(true);
    const list = bannerListRows?.map((e) => e.id);
    const response = await AxiosAll('put', `seller/bannerOrdering`, { ids: list });
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      getBannerList();
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    getBannerList();
  }, [page, limit]);

  return (
    <div className="site-banner-list">
      <Loader open={loading} />
      <BannerCard title="Promo Banners" para={para} smallCard />
      <div className="container-27inch">
        <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
        <BlueBtn
          title="Add New Banner"
          onClick={() => route(`/onboarding/site-editor/banner-with-multiple-image?id=${id}`)}
          customClass="collection-btn"
        />
        <BlueBtn title="Save" customClass="return-btn" onClick={() => SaveOrder(bannerList)} />

        <div className="site-table">
          <BannerTable list={bannerList} setBannerList={setBannerList} getList={getBannerList} />
        </div>

        {bannerData?.total >= 12 && (
          <CardPagination
            noOfPages={bannerData?.last_page}
            page={page}
            handleChange={handlePagination}
            rowsPerPage={limit}
          />
        )}
      </div>
    </div>
  );
};
