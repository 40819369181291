import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import BannerCard from '../../../components/universal/WelcomeCard';
import { AxiosAll } from '../../../services/axiosAll';
import { notify } from '../../../core/constants';
import { ShippingTable } from '../../../components/SiteEditorTable/ComonTable/ShippingTable';
import AddCourierClass from '../../../components/dialogs/AddCourierClass';

export const ShippingClassList = () => {
  const route = useNavigate();
  const [ShippingList, setShippingList] = useState([]);
  const [loading, setloading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const { id } = useParams();
  const mainShippingId = id;
  const [total, setTotal] = useState(0);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getShippingList = async () => {
    setloading(true);
    const response = await AxiosAll(
      'get',
      `seller/shippingCourierClasses?per_page=20&courier_id=${mainShippingId}`,
      null,
    );
    if (response.statusCode === 200) {
      setShippingList(response.data.data);
      setTotal(response.data.total);
      setloading(false);
    } else {
      notify(response.message, 'error');
      setloading(false);
    }
  };

  useEffect(() => {
    getShippingList();
  }, []);

  const AddNewCourierClass = async (value) => {
    // setisLoading(true);
    const response = await AxiosAll(
      value.id === 0 ? 'post' : 'put',
      value.id === 0 ? 'seller/shippingCourierClasses' : `seller/shippingCourierClasses/${value.id}`,
      {
        courier_id: mainShippingId,
        name: value?.courierClassName,
        min_transit_time: 1,
        max_transit_time: 2,
        shipping_time: value?.shippingTime,
        is_express: value?.isExpress,
        status: 1,
      },
      null,
    );
    if (response.statusCode === 200) {
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }

    getShippingList();
  };

  const EditStatus = async (value, data) => {
    // setisLoading(true);
    const response = await AxiosAll(
      'put',
      `seller/shippingCourierClasses/${data.id}`,
      {
        courier_id: mainShippingId,
        name: data?.name,
        min_transit_time: 1,
        max_transit_time: 2,
        shipping_time: data?.shipping_time,
        is_express: data?.is_express,
        status: value,
      },
      null,
    );

    getShippingList();
  };
  return (
    <div className="site-shipping">
      <BannerCard title="Shipping Classes" para={para} smallCard />
      <div className="container-27inch">
        <div className="flex">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route(-1)} />
          <AddCourierClass AddNewCourierClass={AddNewCourierClass} />
        </div>
        {loading ? (
          'Loading...'
        ) : (
          <div className="site-table">
            <ShippingTable
              isclass
              ShippingList={ShippingList}
              getShippingList={getShippingList}
              AddNewCourierClass={AddNewCourierClass}
              EditStatus={EditStatus}
            />
          </div>
        )}
      </div>
    </div>
  );
};
