export const SET_Mode = 'SET_Mode';
export const DELETE_Mode = 'DELETE_Mode';
export const UPDATE_Mode = 'UPDATE_Mode';

export const SET_USER = 'SET_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_Id = 'UPDATE_Id';

export const SET_CART = 'SET_CART';
export const DELETE_CART = 'DELETE_CART';
export const UPDATE_CART = 'UPDATE_CART';

export const SET_NEWSLATTER = 'SET_NEWSLATTER';
export const DELETE_NEWSLATTER = 'DELETE_NEWSLATTER';
export const UPDATE_NEWSLATTER = 'UPDATE_NEWSLATTER';

export const SET_CONTROL = 'SET_CONTROL';
export const DELETE_CONTROL = 'DELETE_CONTROL';
export const UPDATE_CONTROL = 'UPDATE_CONTROL';

export const SET_CONTROL_CHALLENGE = 'SET_CONTROL_CHALLENGE';
export const DELETE_CONTROL_CHALLENGE = 'DELETE_CONTROL_CHALLENGE';
export const UPDATE_CONTROL_CHALLENGE = 'UPDATE_CONTROL_CHALLENGE';

export const SET_PROFILE = 'SET_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SET_LOCATION_PERMISSION = 'SET_LOCATION_PERMISSION';
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_INFO = 'UPDATE_INFO';
export const REMEMBER = 'REMEMBER_LOGIN';
export const REMOVE_LOGIN = 'REMOVE_LOGIN';

export const GUEST = 'GUEST';
export const DELETE_GUEST = 'DELETE_GUEST';
export const UPDATE_GUEST_INFO = 'UPDATE_GUEST_INFO';
export const REMEMBER_GUEST = 'REMEMBER_GUEST';
export const REMOVE_GUEST = 'REMOVE_GUEST';

export const SET_GUEST_USER = 'SET_GUEST_USER';
export const DELETE_GUEST_USER = 'DELETE_GUEST_USER';
export const UPDATE_GUEST_USER = 'UPDATE_GUEST_USER';
