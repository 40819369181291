import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from '@progress/kendo-react-charts';

const DashboardChart = ({ chart, categories, type }) => (
  <div className="dashboardchart">
    <div className=" k-card">
      <Chart
        style={{
          height: 350,
        }}
      >
        {/* <ChartTitle text="Column Chart" /> */}
        <ChartLegend position="top" orientation="horizontal" />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} startAngle={45} />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem
            color="#949d8c"
            type={type}
            tooltip={{
              visible: true,
            }}
            data={chart}
          />
        </ChartSeries>
      </Chart>
    </div>
  </div>
);

export default DashboardChart;
