import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useEffect } from 'react';

export const RadioButtonsGroup = ({ name, value, disabled, EditStatus, data, onChange }) => {
  const [StatusValue, setStatusValue] = React.useState(value);

  const handleChange = (event) => {
    setStatusValue(event.target.value);
    EditStatus && EditStatus(event.target.value, data);
  };

  useEffect(() => {
    setStatusValue(value);
  }, [value]);

  return (
    <FormControl className="radiobtn" disabled={disabled}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="1"
        value={StatusValue}
        name={name || 'radio-buttons-group'}
        onChange={onChange || handleChange}
        style={{ alignItems: 'baseline ' }}
      >
        <FormControlLabel value="1" control={<Radio />} label="Active" />
        <FormControlLabel value="0" control={<Radio />} label="Inactive" />
      </RadioGroup>
    </FormControl>
  );
};
