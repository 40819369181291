import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import LightBtn from '../../universal/Buttons/LightBtn';
import SubHeading from '../../universal/Headings/SubHeading';

export const SubscriptionInfo = () => {
  const [Loading, setLoading] = useState(true);
  const [error, seterror] = useState(false);
  const [data, setdata] = useState({});
  const { userData } = useSelector((state) => state.guestUserReducer);
  const route = useNavigate();

  const getSubscription = async () => {
    setLoading(true);
    seterror(false);
    try {
      // const formattedDate = moment(date)?.format("YYYY-MM-DD");
      const response = await AxiosAll('get', `seller/subscriptions`, null, userData?.userToken);
      if (response?.statusCode === 200) {
        setdata(response?.data);
        setLoading(false);
        // notify(response.message, "success");
      } else {
        setLoading(false);
        notify(response.message, 'error');
      }
    } catch (err) {
      seterror(true);
      console.log(err);
    }
  };

  useEffect(() => {
    getSubscription();
    console.log('subscription');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return error ? null : Loading ? (
    'Loading...'
  ) : (
    <div className="personal-container account-subscription">
      <div className="width-container">
        <div className="text-section">
          <SubHeading title="Your Subscription" />

          <LightBtn onClick={() => route(`/onboarding/my-profile/subscription?id=${data?.id}`)} title="Update" />
        </div>

        <Grid container spacing={0} className="gridcenter">
          <Grid item xs={12} sm={3.4}>
            <div className="order-number month-sub">
              {data?.plan_detail?.recurring_interval}ly{'  '}
              {data?.cancel_at_period_end ? null : <div className="icon-info info-new">Active</div>}
            </div>

            {data?.cancel_at_period_end == 1 ? (
              <div className="icon-info info-new">Cancelation requested</div>
            ) : data?.plan_detail?.trial_end && data?.plan_detail?.trial_end != undefined ? (
              <div className="icon-info info-new">`Trial ends on ${data?.plan_detail?.trial_end}`</div>
            ) : null}
            {data?.cancel_at_period_end == 1 ? (
              <p>Note:After ending of your plan you will not able to access the platform</p>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className="order-data ">£{data?.plan_detail?.amount}</div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
