import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../assets';
import { FooterInput } from '../../components/AllInputBox/WebsiteFooterInput';
import { CircleBtnIcon } from '../../components/siteEitor/circleBtnIcon';
import { BlueBtn } from '../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import InputTypeOutline from '../../components/universal/InputType/inputTypeOutline';
import BannerCard from '../../components/universal/WelcomeCard';
import { notify } from '../../core/constants';
import { AxiosAll } from '../../services/axiosAll';
import { OrangeBtn } from '../../components/universal/Buttons/orangeBtn';

export const WebsiteFooter = () => {
  const route = useNavigate();
  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    copyRight: '',
    instagramURL: '',
    facebookURL: '',
    twitterURL: '',
    linkedinURL: '',
    menuArr: [
      {
        mainMenu: '',
        mainLink: '',
        subMenuArr: [
          {
            subMenu: '',
            subLink: '',
          },
          {
            subMenu: '',
            subLink: '',
          },
          {
            subMenu: '',
            subLink: '',
          },
        ],
      },
    ],
  };

  const { id } = useParams();

  const validationSchema = Yup.object({
    copyRight: Yup.string().required('Copy Right is required'),
    facebookURL: Yup.string()
      .matches(
        /^https:\/\/(www\.)?facebook\.com\//,
        'Facebook URL is invalid; it must begin with https://www.facebook.com/',
      )
      .required('Facebook URL is required'),
    instagramURL: Yup.string()
      .matches(
        /^https:\/\/(www\.)?instagram\.com\//,
        'Instagram URL is invalid; it must begin with https://www.instagram.com/',
      )
      .required('Instagram URL is required'),
    twitterURL: Yup.string()
      .matches(
        /^https:\/\/(www\.)?twitter\.com\//,
        'Twitter URL is invalid; it must begin with https://www.twitter.com/',
      )
      .required('Twitter URL is required'),
    linkedinURL: Yup.string()
      .matches(
        /^https:\/\/(www\.)?linkedin\.com\//,
        ' LinkedIn URL is invalid; it must begin with https://www.linkedin.com/',
      )
      .required('LinkedIn URL is required'),
    menuArr: Yup.array().of(
      Yup.object({
        mainMenu: Yup.string().required('Main Menu is required'),
        mainLink: Yup.string().url('Invalid Link').required('Link is required'),
        subMenuArr: Yup.array().of(
          Yup.object({
            subMenu: Yup.string().required('Sub Menu is required'),
            subLink: Yup.string().url('Invalid Link').required('Link is required'),
          }),
        ),
      }),
    ),
  });

  const onSubmit = async (values) => {
    const data = {
      copy_right_title: values?.copyRight,
      facebook_link: values?.facebookURL,
      twitter_link: values?.twitterURL,
      instagram_link: values?.instagramURL,
      linkedin_link: values?.linkedinURL,
      footer_menu_content: values?.menuArr,
    };

    const endPoint = id
      ? `seller/sellerSections/${id}?section_type=footer&_method=PUT`
      : `seller/sellerSections?section_type=footer`;

    const response = await AxiosAll('post', endPoint, data);
    if (response.statusCode === 200) {
      notify(response?.message, 'success');
      route('/onboarding/site-editor');
    } else {
      notify('Something went wrong', 'error');
    }
  };

  const getFooterDetails = async () => {
    const response = await AxiosAll('get', `seller/sellerSections/${id}?section_type=footer`, null);
    if (response.statusCode === 200) {
      const footerData = response?.data?.footer;
      formik.setFieldValue('copyRight', footerData?.copy_right_title || '');
      formik.setFieldValue('facebookURL', footerData?.facebook_link || '');
      formik.setFieldValue('twitterURL', footerData?.twitter_link || '');
      formik.setFieldValue('instagramURL', footerData?.instagram_link || '');
      formik.setFieldValue('linkedinURL', footerData?.linkedin_link || '');
      formik.setFieldValue('menuArr', footerData?.footer_menu_content || []);
    } else {
      notify(response.message, 'error');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getFooterDetails();
    }
  }, []);

  const handleRemoveMenu = (menu, index) => {
    const menuArr = [...formik.values.menuArr];
    menuArr.splice(index, 1);
    formik.setFieldValue('menuArr', menuArr);
    // scroll to previous size
    const element = document.getElementById(`menu-${index - 1}`);
    element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <div className="website-footer">
      <BannerCard title="Website Footer" para={para} backgroundImageURL={images.websiteFooterImg} smallCard />
      <BlueBtn title="Return" customClass="return-btn" onClick={() => route(-1)} />
      <BlueBtn
        title="Add additional menu"
        customClass="menu-btn"
        onClick={() => {
          const menuArr = [...formik.values.menuArr];
          menuArr.push({
            mainMenu: '',
            mainLink: '',
            subMenuArr: [],
          });
          formik.setFieldValue('menuArr', menuArr);
        }}
      />

      <FooterInput
        footerTitle="Footer Socials"
        footerDes="The Newsletter gives you the opportunity to build a community by collecting some information from them."
        copyRightTitle="Copyright"
        copyRightPlaceholder="Copy Right"
        instagramTitle="Instagram "
        instagramPlaceholder="Instagram Link"
        facebookTitle="Facebook"
        facebookPlaceholder="Facebook Link"
        twitterTitle="Twitter"
        twitterTitlePlaceholder="Twitter Link"
        linkedinTitles="LinkedIn"
        linkedinTitlePlaceholders="LinkedIn Link"
        formik={formik}
      />

      {formik.values.menuArr.map((item, index) => {
        return (
          <div key={index} className="menu-submenu-section" id={`menu-${index}`}>
            <Typography variant="h4">Main Menu</Typography>
            <Typography paragraph className="description">
              The Newsletter gives you the opportunity to build a community by collecting some information from them.
            </Typography>

            <div className="menu">
              <div className="main-menu">
                <div className="flex-row">
                  <Typography>Main Menu</Typography>
                  <InputTypeOutline
                    placeholder="E.g. Yes! Send me exclusive blogs"
                    name={`menuArr[${index}].mainMenu`}
                    value={item.mainMenu}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.menuArr?.[index]?.mainMenu && formik.errors.menuArr?.[index]?.mainMenu}
                  />
                </div>
                <div className="flex-row">
                  <Typography>Link to</Typography>
                  <InputTypeOutline
                    placeholder="E.g. Yes! Send me exclusive blogs"
                    name={`menuArr[${index}].mainLink`}
                    value={item.mainLink}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.menuArr?.[index]?.mainLink && formik.errors.menuArr?.[index]?.mainLink}
                  />
                </div>
              </div>
            </div>
            <BlueBtn
              title="Add additional sub-menu"
              customClass="menu-btn"
              onClick={() => {
                const menuArr = [...formik.values.menuArr];
                menuArr[index].subMenuArr.push({ subMenu: '', subLink: '' });
                formik.setFieldValue('menuArr', menuArr);
              }}
            />
            {item.subMenuArr.length > 0 && (
              <>
                <Typography variant="h4">Sub Menu</Typography>
                <Typography paragraph className="description">
                  The Newsletter gives you the opportunity to build a community by collecting some information from
                  them.
                </Typography>
              </>
            )}
            {item.subMenuArr.map((subItem, subIndex) => {
              return (
                <div key={subIndex} className="sub-menu">
                  <div className="main-sub-menu">
                    <div className="flex-row">
                      <Typography>Sub Menu</Typography>
                      <InputTypeOutline
                        placeholder="E.g. Yes! Send me exclusive blogs"
                        name={`menuArr[${index}].subMenuArr[${subIndex}].subMenu`}
                        value={subItem.subMenu}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.menuArr?.[index]?.subMenuArr?.[subIndex]?.subMenu &&
                          formik.errors.menuArr?.[index]?.subMenuArr?.[subIndex]?.subMenu
                        }
                      />
                    </div>
                    <div className="flex-row">
                      <Typography>Link to</Typography>
                      <div className="flex-input">
                        <InputTypeOutline
                          placeholder="E.g. Yes! Send me exclusive blogs"
                          name={`menuArr[${index}].subMenuArr[${subIndex}].subLink`}
                          value={subItem.subLink}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.menuArr?.[index]?.subMenuArr?.[subIndex]?.subLink &&
                            formik.errors.menuArr?.[index]?.subMenuArr?.[subIndex]?.subLink
                          }
                        />
                        <CircleBtnIcon
                          iconColor="#D75D4C"
                          onPress={() => {
                            const menuArr = [...formik.values.menuArr];
                            menuArr[index].subMenuArr.splice(subIndex, 1);
                            formik.setFieldValue('menuArr', menuArr);
                          }}
                        >
                          <DeleteIcon />
                        </CircleBtnIcon>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="centerbtn">
              <OrangeBtn title="Remove this menu" onClick={() => handleRemoveMenu(item, index)} />
            </div>
          </div>
        );
      })}

      {/* <MenuSubMenu
        menuTitle="Main Menu"
        MenuDes="The Newsletter gives you the opportunity to build a community by collecting some information from them."
        menuInputTitle="Main Menu"
        menuPlaceholder="E.g. Yes! Send me exclusive blogs"
        linkTitle="Link to"
        linkPlaceholder="E.g. Yes! Send me exclusive blogs"
        subMenuTitle="Sub Menu"
        subMenuDes="The Newsletter gives you the opportunity to build a community by collecting some information from them."
        formik={formik}
      /> */}

      <div className="buttons">
        <div className="buttons-position">
          <LightBtn title="Cancel" onClick={() => route(-1)} />
          <BlueBtn title="Save" onClick={formik.handleSubmit} />
        </div>
      </div>
    </div>
  );
};
