import { Container } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ProductSkeleton from '../../../components/Skeleton/ProductSkeleton';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import CardPagination from '../../../components/universal/CardPagination';
import DropDown from '../../../components/universal/DropDown';
import { Empty } from '../../../components/universal/Empty';
import ProductsCard from '../../../components/universal/ProductsCard';
import SearchBar from '../../../components/universal/SearchBar';
import sweatAlertBoxEdit from '../../../components/universal/SweetAlertBoxEdit';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { store } from '../../../redux/store';
import { AxiosAll, axiosAll } from '../../../services/axiosAll';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filterTag, setFilterTag] = useState([]);
  const [categoriesOption, setCategory] = useState();
  const [tag, setTag] = useState('0');
  const [searchProduct, setSearch] = useState();
  const [searchText, setSearchText] = useState();
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const { loginReducer } = store.getState();
  const userId = loginReducer?.userDetails?.user?.id;
  const [Loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.userReducer);
  const [page, setPage] = React.useState(1);
  const { userData } = useSelector((state) => state.guestUserReducer);

  const defaultOption = {
    title: 'All Categories',
    value: '',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getProducts = async (tagData, searchData) => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `seller/products?per_page=10&search=${searchProduct || ''}&page=${page}`,
      null,
      userData?.userToken,
    );
    if (response?.statusCode === 200) {
      setProducts(response?.data);
    }
    setLoading(false);
  };

  const getFilterTag = async () => {
    const response = await AxiosAll('get', 'seller/categories', null, userData?.userToken);
    setFilterTag(response?.data.data);
  };

  const handleDelete = async (id) => {
    const response = await AxiosAll('delete', `seller/products/${id}`, null, userData?.userToken);
    // notify(response.data.message, "success");
    if (response?.statusCode === 200) {
      if (response?.statusCode === 200) {
        getProducts(tag, searchProduct);
        notify(response.message, 'success');
      } else {
        notify(response.message, 'error');
      }
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setTag(value);
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  const handleSearch = (e) => {
    if (page > 1) {
      setPage(1);
    }
    const { value } = e.target;
    setSearchText(value);
  };

  useEffect(() => {
    getFilterTag();
  }, []);

  useEffect(() => {
    getProducts(tag, searchProduct);
    // eslint-disable-next-line
  }, [tag, searchProduct, page]);

  const filterdata = [];

  const getCategories = () => {
    const ethicalCategory = filterTag?.filter((x) => x.name);

    // eslint-disable-next-line no-unsafe-optional-chaining
    for (let i = 0; i <= ethicalCategory?.length - 1; i++) {
      const mainCategory = ethicalCategory[i];
      const category1 = {
        title: mainCategory?.name,
        id: mainCategory?.id,
      };

      filterdata.push(category1);

      // eslint-disable-next-line no-unsafe-optional-chaining
      for (let j = 0; j <= mainCategory?.children?.length - 1; j++) {
        const subCategory = mainCategory?.children[j];
        const category2 = {
          title: `--${subCategory?.name}`,
          id: subCategory?.id,
        };

        filterdata.push(category2);

        // eslint-disable-next-line no-unsafe-optional-chaining
        for (let k = 0; k <= subCategory?.children?.length - 1; k++) {
          const subChild = subCategory?.children[k];
          const category3 = {
            title: `---${subChild?.name}`,
            id: subChild?.id,
          };
          filterdata.push(category3);
        }
      }
    }
    setCategory(filterdata);
    filterdata.unshift(defaultOption);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, [filterTag]);

  const handleEdit = (title, id) => {
    const newTitle = title?.replaceAll(/\s/g, '-').toLowerCase();

    navigate(`/onboarding/seller/edit-product/${newTitle}/${id}`);
  };

  return (
    <div className="products">
      <div className="product-container">
        <BannerCard
          title="Inventory"
          para="This is where you can view and upload photos of your products. When bulk uploading products you need to upload your images here and reference the link in your CSV."
          smallCard
        />
        <Container className="all-container-27inch">
          <div className="search-row">
            <div className="btn-part">
              <SearchBar handleChange={handleSearch} value={searchText} />
              <div className="drop-down">
                <DropDown
                  placholder="Select"
                  option={categoriesOption}
                  handleChange={handleChange}
                  label="All Category"
                  value={tag}
                  customClass="inventory-drop-down"
                />
              </div>
            </div>
            <div className="bulk-btn-section">
              {/* {user?.user?.isProSeller ? ( */}
              <LightBtn
                title="Bulk upload "
                onClick={() =>
                  // user?.user?.stripe_account_status != '1'
                  // ? notify('Please add your bank account details from My Profile. for adding a product.', 'error')
                  // :
                  navigate(`/onboarding/bulk-upload`)
                }
              />
              {/* ) : null} */}
              <DarkBtn
                title="Add single product"
                onClick={() =>
                  user?.user?.stripe_account_status != '1'
                    ? notify('Please add your bank account details from My Profile. for adding a product.', 'error')
                    : navigate('/onboarding/add-single-product')
                }
              />
              {/* <UploadImage /> */}
            </div>
          </div>
          {/* )} */}
          {Loading ? (
            <ProductSkeleton type={1} />
          ) : products?.data?.length > 0 ? (
            products?.data?.map((product, index) => {
              return (
                <Fragment key={index}>
                  <div className="img-container ss">
                    {product?.status === 0 ? (
                      <div className="unpublished"> Unpublished </div>
                    ) : product?.stock_status === 0 ? (
                      <div className="outofstock"> out of stock </div>
                    ) : (
                      ''
                    )}
                    <ProductsCard
                      name={product?.name}
                      actual_price={product?.variants?.[0]?.actual_price}
                      price={product?.variants?.[0]?.sales_price}
                      image={product?.cover_image?.image}
                      handleDelete={handleDelete}
                      id={product?.id}
                      isAddedToCart={product?.isAddedToCart}
                      isAddedToWishlist={product?.isAddedToWishlist}
                      isPublished={product?.status}
                      stockStatus={product?.stock_status}
                      handleEdit={() => {
                        if (product?.device_type === 3 || product?.device_type === null) {
                          handleEdit(product?.name_slug, product?.id);
                        } else {
                          sweatAlertBoxEdit(
                            'Are You Sure?',
                            `You are about to edit the product titled ${product?.name?.bold()}. Once you confirm, you will no longer be able to make changes to this product using the GoEthical mobile app. All future changes will have to be made using the seller dashboard.`,
                            'Confirm',
                            'Cancel',

                            handleEdit,
                            product?.productSlug,
                            product?.id,
                          );
                        }
                      }}
                      isProducts
                    />
                  </div>
                </Fragment>
              );
            })
          ) : (
            <Empty message="No Product added " />
          )}

          {products?.total >= 10 ? (
            <CardPagination
              noOfPages={products?.last_page}
              page={page}
              handleChange={handlePagination}
              rowsPerPage={itemsPerPage}
            />
          ) : (
            ''
          )}
        </Container>{' '}
      </div>
    </div>
  );
};

export default Products;
