/* eslint-disable react/jsx-key */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, useNavigate, useParams } from 'react-router';
import { CategoryCard } from '../../components/CategoriesHeader/categoryCard';
import BannerCard from '../../components/universal/WelcomeCard';
import { AxiosAll } from '../../services/axiosAll';
import CategoryAdd from '../../components/dialogs/CategoryAdd';
import { notify } from '../../core/constants';
import { BlueBtn } from '../../components/universal/Buttons/BlueBtn';
import { Loader } from '../../components/Skeleton/Loader';

export const SubCategoriesAdd = () => {
  const route = useNavigate();
  const [MainCategory, setMainCategory] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { id } = useParams();
  const mainCategoryId = id;
  const AddCategory = async (value, itemId) => {
    setisLoading(true);
    // eslint-disable-next-line no-unused-vars
    const response = await AxiosAll(
      itemId === 0 ? 'post' : 'put',
      itemId === 0 ? 'seller/categories' : `seller/categories/${itemId}`,
      {
        parent_id: mainCategoryId,
        name: value,
        status: 1,
      },
      userDetails?.token,
    );
    if (response?.statusCode === 200) {
      setisLoading(false);

      notify(response.message, 'success');
      getSubCategories();
    } else {
      setisLoading(false);

      notify(response.message, 'error');
    }
  };

  const getSubCategories = async () => {
    setisLoading(true);
    const response = await AxiosAll('get', `seller/categories?parent_id=${mainCategoryId}`, null, userDetails?.token);
    setStep(false);
    setMainCategory(response.data?.data);
    setisLoading(false);
  };

  const deleteCategory = async (itemId) => {
    setisLoading(true);
    // eslint-disable-next-line no-unused-vars
    const response = await AxiosAll('delete', `seller/categories/${itemId}`, null, userDetails?.token);
    if (response?.statusCode === 200) {
      setisLoading(false);
      notify(response.message, 'success');
      getSubCategories();
    } else {
      setisLoading(false);

      notify(response.message, 'error');
    }
  };

  const SaveOrder = async (ReorderedSections) => {
    setisLoading(true);
    const formData = new FormData();
    if (ReorderedSections?.length > 0) {
      for (let i = 0; i < ReorderedSections?.length; i++) {
        formData.append(`category_ids[${i}]`, ReorderedSections[i]?.id);
      }
    } else {
      formData.append(`category_ids`, '');
    }
    const response = await AxiosAll('post', 'seller/categories-order-by', formData);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      getSubCategories();
      setisLoading(false);
    } else {
      notify(response.message, 'error');
      setisLoading(false);
    }
  };

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...MainCategory];

    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);

    setMainCategory(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  useEffect(() => {
    getSubCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCategoryId, step]);
  return (
    <div className="sub-categories">
      <Loader open={isLoading} />
      <BannerCard title="Categories" para={para} smallCard />
      <div className="main-section container-27inch">
        <div className="categorybtn">
          <div className="save-create">
            <BlueBtn title="Return" customClass="save-order" onClick={() => route(-1)} />
            <CategoryAdd AddCategory={AddCategory} titleName=" Sub Menu " />
            {MainCategory?.length > 0 ? (
              <BlueBtn title="Save Order" onClick={() => SaveOrder(MainCategory)} customClass="save-order" />
            ) : null}
          </div>
          <div className="save-create">{/* <PopUpBeauty id={id} /> */}</div>
        </div>

        <div className="menu-list">
          {isLoading
            ? ''
            : MainCategory?.length > 0
            ? MainCategory?.map((list, index) => {
                // eslint-disable-next-line react/jsx-key
                return (
                  <CategoryCard
                    dragStart={(e) => handleDragStart(e, index)}
                    dragOver={(e) => handleDragOver(e, index)}
                    dragEnd={() => setDraggedOverIndex(null)}
                    dropCard={(e) => handleDrop(e, index)}
                    setStep={setStep}
                    key={list?.id}
                    data={list}
                    AddCategory={AddCategory}
                    deleteCategory={deleteCategory}
                  />
                );
              })
            : 'No Category '}
        </div>

        {/* <div className="add-product">
          <Typography>Add Products</Typography>
          <SearchBar />
        </div>

        <div className="product-list">
          <ProductSectionCard />
        </div> */}

        {/* <SearchEngineSection
          searchTitle="Search Engine Optimisation"
          searchDes="Product SEO is the practice of optimising elements of product to provide search engines with enough relevant content to get the product found in search engines."
          inputTitle="Meta title"
          inputPlaceholder="Enter"
          metaTitle="Meta Description"
          metaPlaceholder="Enter"
          metaKey="Meta keywords"
          metaKeyPlaceholder="Enter"
        />

        <div className="buttons">
          <div className="buttons-position">
            <LightBtn title="Cancel" />
            <BlueBtn title="Save" />
          </div>
        </div> */}
      </div>
    </div>
  );
};
