import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../../../../assets';
import { BlueBtn } from '../../../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../../../components/universal/Buttons/LightBtn';
import InputTypeOutline from '../../../../../components/universal/InputType/inputTypeOutline';
import { UploadDraggableContainer } from '../../../../../components/universal/UploadDraggableContainer';
import { UploadSingleImage } from '../../../../../components/universal/UploadImage';
import BannerCard from '../../../../../components/universal/WelcomeCard';
import { notify } from '../../../../../core/constants';
import { AxiosAll } from '../../../../../services/axiosAll';
import { Loader } from '../../../../../components/Skeleton/Loader';

export const SellerBanners = () => {
  const route = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [sellerLogo, setSellerLogo] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [imageMobile, setImageMobile] = useState('');
  const [imageTablet, setImageTablet] = useState('');

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    subTitle: '',
    status: '0',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    subTitle: Yup.string().required('subTitle is required'),
    status: Yup.string().required('Status is required'),
  });

  const onSubmit = async (values) => {
    if (!bannerImage) {
      notify('Image is required', 'error');
    } else {
      const formData = new FormData();
      formData.append('title', values?.title);
      formData.append('sub_title', values?.subTitle);
      formData.append('status', values?.status);
      formData.append('logo', sellerLogo);
      formData.append('banner_image', bannerImage);
      formData.append('banner_image_mobile', imageMobile);
      formData.append('banner_image_tablet', imageTablet);
      setLoading(true);

      const endPoint = id ? `seller/common-seller-banners/${id}?_method=PUT` : 'seller/common-seller-banners';

      const response = await AxiosAll('post', endPoint, formData);
      if (response.statusCode === 200) {
        notify(response.message, 'success');
        route('/onboarding/site-editor/seller-banners');
        setLoading(false);
      } else {
        notify(response.message, 'error');
        setLoading(false);
      }
    }
  };

  const getBannerDetails = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/common-seller-banners/${id}`, null);
    if (response.statusCode === 200) {
      const bannerData = response.data;
      formik.setFieldValue('title', bannerData?.title || '');
      formik.setFieldValue('subTitle', bannerData?.sub_title || '');
      formik.setFieldValue('status', bannerData?.status);
      setSellerLogo(bannerData?.logo || '');
      setBannerImage(bannerData?.banner_image || '');
      setImageMobile(bannerData?.banner_image_mobile || '');
      setImageTablet(bannerData?.banner_image_tablet || '');

      setLoading(false);
    } else {
      setLoading(false);
      notify(response.message, 'error');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getBannerDetails();
    }
  }, []);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  return (
    <div className="site-seller-Banners">
      <Loader open={loading} />

      <BannerCard title="Add Seller Banner" para={para} backgroundImageURL={images.dynamicProductsImg} />
      <form onSubmit={formik.handleSubmit}>
        <div className="main-section container-27inch">
          <BlueBtn
            title="Return"
            customClass="return-btn"
            onClick={() => route('/onboarding/site-editor/seller-banners')}
          />
          <div className="trending-detail">
            <div className="search-engine-section">
              <Typography variant="h4">Section Details</Typography>
              <Typography paragraph className="description">
                These details will populate the featured products section on your website.
              </Typography>
              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Banner Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="title"
                      value={formik?.values?.title}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.title && formik?.errors?.title}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Sub Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="subTitle"
                      value={formik?.values?.subTitle}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.subTitle && formik?.errors?.subTitle}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Status</Typography>
                    <div className="radiobtn-section">
                      <RadioGroup
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.status && formik.errors.status}
                      >
                        <FormControlLabel value="1" label="Active" control={<Radio />} />
                        <FormControlLabel value="0" label="Inactive" control={<Radio />} />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                <UploadSingleImage
                  title="Seller logo"
                  description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                  image={sellerLogo}
                  setImage={setSellerLogo}
                  imageSize={2}
                />
              </div>
            </div>
          </div>

          <UploadSingleImage
            title="Banner Image"
            description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
            image={bannerImage}
            setImage={setBannerImage}
            imageSize={2}
          />

          <UploadSingleImage
            title="Mobile Image"
            description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
            image={imageMobile}
            setImage={setImageMobile}
            imageSize={2}
          />

          <UploadSingleImage
            title="Tablet Image"
            description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
            image={imageTablet}
            setImage={setImageTablet}
            imageSize={2}
          />

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor/seller-banners')} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
