import { Button } from '@mui/material';
import React from 'react';
import LockIcon from '@mui/icons-material/Lock';

const SubscriptionDisableBtn = ({ firstTitle, isLoading, isNewBtn, secTitle }) => {
  return (
    <Button variant="contained" className="disabledBtn subscription-disabled-btn">
      <LockIcon />
      {isLoading ? 'loading' : isNewBtn == 2 ? firstTitle : secTitle}
    </Button>
  );
};

export default SubscriptionDisableBtn;
