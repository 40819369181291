import IconButton from '@mui/material/IconButton';
import * as React from 'react';

export const CircleBtnIcon = ({ onPress, iconColor, iconClass, children }) => {
  return (
    <div className={`circle-btn ${iconClass}`} style={{ background: iconColor }}>
      <IconButton aria-label="delete" size="large" onClick={onPress}>
        {children}
      </IconButton>
    </div>
  );
};
