/* eslint-disable no-debugger */
import CloseIcon from '@mui/icons-material/Close';
import { Container, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
// import { Container } from "@mui/system";
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import 'react-quill/dist/quill.snow.css';
import { images } from '../../../assets';
import { Loader } from '../../../components/Skeleton/Loader';
import MediaLibrary from '../../../components/dialogs/MediaLibrary';
import PublishUpdateDialog from '../../../components/dialogs/PublishUpdate';
import CategoryDropDown from '../../../components/universal/AddProduct/CategoryDropDown';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import SubHeading from '../../../components/universal/Headings/SubHeading';
import Editor from '../../../components/universal/InputType/Editor';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import ProductsCard from '../../../components/universal/ProductsCard';
import BannerCard from '../../../components/universal/WelcomeCard';
import { formats, modules, notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';

const AddSingleProduct = () => {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [LoadingMessage, setLoadingMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState([]);
  const [masterCategory, setMasterCategory] = useState([]);
  const [mainCategory, setMainCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedMasterCategory, setSelectedMasterCategory] = useState('0');
  const [masterCategoryArr, setMasterCategoryArr] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState('0');
  const [selectedSubCategory, setSelectedSubCategory] = useState('0');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [shippingTypeOptions, setShippingTypeOptions] = useState([]);
  const [condition, setCondition] = useState('0');
  const [productType, setProductType] = useState('0');
  const [shippingType, setShippingType] = useState('0');
  const [tag, setTag] = useState([]);
  const [isShowVariationsTable, setIsShowVariationsTable] = useState(false);
  const [errors, setErrors] = useState({});
  const [type, setType] = useState('');
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);

  const navigate = useNavigate();

  const getSelectedImage = (image) => {
    setSelectedImage((prev) => {
      return [...prev, image];
    });
  };

  const getDropdowns = async () => {
    const types = ['categories', 'shipping_types', 'product_types', 'product_conditions'];

    const response = await AxiosAll(
      'get',
      `dropdowns?type[]=${types.join('&type[]=')}&seller_id=${userDetails?.user?.id}`,
    );

    setMasterCategory(response?.data?.categories?.filter((e) => e?.childrens?.length > 0));
    setProductTypeOptions(response?.data?.product_types);
    setShippingTypeOptions(response?.data?.shipping_types);
    setConditionOptions(response?.data?.product_conditions);
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  const initialValues = {
    name: '',
    description: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Product title is required'),
    // updated condition because now using editor which returns html  on 17 words we get main text of 10 length characters
    description: Yup.string()
      .required('Product description is required')
      .min(17, 'Product description must be at least 10 characters long'),
  });

  const image = async (data, id) => {
    setLoadingMessage('Uploading Product images ...');
    setIsLoading(true);
    const res = await AxiosAll('post', `seller/productImagesUpload/${id}`, data, userDetails?.token);
    if (res.status === 'Success') {
      setLoadingMessage(res.message);
      navigate('/onboarding/products');
      setIsLoading(false);
    } else {
      notify(res.message, 'error');
      setIsLoading(false);
    }
  };

  const addProduct = async (data) => {
    setLoadingMessage('Uploading Product Details ...');
    if (type === 'save') {
      setIsSaveLoading(true);
    }
    if (type === 'publish') {
      setIsPublishLoading(true);
    }
    setIsLoading(true);
    const res = await AxiosAll('post', 'seller/products', data, userDetails?.token);

    if (res.statusCode === 200) {
      const formData = new FormData();
      // formData.append("type", 2);
      // formData.append("coverIndex", 0);
      const productId = res?.data?.id;
      selectedImage.forEach((img, index) => {
        formData.append(`product_images[${index}]`, img.id);
      });
      setLoadingMessage(res.message);
      image(formData, productId);
      notify(res.message, 'success');
    } else {
      notify(res.message, 'error');
      setIsLoading(false);
    }

    if (type === 'save') {
      setIsSaveLoading(false);
    }
    if (type === 'publish') {
      setIsPublishLoading(false);
    }
  };

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('status', 0);
    formData.append('product_condition', parseInt(condition, 10));
    formData.append('shipping_type', parseInt(shippingType, 10));
    formData.append('product_type', parseInt(productType, 10));
    formData.append('product_category[0]', tag[0]);

    if (selectedImage.length === 0) {
      notify('Please select at least one image', 'error');
    } else if (selectedImage.length > 10) {
      notify('Maximum product photos should be 10', 'error');
    } else if (tag.length === 0) {
      notify('Please select at least one category', 'error');
    } else if (condition === '0') {
      notify('Please select product condition', 'error');
    } else {
      addProduct(formData);
      // console.log('data', data);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (selectedImage?.length === 0) {
      setErrors({
        image: 'Please select at least one image',
      });
    } else if (selectedImage.length > 10) {
      setErrors({
        imagesLength: 'Maximum product photos should be 10',
      });
    } else if (tag?.length === 0) {
      setErrors({
        category: 'Please select at least one category',
      });
    } else if (productType === '0') {
      setErrors({
        condition: 'Please select product type',
      });
    } else if (condition === '0') {
      setErrors({
        condition: 'Please select product condition',
      });
    } else if (shippingType === '0') {
      setErrors({
        condition: 'Please select shipping type',
      });
    } else if (Object.keys(formik.errors).length > 0) {
      setErrors({
        required: 'Please fill all required fields',
      });
    } else {
      setErrors({});
    }
    // eslint-disable-next-line
  }, [
    condition,
    tag,
    formik.values.name,
    formik.values.description,
    selectedImage,
    productType,
    shippingType,
    formik.errors,
  ]);

  useEffect(() => {
    const mainCategoryData = masterCategory?.find((item) => item.id === parseInt(selectedMasterCategory, 10));
    setMainCategory(mainCategoryData?.childrens?.filter((e) => e?.childrens?.length > 0));
  }, [selectedMasterCategory, masterCategory]);

  useEffect(() => {
    const subCategoryData = mainCategory?.find((item) => item.id === parseInt(selectedMainCategory, 10));
    setSubCategory(subCategoryData?.childrens);
  }, [selectedMainCategory, mainCategory]);

  const checkCategoryAdd = () => {
    if (tag.length >= 1) {
      if (masterCategoryArr[0] === selectedMasterCategory) {
        handleCategoryAdd();
      } else {
        notify('Please select same master category', 'error');
      }
    } else {
      handleCategoryAdd();
    }
  };

  useEffect(() => {
    if (tag.length === 0) {
      setMasterCategoryArr([]);
    }
  }, [tag]);

  const handleCategoryAdd = () => {
    if (selectedMainCategory === '0') {
      notify('Please select main category', 'error');
    } else if (selectedSubCategory === '0') {
      notify('Please select sub category', 'error');
    } else {
      setTag((prev) => [...prev, parseInt(selectedSubCategory, 10)]);
      setSelectedMainCategory('0');
      setSelectedSubCategory('0');
      const main_category = mainCategory?.find((item) => item.id === parseInt(selectedMainCategory, 10));

      const sub_category = subCategory?.find((item) => item.id === parseInt(selectedSubCategory, 10));

      if (main_category && sub_category) {
        setSelectedCategories((prevState) => [
          ...prevState,
          {
            label: `${main_category.name} - ${sub_category.name}`,
            id: sub_category.id,
            isColorAvailable: sub_category.isColorAvailable,
            isSizeAvailable: sub_category.isSizeAvailable,
            sizes: sub_category?.tagSizes,
          },
        ]);
      }
    }
  };

  const handleCategoryDelete = (id) => {
    setSelectedCategories((prevState) => prevState.filter((item) => item.id !== id));
    setTag((prev) => prev.filter((item) => item !== id));
  };

  useEffect(() => {
    if (selectedCategories?.length < 2) {
      formik.setFieldValue('variations', [
        {
          actualPrice: '',
          categoryName: '0',
          color_id: '0',
          id: 0,
          discount: 0,
          is_discount: false,
          price: '',
          stocks: '',
          subscription_price: '',
          size: '0',
        },
      ]);
    }

    // eslint-disable-next-line
  }, [selectedCategories]);

  useEffect(() => {
    // check if selectedCategories one or more than one item have isColorAvailable true or isSizeAvailable true
    const isColorAvailable = selectedCategories?.some((item) => item.isColorAvailable);
    const isSizeAvailable = selectedCategories?.some((item) => item.isSizeAvailable);

    if (isColorAvailable || isSizeAvailable) {
      setIsShowVariationsTable(true);
    } else {
      setIsShowVariationsTable(false);
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (selectedCategories?.length > 1 || isShowVariationsTable) {
      formik.setFieldValue('quantity', '');
      formik.setFieldValue('subscription_price', '');
      formik.setFieldValue('actualPrice', '');
      formik.setFieldValue('price', '');
    }

    // eslint-disable-next-line
  }, [selectedCategories]);

  return (
    <div className="addsingleproduct">
      <Loader open={isLoading} message={LoadingMessage} />
      <BannerCard
        title="Product upload"
        para="This is where you can view and upload photos of your products. When bulk uploading products you need to upload your images here and reference the link in your CSV."
        smallCard
      />
      <Container className="all-container-27inch">
        <form className="" onSubmit={formik.handleSubmit}>
          {/* <MainHeading title="Add Product" /> */}
          <div className="personal-container 25">
            <SubHeading title="Photos" />
            <Grid container spacing={6}>
              <Grid item xs={12} sm={2.8}>
                <div>
                  <div className="order-number">Product Photos</div>
                  <div className="pixel-text"> Maximum of 10 photos in jpg or png format </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                {selectedImage.length > 0 && (
                  <>
                    {selectedImage.map((img, index) => {
                      return (
                        <div key={index} className="img-container">
                          <CloseIcon
                            className="close-icon"
                            onClick={() => {
                              setSelectedImage((prevState) => prevState.filter((item) => item.id !== img.id));
                            }}
                          />
                          <ProductsCard image={img?.image} name={img?.name} type="add_product" />
                        </div>
                      );
                    })}
                  </>
                )}
                <div className="drop-down">
                  <div className="file-label">
                    <label htmlFor="files">
                      <img src={images.upload} className="upload-img" alt="gallery" />
                      <p className="select-image-text">
                        <MediaLibrary getSelectedImage={getSelectedImage} /> to select files or upload images
                      </p>
                    </label>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="personal-container 26">
            <SubHeading title="Listing Details" />
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={2.8}>
                <div>
                  <div className="order-number">Product Title</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <InputTypeOutline
                  placeholder="Product Title"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={2.8}>
                <div>
                  <div className="order-number">Categories</div>
                  <div className="pixel-text">Maximum of 1</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div className="categories-drop-down">
                  <CategoryDropDown
                    options={masterCategory}
                    value={selectedMasterCategory}
                    disabled={tag?.length === 1}
                    defaultSelect={{
                      name: 'Master category',
                      value: '0',
                    }}
                    onChange={(e) => {
                      setSelectedMasterCategory(e.target.value);
                      setSelectedMainCategory('0');
                      setMasterCategoryArr((prev) => [...prev, e.target.value]);
                    }}
                    onClick={() => {
                      if (tag?.length >= 1) {
                        notify('You can only add one category.', 'error');
                      }
                    }}
                  />
                </div>
                {tag?.length < 1 && mainCategory?.length > 0 && (
                  <div className="categories-drop-down">
                    <CategoryDropDown
                      value={selectedMainCategory}
                      options={mainCategory}
                      defaultSelect={{
                        name: 'Main category',
                        value: '0',
                      }}
                      onChange={(e) => {
                        setSelectedMainCategory(e.target.value);
                        setSelectedSubCategory('0');
                      }}
                    />
                  </div>
                )}
                {subCategory?.length > 0 && (
                  <div className="categories-drop-down">
                    <CategoryDropDown
                      value={selectedSubCategory}
                      options={subCategory}
                      defaultSelect={{
                        name: 'Sub category',
                        value: '0',
                      }}
                      // getSubCategory={getSubCategory}
                      onChange={(e) => {
                        setSelectedSubCategory(e.target.value);
                      }}
                    />
                  </div>
                )}
                <div className="categories-drop-down">
                  {' '}
                  <LightBtn
                    disabled={
                      selectedMasterCategory === '0' || selectedMainCategory === '0' || selectedSubCategory === '0'
                    }
                    customClass={`${selectedSubCategory !== '0' ? 'singleproductbtndrk' : ''}`}
                    title="Add"
                    onClick={checkCategoryAdd}
                  />
                </div>
                {selectedCategories.map((item, index) => (
                  <Chip
                    key={index}
                    label={item.label}
                    variant="outlined"
                    onDelete={() => handleCategoryDelete(item.id)}
                  />
                ))}

                <div className="add-new">You can add sizes and colours below if applicable</div>
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={2.8}>
                <div>
                  <div className="order-number">Description</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Editor
                  modules={modules}
                  formats={formats}
                  theme="snow"
                  placeholder="Description"
                  name="description"
                  value={formik.values.description}
                  error={formik.touched.description && formik.errors.description}
                  onChange={(e) => {
                    formik.setFieldValue('description', e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={2.8}>
                <div>
                  <div className="order-number">Product type</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <CategoryDropDown
                  defaultSelect={{
                    name: 'Product type',
                    value: '0',
                  }}
                  value={productType}
                  options={productTypeOptions}
                  onChange={(e) => setProductType(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={2.8}>
                <div>
                  <div className="order-number">Product condition</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <CategoryDropDown
                  defaultSelect={{
                    name: 'Product condition',
                    value: '0',
                  }}
                  value={condition}
                  options={conditionOptions}
                  onChange={(e) => setCondition(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={2.8}>
                <div>
                  <div className="order-number">Shipping type</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <CategoryDropDown
                  defaultSelect={{
                    name: 'Shipping type',
                    value: '0',
                  }}
                  value={shippingType}
                  options={shippingTypeOptions}
                  onChange={(e) => setShippingType(e.target.value)}
                />
              </Grid>
            </Grid>
          </div>

          <div className="btn-section">
            <LightBtn title="Cancel" onClick={() => navigate(-1)} />
            {/* <DarkBtn title="Save" type="submit" loading={isLoading} /> */}
            <div>
              {/* <span className="preview-btn">
                {' '}
                <LightBtn title="Preview" />
              </span> */}
              <PublishUpdateDialog
                errors={{
                  ...formik.errors,
                  ...errors,
                }}
                values={formik.values}
                onSubmit={formik.handleSubmit}
                setType={setType}
                isPublishLoading={isPublishLoading}
                isSaveLoading={isSaveLoading}
                hidePublish
              />
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default AddSingleProduct;
