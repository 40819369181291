/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-debugger */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, DialogActions, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { images } from '../../assets';
import { UploadSingleImage } from '../../components/universal/UploadImage';
import { BlueBtn } from '../../components/universal/Buttons/BlueBtn';
import BannerCard from '../../components/universal/WelcomeCard';
import { AxiosAll } from '../../services/axiosAll';
import TextArea from '../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../components/universal/InputType/inputTypeOutline';
import { notify } from '../../core/constants';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import ProductsCard from '../../components/universal/ProductsCard';
import { Empty } from '../../components/universal/Empty';
import { NormalCard } from '../../components/NormalCard/NormalCard';
import { Loader } from '../../components/Skeleton/Loader';

export const LatestArrival = () => {
  const route = useNavigate();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { id } = useParams();
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaImage, setMetaImage] = useState('');
  const [product, setProduct] = useState([]);
  const [productImages, setProductImages] = useState();
  const [selectedProduct, setSelected] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    description: '',
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    button_label: '',
    button_link: '',
    title_mobile: '',
    description_mobile: '',
    button_label_mobile: '',
    description_tablet: '',
    button_label_tablet: '',
    title_web: '',
    description_web: '',
    button_label_web: '',
  };

  const validationSchema = Yup.object({
    // title: Yup.string().required('Title is required'),
    // description: Yup.string().required('Description is required'),
    // button_label: Yup.string().required('Button label is required'),
  });

  const onSubmit = async (values) => {
    if (selectedProduct.length === 0) {
      notify('Please select at least one product', 'error');
    } else if (!metaImage) {
      notify('Image is required', 'error');
    } else {
      const formData = new FormData();
      formData.append('title', values?.title);
      formData.append('description', values?.description);
      formData.append('button_label', values?.button_label);
      formData.append('title_mobile', values?.title_mobile);
      formData.append('description_mobile', values?.description_mobile);
      formData.append('button_label_mobile', values?.button_label_mobile);
      formData.append('title_tablet', values?.title_tablet);
      formData.append('description_tablet', values?.description_tablet);
      formData.append('button_label_tablet', values?.button_label_tablet);
      formData.append('title_web', values?.title_web);
      formData.append('description_web', values?.description_web);
      formData.append('button_label_web', values?.button_label_web);
      formData.append('seo_meta_title', values?.metaTitle);
      formData.append('seo_meta_description', values?.metaDescription);
      formData.append('seo_meta_keywords', values?.metaKeywords);
      formData.append('seo_meta_image', metaImage);
      if (selectedProduct.length > 0) {
        for (let i = 0; i < selectedProduct.length; i++) {
          formData.append(`product_ids[${i}]`, selectedProduct[i]?.id);
        }
      } else {
        formData.append(`product_ids`, '');
      }

      setLoading(true);
      try {
        const endPoint = id
          ? `seller/sellerSections/${id}?section_type=latest_arrival&_method=PUT`
          : `seller/sellerSections?section_type=latest_arrival`;
        const response = await AxiosAll('post', endPoint, formData);
        if (response.statusCode === 200) {
          notify(response.message, 'success');
          // route('/onboarding/site-editor/product-collection');
          setLoading(false);
          route('/onboarding/site-editor');
        } else {
          notify(response.message, 'error');
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const getDropdowns = async () => {
    const types = ['links'];

    const response = await AxiosAll(
      'get',
      `dropdowns?type[]=${types.join('&type[]=')}&seller_id=${userDetails?.user?.id}`,
    );
    if (response.statusCode === 200) {
      setLinks(response?.data?.links);
    }
  };

  const GetLatestArrivalDetail = async () => {
    setLoading(true);
    const res = await AxiosAll('get', `seller/sellerSections/${id}?section_type=latest_arrival&_method=PUT`, null);

    if (res.statusCode === 200) {
      const latestArrival = res?.data?.latest_arrival;
      formik.setFieldValue('title', latestArrival?.title ? latestArrival?.title : '');
      formik.setFieldValue('description', latestArrival?.description ? latestArrival?.description : '');
      formik.setFieldValue('button_label', latestArrival?.button_label ? latestArrival?.button_label : '');
      formik.setFieldValue('title_mobile', latestArrival?.title_mobile ? latestArrival?.title_mobile : '');
      formik.setFieldValue(
        'description_mobile',
        latestArrival?.description_mobile ? latestArrival?.description_mobile : '',
      );
      formik.setFieldValue(
        'button_label_mobile',
        latestArrival?.button_label_mobile ? latestArrival?.button_label_mobile : '',
      );
      formik.setFieldValue('title_tablet', latestArrival?.title_tablet ? latestArrival?.title_tablet : '');
      formik.setFieldValue(
        'description_tablet',
        latestArrival?.description_tablet ? latestArrival?.description_tablet : '',
      );
      formik.setFieldValue(
        'button_label_tablet',
        latestArrival?.button_label_tablet ? latestArrival?.button_label_tablet : '',
      );
      formik.setFieldValue('title_web', latestArrival?.title_web ? latestArrival?.title_web : '');
      formik.setFieldValue('description_web', latestArrival?.description_web ? latestArrival?.description_web : '');
      formik.setFieldValue('button_label_web', latestArrival?.button_label_web ? latestArrival?.button_label_web : '');
      formik.setFieldValue('metaTitle', latestArrival?.seo_meta_title ? latestArrival?.seo_meta_title : '');
      formik.setFieldValue(
        'metaDescription',
        latestArrival?.seo_meta_description ? latestArrival?.seo_meta_description : '',
      );
      formik.setFieldValue('metaKeywords', latestArrival?.seo_meta_keywords ? latestArrival?.seo_meta_keywords : '');
      formik.setFieldValue(
        'metaDescription',
        latestArrival?.seo_meta_description ? latestArrival?.seo_meta_description : '',
      );
      formik.setFieldValue('metaKeywords', latestArrival?.seo_meta_keywords ? latestArrival?.seo_meta_keywords : '');
      setMetaImage(latestArrival?.seo_meta_image ? latestArrival?.seo_meta_image : '');
      setSelected(latestArrival?.products ? latestArrival?.products : '');
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDropdowns();
    if (id) {
      GetLatestArrivalDetail();
    }
  }, [id]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const getProducts = async () => {
    const response = await AxiosAll('get', `seller/products?&search=${search || ''}`, null);
    if (response?.statusCode === 200) {
      const responseData = response?.data?.data;
      // notify(response.message, 'success');

      setProduct(responseData);
    } else {
      // notify(response.message, 'error');
    }
  };

  const handleDelete = (id) => {
    setSelected(selectedProduct?.filter((e) => e.id !== id));
    setOpenDelete(false);
    setProduct([]);
    setSearchText('');
  };

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedProduct];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    const updateImage = { newSections };
    setSelected(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  useEffect(() => {
    if (search !== '') {
      getProducts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleSearch = (e) => {
    if (e?.target?.value) {
      setSearchText(e?.target?.value);
    } else {
      setSearchText('');
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  useEffect(() => {
    if (selectedProduct?.length) {
      setProduct([]);
    }
  }, [selectedProduct]);
  console.log('product', product);
  console.log('searchText', searchText);

  return (
    <div className="site-latest-arrival">
      <Loader open={loading} />
      <BannerCard title="Latest Arrivals" para={para} backgroundImageURL={images.productSectionImg} />
      <form onSubmit={formik.handleSubmit}>
        <div className="main-section container-27inch">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route(-1)} />
          <div className="trending-detail">
            <div className="search-engine-section">
              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="title"
                      value={formik?.values?.title}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.title && formik?.errors?.title}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Description</Typography>
                    <TextArea
                      placeholder="Enter"
                      name="description"
                      value={formik?.values?.description}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.description && formik?.errors?.description}
                    />
                  </div>
                  <div className="meta-title">
                    <Typography>Button Label</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="button_label"
                      value={formik?.values?.button_label}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.button_label && formik?.errors?.button_label}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="search-engine-section">
            <Typography variant="h4">Search Engine Optimisation</Typography>
            <Typography paragraph className="description">
              Product SEO is the practice of optimising elements of product to provide search engines with enough
              relevant content to get the product found in search engines.
            </Typography>
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Meta Title</Typography>
                  <InputTypeOutline
                    placeholder="Enter"
                    name="metaTitle"
                    value={formik?.values?.metaTitle}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaTitle && formik?.errors?.metaTitle}
                  />
                </div>
                <div className="meta-des">
                  <Typography>Meta Description</Typography>
                  <TextArea
                    placeholder="Enter"
                    name="metaDescription"
                    value={formik?.values?.metaDescription}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaDescription && formik?.errors?.metaDescription}
                  />
                </div>
                <div className="meta-keywords">
                  <Typography>Meta Keywords</Typography>
                  <TextArea
                    placeholder="Enter"
                    name="metaKeywords"
                    value={formik?.values?.metaKeywords}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaKeywords && formik?.errors?.metaKeywords}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="single-img-upload">
            {/* Remove the heading SEO meta image by sanket 28-07-23 */}
            {/* <Typography variant="h4">SEO Meta Image </Typography> */}
            <UploadSingleImage
              title="Meta Image"
              description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
              image={metaImage}
              setImage={setMetaImage}
              imageSize={2}
            />
          </div>

          <div className="product-list">
            <Typography variant="h4">Latest Arrival</Typography>
            <div className="product-gallery">
              <Autocomplete
                options={product?.map((prediction) => prediction?.name)}
                noOptionsText="No Products Found"
                inputValue={searchText}
                onInputChange={handleSearch}
                renderInput={(params) => <TextField {...params} label="Add Custom Products" variant="outlined" />}
                filterSelectedOptions={true}
                onChange={(event, value, reason) => {
                  if (value) {
                    const selectedPrediction = product?.find((prediction) => prediction?.name === value);

                    if (selectedPrediction) {
                      const isDuplicate = selectedProduct?.some((product) => product?.id === selectedPrediction.id);

                      if (isDuplicate) {
                        notify('This product has already been added', 'error');
                        return;
                      }

                      const updatedTopPicks = [...selectedProduct, selectedPrediction];

                      setSelected(updatedTopPicks);
                      setSearchText('');
                      setProduct([]);
                    }
                  }
                }}
              />{' '}
              {selectedProduct?.map((productImg, index) => {
                return (
                  <div className="img-container" key={productImg?.id}>
                    <label className="label-file" htmlFor={`myCheckbox${index}`}>
                      <ProductsCard
                        dragStart={(e) => handleDragStart(e, index)}
                        dragOver={(e) => handleDragOver(e, index)}
                        dragEnd={() => setDraggedOverIndex(null)}
                        dropCard={(e) => handleDrop(e, index)}
                        image={productImg?.cover_image?.image}
                        name={productImg?.name}
                        copyUrl={productImg?.banner_image}
                        id={productImg?.id}
                        isImages
                      />
                    </label>
                    {/* <Checkbox
                        className="checkbox-style"
                        {...Label}
                        id={`myCheckbox${index}`}
                        checkedIcon={<CheckCircleIcon />}
                        checked={!!selectedProductIds?.ids?.includes(productImg?.id)}
                        value={productImg?.id}
                        onClick={(e) => handleCheckbox(productImg?.id, e)}
                        icon={<RadioButtonUncheckedIcon />}
                      /> */}

                    {selectedProduct?.length > 0 ? (
                      <DeleteIcon
                        onClick={() => {
                          setOpenDelete(true);
                          setDeleteId(productImg?.id);
                        }}
                      />
                    ) : null}
                  </div>
                );
              })}
              <Dialog
                open={openDelete}
                onClose={() => {
                  setOpenDelete(false);
                  setDeleteId(null);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="delete-pop-up"
              >
                <div className="delete-container">
                  <Typography variant="h2">Delete</Typography>
                  <Typography paragraph>Are you sure you want to delete this product?</Typography>
                </div>
                <DialogActions>
                  <BlueBtn title="Yes" onClick={() => handleDelete(deleteId)} />
                  <LightBtn
                    title="No"
                    onClick={() => {
                      setOpenDelete(false);
                      setDeleteId(null);
                    }}
                  />
                </DialogActions>
              </Dialog>
              {/* <CardPagination
                page={page}
                noOfPages={productImages?.last_page}
                handleChange={(e, value) => setPage(value)}
                rowsPerPage={productImages?.per_page}
              /> */}
            </div>
          </div>

          <NormalCard>
            <Typography variant="h4">Mobile</Typography>
            <div className="mobile-inputs">
              <div className="meta-title">
                <Typography>Title</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="title_mobile"
                  value={formik?.values?.title_mobile}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.title_mobile && formik?.errors?.title_mobile}
                />
              </div>
              <div className="meta-des">
                <Typography>Description</Typography>
                <TextArea
                  placeholder="Enter"
                  name="description_mobile"
                  value={formik?.values?.description_mobile}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.description_mobile && formik?.errors?.description_mobile}
                />
              </div>
              <div className="meta-title">
                <Typography>Button Label</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="button_label_mobile"
                  value={formik?.values?.button_label_mobile}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.button_label_mobile && formik?.errors?.button_label_mobile}
                />
              </div>
            </div>
          </NormalCard>

          <NormalCard>
            <Typography variant="h4">Tablet</Typography>
            <div className="tablet-inputs">
              <div className="meta-title">
                <Typography>Title</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="title_tablet"
                  value={formik?.values?.title_tablet}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.title_tablet && formik?.errors?.title_tablet}
                />
              </div>
              <div className="meta-des">
                <Typography>Description</Typography>
                <TextArea
                  placeholder="Enter"
                  name="description_tablet"
                  value={formik?.values?.description_tablet}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.description_tablet && formik?.errors?.description_tablet}
                />
              </div>
              <div className="meta-title">
                <Typography>Button Label</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="button_label_tablet"
                  value={formik?.values?.button_label_tablet}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.button_label_tablet && formik?.errors?.button_label_tablet}
                />
              </div>
            </div>
          </NormalCard>

          <NormalCard>
            <Typography variant="h4">Web</Typography>
            <div className="web-inputs">
              <div className="meta-title">
                <Typography>Title</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="title_web"
                  value={formik?.values?.title_web}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.title_web && formik?.errors?.title_web}
                />
              </div>
              <div className="meta-des">
                <Typography>Description</Typography>
                <TextArea
                  placeholder="Enter"
                  name="description_web"
                  value={formik?.values?.description_web}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.description_web && formik?.errors?.description_web}
                />
              </div>
              <div className="meta-title">
                <Typography>Button Label</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="button_label_web"
                  value={formik?.values?.button_label_web}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.button_label_web && formik?.errors?.button_label_web}
                />
              </div>
            </div>
          </NormalCard>
          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route(-1)} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
