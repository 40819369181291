import { Grid } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { axiosAll } from '../../../services/axiosAll';
import BlackBtn from '../../universal/Buttons/BlackBtn';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import DropDown from '../../universal/DropDown';
import InputType from '../../universal/InputType';
import { ReachOptions, doesStoreRunEcommerce, marketPlace, notify } from '../../../core/constants';

const AlmostThere = ({ registration }) => {
  const [captch, setCaptch] = useState('');
  const [validatCaptch, setValidate] = useState(false);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let random1;
  let random2;
  // formik initial state
  const initialValues = {
    reasonForChoosingBazar: '',
    reachOptions: '',
    existingMarketPlace: '',
    doesStoreRunEcommerce: '',
    newsLatter: false,
    termAndCondition: false,
  };

  const randomFunction = () => {
    random1 = Math.floor(Math.random() * 10);
    random2 = Math.floor(Math.random() * 20) + random1;
    const operators = ['*', '+', '-'];

    const index = Math.floor(Math.random() * 3);
    if (index === 0) {
      setTotal(random2 * random1);
    } else if (index === 1) {
      setTotal(random2 + random1);
    } else {
      setTotal(random2 - random1);
    }

    const captchaStr = `${random2} ${operators[index]}  ${random1} =`;
    document.getElementById('#captch').innerHTML = captchaStr;
  };

  useEffect(() => {
    randomFunction();
  }, []);

  // formik validation
  const validationSchema = Yup.object({
    reasonForChoosingBazar: Yup.string().required('This feild is required'),
    reachOptions: Yup.string().required('This feild is required'),
    existingMarketPlace: Yup.string().required('This feild is required'),
    doesStoreRunEcommerce: Yup.string().required('This feild is required'),
    newsLatter: Yup.string().required('This feild is required'),
    termAndCondition: Yup.string().required('This feild is required'),
  });

  const handleRegistration = async (values) => {
    setLoading(true);
    const data = {
      firstName: registration.firstName,
      lastName: registration.lastName,
      companyName: registration.companyName,
      email: registration.email,
      phone: registration.phone,
      city: registration.city,
      state: registration.state,
      country: registration.country,
      postcode: registration.postcode,
      address1: registration.address1,
      address2: registration.address2,
      isVat: registration.isVat,
      vatNumber: registration.vatNumber,
      isRegistered: registration.isRegistered,
      registrationNumber: registration.registrationNumber,
      isWebsite: registration.isWebsite,
      website: registration.website,
      traderType: registration.traderType,
      reasonForChoosingBazar: values.reasonForChoosingBazar,
      reachOptions: values.reachOptions,
      doesStoreRunEcommerce: values.doesStoreRunEcommerce,
      existingMarketPlace: values.existingMarketPlace,
      ethicalProductsType: registration.ethicalProductsType,
      newCategory: registration.newCategory,
    };
    try {
      const responce = await axiosAll({
        method: 'post',
        url: 'v2/pro_seller_requests',
        data,
      });
      if (responce?.statusCode === '102') {
        formik.setFieldValue('newsLatter', false);
        formik.setFieldValue('termAndCondition', false);
        notify(responce?.message, 'error');
        setLoading(false);
      } else {
        setLoading(false);
        notify(responce?.message, 'success');
        navigate(`/thanks-for-registering`);
      }
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };
  const onSubmit = (values) => {
    if (values.termAndCondition === false) {
      notify('Please accept term And Condition', 'error');
    }
    if (captch === total) {
      if (
        values.reasonForChoosingBazar &&
        values.reachOptions &&
        values.existingMarketPlace &&
        values.doesStoreRunEcommerce &&
        values.termAndCondition
      ) {
        handleRegistration(values);
      } else {
        console.log('fill please data');
      }
    } else {
      setValidate(true);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handlechangeCaptch = (e) => {
    const { value } = e.target;
    setCaptch(value);
    setValidate(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const validCaptcha = () => {
    if (captch === '') {
      return <div className="success">Please enter answer</div>;
    }
    if (validatCaptch) {
      return <div className="error">Please enter valid answer</div>;
    }
    return '';
  };

  return (
    <div className="almostthere">
      <div className="register-text-heading">Almost There!</div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputType
              placeholder="What made you choose to use GoEthical?"
              name="reasonForChoosingBazar"
              onChange={formik.handleChange}
              value={formik.values.reasonForChoosingBazar}
              touched={formik.touched.reasonForChoosingBazar}
              error={formik.errors.reasonForChoosingBazar}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DropDown
              name="reachOptions"
              handleChange={(e) => formik.setFieldValue('reachOptions', e.target.value)}
              value={formik.values.reachOptions}
              option={ReachOptions}
              blur={formik.handleBlur}
              touched={formik.touched.reachOptions}
              error={formik.errors.reachOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <DropDown
              name="existingMarketPlace"
              h
              handleChange={(e) => formik.setFieldValue('existingMarketPlace', e.target.value)}
              value={formik.values.existingMarketPlace}
              option={marketPlace}
              blur={formik.handleBlur}
              touched={formik.touched.existingMarketPlace}
              error={formik.errors.existingMarketPlace}
            />
          </Grid>
          <Grid item xs={12}>
            <DropDown
              name="doesStoreRunEcommerce"
              handleChange={(e) => formik.setFieldValue('doesStoreRunEcommerce', e.target.value)}
              value={formik.values.doesStoreRunEcommerce}
              option={doesStoreRunEcommerce}
              blur={formik.handleBlur}
              touched={formik.touched.doesStoreRunEcommerce}
              error={formik.errors.doesStoreRunEcommerce}
            />
          </Grid>
        </Grid>
        <div className="checkbox-section">
          <input
            placeholder="What made you choose to use GoEthical?"
            name="termAndCondition"
            onChange={formik.handleChange}
            value={formik.values.termAndCondition}
            // touched={formik.touched.termAndCondition}
            // error={formik.errors.termAndCondition}
            type="checkbox"
            className="checkbox-trader"
          />
          <p className="label-trader">I agree to the terms and conditions below</p>
        </div>
        <div className="terms-para">
          {' '}
          I confirm that I have read and understood the GoEthical Terms &amp; Conditions. I understand that my trading
          account can be terminated at any time without notice or appeal if I do not keep to these . GoEthical reserve
          the right to update T&amp;Cs at its discretion and will notify sellers as and when these changes happen.{' '}
        </div>
        <div className="checkbox-section">
          <input
            placeholder="What made you choose to use GoEthical?"
            name="newsLatter"
            onChange={formik.handleChange}
            value={formik.values.newsLatter}
            // touched={formik.touched.newsLatter}
            // error={formik.errors.newsLatter}
            type="checkbox"
            className="checkbox-trader"
          />
          <p className="label-trader">Newsletter Sign Up</p>
        </div>

        <div className="btn-section">
          <div className="name-heading">
            {' '}
            Captcha<span className="asterisk">*</span>
          </div>
          <div className="captcha-code" id="#captch" />

          <InputType placeholder="Captcha Element here" onChange={handlechangeCaptch} value={captch} />
          {validCaptcha()}
          {loading ? 'Loading...' : <BlackBtn title="Register" />}
        </div>
      </form>
    </div>
  );
};

export default AlmostThere;
