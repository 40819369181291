import CloseIcon from '@mui/icons-material/Close';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Alert, AlertTitle, Container, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { images } from '../../../assets';
import { SubscriptionInfo } from '../../../components/Profile/subscriptionCard';
import UpdateBankDetails from '../../../components/dialogs/UpdateBankDetails';
import UpdateBusinessDetails from '../../../components/dialogs/UpdateBusinessDetails';
import UpdatePassword from '../../../components/dialogs/UpdatePassword';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import SubHeading from '../../../components/universal/Headings/SubHeading';
import TextArea from '../../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { addUser } from '../../../redux/actions/user';
import { AxiosAll } from '../../../services/axiosAll';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  const bussinessDetail =
    'Your business information will be publicly viewable to users within<br />the app on your Pro Seller Profile screen. To update or add a VAT number, please contact support@goethical.com';

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const MyProfile = () => {
  const [profileData, setProfileData] = useState();
  const [date, setDate] = useState();
  const [profile, setProfile] = useState('');
  const [validationUrl, setValidationUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dobLoading, setDOBLoading] = useState(false);
  const [policyValidation, setPolicyValidation] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [bankAccountModal, setBankAccountModal] = useState(false);
  const [DobValidation, setDobValidation] = useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const [personalData, setPersonalData] = useState();
  const [accountToggle, setAccountToggle] = useState(false);
  const [shopToggle, setShopToggle] = useState(false);
  const [policyToggle, setPolicyToggle] = useState(false);
  const [bannerRemove, setRemove] = useState(false);
  const [openBusinessDetailsDialog, setOpenBusinessDetailsDialog] = useState(false);
  const location = useLocation();
  const tabValue = location?.state?.tab;
  const [value, setValue] = React.useState(0);
  const [error, setError] = useState('');
  const [bankError, setBankError] = useState('');

  const max_date = moment(new Date()).subtract(13, 'years')._d;
  const Max_Date = moment(new Date()).subtract(12, 'years')._d;
  const { user } = useSelector((state) => state.userReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const dispatch = useDispatch();

  const [bannerImage, setBannerImage] = useState('');
  const [userDataProfile, setUserData] = useState({
    bio: '',
    businessAddress: '',
    businessEmail: '',
    businessName: '',
    businessPhoneNumber: '',
    registrationNumber: '',
    videoUrl: '',
    vatNumber: '',
    name: '',
    phoneNumber: '',
    proSeller: 0,
    storeName: '',
    returnPolicy: '',
    refundPolicy: '',
    shippingPolicy: '',
  });

  const [accountData, setAccountData] = useState({
    accountNumber: '',
    routingNumber: '',
    deviceToken: '',
    deviceType: '',
    firstName: '',
    lastName: '',
  });

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    getAccount();
    getProfile();
  };

  const userDetailsCall = async () => {
    try {
      const response = await AxiosAll('get', 'seller/profile', null, userData?.userToken);
      if (response?.data?.is_email_verified == 1) {
        // notify(response.data.message, 'success');

        dispatch(addUser({ user: response?.data }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBankType = (bankType) => {
    switch (true) {
      case bankType === 'swift_code_account_number':
        return {
          accountType: 'Account Number',
          accountSubType: 'Swift Code',
        };
      case bankType === 'swift_code_iban_number':
        return {
          accountType: 'IBAN Number',
          accountSubType: 'Swift Code',
        };
      case bankType === 'account_number':
        return {
          accountType: 'Account Number',
          accountSubType: '',
        };
      case bankType === 'routing_account_number':
        return {
          accountType: 'Account Number',
          accountSubType: 'Routing Number',
        };
      case bankType === 'iban_number':
        return {
          accountType: 'IBAN Number',
          accountSubType: '',
        };

      default:
        return {
          accountType: '',
          accountSubType: '',
        };
    }
  };

  const getAccount = async (status) => {
    const response = await AxiosAll('get', `seller/getBankAccount`);
    if (response?.statusCode === 200) {
      if (
        (profileData?.verificationStatus === 'unverified' && status === 'openLink') ||
        (profileData?.verificationStatus == null && status === 'openLink')
      ) {
        const url = response?.data?.verification_link;
        window.open(url, '_self');
      }
      setProfileData(response?.data);
      setAccountData({
        ...accountData,
        accountNumber: response?.data?.last4 ? response?.data?.last4 : '',
        routingNumber: response?.data?.routingNumber ? response?.data?.routingNumber?.replaceAll('-', '') : '',
        fullName: response?.data?.account_holder_name ? `${response?.data?.account_holder_name}` : '',
      });
    } else {
      setBankError(response.statusCode === 419 && response?.message);
    }
  };

  const DobDate = `${profileData?.dob?.year}-${profileData?.dob?.month}-${profileData?.dob?.day}`;

  const getProfile = async () => {
    try {
      const response = await AxiosAll('get', 'seller/profile', null, userData?.userToken);
      const responseData = response?.data;

      if (user?.user?.stripe_account_status != '1') {
        dispatch(addUser({ user: response?.data }));
      }
      setPersonalData(response?.data);
      setUserData({
        ...userDataProfile,
        registrationNumber: responseData?.b_registration_number ? responseData?.b_registration_number : '',
        bio: responseData?.store?.store_description ? responseData?.store?.store_description : '',
        businessAddress: responseData?.b_business_address ? responseData?.b_business_address : '',
        businessEmail: responseData?.b_business_email ? responseData?.b_business_email : '',
        businessName: responseData?.b_business_name ? responseData?.b_business_name : '',
        businessPhoneNumber: responseData?.b_phone_number ? responseData?.b_phone_number : '',
        videoUrl: responseData?.store?.store_video_url ? responseData?.store?.store_video_url : '',
        vatNumber: responseData?.b_vat_number ? responseData?.b_vat_number : '',
        name: responseData?.name ? responseData?.name : '',
        phoneNumber: responseData?.phone_number ? responseData?.phone_number : '',
        storeName: responseData?.store?.store_name ? responseData?.store?.store_name : '',
        returnPolicy: responseData?.store?.return_policy || '',
        refundPolicy: responseData?.store?.refund_policy || '',
        shippingPolicy: responseData?.store?.shipping_policy || '',
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateUserData = async (section) => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (section === 'accountDetails') {
        formData.append('profile[phone_number]', userDataProfile?.phoneNumber);
      }
      if (section === 'shopDetails') {
        formData.append('store[store_logo]', profile);
        formData.append('store[store_banner]', bannerImage);
        formData.append('store[store_name]', userDataProfile?.storeName);
        formData.append('profile[name]', userDataProfile?.name);
        formData.append('store[store_description]', userDataProfile?.bio);
        formData.append('store[store_video_url]', userDataProfile?.videoUrl);
      }
      if (section === 'Policies') {
        formData.append('store[return_policy]', userDataProfile?.returnPolicy);
        formData.append('store[refund_policy]', userDataProfile?.refundPolicy);
        formData.append('store[shipping_policy]', userDataProfile?.shippingPolicy);
      }
      if (section === 'businessDetails') {
        formData.append('business[business_name]', userDataProfile?.businessName);
        formData.append('business[business_email]', userDataProfile?.businessEmail);
        formData.append('business[business_address]', userDataProfile?.businessAddress);
        formData.append('business[phone_number]', userDataProfile?.businessPhoneNumber);
        formData.append('business[registration_number]', userDataProfile?.registrationNumber);
        formData.append('business[vat_number]', userDataProfile?.vatNumber);
      }

      const response = await AxiosAll('post', `seller/profile`, formData);
      if (response?.statusCode === 200) {
        setLoading(false);
        notify(response.message, 'success');
        getProfile();
        setAccountToggle(false);
        setPolicyToggle(false);
        setPolicyValidation(false);
        setOpenBusinessDetailsDialog(false);
        setShopToggle(false);
      } else {
        notify(response.message, 'error');
        setLoading(false);
        setPolicyValidation(true);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const updatePassword = async (data) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('change_password[oldPassword]', data?.currentPassword);
      formData.append('change_password[newPassword]', data?.newPassword);
      const response = await AxiosAll('post', `seller/profile`, formData);
      if (response?.statusCode === 200) {
        setPasswordModal(false);
        setLoading(false);
        notify(response?.message, 'success');
      } else {
        setLoading(false);
        notify(response?.message, 'error');
      }
      setAccountToggle(false);
      setShopToggle(false);
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const updateAccountData = async (values, bankType) => {
    const bankAccount = {
      account_holder_name: values?.fullName,
      account_number: `${values?.accountNumber}`,
    };
    if (bankType?.accountSubType !== '' && values?.routingNumber) {
      bankAccount.routing_number = `${values?.routingNumber}`;
    }
    setLoading(true);
    try {
      const response = await AxiosAll('post', `seller/addBankAccount`, bankAccount);
      if (response?.statusCode === 200) {
        notify(response?.message, 'success');
        setLoading(false);
        setAccountToggle(false);
        setBankAccountModal(false);
        getAccount('openLink');
        userDetailsCall();
      } else {
        setLoading(false);
        notify(response?.message, 'error');
      }
    } catch (err) {
      notify(err, 'error');
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event?.dataTransfer.files[0]) {
      setBannerImage(event?.dataTransfer.files[0]);
      setRemove(false);
      setDragActive(false);
    }
  };

  const updateAll = (section) => {
    if (userDataProfile?.businessEmail === '') {
      notify('Business email is required to update shop details', 'error');
    } else if (userDataProfile?.businessName === '') {
      notify('Business name is required  to update shop details', 'error');
    } else if (userDataProfile?.businessAddress === '') {
      notify('Business address is required  to update shop details', 'error');
    } else if (userDataProfile?.bio === '') {
      notify('Bio is required to update shop details', 'error');
    } else if (validationUrl) {
      notify('Please add valid link for video url', 'error');
    } else {
      updateUserData(section);
    }
  };

  const updateDate = async () => {
    setDOBLoading(true);
    try {
      const formattedDate = moment(date)?.format('YYYY-MM-DD');
      const response = await AxiosAll('post', `seller/updateStripeAccountDetail`, { date_of_brith: formattedDate });
      if (response?.statusCode == 200) {
        getAccount();
        setDOBLoading(false);
        notify(response.message, 'success');
      } else {
        setDOBLoading(false);
        notify(response.message, 'error');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AccountToggle = () => {
    setAccountToggle(!accountToggle);
  };

  const ShopToggle = () => {
    setShopToggle(!shopToggle);
    setProfile('');
    setBannerImage('');
    setRemove(false);
  };

  const PolicyToggle = () => {
    setPolicyToggle(!policyToggle);
    setPolicyValidation(true);
  };

  const handleUserChange = (e, item) => {
    setUserData({ ...userDataProfile, [e.target.name]: e.target.value });
    if (item === 'videoUrl') {
      const regex = /(\/|%3D|v=)([0-9A-z-_]{11})([%#?&]|$)/;
      const videos = ['mp4', '3gp', 'ogg', 'mov', 'wmv', 'avi', 'mkv'];

      const extension = e.target.value.split(/[#?]/)[0].split('.').pop().trim();
      if (videos?.includes(extension)) {
        setValidationUrl(false);
      } else if (regex.test(e.target.value)) {
        setValidationUrl(false);
      } else if (e.target.value === '') {
        setValidationUrl(false);
      } else {
        setValidationUrl(true);
      }
    }
  };

  const handleUploadBanner = (event) => {
    if (event.target.files.length > 0) {
      setBannerImage(event.currentTarget.files[0]);
      setRemove(false);
    }
  };

  const handleDateChange = (newValue) => {
    if (Max_Date > newValue) {
      console.log(true);
      setDobValidation(false);
      setDate(newValue);
    } else {
      console.log(false);

      setDobValidation(true);
    }
  };

  const validatePhoneNumber = /^[0-9]{10}$/;

  useEffect(() => {
    if (userDataProfile?.phoneNumber !== '' && !validatePhoneNumber.test(userDataProfile?.phoneNumber)) {
      setError({
        phoneNumber: 'Please enter valid phone number',
      });
    } else {
      setError('');
    }
  }, [userDataProfile?.phoneNumber]);

  useEffect(() => {
    getProfile();
    getAccount();
  }, []);

  useEffect(() => {
    if (tabValue) {
      setValue(tabValue);
    }
  }, [tabValue]);

  const banner = personalData?.store?.store_banner;

  const handleRemoveImage = () => {
    setRemove(true);
    setBannerImage('');
  };

  const showBannerImage = () => {
    if (loading) {
      return false;
    }
    if (bannerRemove) {
      return false;
    }
    if (banner) {
      return true;
    }
    if (bannerImage) {
      return true;
    }
    if (!banner || !bannerImage) {
      return false;
    }
    return true;
  };

  const bankType = personalData?.bank_country?.support_type || userData?.user?.bank_country?.support_type;

  return (
    <div className="myprofile">
      <BannerCard
        title="Your Profile"
        para="This is where you can view and upload photos of your products. When bulk uploading products you need to upload your images here and reference the link in your CSV."
        smallCard
      />
      <Container className="all-container-27inch">
        {/* <Grid container spacing={2}> */}
        {/* <Grid item xs={12} sm={3} className="mobilehide">
                    <SellerSideBar />
                </Grid> */}
        {/* <Grid item xs={12} sm={12} className="myprofilecontainer"> */}
        {/* <MainHeading title="My Profile" /> */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Personal" {...a11yProps(0)} />
            <Tab label="Business & payment" {...a11yProps(1)} />
            {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="personal-container 18">
            <div className="width-container">
              <div className="text-section">
                <SubHeading title="Account Details" />
                {accountToggle ? (
                  <div className="btn-section">
                    <LightBtn title="Close" onClick={AccountToggle} />
                    <DarkBtn
                      title="Save"
                      onClick={() => {
                        if (userDataProfile?.phoneNumber === '') {
                          notify('Please add mobile number', 'error');
                        } else if (error?.phoneNumber) {
                          notify(error?.phoneNumber, 'error');
                        } else {
                          updateUserData('accountDetails');
                        }
                      }}
                    />
                  </div>
                ) : (
                  <LightBtn title="Update" onClick={AccountToggle} />
                )}
              </div>
              <Grid container spacing={0} className="gridcenter">
                <Grid item xs={12} sm={3.4}>
                  <div className="order-number">Login email</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="order-data "> {personalData?.email}</div>
                </Grid>
              </Grid>

              <Grid container spacing={0} className="">
                <Grid item xs={12} sm={3.4}>
                  <div className="order-number">Mobile number</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  {accountToggle ? (
                    <div className="order-data ">
                      <InputTypeOutline
                        type="number"
                        name="phoneNumber"
                        onChange={handleUserChange}
                        value={userDataProfile?.phoneNumber}
                        error={error?.phoneNumber}
                      />
                    </div>
                  ) : (
                    <div className="order-data ">{personalData?.phone_number}</div>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="personal-container 19">
            <div className="width-container">
              <div className="text-section">
                <SubHeading title="Password" />
                <UpdatePassword
                  updatePassword={updatePassword}
                  setLoading={setLoading}
                  loading={loading}
                  passwordModal={passwordModal}
                  setPasswordModal={setPasswordModal}
                />
              </div>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={3.4}>
                  <div className="order-number">Password</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="order-data ">**** </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="personal-container 20">
            <div className="width-container">
              <div className="text-section">
                <div>
                  <SubHeading title="Shop Details" />
                  <div className="shop-para"> These details will form your seller profile in the app </div>
                </div>

                <div className="btn-section">
                  {shopToggle ? (
                    <div className="btn-section">
                      <LightBtn title="Close" onClick={ShopToggle} />
                      <DarkBtn title="Save" onClick={() => updateAll('shopDetails')} />
                    </div>
                  ) : (
                    <LightBtn title="Update" onClick={ShopToggle} />
                  )}
                </div>
              </div>
              <Grid container spacing={0} className="gridcenter mrgbottom">
                <Grid item xs={12} sm={3.4}>
                  <div className="logo-text">Logo</div>
                  <div className="pixel-text">(135px x 135px)</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  {shopToggle ? (
                    <div className="file-section-logo">
                      <label htmlFor="files" className="drag-label">
                        <div className="store-img-row">
                          <div className="upload-text">
                            <input
                              id="files"
                              accept="image/x-png ,image/jpeg"
                              className="input-type-file-upload"
                              type="file"
                              name="logo"
                              style={{ display: 'none' }}
                              onChange={(event) => {
                                if (event.target.files.length > 0) {
                                  setProfile(event.currentTarget.files[0]);
                                }
                              }}
                            />
                            {/* Upload */}
                            {loading ? (
                              'Loading...'
                            ) : (
                              <img
                                src={profile ? URL.createObjectURL(profile) : personalData?.store?.store_logo}
                                className="upload-img-new"
                                alt="profile"
                              />
                            )}
                          </div>
                        </div>
                      </label>
                    </div>
                  ) : (
                    <img
                      className="store-img-row "
                      src={personalData?.store?.store_logo ? personalData?.store?.store_logo : images?.defaultUser}
                      height="125px"
                      width="125px"
                      alt="user"
                    />
                  )}

                  {/* (

                      )}

                      {profile && shopToggle ? (
                        <img
                          className="store-img-row "
                          src={URL.createObjectURL(profile)}
                          height="125px"
                          width="125px"
                        />
                      ) : (
                        ""
                      )} */}
                </Grid>
              </Grid>
              <Grid container spacing={0} className="gridcenter mrgbottom">
                <Grid item xs={12} sm={3.4}>
                  <div className="logo-text">Store Banner</div>
                  <div className="pixel-text">(2446px x 580px)</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  {shopToggle ? (
                    <div className="file-section">
                      {showBannerImage() && <CloseIcon onClick={handleRemoveImage} className="upload-close-icon" />}
                      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <label htmlFor="files_banner" className={`${dragActive ? 'drag-active' : ''} drag-label`}>
                          <div className="cover-img">
                            {/* <div > */}

                            {loading ? (
                              'Loading...'
                            ) : showBannerImage() ? (
                              <img
                                src={bannerImage ? URL.createObjectURL(bannerImage) : personalData?.store.store_banner}
                                className="upload-banner-new"
                                alt="banner"
                              />
                            ) : (
                              ''
                            )}
                            {/* </div> */}

                            {!showBannerImage() && (
                              <div className="">
                                <div className="upload-text banner-img1">
                                  {' '}
                                  <div>
                                    <img src={images.upload} className="upload-img" alt="upload" />
                                  </div>
                                  <span className="uploadtext">
                                    <input
                                      id="files_banner"
                                      accept="image/x-png ,image/jpeg"
                                      className="input-type-file-upload"
                                      type="file"
                                      name="banner"
                                      style={{ display: 'none' }}
                                      onChange={handleUploadBanner}
                                    />
                                    click here
                                  </span>{' '}
                                  to select files or upload images
                                </div>
                              </div>
                            )}
                          </div>
                        </label>
                        {dragActive && (
                          <div
                            id="drag-file-element"
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                          />
                        )}
                      </form>
                    </div>
                  ) : (
                    <div className="cover-img">
                      {personalData?.store?.store_banner || bannerImage ? (
                        <img
                          alt="banner"
                          className="bg-img"
                          src={bannerImage ? URL.createObjectURL(bannerImage) : personalData?.store?.store_banner}
                        />
                      ) : (
                        <span className="nobanner_image">No banner image available</span>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={0} className="gridcenter mrgbottom">
                <Grid item xs={12} sm={3.4}>
                  <div className="logo-text">Store Name</div>
                </Grid>
                {shopToggle ? (
                  <Grid item xs={12} sm={7}>
                    <InputTypeOutline name="storeName" onChange={handleUserChange} value={userDataProfile?.storeName} />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={7} className="logo-text">
                    {personalData?.store?.store_name}
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={0} className="gridcenter mrgbottom">
                <Grid item xs={12} sm={3.4}>
                  <div className="logo-text">Full Name</div>
                </Grid>
                {shopToggle ? (
                  <Grid item xs={12} sm={7} className="logo-text">
                    <InputTypeOutline name="name" onChange={handleUserChange} value={userDataProfile?.name} />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={7} className="logo-text">
                    {' '}
                    {personalData?.name}
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={0} className="gridcenter mrgbottom">
                <Grid item xs={12} sm={3.4}>
                  <div className="logo-text">Bio</div>
                </Grid>
                {shopToggle ? (
                  <Grid item xs={12} sm={7}>
                    <TextArea name="bio" value={userDataProfile?.bio} onChange={handleUserChange} />{' '}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={7} className="emailidtext">
                    {' '}
                    {personalData?.store?.store_description}
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={0} className=" mrgbottom">
                <Grid item xs={12} sm={3.4}>
                  <div className="logo-text">
                    Video Url <br /> (Optional)
                  </div>
                </Grid>
                {shopToggle ? (
                  <Grid item xs={12} sm={7}>
                    <InputTypeOutline
                      name="videoUrl"
                      value={userDataProfile?.videoUrl}
                      onChange={(e) => handleUserChange(e, 'videoUrl')}
                    />
                    {validationUrl ? (
                      <div className="input-error-message">Please add valid link for video url</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={7} className="emailidtext">
                    {personalData?.store?.store_video_url}
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
          <div className="personal-container 21">
            <div className="width-container">
              <div className="text-section">
                <SubHeading title="Policies" />

                <div className="btn-section">
                  {policyToggle ? (
                    <div className="btn-section">
                      <LightBtn title="Close" onClick={PolicyToggle} />
                      <DarkBtn title="Save" onClick={() => updateUserData('Policies')} />
                    </div>
                  ) : (
                    <LightBtn title="Update" onClick={PolicyToggle} />
                  )}
                </div>
              </div>
              <Grid container spacing={0} className="gridcenter mrgbottom">
                <Grid item xs={12} sm={3.4}>
                  <div className="logo-text">Return Policy </div>
                </Grid>
                {policyToggle ? (
                  <Grid item xs={12} sm={7}>
                    <TextArea name="returnPolicy" value={userDataProfile?.returnPolicy} onChange={handleUserChange} />{' '}
                    {policyValidation && userDataProfile?.returnPolicy == '' ? (
                      <div className="input-error-message">This field is required</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={7} className="emailidtext">
                    {personalData?.store?.return_policy}
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={0} className="gridcenter mrgbottom">
                <Grid item xs={12} sm={3.4}>
                  <div className="logo-text"> Refund Policy</div>
                </Grid>
                {policyToggle ? (
                  <Grid item xs={12} sm={7}>
                    <TextArea name="refundPolicy" value={userDataProfile?.refundPolicy} onChange={handleUserChange} />{' '}
                    {policyValidation && userDataProfile?.refundPolicy == '' ? (
                      <div className="input-error-message">This field is required</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={7} className="emailidtext">
                    {personalData?.store?.refund_policy}
                  </Grid>
                )}
              </Grid>{' '}
              <Grid container spacing={0} className="mrgbottom">
                <Grid item xs={12} sm={3.4}>
                  <div className="logo-text">Shipping Policy </div>
                </Grid>
                {policyToggle ? (
                  <Grid item xs={12} sm={7}>
                    <TextArea
                      name="shippingPolicy"
                      value={userDataProfile?.shippingPolicy}
                      onChange={handleUserChange}
                    />{' '}
                    {policyValidation && userDataProfile?.shippingPolicy == '' ? (
                      <div className="input-error-message">This field is required</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={7} className="emailidtext">
                    {personalData?.store?.shipping_policy}
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="personal-container 22">
            <div className="width-container">
              <div className="text-section">
                <SubHeading title="Business Details" />
                <UpdateBusinessDetails
                  userData={userDataProfile}
                  handleChange={handleUserChange}
                  updateUserData={updateUserData}
                  setOpenBusinessDetailsDialog={setOpenBusinessDetailsDialog}
                  openBusinessDetailsDialog={openBusinessDetailsDialog}
                  loading={loading}
                  setLoading={setLoading}
                  getProfile={getProfile}
                />
              </div>
              <div className="shop-para">
                Your business information will be publicly viewable to users within the app on your Pro Seller Profile
                screen.
                {/* To update or add a VAT number, please contact support@goethical.com{' '} */}
              </div>

              <Grid container spacing={0} className="gridcenter">
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-number">Business Name</div>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-data "> {personalData?.b_business_name} </div>
                </Grid>
              </Grid>

              <Grid container spacing={0} className="gridcenter">
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-number">Company registration number</div>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-data "> {personalData?.b_registration_number} </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="gridcenter">
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-number">
                    VAT registration number.
                    {/* <span style={{ color: '#757061' }}> Contact us to update</span> */}
                  </div>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-data "> {personalData?.b_vat_number} </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="gridcenter">
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-number">Business Email</div>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-data "> {personalData?.b_business_email}</div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="gridcenter">
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-number">Business Phone</div>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-data "> {personalData?.b_phone_number} </div>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-number">Business Address</div>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-data "> {personalData?.b_business_address} </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="personal-container 23">
            <div className="width-container">
              <div className="text-section">
                <SubHeading title="Bank Details" />
                <UpdateBankDetails
                  updateDisabled={!!bankError}
                  accountData={accountData}
                  onclick={updateAccountData}
                  bankType={getBankType(bankType)}
                  setLoading={setLoading}
                  loading={loading}
                  bankAccountModal={bankAccountModal}
                  setBankAccountModal={setBankAccountModal}
                />
              </div>
              {bankError && (
                <Alert severity="error">
                  <AlertTitle>{bankError}</AlertTitle>
                </Alert>
              )}
              <div className="shop-para">
                {' '}
                After each purchase Sell Stream will pay the amount owed directly to your bank account. The reference
                for the transaction will include the invoice number.{' '}
              </div>
              {profileData?.verificationStatus === 'unverified' ? (
                <div className="info-icon-row">
                  <span className="icon-info">
                    Restricted <NotInterestedIcon />
                  </span>
                  <span className="info-box">
                    Provide more information in order to enable payments and payouts for this account.
                    <div className="textdark">information needed - Due now</div>
                    <ul>
                      {profileData?.fields_needed
                        ? profileData?.fields_needed?.map((item, index) => {
                            return <li key={index}>{item}</li>;
                          })
                        : null}
                    </ul>
                    <hr className="line" />
                    <div className="lastbtn-section">
                      <button
                        type="button"
                        className="verify-btn"
                        onClick={() => window.open(profileData?.verification_link, '_self')}
                      >
                        Verify your account
                      </button>
                    </div>
                  </span>
                </div>
              ) : (
                ''
              )}
              <Grid container spacing={0} className="gridcenter">
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-number">Country</div>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-data ">{personalData?.bank_country?.country} </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="gridcenter">
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-number">Account holder name</div>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-data ">{profileData?.account_holder_name} </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="gridcenter">
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-number">{getBankType(bankType)?.accountType}</div>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  {profileData?.last4 ? <div className="order-data "> ***{profileData?.last4} </div> : ''}
                </Grid>
              </Grid>

              {getBankType(bankType)?.accountSubType && (
                <Grid container spacing={0} className="gridcenter">
                  <Grid item xs={6} sm={4} lg={2}>
                    <div className="order-number">{getBankType(bankType)?.accountSubType}</div>
                  </Grid>
                  <Grid item xs={6} sm={4} lg={2}>
                    <div className="order-data ">{profileData?.routingNumber?.replaceAll('-', '')}</div>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>

          <SubscriptionInfo />

          <div className="personal-container 24">
            <div className="width-container">
              <Grid container spacing={0} className="gridcenter spacegrid">
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="order-number">Update date of birth</div>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <div className="update-date">
                    <LightBtn disabled={dobLoading} title={dobLoading ? 'Loading...' : 'Update'} onClick={updateDate} />
                  </div>
                </Grid>
              </Grid>
              <div className="datepicker">
                {/* <div className="input-field-outline"> */}{' '}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {' '}
                  <DesktopDatePicker
                    name="dob"
                    inputFormat="dd/MM/yyyy"
                    value={date || moment(DobDate).toDate()}
                    maxDate={max_date}
                    openTo="year"
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {/* </div> */}
              </div>
              {DobValidation ? <div className="input-error-message">please choose valid DOB</div> : ''}
            </div>
          </div>
        </TabPanel>
        {/* </Grid>
        </Grid> */}
      </Container>
    </div>
  );
};
