import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ProductCollectionTable } from '../../../components/SiteEditorTable/ComonTable/productCollectionTable';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import CardPagination from '../../../components/universal/CardPagination';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { ShopCollectionSorting } from '../../../components/dialogs/ShopCollectionSorting';
import { Loader } from '../../../components/Skeleton/Loader';

export const ProductCollection = () => {
  const route = useNavigate();
  const [loading, setLoading] = useState(false);
  const [collectionList, setCollectionList] = useState([]);
  const [collectionData, setCollectionData] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getCollectionList = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/shop-collections?per_page=${limit}&page=${page}`, null);
    if (response?.statusCode === 200) {
      setCollectionList(response?.data?.data);
      setCollectionData(response?.data);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getCollectionList();
  }, [page, limit]);

  return (
    <div className="site-product-collection">
      <Loader open={loading} />
      <BannerCard title="Promo Banners" para={para} smallCard />
      <div className="container-27inch">
        <div className="shop-collection-btn">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
          <BlueBtn
            title="Add New Collection"
            customClass="collection-btn"
            onClick={() => route('/onboarding/site-editor/add-product-collection')}
          />
          <ShopCollectionSorting />
        </div>
        <div className="site-table">
          <ProductCollectionTable getCollectionList={getCollectionList} collectionList={collectionList} />
        </div>
        {collectionData?.total >= 12 && (
          <CardPagination
            noOfPages={collectionData?.last_page}
            page={page}
            handleChange={handlePagination}
            rowsPerPage={limit}
          />
        )}
      </div>
    </div>
  );
};
