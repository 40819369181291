import { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';

const EditMultiSelect = ({ name, badges, selectedBadges, setSelectedBadges, setMetadata, metadata, limit }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const optionsData = badges.map((badge) => ({
      id: badge?.id,
      name: badge?.name || badge?.title,
      ...((badge?.image || badge?.cover_image) && { image: badge?.image || badge?.cover_image }),
      ...(badge?.description && { description: badge?.description }),
      ...(badge?.rating && { rating: badge?.rating }),
    }));
    setOptions(optionsData);
  }, [badges]);

  useEffect(() => {
    if (metadata?.length === 0 && selectedBadges) {
      setMetadata(selectedBadges);
    }
  }, [selectedBadges, metadata]);

  return (
    <Multiselect
      name={name || 'badges'}
      options={options}
      selectionLimit={limit || 4}
      selectedValues={selectedBadges}
      onSelect={(selectedList, selectedItem) => {
        setSelectedBadges && setSelectedBadges(selectedList);
        setMetadata && setMetadata(selectedList);
      }}
      onRemove={(selectedList, selectedItem) => {
        const newMetadata = selectedBadges.map((badge) => {
          if (badge?.id === selectedItem?.id) {
            return {
              action: 'delete',
              ...badge,
            };
          }
          return badge;
        });
        setMetadata && setMetadata(newMetadata);
        setSelectedBadges && setSelectedBadges(selectedList);
      }}
      displayValue="name"
    />
  );
};

export default EditMultiSelect;
