import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AxiosAll } from '../../../services/axiosAll';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';

export const AddNewSectionModel = ({ sectionOptions }) => {
  const route = useNavigate();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BlueBtn title="Add New Section" customClass="add-section-btn" onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="add-new-section"
      >
        {/* <DialogContent>
          <Typography variant="h4">
            Add a new section to your website landing page
          </Typography>
          <Typography paragraph>
            Please make sure that all images are in png or jpg format. Image
            dimensions should be {width} x {height}.
          </Typography>
          <img src={images.addNewSectionTemp} alt="" />
        </DialogContent> */}

        <DialogContent>
          <Typography variant="h4">Home Screen</Typography>
          <div className="all-btn-link">
            {sectionOptions.map((buttonData, index) => {
              return (
                <DarkBtn
                  key={buttonData?.id}
                  title={buttonData.section_name}
                  onClick={() => {
                    route(`/onboarding/site-editor/${buttonData.section_slug?.replaceAll('_', '-')}`);
                  }}
                />
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <LightBtn title="Cancel" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
