/* eslint-disable react-hooks/exhaustive-deps */
import { CardContent, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { images } from '../../assets';
import { UploadSingleImage } from '../../components/universal/UploadImage';
import { BlueBtn } from '../../components/universal/Buttons/BlueBtn';
import BannerCard from '../../components/universal/WelcomeCard';
import { AxiosAll } from '../../services/axiosAll';
import TextArea from '../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../components/universal/InputType/inputTypeOutline';
import { notify } from '../../core/constants';
import DropDown from '../../components/universal/DropDown';
import { CardContainer } from '../../components/universal/CardContainer';
import { NormalCard } from '../../components/NormalCard/NormalCard';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import { Loader } from '../../components/Skeleton/Loader';

export const AddThemeBaseContant = () => {
  const route = useNavigate();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { id } = useParams();
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaImage, setMetaImage] = useState('');
  const [image, setImage] = useState('');
  const [icon, setIcon] = useState('');
  const [imagemobile, setImagemobile] = useState('');
  const [iconmobile, setIconmobile] = useState('');
  const [imagetablet, setImagetablet] = useState('');
  const [icontablet, setIcontablet] = useState('');
  const [imageweb, setImageweb] = useState('');
  const [iconweb, seticonweb] = useState('');

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    description: '',
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    button_label: '',
    button_link: '',

    title_mobile: '',
    description_mobile: '',
    button_label_mobile: '',
    button_link_mobile: '',
    title_tablet: '',
    description_tablet: '',
    button_label_tablet: '',
    button_link_tablet: '',

    title_web: '',
    description_web: '',
    button_label_web: '',
    button_link_web: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string(),
    description: Yup.string(),
    metaTitle: Yup.string(),
    metaDescription: Yup.string(),
    metaKeywords: Yup.string(),
    image: Yup.string(),
  });

  const onSubmit = async (values) => {
    if (!image) {
      notify('Image is required', 'error');
    } else {
      const formData = new FormData();
      formData.append('title', values?.title);
      formData.append('description', values?.description);
      formData.append('button_label', values?.button_label);
      formData.append('button_link', values?.button_link);
      formData.append('image', image);
      formData.append('icon', icon);
      formData.append('title_mobile', values?.title_mobile);
      formData.append('description_mobile', values?.description_mobile);
      formData.append('button_label_mobile', values?.button_label_mobile);
      formData.append('button_link_mobile', values?.button_link_mobile);
      formData.append('image_mobile', imagemobile);
      formData.append('icon_mobile', iconmobile);
      formData.append('title_tablet', values?.title_tablet);
      formData.append('description_tablet', values?.description_tablet);
      formData.append('button_label_tablet', values?.button_label_tablet);
      formData.append('button_link_tablet', values?.button_link_tablet);
      formData.append('image_tablet', imagetablet);
      formData.append('icon_tablet', icontablet);
      formData.append('title_web', values?.title_web);
      formData.append('description_web', values?.description_web);
      formData.append('button_label_web', values?.button_label_web);
      formData.append('button_link_web', values?.button_link_web);
      formData.append('image_web', imageweb);
      formData.append('icon_web', iconweb);
      formData.append('seo_meta_title', values?.metaTitle);
      formData.append('seo_meta_description', values?.metaDescription);
      formData.append('seo_meta_keywords', values?.metaKeywords);
      formData.append('seo_meta_image', metaImage);

      setLoading(true);
      try {
        const endPoint = id
          ? `seller/sellerSections/${id}?section_type=theme_base_content&_method=PUT`
          : `seller/sellerSections?section_type=theme_base_content`;

        const response = await AxiosAll('post', endPoint, formData);
        if (response.statusCode === 200) {
          notify(response.message, 'success');
          // route('/onboarding/site-editor');
          setLoading(false);
          route('/onboarding/site-editor');
        } else {
          notify(response.message, 'error');
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const getDropdowns = async () => {
    const types = ['links'];

    const response = await AxiosAll(
      'get',
      `dropdowns?type[]=${types.join('&type[]=')}&seller_id=${userDetails?.user?.id}`,
    );
    if (response.statusCode === 200) {
      setLinks(response?.data?.links);
    }
  };

  const GetThemeBaseDetail = async (data) => {
    setLoading(true);
    const res = await AxiosAll('get', `seller/sellerSections/${id}?section_type=theme_base_content&_method=PUT`, data);

    if (res.statusCode === 200) {
      const themeBaseContent = res?.data?.theme_base_content;
      formik.setFieldValue('title', themeBaseContent?.title || '');
      formik.setFieldValue('description', themeBaseContent?.description || '');
      formik.setFieldValue('button_label', themeBaseContent?.button_label || '');
      formik.setFieldValue('button_link', themeBaseContent?.button_link || '');
      formik.setFieldValue('title_mobile', themeBaseContent?.title_mobile || '');
      formik.setFieldValue('description_mobile', themeBaseContent?.description_mobile || '');
      formik.setFieldValue('button_label_mobile', themeBaseContent?.button_label_mobile || '');
      formik.setFieldValue('button_link_mobile', themeBaseContent?.button_link_mobile || '');
      formik.setFieldValue('title_tablet', themeBaseContent?.title_tablet || '');
      formik.setFieldValue('description_tablet', themeBaseContent?.description_tablet || '');
      formik.setFieldValue('button_label_tablet', themeBaseContent?.button_label_tablet || '');
      formik.setFieldValue('button_link_tablet', themeBaseContent?.button_link_tablet || '');
      formik.setFieldValue('title_web', themeBaseContent?.title_web || '');
      formik.setFieldValue('description_web', themeBaseContent?.description_web || '');
      formik.setFieldValue('button_label_web', themeBaseContent?.button_label_web || '');
      formik.setFieldValue('button_link_web', themeBaseContent?.button_link_web || '');
      formik.setFieldValue('metaTitle', themeBaseContent?.seo_meta_title || '');
      formik.setFieldValue('metaDescription', themeBaseContent?.seo_meta_description || '');
      formik.setFieldValue('metaKeywords', themeBaseContent?.seo_meta_keywords || '');

      setImage(themeBaseContent?.image);
      setImagemobile(themeBaseContent?.image_mobile);
      setImagetablet(themeBaseContent?.image_tablet);
      setImageweb(themeBaseContent?.image_web);
      setMetaImage(themeBaseContent?.seo_meta_image);
      setIcon(themeBaseContent?.icon);
      setIconmobile(themeBaseContent?.icon_mobile);
      setIcontablet(themeBaseContent?.icon_tablet);
      seticonweb(themeBaseContent?.icon_web);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDropdowns();
    if (id) {
      GetThemeBaseDetail();
    }
  }, [id]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  return (
    <div className="theme-base-content">
      <Loader open={loading} />
      <BannerCard title="Theme Base Contact" para={para} backgroundImageURL={images.dynamicProductsImg} />
      <form onSubmit={formik.handleSubmit}>
        <div className="main-section container-27inch">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
          <div className="trending-detail">
            <div className="search-engine-section">
              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="title"
                      value={formik?.values?.title}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.title && formik?.errors?.title}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Description</Typography>
                    <TextArea
                      placeholder="Enter"
                      name="description"
                      value={formik?.values?.description}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.description && formik?.errors?.description}
                    />
                  </div>
                  <div className="meta-title">
                    <Typography>Button Label</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="button_label"
                      value={formik?.values?.button_label}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.button_label && formik?.errors?.button_label}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="search-engine-section">
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Meta Title</Typography>
                  <InputTypeOutline
                    placeholder="Enter"
                    name="metaTitle"
                    value={formik?.values?.metaTitle}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaTitle && formik?.errors?.metaTitle}
                  />
                </div>
                <div className="meta-des">
                  <Typography>Meta Description</Typography>
                  <TextArea
                    placeholder="Enter"
                    name="metaDescription"
                    value={formik?.values?.metaDescription}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaDescription && formik?.errors?.metaDescription}
                  />
                </div>
                <div className="meta-keywords">
                  <Typography>Meta Keywords</Typography>
                  <TextArea
                    placeholder="Enter"
                    name="metaKeywords"
                    value={formik?.values?.metaKeywords}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaKeywords && formik?.errors?.metaKeywords}
                  />
                </div>
                <div className="single-img-upload">
                  <UploadSingleImage
                    title="Banner Image"
                    description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={metaImage}
                    setImage={setMetaImage}
                    imageSize={2}
                  />
                  <br />
                  {/* <div className="meta-title">
                    <Typography>Button Label</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="button_label"
                      value={formik?.values?.button_label}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.button_label && formik?.errors?.button_label}
                    />
                  </div> */}
                </div>
                <div className="meta-des">
                  <Typography>Button Link</Typography>
                  <DropDown
                    placholder="Select"
                    name="button_link"
                    handleChange={(e) => formik.setFieldValue('button_link', e.target.value)}
                    value={formik.values.button_link}
                    option={links}
                    touched={formik.touched.button_link}
                    error={formik.errors.button_link}
                    blur={formik.handleBlur}
                  />
                </div>
                <div className="single-img-upload">
                  {/* <Typography variant="h6"> Image </Typography> */}
                  <UploadSingleImage
                    title="Image"
                    description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={image}
                    setImage={setImage}
                    imageSize={2}
                  />
                </div>
                <div className="single-img-upload">
                  {/* <Typography variant="h6">icon </Typography> */}

                  <UploadSingleImage
                    title="Icon"
                    description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={icon}
                    setImage={setIcon}
                    imageSize={2}
                  />
                </div>
              </div>
            </div>
          </div>

          <NormalCard>
            <Typography variant="h4">Mobile</Typography>
            <div>
              <div className="meta-title">
                <Typography>Title</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="title_mobile"
                  value={formik?.values?.title_mobile}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.title_mobile && formik?.errors?.title_mobile}
                />
              </div>
              <div className="meta-des">
                <Typography>Description</Typography>
                <TextArea
                  placeholder="Enter"
                  name="description_mobile"
                  value={formik?.values?.description_mobile}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.description_mobile && formik?.errors?.description_mobile}
                />
              </div>
              <div className="meta-title">
                <Typography>Button Label</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="button_label_mobile"
                  value={formik?.values?.button_label_mobile}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.button_label_mobile && formik?.errors?.button_label_mobile}
                />
              </div>
              <div className="meta-des">
                <Typography>Button Link</Typography>
                <DropDown
                  placholder="Select"
                  name="button_link_mobile"
                  handleChange={(e) => formik.setFieldValue('button_link_mobile', e.target.value)}
                  value={formik.values.button_link_mobile}
                  option={links}
                  touched={formik.touched.button_link_mobile}
                  error={formik.errors.button_link_mobile}
                  blur={formik.handleBlur}
                />
              </div>
              <div className="single-img-upload">
                {/* <Typography variant="h6"> Image </Typography> */}

                <UploadSingleImage
                  title="Image"
                  description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                  image={imagemobile}
                  setImage={setImagemobile}
                  imageSize={2}
                />
              </div>
              <div className="single-img-upload">
                {/* <Typography variant="h6">icon </Typography> */}

                <UploadSingleImage
                  title="Icon"
                  description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                  image={iconmobile}
                  setImage={setIconmobile}
                  imageSize={2}
                />
              </div>
            </div>
          </NormalCard>

          <NormalCard>
            <Typography variant="h4">Tablet</Typography>
            <div>
              <div className="meta-title">
                <Typography>Title</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="title_tablet"
                  value={formik?.values?.title_tablet}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.title_tablet && formik?.errors?.title_tablet}
                />
              </div>
              <div className="meta-des">
                <Typography>Description</Typography>
                <TextArea
                  placeholder="Enter"
                  name="description_tablet"
                  value={formik?.values?.description_tablet}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.description_tablet && formik?.errors?.description_tablet}
                />
              </div>
              <div className="meta-title">
                <Typography>Button Label</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="button_label_tablet"
                  value={formik?.values?.button_label_tablet}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.button_label_tablet && formik?.errors?.button_label_tablet}
                />
              </div>
              <div className="meta-des">
                <Typography>Button Link</Typography>
                <DropDown
                  placholder="Select"
                  name="button_link_tablet"
                  handleChange={(e) => formik.setFieldValue('button_link_tablet', e.target.value)}
                  value={formik.values.button_link_tablet}
                  option={links}
                  touched={formik.touched.button_link_tablet}
                  error={formik.errors.button_link_tablet}
                  blur={formik.handleBlur}
                />
              </div>
              <div className="single-img-upload">
                {/* <Typography variant="h6"> Image </Typography> */}

                <UploadSingleImage
                  title="Image"
                  description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                  image={imagetablet}
                  setImage={setImagetablet}
                  imageSize={2}
                />
              </div>
              <div className="single-img-upload">
                {/* <Typography variant="h6">icon </Typography> */}

                <UploadSingleImage
                  title="Icon"
                  description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                  image={icontablet}
                  setImage={setIcontablet}
                  imageSize={2}
                />
              </div>
            </div>
          </NormalCard>

          <NormalCard>
            <Typography variant="h4">Web</Typography>
            <div>
              <div className="meta-title">
                <Typography>Title</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="title_web"
                  value={formik?.values?.title_web}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.title_web && formik?.errors?.title_web}
                />
              </div>
              <div className="meta-des">
                <Typography>Description</Typography>
                <TextArea
                  placeholder="Enter"
                  name="description_web"
                  value={formik?.values?.description_web}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.description_web && formik?.errors?.description_web}
                />
              </div>
              <div className="meta-title">
                <Typography>Button Label</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="button_label_web"
                  value={formik?.values?.button_label_web}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.button_label_web && formik?.errors?.button_label_web}
                />
              </div>
              <div className="meta-des">
                <Typography>Button Link</Typography>
                <DropDown
                  placholder="Select"
                  name="button_link_web"
                  handleChange={(e) => formik.setFieldValue('button_link_web', e.target.value)}
                  value={formik.values.button_link_web}
                  option={links}
                  touched={formik.touched.button_link_web}
                  error={formik.errors.button_link_web}
                  blur={formik.handleBlur}
                />
              </div>
              <div className="single-img-upload">
                {/* <Typography variant="h6"> Image </Typography> */}

                <UploadSingleImage
                  title="Image"
                  description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                  image={imageweb}
                  setImage={setImageweb}
                  imageSize={2}
                />
              </div>
              <div className="single-img-upload">
                {/* <Typography variant="h6">icon </Typography> */}

                <UploadSingleImage
                  title="Icon"
                  description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                  image={iconweb}
                  setImage={seticonweb}
                  imageSize={2}
                />
              </div>
            </div>
          </NormalCard>

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor')} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
