import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import 'react-quill/dist/quill.snow.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { images } from '../../../assets';
import { ProductSectionCard } from '../../../components/ProductSection/ProductSectionCard';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import TextArea from '../../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import ProductsCard from '../../../components/universal/ProductsCard';
import { UploadDraggableContainer } from '../../../components/universal/UploadDraggableContainer';
import { UploadSingleImage } from '../../../components/universal/UploadImage';
import BannerCard from '../../../components/universal/WelcomeCard';
import { formats, modules, notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import Editor from '../../../components/universal/InputType/Editor';

export const AddProductBadges = () => {
  const route = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [LogoImage, setImage] = useState('');
  const [metaImage, setMetaImage] = useState('');
  const [banners, setBanners] = useState({
    desktopBanner: '',
    tabletBanner: '',
    mobileBanner: '',
  });

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    name: '',
    title: '',
    description: '',
    status: '1',
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    image: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    title: Yup.string().required('Title is required'),
    status: Yup.string().required('Status is required'),
    image: Yup.string().required('Image is required'),
    description: Yup.string().required('Description is required'),
    metaTitle: Yup.string(),
    metaDescription: Yup.string(),
    metaKeywords: Yup.string(),
  });

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('banner_title', values?.title);
    formData.append('banner_desc', values?.description);
    formData.append('status', values?.status);
    formData.append('image', LogoImage);
    formData.append('seo_meta_title', values?.metaTitle);
    formData.append('seo_meta_description', values?.metaDescription);
    formData.append('seo_meta_keywords', values?.metaKeywords);
    formData.append('seo_meta_image', metaImage);

    formData.append('banner_image', banners?.desktopBanner);
    formData.append('banner_image_tablet', banners?.tabletBanner);
    formData.append('banner_image_mobile', banners?.mobileBanner);

    setLoading(true);

    const endPoint = id ? `seller/badges/${id}?_method=PUT` : 'seller/badges';

    const response = await AxiosAll('post', endPoint, formData);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      route('/onboarding/site-editor/product-badges');
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const getCollectionDetails = async () => {
    const response = await AxiosAll('get', `seller/badges/${id}`, null);
    if (response.statusCode === 200) {
      const collectionData = response.data;
      formik.setFieldValue('title', collectionData?.banner_title || '');
      formik.setFieldValue('name', collectionData?.name || '');
      formik.setFieldValue('description', collectionData?.banner_desc || '');
      formik.setFieldValue('status', collectionData?.status);
      formik.setFieldValue('metaTitle', collectionData?.seo_meta_title || '');
      formik.setFieldValue('metaDescription', collectionData?.seo_meta_description || '');
      formik.setFieldValue('metaKeywords', collectionData?.seo_meta_keywords || '');
      formik.setFieldValue('image', collectionData?.image || '');
      setImage(collectionData?.image || '');
      setMetaImage(collectionData?.seo_meta_image || '');
      setBanners({
        desktopBanner: collectionData?.banner_image || '',
        tabletBanner: collectionData?.banner_image_tablet || '',
        mobileBanner: collectionData?.banner_image_mobile || '',
      });
    } else {
      notify(response.message, 'error');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getCollectionDetails();
    }
  }, []);

  useEffect(() => {
    formik.setFieldValue('image', LogoImage);
  }, [LogoImage]);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  return (
    <div className="addProductBadge">
      <BannerCard title="Product Badge" para={para} backgroundImageURL={images.dynamicProductsImg} />
      <form onSubmit={formik.handleSubmit}>
        <div className="main-section container-27inch">
          <div className="trending-detail">
            <div className="search-engine-section">
              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Name</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="name"
                      value={formik?.values?.name}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.name && formik?.errors?.name}
                    />
                  </div>
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="title"
                      value={formik?.values?.title}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.title && formik?.errors?.title}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Description</Typography>
                    <div className="qul-editor-testimonial">
                      <Editor
                        modules={modules}
                        formats={formats}
                        theme="snow"
                        placeholder="Description"
                        name="description"
                        value={formik.values.description}
                        error={formik.touched.description && formik.errors.description}
                        onChange={(e) => {
                          formik.setFieldValue('description', e);
                        }}
                      />
                    </div>
                  </div>
                  <UploadSingleImage
                    title="Image"
                    //   description="logo"
                    image={LogoImage}
                    setImage={setImage}
                    imageSize={2}
                  />
                  {formik?.touched?.image && formik?.errors?.image && (
                    <div className="input-error-message">{formik?.errors?.image}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="search-engine-section">
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Meta Title</Typography>
                  <InputTypeOutline
                    placeholder="Enter"
                    name="metaTitle"
                    value={formik?.values?.metaTitle}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaTitle && formik?.errors?.metaTitle}
                  />
                </div>
                <div className="meta-des">
                  <Typography>Meta Description</Typography>
                  <TextArea
                    placeholder="Enter"
                    name="metaDescription"
                    value={formik?.values?.metaDescription}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaDescription && formik?.errors?.metaDescription}
                  />
                </div>
                <div className="meta-keywords">
                  <Typography>Meta Keywords</Typography>
                  <TextArea
                    placeholder="Enter"
                    name="metaKeywords"
                    value={formik?.values?.metaKeywords}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaKeywords && formik?.errors?.metaKeywords}
                  />
                </div>

                <div className="meta-keywords margin-top">
                  <Typography>Meta Image</Typography>
                  <UploadSingleImage
                    title="Meta Image"
                    description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={metaImage}
                    setImage={setMetaImage}
                    imageSize={2}
                  />
                </div>
                <div className="meta-keywords margin-top">
                  <Typography>Status</Typography>
                  <div className="status-dropdown">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          name="status"
                          onChange={formik.handleChange}
                          id="demo-simple-select"
                          value={formik.values.status}
                          className="select-field setupselectbox"
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
              </div>

              <UploadDraggableContainer
                dec={para}
                imagePreview={images.sitePreview}
                imageTrash={images.trashBtn}
                banners={banners}
                setBanners={setBanners}
                imageSize={2}
              />
            </div>
          </div>

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor/product-badges')} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
