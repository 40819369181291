import React from 'react';
import { Button } from '@mui/material';

const OutOfStock = () => {
  return (
    <div className="">
      <Button variant="contained" className="outofstock unp-ofs">
        Out Of Stock
      </Button>
    </div>
  );
};

export default OutOfStock;
