import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function OrderSkeleton() {
  return (
    <div className="orderskeleton">
      <table>
        <tr>
          <td className="ordernumber">
            <Skeleton variant="text" className="number" />
          </td>
          <td className="statustd">
            <Skeleton variant="text" className="status" />
            <Skeleton variant="text" className="status" />
          </td>
          <td className="orderandstatus">
            <Skeleton variant="text" className="orderdate" />
            <Skeleton variant="text" className="status" />
          </td>
          <td className="sale">
            <Skeleton variant="text" className="salen" />
          </td>
          <td className="noofitem">
            <Skeleton variant="text" className="noofitemn" />
          </td>
          <td className="acionbtntd">
            <Skeleton variant="rectangular" className="acionbtn" />
          </td>
        </tr>
        <tr>
          <td className="ordernumber">
            <Skeleton variant="text" className="number" />
          </td>
          <td className="statustd">
            <Skeleton variant="text" className="status" />
            <Skeleton variant="text" className="status" />
          </td>
          <td className="orderandstatus">
            <Skeleton variant="text" className="orderdate" />
            <Skeleton variant="text" className="status" />
          </td>
          <td className="sale">
            <Skeleton variant="text" className="salen" />
          </td>
          <td className="noofitem">
            <Skeleton variant="text" className="noofitemn" />
          </td>
          <td className="acionbtntd">
            <Skeleton variant="rectangular" className="acionbtn" />
          </td>
        </tr>
        <tr>
          <td className="ordernumber">
            <Skeleton variant="text" className="number" />
          </td>
          <td className="statustd">
            <Skeleton variant="text" className="status" />
            <Skeleton variant="text" className="status" />
          </td>
          <td className="orderandstatus">
            <Skeleton variant="text" className="orderdate" />
            <Skeleton variant="text" className="status" />
          </td>
          <td className="sale">
            <Skeleton variant="text" className="salen" />
          </td>
          <td className="noofitem">
            <Skeleton variant="text" className="noofitemn" />
          </td>
          <td className="acionbtntd">
            <Skeleton variant="rectangular" className="acionbtn" />
          </td>
        </tr>
      </table>
    </div>
  );
}
