import sweatAlertBox, { sweatAlert } from '../../universal/SweetAlertBox';

const DeleteDialog = ({ name, isAddedToCart, isAddedToWishlist, handleDelete }) => {
  return (
    <div
      tabIndex={0}
      role="button"
      onClick={() =>
        sweatAlertBox(
          'Are You Sure?',
          `You are about  delete the product titled ${name?.bold()} cannot undo this action  once confirmed`,
          'Confirm',
          'Cancel',

          handleDelete,
        )
      }
      className="order-number delete"
    >
      Delete listing
    </div>
  );
};

export default DeleteDialog;
