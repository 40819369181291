import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import DropDown from '../../components/universal/DropDown';
import TextArea from '../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../components/universal/InputType/inputTypeOutline';
import ProgressBar from '../../components/universal/ProgressBar';
import { notify } from '../../core/constants';
import { store } from '../../redux/store';
import { AxiosAll } from '../../services/axiosAll';

const BusinessDetails = () => {
  const nav = useNavigate();
  const state = store.getState();
  const { guestDetails } = state.guestReducer;
  const [userDetails, setUserDetails] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [countryOptions, setCountryOptions] = useState([]);
  const [sellerData, setSellerData] = useState({});
  const [registeredUser, setRegisteredUser] = useState(1);
  const [count, setCount] = useState(5);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { token } = useParams();

  const getUserData = async () => {
    try {
      const res = await AxiosAll('get', 'seller-profile', null, token);
      const user = res?.data?.user;
      setUserDetails(user);
      formik.setFieldValue('businessName', user?.b_business_name ? user?.b_business_name : '');
      formik.setFieldValue('businessAddress', user?.b_business_address ? user?.b_business_address : '');
      formik.setFieldValue('businessCountry', user?.bank_country_id ? user?.bank_country_id : '');
      formik.setFieldValue('businessPhone', user?.b_phone_number ? user?.b_phone_number : '');
      formik.setFieldValue('businessEmail', user?.b_business_email ? user?.b_business_email : '');
      formik.setFieldValue('registrationNo', user?.b_registration_number ? user?.b_registration_number : '');
      formik.setFieldValue('VAT', user?.b_vat_number ? user?.b_vat_number : '');
      setSellerData(res?.data);
      setRegisteredUser(user?.stripe_sub_is_first_time);
      if (res?.data?.user?.stripe_sub_is_first_time !== 1) {
        const timer = setInterval(() => {
          setCount((prev) => prev - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDropdowns = async () => {
    const types = ['stripe_countries'];

    const response = await AxiosAll('get', `dropdowns?type[]=${types.join('&type[]=')}&seller_id=${id}`, null, token);
    if (response.statusCode === 200) {
      const data = response?.data?.stripe_countries;
      setCountryOptions(data);
    }
  };

  useEffect(() => {
    if (!userDetails?.bank_country_id && !formik?.values?.businessCountry) {
      const defaultCountry = countryOptions?.find((item) => item?.is_default === 1);
      formik.setFieldValue('businessCountry', defaultCountry?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryOptions, userDetails?.bank_country_id]);

  useEffect(() => {
    getUserData();
    getDropdowns();
  }, []);

  const onSubmit = async (values) => {
    const data = {
      business_name: values?.businessName,
      business_email: values?.businessEmail,
      bank_country_id: values?.businessCountry,
      phone_number: values?.businessPhone,
      business_address: values?.businessAddress,
      vat_number: values?.VAT,
      registration_number: values?.registrationNo,
    };

    try {
      const response = await AxiosAll('post', 'seller/bussinessDetail', data, token);
      if (response?.statusCode === 200) {
        notify(response?.message, 'success');
        formik.handleReset();
        nav(`/business-product-details/${id}/${token}`);
      } else {
        notify(response?.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // formik start
  const initialValues = {
    businessName: '',
    registrationNo: '',
    VAT: '',
    businessEmail: '',
    businessCountry: '',
    businessPhone: '',
    businessAddress: '',
  };
  const validationSchema = Yup.object({
    businessName: Yup.string().required('Business name is required'),
    businessEmail: Yup.string().required('Business email is required').email('Email is invalid'),
    businessPhone: Yup.string()
      .required('Business phone is required')
      .matches(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Please provide valid phone')
      .min(10, 'Please provide valid phone'),
    businessAddress: Yup.string().required('Business address is required'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (count <= 0 && sellerData?.user?.stripe_sub_is_first_time !== 1) {
      nav(
        `/subscription/${sellerData?.user?.id}/${sellerData?.user_token}?isNew=${sellerData?.user?.stripe_sub_is_first_time}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, sellerData]);

  useEffect(() => {
    if (
      formik?.values?.businessName &&
      formik?.values?.businessEmail &&
      formik?.values?.businessPhone &&
      formik?.values?.businessAddress
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [formik]);

  return (
    <>
      <ProgressBar value={75} />
      {registeredUser !== 1 ? (
        <div className="verifyyouremail">
          <div>You are already registered!</div>
          <div className="text-para">You will be redirected to subscription page in {count} seconds</div>
        </div>
      ) : (
        <div className="policydetails">
          <form onSubmit={formik.handleSubmit}>
            <div className="text-heading">Business details</div>
            <div className="text-para">This information won’t be viewable to customers</div>
            <div className="mrgbottom">
              <div className="label-text">Business Name</div>

              <InputTypeOutline
                name="businessName"
                id="businessName"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.businessName}
                onChange={formik.handleChange}
                error={formik.touched.businessName && formik.errors.businessName}
              />
            </div>

            <div className="mrgbottom">
              <div className="label-text">Company registration number (Optional)</div>
              <InputTypeOutline
                name="registrationNo"
                id="registrationNo"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.registrationNo}
                onChange={formik.handleChange}
              />
            </div>

            <div className="mrgbottom">
              <div className="label-text">VAT registration number (Optional)</div>
              <InputTypeOutline
                name="VAT"
                id="VAT"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.VAT}
                onChange={formik.handleChange}
              />
            </div>

            <div className="mrgbottom">
              <div className="label-text">Business Email</div>
              <InputTypeOutline
                name="businessEmail"
                id="businessEmail"
                type="email"
                onBlur={formik.handleBlur}
                value={formik.values.businessEmail}
                onChange={formik.handleChange}
                error={formik.touched.businessEmail && formik.errors.businessEmail}
              />
            </div>

            <div className="mrgbottom">
              <div className="bussiness-tool-tip">
                <div className="label-text">Business Country</div>
                <Tooltip title="Once you have chosen the country, it cannot be altered at a later time.">
                  <InfoIcon />
                </Tooltip>
              </div>
              <DropDown
                customClass="bussiness-country-dropdown"
                name="businessCountry"
                option={countryOptions}
                disabled={userDetails?.stripe_sub_is_first_time !== 1}
                handleChange={formik.handleChange}
                blur={formik.handleBlur}
                value={formik.values.businessCountry}
                touched={formik.touched.businessCountry}
                error={formik.touched.businessCountry && formik.errors.businessCountry}
                showTooltip
                tooltipTitle="Once you have chosen the country, it cannot be altered at a later time."
              />
            </div>

            <div className="mrgbottom">
              <div className="label-text">Business Phone</div>
              <InputTypeOutline
                name="businessPhone"
                id="businessPhone"
                type="number"
                onBlur={formik.handleBlur}
                value={formik.values.businessPhone}
                onChange={formik.handleChange}
                error={formik.touched.businessPhone && formik.errors.businessPhone}
              />
            </div>

            <div className="">
              <div className="label-text">Business Address</div>
              <TextArea
                name="businessAddress"
                id="businessAddress"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.businessAddress}
                onChange={formik.handleChange}
                error={formik.touched.businessAddress && formik.errors.businessAddress}
              />
            </div>
            <div className="btn-section">
              <div>
                <LightBtn title="Back" type="button" onClick={() => nav(`/policy-details/${id}/${token}`)} />
              </div>
              <div>
                <DarkBtn title=" Continue " type="submit" disable={disableButton} />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default BusinessDetails;
