import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { images } from '../../../../assets';

const SizeDropDown = ({ options, defaultSelect, onChange, value, name }) => {
  return (
    <div className="selectbox">
      <FormControl className="selectboxwidth">
        <Select
          defaultValue={defaultSelect?.value}
          style={{
            backgroundImage: `url(${images.errowDown})`,
          }}
          value={`${value}`}
          className="select-field"
          onChange={onChange}
          name={name}
        >
          <MenuItem value={defaultSelect?.value} disabled>
            {defaultSelect?.name}
          </MenuItem>
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.id}>
              {option?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SizeDropDown;
