import { Typography } from '@mui/material';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';

export const FooterInput = ({
  footerTitle,
  footerDes,
  copyRightTitle,
  copyRightPlaceholder,
  instagramTitle,
  instagramPlaceholder,
  facebookTitle,
  facebookPlaceholder,
  twitterTitle,
  twitterTitlePlaceholder,
  linkedinTitles,
  linkedinTitlePlaceholders,
  formik,
}) => {
  return (
    <div className="footer-input">
      <Typography variant="h4">{footerTitle}</Typography>
      <Typography paragraph className="description">
        {footerDes}
      </Typography>

      <div className="input-title">
        <div className="input-info">
          <div className="social-media">
            <Typography>{copyRightTitle}</Typography>
            <InputTypeOutline
              placeholder={copyRightPlaceholder}
              name="copyRight"
              value={formik.values.copyRight}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.copyRight && formik.errors.copyRight}
            />
          </div>
          <div className="social-media">
            <Typography>{instagramTitle}</Typography>
            <InputTypeOutline
              placeholder={instagramPlaceholder}
              name="instagramURL"
              value={formik.values.instagramURL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.instagramURL && formik.errors.instagramURL}
            />
          </div>
          <div className="social-media">
            <Typography>{facebookTitle}</Typography>
            <InputTypeOutline
              placeholder={facebookPlaceholder}
              name="facebookURL"
              value={formik.values.facebookURL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.facebookURL && formik.errors.facebookURL}
            />
          </div>
          <div className="social-media">
            <Typography>{twitterTitle}</Typography>
            <InputTypeOutline
              placeholder={twitterTitlePlaceholder}
              name="twitterURL"
              value={formik.values.twitterURL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.twitterURL && formik.errors.twitterURL}
            />
          </div>
          <div className="social-media">
            <Typography>{linkedinTitles}</Typography>
            <InputTypeOutline
              placeholder={linkedinTitlePlaceholders}
              name="linkedinURL"
              value={formik.values.linkedinURL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.linkedinURL && formik.errors.linkedinURL}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
