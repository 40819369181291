import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import TextArea from '../../universal/InputType/TextArea';
import { UploadSingleImage } from '../../universal/UploadImage';
import LightBtn from '../../universal/Buttons/LightBtn';

// import { TextArea } from "gestalt";

const SEOMetaSection = ({ type, name, id, data }) => {
  const [loading, setLoading] = useState(false);
  const [Open, setOpen] = useState(false);
  const [title, settitle] = useState('');
  const [metaImage, setMetaImage] = useState('');
  const [metaData, setMetaData] = useState(null);

  const handleClose = () => {
    setOpen(false);
    settitle('');
  };
  const handleCloseAndAdd = () => {
    setOpen(false);
    settitle('');
  };

  const initialValues = {
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
  };

  // const validationSchema = Yup.object({
  //   metaTitle: Yup.string(),
  //   metaDescription: Yup.string(),
  //   metaKeywords: Yup.string(),
  // });

  const getSEOMetaData = async () => {
    const response = await AxiosAll('get', `seller/store`, null);
    const Seodata = response?.data;
    if (response.statusCode === 200) {
      setMetaData(Seodata);
      formik.setFieldValue('metaTitle', Seodata?.seo_meta_title);
      formik.setFieldValue('metaDescription', Seodata?.seo_meta_description);
      formik.setFieldValue('metaKeywords', Seodata?.seo_meta_keywords);
      setMetaImage(Seodata?.seo_meta_image || '');
    } else {
      notify(response.message, 'error');
    }
  };

  const onSubmit = async (values) => {
    if (values?.metaTitle || values?.metaDescription) {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', name);
      formData.append('seo_meta_title', values?.metaTitle);
      formData.append('seo_meta_description', values?.metaDescription);
      formData.append('seo_meta_keywords', values?.metaKeywords);
      formData.append('seo_meta_image', metaImage);

      const response = await AxiosAll('post', `seller/store`, formData);
      if (response.statusCode === 200) {
        notify(response.message, 'success');

        setOpen(false);
        setLoading(false);
      } else {
        notify(response.message, 'error');
        setOpen(true);
        setLoading(false);
      }
    } else {
      setOpen(false);
    }
  };

  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit,
  });
  useEffect(() => {
    getSEOMetaData();
  }, []);

  return (
    <div className="btn-width">
      {metaData !== null ? (
        <BlueBtn
          onClick={() => {
            setOpen(true);
          }}
          title="Edit Meta data"
          customClass="save-order-btn"
        />
      ) : (
        <BlueBtn
          onClick={() => {
            setOpen(true);
          }}
          title=" Add Meta data"
          customClass="save-order-btn"
        />
      )}
      <Dialog
        open={Open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="top-picks"
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <div className="search-pop-up">
              <Typography variant="h4">Search Engine Optimisation</Typography>
              <Typography paragraph className="description">
                Product SEO is the practice of optimising elements of product to provide search engines with enough
                relevant content to get the product found in search engines.Product seo is the practice..
              </Typography>

              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Meta Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="metaTitle"
                      value={formik?.values?.metaTitle}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.metaTitle && formik?.errors?.metaTitle}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Meta Description</Typography>
                    <TextArea
                      topClass="textarea-box"
                      placeholder="Enter"
                      name="metaDescription"
                      value={formik?.values?.metaDescription}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.metaDescription && formik?.errors?.metaDescription}
                    />
                  </div>
                  <div className="meta-keywords">
                    <Typography>Meta Keywords</Typography>
                    <TextArea
                      topClass="textarea-box"
                      placeholder="Enter"
                      name="metaKeywords"
                      value={formik?.values?.metaKeywords}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.metaKeywords && formik?.errors?.metaKeywords}
                    />
                  </div>

                  <div className="single-upload">
                    {/* <Typography>Meta Image</Typography> */}
                    <UploadSingleImage
                      title="Meta Image"
                      description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={metaImage}
                      setImage={setMetaImage}
                      imageSize={2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-section" style={{ display: 'flex', justifyContent: 'end' }}>
              <LightBtn title="Cancel" onClick={handleClose} />
              <BlueBtn title="Save" loading={loading} type="submit" />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SEOMetaSection;
