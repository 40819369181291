import React from 'react';
import 'gestalt/dist/gestalt.css';
import 'gestalt-datepicker/dist/gestalt-datepicker.css';
import DatePicker from 'gestalt-datepicker';
import { enGB } from 'date-fns/locale';

import { Flex } from 'gestalt';

export default function DatePickerRangeExample({
  setStartDate,
  startDate,
  endDate,
  setEndDate,
  endDateInput,
  startDateInput,
}) {
  return (
    <Flex gap={2}>
      <DatePicker
        rangeStartDate={startDate}
        rangeEndDate={endDate}
        id="example-start-date"
        // label="Check In"
        nextRef={endDateInput}
        onChange={({ event, value }) => {
          setStartDate(value);
        }}
        rangeSelector="start"
        value={startDate}
        ref={startDateInput}
        localeData={enGB}
      />
      <DatePicker
        rangeStartDate={startDate}
        rangeEndDate={endDate}
        id="example-end-date"
        // label="Check Out"
        nextRef={startDateInput}
        onChange={({ event, value }) => setEndDate(value)}
        rangeSelector="end"
        value={endDate}
        ref={endDateInput}
        localeData={enGB}
      />
    </Flex>
  );
}
