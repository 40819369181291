import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate, useParams } from 'react-router';
import { images } from '../../assets';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import ProgressBar from '../../components/universal/ProgressBar';
import { notify } from '../../core/constants';
import { AxiosAll } from '../../services/axiosAll';

const VerifyYourEmail = () => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const nav = useNavigate();
  const [isVerified, setIsVerified] = useState('');
  const { id } = useParams();
  const { token } = useParams();
  const [userDetails, setUserDetails] = useState('');
  const [userData, setUserData] = useState('');
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(5);

  const resendVerification = async () => {
    setOtp('');
    try {
      const res = await AxiosAll('post', `seller/resendAccountOtp`, { email: `${userDetails?.email}` });
      notify(res.message, 'success');
    } catch (err) {
      console.log(err);
    }
  };

  const otpVerify = async (data) => {
    setLoading(true);
    try {
      const res = await AxiosAll('post', `seller/email-confirmation`, data);
      if (res?.statusCode === 200) {
        notify(res?.message, 'success');
        setLoading(false);
        nav(`/store-details/${res?.data?.user?.id}/${res?.data?.user_token}`);
      } else {
        notify(res?.message, 'error');
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getUserData = async () => {
    try {
      const res = await AxiosAll('get', 'seller-profile', null, token);
      setUserDetails(res?.data?.user);
      setIsVerified(res?.data?.user?.is_email_verified);
      setUserData(res?.data);
      if (res?.data?.user?.is_email_verified === 1) {
        const timer = setInterval(() => {
          setCount((prev) => prev - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
        }, 5000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // func for varification
  const handleSendForm = () => {
    if (otp.length < 4) {
      setError(true);
    } else if (otp.length === 4) {
      setError(false);
      const data = {
        verification_code: otp,
      };
      otpVerify(data);
      setOtp('');
    }
  };

  const ProfileNav = async (data) => {
    switch (data.user.is_profile_completed === 0) {
      case data?.user?.profile_completed_step === 1:
        nav(`/store-details/${data?.user?.id}/${data?.user_token}`);
        break;
      case data?.user?.profile_completed_step === 2:
        nav(`/policy-details/${data?.user?.id}/${data?.user_token}`);
        break;
      case data?.user?.profile_completed_step === 3:
        nav(`/business-details/${data?.user?.id}/${data?.user_token}`);
        break;
      case data?.user?.profile_completed_step === 4:
        nav(`/business-product-details/${data?.user?.id}/${data?.user_token}`);
        break;
      case data?.user?.profile_completed_step === 5:
        nav(`/subscription/${data?.user?.id}/${data?.user_token}`);
        break;

      default:
        nav('/login');
        break;
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (count === 0) {
      ProfileNav(userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, userData]);

  useEffect(() => {
    if (otp.length === 4) {
      setDisableButton(false);
    }
  }, [otp]);
  return (
    <>
      <ProgressBar value={30} />

      {/* <PublicHeader /> */}
      {isVerified === 1 ? (
        <div className="verifyyouremail">
          <div>You are already verified!</div>
          <div className="text-para">You will be redirected in {count} seconds</div>
        </div>
      ) : (
        <div className="verifyyouremail">
          <img src={images.verify} alt="goEthical logo" className="logo-img" />
          <div className="text-heading">Verify Your Email</div>
          <div className="text-para">
            {' '}
            We’ve emailed you a code. Enter the code to verify your email {userDetails?.email}
          </div>
          <form onSubmit={handleSubmit}>
            <OtpInput
              containerStyle={{
                justifyContent: 'center',
              }}
              inputStyle={{
                margin: '5px',
                textAlign: 'center',
                fontSize: '26px',
                fontFamily: 'FuturaPTBold',
                width: '48px',
                height: '78px',
                borderRadius: '8px',
                borderColor: '#949D8C',
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
              isInputNum
              value={otp}
              onChange={(value) => {
                if (value.length === 4) {
                  setError(false);
                } else {
                  setError(true);
                }
                setOtp(value);
              }}
              numInputs={4}
            />
            {error ? <div className="ErrorMessage">Verification code is required</div> : null}
            <div>
              <div className="code-text">
                Didn’t receive a code?{' '}
                <span tabIndex={0} role="button" className="resend-text" onClick={() => resendVerification()}>
                  Resend
                </span>
              </div>
            </div>
            <div className="btn-section">
              <div>
                <LightBtn title="Back" onClick={() => nav('/create-account/seller-registration')} />
              </div>
              <div>
                <DarkBtn
                  loading={loading}
                  title=" Continue "
                  disable={disableButton || loading}
                  onClick={() => handleSendForm()}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default VerifyYourEmail;
