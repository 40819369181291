/* eslint-disable no-debugger */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import { notify } from '../../../core/constants';
import { AxiosAll, axiosAll } from '../../../services/axiosAll';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import SubHeading from '../../universal/Headings/SubHeading';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import DropDown from '../../universal/DropDown';

const CancelOrderDialog = ({ order_id, OrdersList, open, setOpenin, courier, CourierClass }) => {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [loading, setloading] = useState(false);
  const [status, setStatus] = useState(0);
  const [reason, setReason] = useState(0);

  const [curier, setCurier] = useState();
  const [curierClass, setCurierClass] = useState();
  const [reasonList, setReasonList] = useState([]);

  const getDropdowns = async () => {
    const response = await AxiosAll('get', `dropdowns?seller_id=${userDetails?.user?.id}&type[]=cancel_reasons`);
    if (response.statusCode === 200) {
      const filterdata = response?.data?.cancel_reasons;
      setReasonList(filterdata);
    }
  };

  const handleCancelOrder = async () => {
    if (status === 0) {
      notify('Please select a product', 'error');
      return;
    }
    if (reason === 0) {
      notify('Please select reason', 'error');
      return;
    }
    setloading(true);

    const formData = new FormData();
    formData.append('cancelled_reason_id', reason);

    const response = await AxiosAll('post', `seller/orders-cancelled/${status}`, formData);
    if (response?.statusCode === 200) {
      // setOrder(response?.data);
      setloading(false);
      setOpenin(false);
    } else {
      setloading(false);
    }
  };

  const handleClickOpen = () => {
    setOpenin(true);
    getDropdowns();
  };

  const handleClose = () => {
    setOpenin(false);
  };

  const handleStatus = (e) => {
    const { value } = e.target;
    const object = OrdersList.find((a) => a.value === value);
    setStatus(value);
  };

  const handleReason = (e) => {
    const { value } = e.target;
    const object = reasonList.find((a) => a.value === value);
    setReason(value);
  };

  useEffect(() => {
    if (OrdersList?.length === 1) {
      setStatus(OrdersList[0]?.value);
    }
  }, [OrdersList]);

  return (
    <div className="last-btn-section-container">
      <Button variant="contained" className="darkbtn" onClick={handleClickOpen}>
        Cancel Order
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dispatchorderdialogs"
      >
        <DialogContent>
          <SubHeading title="Cancel order" />
          <div className="heading-para"> Please select the reason for cancelling the order. </div>
          <div className="label-text">Product list</div>
          <DropDown
            option={OrdersList}
            handleChange={handleStatus}
            value={status}
            placholder="Select"
            customClass="dispatch-dropdown"
          />

          <div className="label-text">Cancel Reasons list</div>
          <DropDown
            option={reasonList}
            handleChange={handleReason}
            value={reason}
            placholder="Select"
            customClass="dispatch-dropdown"
          />

          <div className="btn-section">
            <LightBtn onClick={handleClose} autoFocus title="Cancel" />
            <span className="disable-add-btn">
              <DarkBtn title="Add" onClick={handleCancelOrder} loading={loading} />
            </span>
            {/* ADD Condition  */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CancelOrderDialog;
