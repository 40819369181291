import React from 'react';
import Lightbtn from '../../components/universal/Buttons/LightBtn';

const Expiry = () => {
  return (
    <div className="nointernet">
      <h1>
        The action could not be performed, please contact{' '}
        <span
          tabIndex={0}
          role="button"
          className="goethical_text"
          onClick={() => {
            window?.open('https://goethical.com/blog/contact-us-goethical/', '_self');
          }}
        >
          @goethical.com
        </span>
      </h1>
      <Lightbtn
        onClick={() => {
          window?.open('https://goethical.com/blog/contact-us-goethical/', '_self');
        }}
        title="Contact Us"
      />
    </div>
  );
};

export default Expiry;
