import { Navigate, Route, Routes } from 'react-router';
import SellerSideBar from '../components/SellerSide/SellerSideBar';
import AdminSidebar from '../components/universal/Admin/AdminSidebar';
import AdminViewDetails from '../pages/Admin/AdminViewDetails';
import BrandWithCause from '../pages/Admin/BrandWithCause';
import BuyerIdDetails from '../pages/Admin/BuyerIdDetails';
import Categories from '../pages/Admin/Categories';
import FeaturedSection from '../pages/Admin/FeaturedSection';
import OrderItemReport from '../pages/Admin/OrderItemReport';
import PendingOrders from '../pages/Admin/PendingOrders';
import { EnhancedTable as PopularCategory } from '../pages/Admin/PopularCategory';
import SellerAdmin from '../pages/Admin/SellerAdmin';
import SellerIdDetails from '../pages/Admin/SellerIdDetails';
import TrendingProducts from '../pages/Admin/TrendingProducts';
import UserRegistration from '../pages/Admin/UserRegistration';
import BusinessDetails from '../pages/BusinessDetails';
import CreateAccount from '../pages/CreateAccount';
import Home from '../pages/Home';
import { NotFound } from '../pages/NotFound';
import PolicyDetails from '../pages/PolicyDetails';
import ProSellerRegistration from '../pages/ProSellerRegistration';
import ResetPassword from '../pages/ResetPassword';
import Seller from '../pages/Seller';
import AddSingleProduct from '../pages/Seller/AddSingleProduct';
import Dashboard from '../pages/Seller/Dashboard';
import FinancialReports from '../pages/Seller/FinancialReports';
import { Help } from '../pages/Seller/Help';
import { MyProfile } from '../pages/Seller/MyProfile';
import Orders from '../pages/Seller/Orders';
import ProductImages from '../pages/Seller/ProductImages';
import Products from '../pages/Seller/Products';
import SellerOrderDetails from '../pages/Seller/SellerOrderDetails';
import StoreDetails from '../pages/StoreDetails/indes';
import StripVerification from '../pages/StripVerification';
import VerifyYourEmail from '../pages/VerifyYourEmail';
import ViewDetails from '../pages/ViewDetails';
import Welcome from '../pages/Welcome';

const AppAdminRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/admin/user-registration" replace />} />
      <Route path="login" element={<Navigate to="/admin/user-registration" replace />} />
      <Route path="admin" element={<AdminSidebar />}>
        <Route path="categories" element={<Categories />} />
        <Route path="popular-category" element={<PopularCategory />} />
        <Route path="featured-section" element={<FeaturedSection />} />
        <Route path="picked-favourites" element={<FeaturedSection />} />
        <Route path="brand-with-cause" element={<BrandWithCause />} />
        <Route path="view-details" element={<ViewDetails />} />
        <Route path="pending-dispatch" element={<PendingOrders />} />
        <Route path="seller" element={<SellerAdmin />} />
        <Route path="buyer-id-details" element={<BuyerIdDetails />} />
        <Route path="view-registration/seller/detail/:id" element={<SellerIdDetails />} />
        <Route path="order-view-details" element={<AdminViewDetails />} />
        <Route path="trending" element={<TrendingProducts />} />
        <Route path="orderitem" element={<OrderItemReport />} />
        <Route path="user-registration" element={<UserRegistration />} />
      </Route>
      <Route path="onboarding" element={<SellerSideBar />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="home" element={<Home />} />
        <Route path="seller" element={<Seller />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="create-account" element={<CreateAccount />} />
        <Route path="verify-email" element={<VerifyYourEmail />} />
        <Route path="store-details" element={<StoreDetails />} />
        <Route path="welcome" element={<Welcome />} />
        <Route path="policy-details" element={<PolicyDetails />} />
        <Route path="business-details" element={<BusinessDetails />} />
        <Route path="pro-seller-registration" element={<ProSellerRegistration />} />
        <Route path="add-single-product" element={<AddSingleProduct />} />
        <Route path="products" element={<Products />} />
        <Route path="product-images" element={<ProductImages />} />

        <Route path="orders" element={<Orders />} />
        <Route path="seller-order-details" element={<SellerOrderDetails />} />
        <Route path="my-profile" element={<MyProfile />} />
        <Route path="help" element={<Help />} />
        <Route path="financial-reports" element={<FinancialReports />} />
        {/* admin side */}
        <Route path="categories" element={<Categories />} />
        <Route path="view-details" element={<ViewDetails />} />
        <Route path="popular-category" element={<PopularCategory />} />
        <Route path="brand-with-cause" element={<BrandWithCause />} />
        <Route path="featured-section" element={<FeaturedSection />} />
        <Route path="pending-dispatch" element={<PendingOrders />} />
        <Route path="seller" element={<SellerAdmin />} />
        <Route path="order-view-details" element={<AdminViewDetails />} />
        <Route path="trending" element={<TrendingProducts />} />
        <Route path="orderitem" element={<OrderItemReport />} />
        <Route path="user-registration" element={<UserRegistration />} />
      </Route>
      <Route path="*" element={<NotFound />} />
      <Route path="stripe-verification" element={<StripVerification />} />
    </Routes>
  );
};

export default AppAdminRoutes;
