import WestIcon from '@mui/icons-material/West';
import { InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import BlackBtn from '../../../components/universal/Buttons/BlackBtn';
import DropDown from '../../../components/universal/DropDown';
import TextArea from '../../../components/universal/InputType/TextArea';
import { Loader } from '../../../components/universal/Loader';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { images } from '../../../assets';

const CancelSubscription = () => {
  const [Loading, setLoading] = useState(true);
  const [LoadingCancelation, setLoadingCancelation] = useState(false);
  const [reasonLoading, setReasonLoading] = useState(true);
  const [error, seterror] = useState(false);
  const [errorReason, seterrorReason] = useState('');
  const [data, setdata] = useState({});
  const [CancelReasons, setCancelReasons] = useState([]);
  const [CancelReasonsSelected, setCancelReasonsSelected] = useState('0');
  const [comment, setcomment] = useState('');
  const { userData } = useSelector((state) => state.guestUserReducer);
  const route = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get('id');
  useEffect(() => {
    getSubscription();
    getDropdownsOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSubscription = async () => {
    setLoading(true);
    seterror(false);
    try {
      // const formattedDate = moment(date)?.format("YYYY-MM-DD");
      const response = await AxiosAll('get', `seller/subscriptions`, null, userData?.userToken);
      if (response?.statusCode === 200) {
        setdata(response?.data);
        setLoading(false);
      } else {
        setLoading(false);
        notify(response.message, 'error');
      }
    } catch (err) {
      seterror(true);
      console.log(err);
    }
  };

  const getDropdownsOption = async () => {
    setReasonLoading(true);
    try {
      const response = await AxiosAll('get', `dropdowns?type=subscription_cancel_reasons`, null, userData?.userToken);
      if (response?.statusCode === 200) {
        setCancelReasons(response?.data);
        setReasonLoading(false);
        // notify(response.message, "success");
      } else {
        setReasonLoading(false);
        notify(response.message, 'error');
      }
    } catch (err) {
      seterror(true);
      console.log(err);
    }
  };

  const onSubmit = async (type) => {
    setLoadingCancelation(true);
    try {
      const response = await AxiosAll(
        type ? 'delete' : 'put',
        `seller/subscriptions/${id}`,
        {
          feedback: type ? CancelReasonsSelected : 'too_expensive',
          comment: comment || 'test',
          cancel_at_period_end: type,
        },
        userData?.userToken,
      );
      if (response?.statusCode === 200) {
        setLoadingCancelation(false);
        getSubscription();
        notify(response.message, 'success');
        if (!type) {
          route('/onboarding/my-profile', { state: { tab: 1 } });
        }
      } else {
        setLoadingCancelation(false);
        notify(response.message, 'error');
      }
    } catch (err) {
      setLoadingCancelation(false);
      console.log(err);
      notify(err.message, 'error');
    }
  };

  return error ? (
    'Something went wrong, Please try again after some time'
  ) : (
    <div className="loginpage cancel-subscription">
      {Loading ? (
        <Loader message="Fetching your plan details..." />
      ) : (
        <>
          <div className="login-full-container">
            <Loader message="Fetching your plan details..." />
            <div className="left-arrow-new">
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <img
                src={images.arrowBack}
                alt="arrow"
                onClick={() => route('/onboarding/my-profile', { state: { tab: 1 } })}
              />
              {/* <WestIcon onClick={() => route('/onboarding/my-profile', { state: { tab: 1 } })} /> */}
              <div className="main-heading">Cancel your subscription</div>
              <div />
            </div>

            {/* <div className="login-para">7-day Free Trial</div> */}
          </div>

          {data?.cancel_at_period_end == 1 ? (
            <div className="form-box box-border">
              <div className="inner-box">
                <p>Cancelation requested on: {moment(data?.canceled_at).format('DD-MM-YYYY')} </p>
                <p>Plan ends on : {moment(data?.current_period_end).format('DD-MM-YYYY')} </p>
                <span>Note : After your plan expires, you will no longer be able to access the platform</span>
                <BlackBtn
                  disabled={LoadingCancelation}
                  onClick={() => {
                    onSubmit(0);
                  }}
                  title={LoadingCancelation ? 'Loading...' : 'Renew Subscription'}
                />
              </div>
            </div>
          ) : (
            <div className="form-box box-border">
              <div className="inner-box">
                <InputLabel className="input-lable-text">Reason for cancelling</InputLabel>
                <DropDown
                  customClass="cancel-sub-dropdown"
                  placholder="Select reason"
                  error={errorReason}
                  touched={() => seterrorReason('')}
                  option={CancelReasons}
                  labelKey="description"
                  idKey="title"
                  handleChange={(e) => {
                    setCancelReasonsSelected(e?.target?.value);
                    seterrorReason('');
                  }}
                  value={CancelReasonsSelected}
                />
              </div>

              <div className="inner-box margin-15">
                <InputLabel className="input-lable-text">Please provide more details</InputLabel>
                <TextArea
                  value={comment}
                  onChange={(e) => setcomment(e?.target?.value)}
                  placeholder="Please enter comment"
                  name="specification"
                />
              </div>
              <div className="inner-box" style={{ textAlign: 'center' }}>
                <BlackBtn
                  disabled={LoadingCancelation}
                  onClick={() => {
                    CancelReasonsSelected === '0' ? seterrorReason('Select a reason') : onSubmit(1);
                  }}
                  title={LoadingCancelation ? 'Loading...' : 'Cancel Subscription'}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CancelSubscription;
