import { Dialog, DialogActions, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { images } from '../../../assets';
import { notify, truncateText } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import { RadioButtonsGroup } from '../RadioBtnTable';
import { TableActionBtn } from '../TableActionBtn';
import { ImgCard } from '../imgCard';
import AddCourier from '../../dialogs/AddCourier';
import AddCourierClass from '../../dialogs/AddCourierClass';

export const ShippingTable = ({
  ShippingList,
  EditStatus,
  getShippingList,
  isclass,
  AddNewAddCourier,
  AddNewCourierClass,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [CourierId, setCourierId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async (id) => {
    setLoading(true);
    const response = await AxiosAll(
      'delete',
      isclass ? `seller/shippingCourierClasses/${id}` : `seller/shippingCouriers/${id}`,
      null,
    );
    if (response?.statusCode === 200) {
      notify(response?.message, 'success');
      getShippingList && getShippingList();
      setLoading(false);
    } else {
      notify(response?.message, 'error');
      setLoading(false);
    }
    handleClose();
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setCourierId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setCourierId(null);
  };

  return (
    <TableContainer component={Paper} className="main-table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{isclass ? 'Courier Class Name' : 'Courier Name'}</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ShippingList.map((row, index) => {
            return (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{row?.name}</TableCell>
                <TableCell align="left">
                  <RadioButtonsGroup value={row?.status} EditStatus={EditStatus} data={row} />
                </TableCell>
                <TableCell align="left">
                  {isclass ? (
                    <TableActionBtn
                      EditComponent={
                        <AddCourierClass AddNewCourierClass={AddNewCourierClass} type="edit" data={row} id={row?.id} />
                      }
                      //  onAdd={() => navigate(`/onboarding/site-editor/shippings/classes/${row?.id}`)}
                      onDelete={() => handleClickOpen(row?.id)}
                    />
                  ) : (
                    <TableActionBtn
                      EditComponent={
                        <AddCourier AddNewAddCourier={AddNewAddCourier} type="edit" data={row} id={row?.id} />
                      }
                      onAdd={() => navigate(`/onboarding/site-editor/shippings/classes/${row?.id}`)}
                      onDelete={() => handleClickOpen(row?.id)}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="delete-pop-up"
          >
            <div className="delete-container">
              <Typography variant="h2">Delete</Typography>
              <Typography paragraph>Are you sure you want to delete this courier?</Typography>
            </div>
            <DialogActions>
              <BlueBtn title="Yes" loading={loading} disabled={loading} onClick={() => handleDelete(CourierId)} />
              <LightBtn title="No" onClick={handleClose} />
            </DialogActions>
          </Dialog>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
