/* eslint-disable no-debugger */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import * as React from 'react';
import { notify } from '../../../core/constants';
import { AxiosAll, axiosAll } from '../../../services/axiosAll';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import DropDown from '../../universal/DropDown';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import TextArea from '../../universal/InputType/TextArea';

const RefundDialog = ({ OrdersList, setOpenUp }) => {
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [status, setStatus] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setAmount('');
    setLoading(false);
    setStatus(0);
    setComment('');
  };

  const handleClose = () => {
    setAmount('');
    setLoading(false);
    setStatus(0);
    setComment('');
    setOpen(false);
  };
  const handleStatus = (e) => {
    const { value } = e.target;
    const object = OrdersList.find((a) => a.value === value);
    setAmount(object?.total_amount);
    setStatus(value);
  };

  const handleRefund = async (Status) => {
    if (comment === '') {
      notify('comment field is required', 'error');
      return;
    }
    if (amount === '') {
      notify('amount field is required', 'error');
      return;
    }
    if (status === 0) {
      notify('Please select order', 'error');
      return;
    }
    setLoading(true);

    const data = {
      dispute_resolved_status: 2,
      dispute_resolved_note: comment,
      dispute_resolved_amount: amount,
    };
    const response = await AxiosAll('post', `seller/orders-dispute-resolve/${status}`, data);
    if (response?.statusCode === 200) {
      // setOrder(response?.data);
      setOpenUp((prev) => !prev);
      notify(response?.message, 'success');
      setLoading(false);
      handleClose();
    } else {
      notify(response?.message, 'error');
      setLoading(false);
    }
  };

  // React.useEffect(() => {
  //   if (status !== 0) {
  //     setAmount(OrdersList[0]?.total_amount);
  //   }
  // }, []);

  return (
    <div className="removefrom">
      <Button className="darkbtn" onClick={handleClickOpen}>
        Refund
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="refund"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="input-heading">
              <h2 className="upload-text">Refund</h2>
              <div className="label-text">Product list</div>
              <DropDown option={OrdersList} handleChange={handleStatus} value={status} />
              <div className="label-text">Refund amount (£)</div>
              <InputTypeOutline
                value={amount}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key) && e.key !== '.') {
                    e.preventDefault();
                  }
                }}
                // onChange={(e) => {
                //   setamount(e.target.value);
                // }}
                disabled={true}
                type="number"
                min="1"
              />

              <div className="heading">
                <label htmlFor="Square Description" className="label-text">
                  comment
                </label>
                <TextArea onChange={(e) => setComment(e.target.value)} name="comment" value={comment} />
              </div>
              <div className="btn-section-refund">
                <LightBtn title="Cancel" onClick={handleClose} />
                <DarkBtn onClick={() => handleRefund()} title="Submit" loading={loading} />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RefundDialog;
