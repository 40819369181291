import { useNavigate, useParams } from 'react-router';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { images } from '../../assets';
import { BlueBtn } from '../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import { UploadDraggableContainer } from '../../components/universal/UploadDraggableContainer';
import BannerCard from '../../components/universal/WelcomeCard';
import InputTypeOutline from '../../components/universal/InputType/inputTypeOutline';
import TextArea from '../../components/universal/InputType/TextArea';
import { AxiosAll } from '../../services/axiosAll';
import { notify } from '../../core/constants';
import { UploadSingleImage } from '../../components/universal/UploadImage';
import DropDown from '../../components/universal/DropDown';
import { Loader } from '../../components/Skeleton/Loader';

export const TopBanner = () => {
  const route = useNavigate();
  const dec =
    'Color is used to express style and communicate meaning. With dynamic<br />color, Material puts personal color preferences and individual needs at the<br />forefront of systematic color application.';

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { id } = useParams();
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaImage, setMetaImage] = useState('');
  const [image, setImage] = useState('');
  const [background, setbackground] = useState('');
  const [imagemobile, setImagemobile] = useState('');
  const [backgroundmobile, setbackgroundmobile] = useState('');
  const [imagetablet, setImagetablet] = useState('');
  const [backgroundtablet, setbackgroundtablet] = useState('');
  const [imageweb, setImageweb] = useState('');
  const [backgroundweb, setbackgroundweb] = useState('');

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    button_link: '',

    title_mobile: '',
    button_link_mobile: '',

    title_mobile_tablet: '',
    button_link_tablet: '',
    backgroundImage: '',
    title_mobile_web: '',
    button_link_web: '',
  };

  const validationSchema = Yup.object({
    backgroundImage: Yup.string().required('Background image is required'),
  });

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values?.title || '');
    formData.append('button_link', values?.button_link || '');
    formData.append('image', image || '');
    formData.append('bg_image', background || '');
    formData.append('title_mobile', values?.title_mobile || '');
    formData.append('button_link_mobile', values?.button_link_mobile || '');
    formData.append('image_mobile', imagemobile || '');
    formData.append('bg_image_mobile', backgroundmobile || '');
    formData.append('title_tablet', values?.title_tablet || '');
    formData.append('button_link_tablet', values?.button_link_tablet || '');
    formData.append('image_tablet', imagetablet || '');
    formData.append('bg_image_tablet', backgroundtablet || '');
    formData.append('title_web', values?.title_web || '');
    formData.append('button_link_web', values?.button_link_web || '');
    formData.append('image_web', imageweb || '');
    formData.append('bg_image_web', backgroundweb || '');

    setLoading(true);
    try {
      const endPoint = id
        ? `seller/sellerSections/${id}?section_type=top_bar&_method=PUT`
        : `seller/sellerSections?section_type=top_bar`;

      const response = await AxiosAll('post', endPoint, formData);
      if (response.statusCode === 200) {
        notify(response.message, 'success');
        // route('/onboarding/site-editor/product-collection');
        setLoading(false);
        route('/onboarding/site-editor');
      } else {
        notify(response.message, 'error');
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getDropdowns = async () => {
    const types = ['links'];

    const response = await AxiosAll(
      'get',
      `dropdowns?type[]=${types.join('&type[]=')}&seller_id=${userDetails?.user?.id}`,
    );
    if (response.statusCode === 200) {
      setLinks(response?.data?.links);
    }
  };

  const GetTopbarDetail = async (data) => {
    setLoading(true);
    const res = await AxiosAll('get', `seller/sellerSections/${id}?section_type=top_bar`, data);

    if (res.statusCode === 200) {
      const Topbar = res?.data?.topbar;
      formik.setFieldValue('title', Topbar?.title || '');
      formik.setFieldValue('button_link', Topbar?.button_link || '');
      formik.setFieldValue('title_mobile', Topbar?.title_mobile || '');
      formik.setFieldValue('button_link_mobile', Topbar?.button_link_mobile || '');
      formik.setFieldValue('title_tablet', Topbar?.title_tablet || '');
      formik.setFieldValue('button_link_tablet', Topbar?.button_link_tablet || '');
      formik.setFieldValue('title_web', Topbar?.title_web || '');
      formik.setFieldValue('button_link_web', Topbar?.button_link_web || '');
      formik.setFieldValue('backgroundImage', Topbar?.bg_image || '');

      setImage(Topbar?.image);
      setImagemobile(Topbar?.image_mobile);
      setImagetablet(Topbar?.image_tablet);
      setImageweb(Topbar?.image_web);
      setbackground(Topbar?.bg_image);
      setbackgroundmobile(Topbar?.bg_image_mobile);
      setbackgroundtablet(Topbar?.bg_image_tablet);
      setbackgroundweb(Topbar?.bg_image_web);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDropdowns();
    if (id) {
      GetTopbarDetail();
    }
  }, [id]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    formik.setFieldValue('backgroundImage', background);
  }, [background]);
  return (
    <div className="top-banner">
      <Loader open={loading} />
      <BannerCard title="Top bar" para={para} backgroundImageURL={images.dynamicProductsImg} smallCard />
      <form onSubmit={formik.handleSubmit}>
        <div className="main-section container-27inch">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route(-1)} />
          <div className="trending-detail">
            <div className="search-engine-section">
              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="title"
                      value={formik?.values?.title}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.title && formik?.errors?.title}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Link</Typography>
                    <DropDown
                      placholder="Select"
                      name="button_link"
                      handleChange={(e) => formik.setFieldValue('button_link', e.target.value)}
                      value={formik.values.button_link}
                      option={links}
                      touched={formik.touched.button_link}
                      error={formik.errors.button_link}
                      blur={formik.handleBlur}
                    />
                  </div>
                  <div className="single-img-upload">
                    {/* <Typography variant="h4"> Image </Typography> */}

                    <UploadSingleImage
                      title="Image"
                      title_mobile="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={image}
                      setImage={setImage}
                      imageSize={2}
                    />
                  </div>{' '}
                  <div className="single-img-upload">
                    <Typography variant="h4">Background </Typography>

                    <UploadSingleImage
                      title="Background"
                      title_mobile="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={background}
                      setImage={setbackground}
                      imageSize={2}
                    />
                    {formik?.touched?.backgroundImage && formik?.errors?.backgroundImage && (
                      <div className="input-error-message">{formik?.errors?.backgroundImage}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="trending-detail">
            <div className="search-engine-section">
              <div className="input-title">
                <Typography variant="h4"> Mobile </Typography>

                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="title_mobile"
                      value={formik?.values?.title_mobile}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.title_mobile && formik?.errors?.title_mobile}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Link</Typography>
                    <DropDown
                      placholder="Select"
                      name="button_link_mobile"
                      handleChange={(e) => formik.setFieldValue('button_link_mobile', e.target.value)}
                      value={formik.values.button_link_mobile}
                      option={links}
                      touched={formik.touched.button_link_mobile}
                      error={formik.errors.button_link_mobile}
                      blur={formik.handleBlur}
                    />
                  </div>
                  <div className="single-img-upload">
                    <Typography variant="h4"> Image </Typography>

                    <UploadSingleImage
                      title="Image"
                      title_mobile="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={imagemobile}
                      setImage={setImagemobile}
                      imageSize={2}
                    />
                  </div>{' '}
                  <div className="single-img-upload">
                    <Typography variant="h4">Background </Typography>

                    <UploadSingleImage
                      title="Background"
                      title_mobile="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={backgroundmobile}
                      setImage={setbackgroundmobile}
                      imageSize={2}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{' '}
          <div className="trending-detail">
            <div className="search-engine-section">
              <div className="input-title">
                <Typography variant="h4"> Tablet </Typography>
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="title_tablet"
                      value={formik?.values?.title_tablet}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.title_tablet && formik?.errors?.title_tablet}
                    />
                  </div>
                </div>
                <div className="meta-des">
                  <Typography>Button Link</Typography>
                  <DropDown
                    placholder="Select"
                    name="button_link_tablet"
                    handleChange={(e) => formik.setFieldValue('button_link_tablet', e.target.value)}
                    value={formik.values.button_link_tablet}
                    option={links}
                    touched={formik.touched.button_link_tablet}
                    error={formik.errors.button_link_tablet}
                    blur={formik.handleBlur}
                  />
                </div>
                <div className="single-img-upload">
                  <Typography variant="h4"> Image </Typography>

                  <UploadSingleImage
                    title="Image"
                    title_mobile="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={imagetablet}
                    setImage={setImagetablet}
                    imageSize={2}
                  />
                </div>{' '}
                <div className="single-img-upload">
                  <Typography variant="h4">Background </Typography>

                  <UploadSingleImage
                    title="Background"
                    title_mobile="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={backgroundtablet}
                    setImage={setbackgroundtablet}
                    imageSize={2}
                  />
                </div>
              </div>
            </div>
          </div>{' '}
          <div className="trending-detail">
            <div className="search-engine-section">
              <div className="input-title">
                <Typography variant="h4"> Web </Typography>

                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="title_web"
                      value={formik?.values?.title_web}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.title_web && formik?.errors?.title_web}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Link</Typography>
                    <DropDown
                      placholder="Select"
                      name="button_link_web"
                      handleChange={(e) => formik.setFieldValue('button_link_web', e.target.value)}
                      value={formik.values.button_link_web}
                      option={links}
                      touched={formik.touched.button_link_web}
                      error={formik.errors.button_link_web}
                      blur={formik.handleBlur}
                    />
                  </div>
                  <div className="single-img-upload">
                    <Typography variant="h4"> Image </Typography>

                    <UploadSingleImage
                      title="Image"
                      title_mobile="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={imageweb}
                      setImage={setImageweb}
                      imageSize={2}
                    />
                  </div>{' '}
                  <div className="single-img-upload">
                    <Typography variant="h4">Background </Typography>

                    <UploadSingleImage
                      title="Background"
                      title_mobile="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                      image={backgroundweb}
                      setImage={setbackgroundweb}
                      imageSize={2}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route(-1)} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
