/* eslint-disable no-debugger */
import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import EditMultiSelect from '../../../components/universal/EditProduct/EditMultiSelect';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify, truncateText } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { UploadSingleImage } from '../../../components/universal/UploadImage';
import { Loader } from '../../../components/Skeleton/Loader';

export const AddTestimonialSection = () => {
  const route = useNavigate();

  const [loading, setLoading] = useState(false);
  const [testimonialsList, setTestimonialsList] = useState([]);
  const [selectedTestimonials, setSelectedTestimonials] = useState([]);
  const [selectedDesktopTestimonials, setSelectedDesktopTestimonials] = useState([]);
  const [selectedTabletTestimonials, setSelectedTabletTestimonials] = useState([]);
  const [selectedMobileTestimonials, setSelectedMobileTestimonials] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState();
  const [desktopBackgroundImage, setDesktopBackgroundImage] = useState();
  const [tabletBackgroundImage, setTabletBackgroundImage] = useState();
  const [mobileBackgroundImage, setMobileBackgroundImage] = useState();
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const { id } = useParams();
  const { userDetails } = useSelector((state) => state.loginReducer);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    desktopTitle: '',
    tabletTitle: '',
    mobileTitle: '',
    list: [],
    image: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    list: Yup.array().min(1, 'Testimonials is required'),
    image: Yup.string().required('Image is required'),
  });

  const getTestimonialDetails = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/sellerSections/${id}?section_type=testimonial`, null);
    if (response.statusCode === 200) {
      const testimonialData = response?.data?.testimonial;
      formik.setFieldValue('title', testimonialData?.title || '');
      formik.setFieldValue('desktopTitle', testimonialData?.web_title || '');
      formik.setFieldValue('tabletTitle', testimonialData?.tablet_title || '');
      formik.setFieldValue('mobileTitle', testimonialData?.mobile_title || '');
      setSelectedTestimonials(testimonialData?.testimonials || []);
      setSelectedDesktopTestimonials(testimonialData?.web_testimonials || []);
      setSelectedTabletTestimonials(testimonialData?.tablet_testimonials || []);
      setSelectedMobileTestimonials(testimonialData?.mobile_testimonials || []);
      setBackgroundImage(testimonialData?.bg_image || '');
      setDesktopBackgroundImage(testimonialData?.web_bg_image || '');
      setTabletBackgroundImage(testimonialData?.tablet_bg_image || '');
      setMobileBackgroundImage(testimonialData?.mobile_bg_image || '');
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', values?.title);
    backgroundImage && formData.append('bg_image', backgroundImage);
    for (let i = 0; i < selectedTestimonials.length; i++) {
      formData.append(`testimonial_ids[${i}]`, selectedTestimonials[i]?.id);
    }
    formData.append('web_title', values?.desktopTitle);
    desktopBackgroundImage && formData.append('web_bg_image', desktopBackgroundImage);
    for (let i = 0; i < selectedDesktopTestimonials.length; i++) {
      formData.append(`web_testimonial_ids[${i}]`, selectedDesktopTestimonials[i]?.id);
    }
    formData.append('tablet_title', values?.tabletTitle);
    tabletBackgroundImage && formData.append('tablet_bg_image', tabletBackgroundImage);
    for (let i = 0; i < selectedTabletTestimonials.length; i++) {
      formData.append(`tablet_testimonial_ids[${i}]`, selectedTabletTestimonials[i]?.id);
    }
    formData.append('mobile_title', values?.mobileTitle);
    mobileBackgroundImage && formData.append('mobile_bg_image', mobileBackgroundImage);
    for (let i = 0; i < selectedMobileTestimonials.length; i++) {
      formData.append(`mobile_testimonial_ids[${i}]`, selectedMobileTestimonials[i]?.id);
    }

    const endPoint = id
      ? `seller/sellerSections/${id}?section_type=testimonial&_method=PUT`
      : 'seller/sellerSections?section_type=testimonial';

    const response = await AxiosAll('post', endPoint, formData);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      route('/onboarding/site-editor');
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const getDropdowns = async () => {
    const response = await AxiosAll('get', `dropdowns?type=testimonials&seller_id=${userDetails?.user?.id}`);
    if (response.statusCode === 200) {
      const dropdownData = response.data;
      setTestimonialsList(dropdownData);
    }
  };

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedTestimonials];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedTestimonials(newSections);
    setDraggedOverIndex(null);
  };

  const handleDropTablet = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedTabletTestimonials];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedTabletTestimonials(newSections);
    setDraggedOverIndex(null);
  };

  const handleDropDesktop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedDesktopTestimonials];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedDesktopTestimonials(newSections);
    setDraggedOverIndex(null);
  };

  const handleDropMobile = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...selectedMobileTestimonials];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setSelectedMobileTestimonials(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getTestimonialDetails();
    }
  }, [testimonialsList]);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  useEffect(() => {
    formik.setFieldValue('image', backgroundImage || '');
    formik.setFieldValue('list', selectedTestimonials || '');
  }, [selectedTestimonials, backgroundImage]);

  return (
    <div className="site-testimonial">
      <Loader open={loading} />
      <BannerCard title="Testimonial Section" para={para} backgroundImageURL={images.latestArrivalImg} smallCard />
      <div className="main-section container-27inch">
        <div className="section-editor">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="search-engine-section">
            <UploadSingleImage
              title="Background Image"
              description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
              image={backgroundImage}
              setImage={setBackgroundImage}
              imageSize={2}
            />
            {formik.touched.image && <div className="input-error-message">{formik.errors.image}</div>}
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && formik.errors.title}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Testimonials</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={testimonialsList}
                      selectedBadges={selectedTestimonials}
                      setSelectedBadges={setSelectedTestimonials}
                      limit={100}
                    />
                  </div>
                </div>
                {formik.touched.list && <div className="input-error-message">{formik.errors.list}</div>}

                <div className="badges-container">
                  {selectedTestimonials?.length > 0 &&
                    selectedTestimonials?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDrop(e, index)}
                        draggable
                      >
                        <div>
                          <Typography variant="h5">{item?.name}</Typography>
                          <Typography>{truncateText(item?.description, 50)}</Typography>
                          <Typography variant="h6">Rating - {item?.rating}</Typography>
                        </div>
                        <Button
                          onClick={() => {
                            const newSelectedTestimonials = selectedTestimonials.filter((badge) => badge !== item);
                            setSelectedTestimonials(newSelectedTestimonials);
                          }}
                        >
                          <img src={images.delete} alt="close" />
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* desktop section */}
          <div className="search-engine-section">
            <Typography variant="h4">Desktop</Typography>
            <UploadSingleImage
              title="Background Image"
              description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
              image={desktopBackgroundImage}
              setImage={setDesktopBackgroundImage}
              imageSize={2}
            />
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="desktopTitle"
                    value={formik.values.desktopTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.desktopTitle && formik.errors.desktopTitle}
                  />
                </div>

                <div className="meta-title">
                  <Typography>Testimonials</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={testimonialsList}
                      selectedBadges={selectedDesktopTestimonials}
                      setSelectedBadges={setSelectedDesktopTestimonials}
                      limit={100}
                    />
                  </div>
                </div>
                <div className="badges-container">
                  {selectedDesktopTestimonials?.length > 0 &&
                    selectedDesktopTestimonials?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDropDesktop(e, index)}
                        draggable
                      >
                        <Typography>{item?.name}</Typography>
                        <Typography>{truncateText(item?.description, 50)}</Typography>
                        <Typography>Rating - {item?.rating}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedTestimonials = selectedDesktopTestimonials.filter(
                              (badge) => badge !== item,
                            );
                            setSelectedDesktopTestimonials(newSelectedTestimonials);
                          }}
                        >
                          <img src={images.delete} alt="close" />
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* tablet section */}
          <div className="search-engine-section">
            <Typography variant="h4">Tablet</Typography>
            <UploadSingleImage
              title="Background Image"
              description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
              image={tabletBackgroundImage}
              setImage={setTabletBackgroundImage}
              imageSize={2}
            />
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="tabletTitle"
                    value={formik.values.tabletTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tabletTitle && formik.errors.tabletTitle}
                  />
                </div>

                <div className="meta-title">
                  <Typography>Testimonials</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={testimonialsList}
                      selectedBadges={selectedTabletTestimonials}
                      setSelectedBadges={setSelectedTabletTestimonials}
                      limit={100}
                    />
                  </div>
                </div>
                <div className="badges-container">
                  {selectedTabletTestimonials?.length > 0 &&
                    selectedTabletTestimonials?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDropTablet(e, index)}
                        draggable
                      >
                        <Typography>{item?.name}</Typography>
                        <Typography>{truncateText(item?.description, 50)}</Typography>
                        <Typography>Rating - {item?.rating}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedTestimonials = selectedTabletTestimonials.filter(
                              (badge) => badge !== item,
                            );
                            setSelectedTabletTestimonials(newSelectedTestimonials);
                          }}
                        >
                          <img src={images.delete} alt="close" />
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* mobile section */}
          <div className="search-engine-section">
            <Typography variant="h4">Mobile</Typography>
            <UploadSingleImage
              title="Background Image"
              description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
              image={mobileBackgroundImage}
              setImage={setMobileBackgroundImage}
              imageSize={2}
            />
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="mobileTitle"
                    value={formik.values.mobileTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.mobileTitle && formik.errors.mobileTitle}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Testimonials</Typography>
                  <div className="multi-select-input">
                    <EditMultiSelect
                      badges={testimonialsList}
                      selectedBadges={selectedMobileTestimonials}
                      setSelectedBadges={setSelectedMobileTestimonials}
                      limit={100}
                    />
                  </div>
                </div>
                <div className="badges-container">
                  {' '}
                  {selectedMobileTestimonials?.length > 0 &&
                    selectedMobileTestimonials?.map((item, index) => (
                      <div
                        className="badge"
                        key={item?.id}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={() => setDraggedOverIndex(null)}
                        onDrop={(e) => handleDropMobile(e, index)}
                        draggable
                      >
                        <Typography>{item?.name}</Typography>
                        <Typography>{truncateText(item?.description, 50)}</Typography>
                        <Typography>Rating - {item?.rating}</Typography>
                        <Button
                          onClick={() => {
                            const newSelectedTestimonials = selectedMobileTestimonials.filter(
                              (badge) => badge !== item,
                            );
                            setSelectedMobileTestimonials(newSelectedTestimonials);
                          }}
                        >
                          <img src={images.delete} alt="close" />
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor')} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
