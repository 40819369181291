import { PreProdServer, ProdServer, staggingServer } from './Sever';

export const envType = (Env) => {
  switch (Env) {
    case 1:
      return staggingServer;

    case 2:
      return PreProdServer;

    case 3:
      return ProdServer;

    default:
      return staggingServer;
  }
};
