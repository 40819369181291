import Grid from '@mui/material/Grid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { images } from '../../../assets';
import { SellerMobileMenu } from '../../../pages/Seller/SellerMobileMenu';
import { deleteLogin, addLogin } from '../../../redux/actions/login';
import { deleteUser } from '../../../redux/actions/user';
import { updateGuestUser } from '../../../redux/actions/guestUser';
import { BasicAccordion } from './Accordion';

const SellerSideBar = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.loginReducer);

  const nav = useNavigate();
  const oldData = JSON.parse(localStorage.getItem('oldData'));

  const handleExitImpersonating = () => {
    dispatch(deleteLogin());
    dispatch(deleteUser());
    dispatch(addLogin(oldData));
    dispatch(updateGuestUser(oldData));
    localStorage.removeItem('oldData');
    nav('/admin/seller');
  };

  return (
    <>
      {oldData && (
        <div className="top-picker">
          You are impersonating {userDetails?.user?.name}
          <span
            tabIndex={0}
            role="button"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={handleExitImpersonating}
          >
            {' '}
            Exit View
          </span>
        </div>
      )}
      <div className="mobileshow tabshow ">
        <div className="mobileviewset">
          {/* <img alt="" src={images.mainLogo} className="logogoethical" /> */}
          <p className="text-logo">Sell-Stream</p>
          <SellerMobileMenu />
        </div>
      </div>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={1.5}
          md={1}
          lg={1}
          xl={1}
          className="mobilehide tabhide seller-side-bg-col fixed-profile"
        >
          <div className="sellersidebar">
            {/* <img alt='' src={images.mainLogo} className="logogoethical" /> */}
            <Link to="dashboard" className="item2">
              <span className="sidebar-new-img">
                <img alt="" src={images.dashboard} className="new-sidebar" />
              </span>
              <div className="sidebar-menu-name">Dashboard</div>
            </Link>
            {/* <Link to="site-editor" className="item2"> */}
            {/* <span className="sidebar-new-img">
                <img alt="" src={images.siteEditor} className="new-sidebar" />
              </span> */}
            {/* <div className="sidebar-menu-name">Site Editor</div> */}
            <BasicAccordion menuName="Site Editor" />
            {/* </Link> */}
            <Link to="product-images" className="item2">
              <span className="sidebar-new-img">
                {' '}
                <img alt="" src={images.gallery} className="new-sidebar" />{' '}
              </span>
              <div className="sidebar-menu-name">Library</div>
            </Link>
            <Link to="products" className="item2">
              <span className="sidebar-new-img">
                {' '}
                <img alt="" src={images.book} className="new-sidebar" />{' '}
              </span>
              <div className="sidebar-menu-name">Inventory</div>
            </Link>
            <Link to="orders" className="item2">
              <span className="sidebar-new-img">
                {' '}
                <img alt="" src={images.basket} className="new-sidebar" />{' '}
              </span>
              <div className="sidebar-menu-name">Orders</div>
            </Link>
            <Link to="users" className="item2">
              <span className="sidebar-new-img">
                {' '}
                <img alt="" src={images.user} className="new-sidebar" />{' '}
              </span>
              <div className="sidebar-menu-name">Users</div>
            </Link>
            <Link to="financial-reports" className="item2">
              <span className="sidebar-new-img">
                {' '}
                <img alt="" src={images.financials} className="new-sidebar" />{' '}
              </span>
              <div className="sidebar-menu-name">Reports</div>
            </Link>
            <Link to="my-profile" className="item2">
              <span className="sidebar-new-img">
                {' '}
                <img alt="" src={images.user} className="new-sidebar" />{' '}
              </span>
              <div className="sidebar-menu-name">Account</div>
            </Link>
            {/* <Link to="help" className="item2">
              <span className="sidebar-new-img">
                {' '}
                <img alt="" src={images.user} className="new-sidebar" />{' '}
              </span>
              <div className="sidebar-menu-name">Help</div>
            </Link> */}
            <div
              tabIndex={0}
              role="button"
              onClick={() => {
                dispatch(deleteLogin());
                dispatch(deleteUser());
                nav('/login');

                localStorage.removeItem('oldData');
              }}
              className="item2"
            >
              <span className="sidebar-new-img">
                {' '}
                <img alt="" src={images.signOut} className="new-sidebar" />{' '}
              </span>
              <div className="sidebar-menu-name">Logout</div>
            </div>
            {/* <div className="image-me">
              <img alt='' src={images.sideMenuImg} />
            </div> */}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={11} xl={11} className="dataside">
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default SellerSideBar;
