import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import LightBtn from '../../../../components/universal/Buttons/LightBtn';
import { Empty } from '../../../../components/universal/Empty';

export const BasicTable = ({ TableColumn, Row, page, rowsPerPage, handleOrderDetail, name, financeReport }) => {
  const funcToAddPrice = (item) => {
    let price = 0;

    item?.map((e, index) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      price += e?.price;
    });

    return price.toFixed(2);
  };
  const nav = useNavigate();
  return (
    <TableContainer component={Paper}>
      {console.log('rows', Row)}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {TableColumn?.map((col, index) => {
              return <TableCell key={index}>{col} </TableCell>;
            })}
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {Row?.length > 0 ? (
            Row?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
              return (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row?.generated_order_id}
                  </TableCell>
                  {row?.seller_amount >= 0 ? (
                    <TableCell>{row?.seller_amount.toFixed(2)}</TableCell>
                  ) : (
                    name === 'Order' ?? (
                      <TableCell>
                        {row?.orderItems.map((item) => {
                          return (
                            <>
                              <p>
                                {item.stocks}x{item?.name}
                              </p>
                              <div
                                className={`orderid ${
                                  item?.status === 1
                                    ? 'pendingbtn'
                                    : item?.status === 2
                                    ? 'deliveredbtn'
                                    : item?.status === 3
                                    ? 'cancelbtn'
                                    : item?.status === 4
                                    ? 'cancelbtn'
                                    : item?.status === 5
                                    ? 'partialbtn'
                                    : 'cancelbtn'
                                }`}
                              >
                                {item?.status === 1
                                  ? 'Pending'
                                  : item?.status === 2
                                  ? 'Dispatched'
                                  : item?.status === 3
                                  ? 'Cancelled'
                                  : item?.status === 4
                                  ? 'Disputed'
                                  : item?.status === 5
                                  ? 'Partial Refund'
                                  : item?.status === 6
                                  ? 'Panding Payment'
                                  : item?.status === 7
                                  ? 'Failed Payment'
                                  : item?.status === 8
                                  ? 'Completed Order'
                                  : item?.status === 9
                                  ? 'Payment Not Auth'
                                  : ''}
                              </div>
                            </>
                          );
                        })}
                      </TableCell>
                    )
                  )}
                  <TableCell className="date">
                    {row?.stripe_fee_charge >= 0
                      ? row?.stripe_fee_charge.toFixed(2)
                      : moment(row?.orderItems[0]?.payoutDate).format('M/DD/YY [at] hh:mm A ')}
                  </TableCell>
                  <TableCell>
                    {row?.orderItems ? `£${funcToAddPrice(row?.orderItems)}` : `${row?.total_charge.toFixed(2)}`}
                  </TableCell>
                  <TableCell>
                    {row?.orderItems?.length
                      ? row?.orderItems?.length
                      : row?.seller_payout_date
                      ? moment(row?.seller_payout_date).format('DD-MM-YYYY')
                      : '--'}
                  </TableCell>
                  {name === 'Order' ? '' : <TableCell>{row?.refund_amount >= 0 ? row?.refund_amount : '--'}</TableCell>}
                  {name === 'Order' ? (
                    <TableCell>
                      <LightBtn
                        onClick={() =>
                          nav(`/onboarding/seller/order/details/${row?.id}`, {
                            state: { id: row?.id, order: 'order' },
                          })
                        }
                        title="View details"
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      <LightBtn
                        onClick={() =>
                          nav(`/onboarding/seller/view-finance-detail/reports/${row?.id}`, { state: { id: row?.id } })
                        }
                        title="View details"
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })
          ) : (
            <tr>
              <td colSpan="5">
                <Empty message="Your orders will show in this table" />
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
