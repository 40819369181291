/* eslint-disable no-debugger */
/* eslint-disable no-shadow */
import { Typography, Dialog, DialogActions, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { ProductSectionCard } from '../../../components/ProductSection/ProductSectionCard';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import ProductsCard from '../../../components/universal/ProductsCard';
import { ToggleButtons } from '../../../components/universal/Toggle';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { FilterSection } from './filterSection';

export const AddLinks = () => {
  const route = useNavigate();

  const [loading, setLoading] = useState(false);
  const [sectionType, setSectionType] = useState(3);

  const [productList, setProductList] = useState([]);
  const [existingProducts, setExistingProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [validFilter, setValidFilter] = useState(false);
  const [errors, setErrors] = useState({});
  const [deleteId, setDeleteId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [product, setProduct] = useState([]);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  const { id } = useParams();
  const filterBtnRef = useRef();

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    link: '',
    product: [],
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('title is Required'),
    link: sectionType === 3 && Yup.string().url('Invalid URL').required('Title is required'),
    product: sectionType === 2 && Yup.array().min(1, 'This feild is Required'),
  });

  const getLinkDetails = async () => {
    const response = await AxiosAll('get', `seller/links/${id}`, null);
    if (response.statusCode === 200) {
      const linkData = response.data;
      formik.setFieldValue('title', linkData?.name);
      // setSectionType(linkData?.type);
      if (linkData?.type === 1) {
        setFilterData(linkData?.content);
      }
      // if (linkData?.type === 2) {
      const productIds = linkData?.products?.map((item) => item?.id);
      setSelectedProduct(productIds);
      setExistingProducts(productIds);
      setProductList(linkData?.products);
      // }
      if (linkData?.type === 3) {
        formik.setFieldValue('link', linkData?.content);
      }
    } else {
      notify(response.message, 'error');
    }
  };

  const handleDelete = (productId) => {
    const filteredProduct = productList.filter((item) => item?.id !== productId);
    const filteredProductIds = selectedProduct.filter((item) => item !== productId);
    setSelectedProduct(filteredProductIds);
    setExistingProducts(filteredProductIds);
    setProductList(filteredProduct);
    setOpenDelete(false);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name', values.title);
    formData.append('type', sectionType);

    if (sectionType === 2) {
      if (productList.length > 0) {
        for (let i = 0; i < productList.length; i++) {
          formData.append(`product_ids[${i}]`, productList[i]?.id);
        }
      } else {
        formData.append(`product_ids`, '');
      }
    }
    if (sectionType === 3) {
      formData.append('content', values.link);
    }

    const data = sectionType === 1 ? { name: values.title, type: sectionType, content: filterData } : formData;

    const endPoint = id ? `seller/links/${id}?_method=PUT` : `seller/links`;

    const response = await AxiosAll('post', endPoint, data);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      route('/onboarding/site-editor/links');
    } else {
      notify(response.message, 'error');
    }
    setLoading(false);
  };

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...productList];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    const updateImage = { newSections };
    setProductList(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };
  const getProducts = async () => {
    const response = await AxiosAll('get', `seller/products?&search=${search || ''}`, null);
    if (response?.statusCode === 200) {
      const responseData = response?.data?.data;
      // notify(response.message, 'success');

      setProduct(responseData);
    } else {
      // notify(response.message, 'error');
    }
  };
  useEffect(() => {
    if (search !== '') {
      getProducts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleSearch = (e) => {
    if (e?.target?.value) {
      setSearchText(e?.target?.value);
    } else {
      setSearchText('');
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getLinkDetails();
    }
  }, []);

  useEffect(() => {
    if (validFilter && sectionType === 1) {
      formik.handleSubmit();
      setValidFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionType, validFilter]);

  useEffect(() => {
    if (sectionType === 2) {
      formik.setFieldValue('product', selectedProduct);
    }
  }, [selectedProduct]);

  useEffect(() => {
    formik.setFieldValue('product', productList);
  }, [productList]);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  useEffect(() => {
    if (productList?.length) {
      setProduct([]);
    }
  }, [productList]);
  return (
    <div className="site-link-page">
      <BannerCard title="Add New Link" para={para} backgroundImageURL={images.latestArrivalImg} smallCard />
      <div className="main-section container-27inch">
        <div className="section-editor">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor/links')} />
        </div>
        <div className="search-engine-section">
          <div className="input-title">
            <div className="meta-info">
              <div className="meta-title">
                <Typography>Title</Typography>
                <InputTypeOutline
                  placeholder="Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && formik.errors.title}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="link-toggle-btn">
          <ToggleButtons type={sectionType} setType={setSectionType}  />
        </div> */}

        <div className="link-filter">
          {sectionType === 1 && (
            <FilterSection
              filterData={filterData}
              setFilterData={setFilterData}
              filterBtnRef={filterBtnRef}
              setValidFilter={setValidFilter}
              errors={errors}
              setErrors={setErrors}
            />
          )}
          {sectionType === 2 && (
            <div className="product-list">
              <Typography variant="h4">Latest Arrival</Typography>
              <div className="product-gallery">
                <Autocomplete
                  options={product?.map((prediction) => prediction?.name)}
                  noOptionsText="No Products Found"
                  inputValue={searchText}
                  onInputChange={handleSearch}
                  renderInput={(params) => <TextField {...params} label="Add Custom Products" variant="outlined" />}
                  renderOption={(option) => <div>{option}</div>}
                  getOptionSelected={(option, value) => option === value}
                  filterSelectedOptions={true}
                  onChange={(event, value) => {
                    if (value) {
                      const selectedPrediction = product?.find((prediction) => prediction?.name === value);

                      if (selectedPrediction) {
                        const isDuplicate = productList?.some((product) => product?.id === selectedPrediction.id);

                        if (isDuplicate) {
                          notify('This product has already been added', 'error');
                          return;
                        }

                        const updatedTopPicks = [...productList, selectedPrediction];

                        setProductList(updatedTopPicks);
                      }
                    }
                  }}
                />{' '}
                {productList?.map((productImg, index) => {
                  return (
                    <div className="img-container" key={productImg?.id}>
                      <label className="label-file" htmlFor={`myCheckbox${index}`}>
                        <ProductsCard
                          dragStart={(e) => handleDragStart(e, index)}
                          dragOver={(e) => handleDragOver(e, index)}
                          dragEnd={() => setDraggedOverIndex(null)}
                          dropCard={(e) => handleDrop(e, index)}
                          image={productImg?.cover_image?.image}
                          name={productImg?.name}
                          copyUrl={productImg?.banner_image}
                          id={productImg?.id}
                          isImages
                        />
                      </label>
                      {/* <Checkbox
                        className="checkbox-style"
                        {...Label}
                        id={`myCheckbox${index}`}
                        checkedIcon={<CheckCircleIcon />}
                        checked={!!selectedProductIds?.ids?.includes(productImg?.id)}
                        value={productImg?.id}
                        onClick={(e) => handleCheckbox(productImg?.id, e)}
                        icon={<RadioButtonUncheckedIcon />}
                      /> */}

                      {productList?.length > 0 ? (
                        <DeleteIcon
                          onClick={() => {
                            setOpenDelete(true);
                            setDeleteId(productImg?.id);
                          }}
                        />
                      ) : null}
                    </div>
                  );
                })}
                <Dialog
                  open={openDelete}
                  onClose={() => {
                    setOpenDelete(false);
                    setDeleteId(null);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className="delete-pop-up"
                >
                  <div className="delete-container">
                    <Typography variant="h2">Delete</Typography>
                    <Typography paragraph>Are you sure you want to delete this product?</Typography>
                  </div>
                  <DialogActions>
                    <BlueBtn title="Yes" onClick={() => handleDelete(deleteId)} />
                    <LightBtn
                      title="No"
                      onClick={() => {
                        setOpenDelete(false);
                        setDeleteId(null);
                      }}
                    />
                  </DialogActions>
                </Dialog>
                {/* <CardPagination
                page={page}
                noOfPages={productImages?.last_page}
                handleChange={(e, value) => setPage(value)}
                rowsPerPage={productImages?.per_page}
              /> */}
              </div>
              {formik?.touched?.product && formik?.errors?.product && (
                <div className="input-error-message">{formik?.errors?.product}</div>
              )}
            </div>
          )}
          {sectionType === 3 && (
            <div className="external-link-container">
              <div className="meta-title">
                <Typography className="external-link">External Link</Typography>
                <InputTypeOutline
                  placeholder="Link"
                  name="link"
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.link && formik.errors.link}
                />
              </div>
            </div>
          )}
          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor/links')} />
              <BlueBtn
                title="Save"
                // type="submit"
                onClick={() => {
                  if (sectionType === 1) {
                    filterBtnRef?.current?.click();
                  } else {
                    formik.handleSubmit();
                  }
                }}
                loading={loading}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
