import { useEffect, useState } from 'react';
import ProductSkeleton from '../../../components/Skeleton/ProductSkeleton';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import OutOfStock from '../../../components/universal/Buttons/OutOfStock';
import CardPagination from '../../../components/universal/CardPagination';
import DropDown from '../../../components/universal/DropDown';
import MainHeading from '../../../components/universal/Headings/MainHeading';
import SearchBar from '../../../components/universal/SearchBar';
import { category, notify } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';

const TrendingProducts = () => {
  const [tag, setTag] = useState(0);
  const [page, setPage] = useState(1);
  const [product, setProduct] = useState();
  const [Loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [totalProducts, setTotalProducts] = useState(0);
  const [timer, setTimer] = useState(null);

  const handleStatus = (e) => {
    const { value } = e.target;
    setTag(value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        getOrder(e.target.value);
      }, 800),
    );
  };

  const getOrder = async (searchData) => {
    const params = {
      page,
      startprice: '',
      endprice: '',
      sortbyprice: '',
      location: '',
      tags: tag === 0 ? '' : tag,
      search: searchData || '',
      feedtype: '',
      metadatas: '',
      sectionId: '',
      subsectionId: '',
      isOnSale: '',
      isFreeDelivery: '',
    };

    const response = await axiosAll({
      method: 'get',
      url: `v2/admin-products`,
      params,
    });
    if (response?.statusCode === 200) {
      setProduct(response?.result?.data);
      setTotalProducts(response?.result?.total);
    }
    setLoading(false);
  };
  const setTrending = async (id) => {
    setLoading(true);
    const responce = await axiosAll({
      method: 'post',
      url: `v2/set-trending-product/${id}`,
      data: {},
    });
    if (responce?.statusCode === 200) {
      notify(responce?.message, 'success');
      setLoading(false);
      getOrder();
    } else {
      notify(responce?.message, 'error');
      setLoading(false);
      getOrder();
    }
  };
  const handlePagination = (event, value) => {
    setPage(value);
  };

  const noOfPages = Math.ceil(totalProducts / 20);

  useEffect(() => {
    getOrder();
  }, [tag, page]);
  return (
    <div className="trendingproducts">
      <MainHeading title="Products" />
      <div className="searchbarsection">
        <SearchBar handleChange={handleSearch} value={search} />
        <DropDown option={category} handleChange={handleStatus} value={tag} />
      </div>

      {Loading ? (
        <ProductSkeleton />
      ) : (
        product?.map((item, index) => {
          return (
            <div className="img-container" key={index}>
              {item?.stockStatus === 1 ? null : <OutOfStock />}
              <img src={item?.images[0]?.image} className="img-row" alt="product-img" />
              <div className="product-row">
                <div className="product-section">{item?.name}</div>
                <div className="price">
                  £{item?.price}
                  {item?.discount !== 0 ? (
                    <>
                      <span> </span>
                      <span className="discount-price"> £{item?.actualPrice}</span>
                    </>
                  ) : null}
                </div>
                <div className="product-section">{item?.isTrending === 0 ? 'Not Trending' : 'Trending'}</div>
                <LightBtn title="Change Trending Status" onClick={() => setTrending(item?.id)} />
              </div>
            </div>
          );
        })
      )}
      {product?.length > 19 ? (
        <CardPagination noOfPages={noOfPages} page={page} handleChange={handlePagination} rowsPerPage={20} />
      ) : (
        ''
      )}

      {/* <div className='img-container'>
                <OutOfStock />
                <img src={images.product} className="img-row" />
                <div className='product-row'>
                    <div className='product-section'>
                        FL Digital Art
                    </div>
                    <div className='price'>
                        £50
                        <span className='discount-price'>£100</span>
                    </div>
                    <div className='product-section'>
                        Trending Product
                    </div>
                    <LightBtn title="Change Trending Status" />
                </div>
            </div> */}
    </div>
  );
};

export default TrendingProducts;
