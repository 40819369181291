import { Typography } from '@mui/material';

export const DraggableCard = ({
  keyID,
  dragStart,
  dragOver,
  dragEnd,
  dropCard,
  cardImg,
  headerTitle,
  descriptionPara,
}) => {
  return (
    <div
      className="draggable-card test"
      key={keyID}
      draggable
      onDragStart={dragStart}
      onDragOver={dragOver}
      onDragEnd={dragEnd}
      onDrop={dropCard}
    >
      <div className="drag-image-card">
        <img src={cardImg} alt="" />
      </div>

      <div className="drag-description-card">
        <Typography variant="h3" dangerouslySetInnerHTML={{ __html: headerTitle }} />
        <Typography paragraph dangerouslySetInnerHTML={{ __html: descriptionPara }} />
      </div>
    </div>
  );
};
