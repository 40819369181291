import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import { notify } from '../../../core/constants';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import SubHeading from '../../universal/Headings/SubHeading';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';

const WelcomeAdminDialog = ({
  userData,
  handleChange,
  updateUserData,
  loading,
  setLoading,
  getProfile,
  setOpenBusinessDetailsDialog,
  openBusinessDetailsDialog,
}) => {
  const oldData = JSON.parse(localStorage.getItem('oldData'));

  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setOpenBusinessDetailsDialog(true);
  };

  const handleClose = () => {
    setOpenBusinessDetailsDialog(false);
    setLoading(false);
    getProfile();
  };

  const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validatePhoneNumber = /^[0-9]{10}$/;

  const validateRegistrationNumber = /^[a-zA-Z0-9 ]*$/;

  useEffect(() => {
    if (userData?.businessEmail && !validateEmail.test(userData?.businessEmail)) {
      setError({
        businessEmail: 'Please enter a valid email address',
      });
    } else if (userData?.businessPhoneNumber && !validatePhoneNumber.test(userData?.businessPhoneNumber)) {
      setError({
        businessPhoneNumber: 'Please enter a valid phone number',
      });
    } else if (userData?.registrationNumber && !validateRegistrationNumber.test(userData?.registrationNumber)) {
      setError({
        registrationNumber: 'Please enter a valid registration number',
      });
    } else {
      setError('');
    }
  }, [userData?.businessEmail, userData?.businessPhoneNumber, userData?.registrationNumber]);

  return (
    <div>
      {/* <LightBtn onClick={handleClickOpen}/> */}
      <Button variant="contained" className="lightbtn" onClick={handleClickOpen}>
        Update
      </Button>
      <Dialog
        open={openBusinessDetailsDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="updatebusinessdetails"
      >
        <DialogContent>
          <SubHeading title="Update Business Details " />
          <div className="tracking-text "> Business name</div>
          <InputTypeOutline name="businessName" onChange={handleChange} value={userData?.businessName} />

          <div className="tracking-text ">Company registration number</div>
          <InputTypeOutline
            name="registrationNumber"
            onChange={handleChange}
            value={userData?.registrationNumber}
            error={error?.registrationNumber}
          />

          <div className="tracking-text ">VAT registration number</div>
          <InputTypeOutline
            // disabled={!oldData}
            name="vatNumber"
            onChange={handleChange}
            value={userData?.vatNumber}
          />
          {/* <div className="update-text">To update or add a VAT number, please contact support@goethical.com</div> */}
          <div className="tracking-text ">Business email</div>
          <InputTypeOutline
            name="businessEmail"
            type="email"
            onChange={handleChange}
            value={userData?.businessEmail}
            error={error?.businessEmail}
          />
          <div className="tracking-text ">Business Phone</div>
          <InputTypeOutline
            type="number"
            name="businessPhoneNumber"
            onChange={handleChange}
            value={userData?.businessPhoneNumber}
            max="15"
            error={error?.businessPhoneNumber}
          />
          <div className="tracking-text ">Business Address</div>
          <InputTypeOutline name="businessAddress" onChange={handleChange} value={userData?.businessAddress} required />

          <div className="btn-section">
            <LightBtn title="Cancel" onClick={handleClose} />
            <DarkBtn
              title="Save"
              type="submit"
              onClick={() =>
                userData?.businessAddress?.length === 0 || userData?.businessEmail?.length === 0
                  ? notify(
                      `Business ${userData?.businessEmail?.length === 0 ? 'email' : 'address'} is required`,
                      'error',
                    )
                  : error?.businessEmail || error?.businessPhoneNumber || error?.registrationNumber
                  ? notify(error?.businessEmail || error?.businessPhoneNumber || error?.registrationNumber, 'error')
                  : updateUserData('businessDetails')
              }
              disabled={!!loading}
              loading={loading}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WelcomeAdminDialog;
