import React, { useState, useRef, useEffect } from 'react';
import { DraggableUploadCard } from '../../topBanner/DraggableUploadCard';
import { DraggableCardDelete } from '../../dialogs/DraggableCardDelete';

export const UploadDraggableContainer = ({ imageTrash, banners, setBanners, imageSize }) => {
  const [bannerArr, setBannerArr] = useState([
    { id: 1, name: 'Desktop Banner', image: banners?.desktopBanner || '' },
    { id: 2, name: 'Tablet Banner', image: banners?.tabletBanner || '' },
    { id: 3, name: 'Mobile Banner', image: banners?.mobileBanner || '' },
  ]);

  const desktopBannerRef = useRef();
  const tabletBannerRef = useRef();
  const mobileBannerRef = useRef();

  const handleProfilePicURL = (item) => {
    if (item instanceof File) {
      return URL.createObjectURL(item);
    }
    return item;
  };

  const handleDelete = (item) => {
    setBannerArr((prev) => {
      return prev.map((prevItem) => {
        if (prevItem.id === item.id) {
          return { ...prevItem, image: '' };
        }
        return prevItem;
      });
    });
  };

  const dec =
    'Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application.';

  useEffect(() => {
    if (banners?.desktopBanner) {
      setBannerArr((prev) => {
        return prev.map((prevItem) => {
          if (prevItem.id === 1) {
            return { ...prevItem, image: banners?.desktopBanner };
          }
          return prevItem;
        });
      });
    }
    if (banners?.tabletBanner) {
      setBannerArr((prev) => {
        return prev.map((prevItem) => {
          if (prevItem.id === 2) {
            return { ...prevItem, image: banners?.tabletBanner };
          }
          return prevItem;
        });
      });
    }
    if (banners?.mobileBanner) {
      setBannerArr((prev) => {
        return prev.map((prevItem) => {
          if (prevItem.id === 3) {
            return { ...prevItem, image: banners?.mobileBanner };
          }
          return prevItem;
        });
      });
    }
  }, [banners?.desktopBanner, banners?.tabletBanner, banners?.mobileBanner]);

  return bannerArr.map((item, index) => (
    <div key={index} className="drag-container">
      <DraggableUploadCard
        keyID={item.id}
        item={item}
        imageSize={imageSize}
        cardImg={item?.image}
        headerTitle={item.name}
        descriptionPara={dec}
        handleProfilePicURL={handleProfilePicURL}
        setImage={(image) => {
          const newBanner = [...bannerArr];
          newBanner[index].image = image;
          setBannerArr(newBanner);
          if (item.id === 1) {
            setBanners((prev) => {
              return { ...prev, desktopBanner: image };
            });
          } else if (item.id === 2) {
            setBanners((prev) => {
              return { ...prev, tabletBanner: image };
            });
          } else {
            setBanners((prev) => {
              return { ...prev, mobileBanner: image };
            });
          }
        }}
        imageRef={item.id === 1 ? desktopBannerRef : item.id === 2 ? tabletBannerRef : mobileBannerRef}
      />
      {item?.image && (
        <div className="upload-arrow-card">
          <DraggableCardDelete
            iconImage={imageTrash}
            textTitle="Delete"
            textDescription="Are you sure you want to delete this image?"
            onPress={(setOpen) => {
              handleDelete(item);
              setOpen(false);
              if (item.id === 1) {
                setBanners((prev) => {
                  return { ...prev, desktopBanner: '' };
                });
              } else if (item.id === 2) {
                setBanners((prev) => {
                  return { ...prev, tabletBanner: '' };
                });
              } else {
                setBanners((prev) => {
                  return { ...prev, mobileBanner: '' };
                });
              }
            }}
          />
        </div>
      )}
    </div>
  ));
};
