import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { images } from '../../../assets';
import { CircleBtnIcon } from '../../siteEitor/circleBtnIcon';
import { AddIconBtn } from '../../CategoriesHeader/AddBtn';

export const TableActionBtn = ({ onAdd, EditComponent, onEdit, onDelete }) => {
  return (
    <div className="actionbtn">
      {onAdd && <AddIconBtn iconColor="#2559C9" iconImg={images.plusIcon} onPress={onAdd} />}
      {EditComponent ||
        (onEdit && (
          <CircleBtnIcon iconClass="edit" iconColor="transparent" iconImg={images.editBlack} onPress={onEdit}>
            <ModeEditOutlineIcon />
          </CircleBtnIcon>
        ))}
      {onDelete && (
        <CircleBtnIcon iconColor="#2F2B23" iconImg={images.trashBtn} onPress={onDelete}>
          {' '}
          <DeleteIcon />
        </CircleBtnIcon>
      )}
    </div>
  );
};
