import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import MobileMenu from './MobileMenu';

const PublicHeader = () => {
  const nav = useNavigate();

  return (
    <div className="sellerheader_new">
      <Grid container spacing={2}>
        <Grid item xs={2.5} sm={4}>
          <div className="mobileshow tabshow mobilemenu ">
            <MobileMenu />
          </div>
        </Grid>
        <Grid item xs={9.5} sm={8}>
          <div className="category-section ">
            <button
              type="button"
              onClick={() => {
                window.location.pathname === '/login' ? window.scroll(0, 0) : nav('/login');
              }}
              className="login-btn-new"
            >
              Login
            </button>
            <button
              type="button"
              onClick={() => {
                window.location.pathname === '/create-account/seller-registration'
                  ? window.scroll(0, 0)
                  : nav('/create-account/seller-registration');
              }}
              className="create-btn-new"
            >
              Create free account
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PublicHeader;
