import { Navigate, Route, Routes } from 'react-router';
import BusinessDetails from '../pages/BusinessDetails';
import BusinessProductDetails from '../pages/BusinessProductDetails';
import CreateAccount from '../pages/CreateAccount';
import ErrorCase from '../pages/Error';
import Expiry from '../pages/ExpiryToken';
import ForgotPassword from '../pages/ForgotPassword';
import Home from '../pages/Home';
import NoInternet from '../pages/NoInternet';
import { NotFound } from '../pages/NotFound';
import PolicyDetails from '../pages/PolicyDetails';
import ProSellerRegistration from '../pages/ProSellerRegistration';
import ResetPassword from '../pages/ResetPassword';
import CancelSubscription from '../pages/Seller/CancelSubscription';
import ContactUs from '../pages/StaticPage/ContactUs';
import GiftVoucherTermsAndCondition from '../pages/StaticPage/GiftVoucherTermsAndConditions';
import { Status } from '../pages/Status';
import StoreDetails from '../pages/StoreDetails/indes';
import StripVerification from '../pages/StripVerification';
import Subscription from '../pages/Subscription';
import ThanksForRegistering from '../pages/ThanksForRegistering';
import VerifyYourEmail from '../pages/VerifyYourEmail';
import Welcome from '../pages/Welcome';
import Login from '../pages/login';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/login" replace />} />
      <Route path="home" element={<Home />} />
      {/* <Route path="onboarding/dashboard" element={<Navigate to="/login" replace />} /> */}

      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="create-account/seller-registration" element={<CreateAccount />} />
      <Route path="create-account/:token" element={<CreateAccount />} />
      <Route path="verify-email/:id/:token" element={<VerifyYourEmail />} />
      <Route path="store-details/:id/:token" element={<StoreDetails />} />
      <Route path="welcome-page" element={<Welcome />} />
      <Route path="welcome" element={<Welcome />} />
      <Route path="policy-details/:id/:token" element={<PolicyDetails />} />
      <Route path="business-details/:id/:token" element={<BusinessDetails />} />
      <Route path="business-product-details/:id/:token" element={<BusinessProductDetails />} />
      <Route path="subscription/:id/:token" element={<Subscription />} />
      <Route path="status" element={<Status />} />

      <Route path="pro-seller-registration" element={<ProSellerRegistration />} />
      <Route path="thanks-for-registering" element={<ThanksForRegistering />} />
      <Route path="stripe-verification" element={<StripVerification />} />
      {/* <Route path="order-invoice" element={<OrderInvoice />} /> */}
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="gift-voucher-terms-and-conditions" element={<GiftVoucherTermsAndCondition />} />
      <Route path="*" element={<NotFound />} />
      <Route path="no-internet" element={<NoInternet />} />
      <Route path="invalid-store" element={<ErrorCase />} />
      <Route path="expiry" element={<Expiry />} />
      <Route path="cancel-subscription" element={<CancelSubscription />} />
    </Routes>
  );
};

export default PublicRoutes;
