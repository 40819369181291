import { combineReducers } from 'redux';
import cartReducer from './cartcount';
import guestReducer from './guestReducer';
import guestUserReducer from './guestUserReducer';
import loginReducer from './loginReducer';
import newsLatterReducer from './newsLatter';
import userReducer from './users';

const rootReducer = combineReducers({
  guestReducer,
  guestUserReducer,
  loginReducer,
  userReducer,
  cartReducer,
  newsLatterReducer,
});

export default rootReducer;
