import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../assets';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import TextArea from '../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../components/universal/InputType/inputTypeOutline';
import ProgressBar from '../../components/universal/ProgressBar';
import { notify } from '../../core/constants';
import { AxiosAll } from '../../services/axiosAll';

const StoreDetails = () => {
  const nav = useNavigate();
  const [logoImage, setLogoImage] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [sellerData, setSellerData] = useState({});
  const [registeredUser, setRegisteredUser] = useState(1);
  const [count, setCount] = useState(5);
  const [userDetails, setUserDetails] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { token } = useParams();

  const videoUrlRegex = /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*\/?/;
  const domainRegex = /^[a-zA-Z][a-zA-Z0-9]+$/;
  // const domainRegex = /^[a-zA-Z][a-zA-Z0-9]*(?:-[a-zA-Z0-9]+)*$/;

  const getUserData = async () => {
    try {
      const res = await AxiosAll('get', 'seller-profile', null, token);
      const user = res?.data?.user;
      setUserDetails(user);

      formik.setFieldValue('storeName', user?.store?.store_name || '');
      formik.setFieldValue('domainName', user?.store?.domain_slug || '');
      formik.setFieldValue('about', user?.store?.store_description);
      formik.setFieldValue('logo', user?.store?.store_logo || '');
      formik.setFieldValue('banner', user?.store?.store_banner || '');
      formik.setFieldValue('videoUrl', user?.store?.store_video_url || '');
      setSellerData(res?.data);
      setRegisteredUser(user?.stripe_sub_is_first_time);
      if (res?.data?.user?.stripe_sub_is_first_time !== 1) {
        const timer = setInterval(() => {
          setCount((prev) => prev - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  // formik start
  const initialValues = {
    logo: '',
    banner: '',
    storeName: '',
    domainName: '',
    about: '',
    videoUrl: '',
  };

  const validationSchema = Yup.object({
    storeName: Yup.string().required('Store name is required'),
    domainName: Yup.string()
      .required('Domain name is required')
      .matches(
        domainRegex,
        'Domain name must start with alphabet, it can only be in alphanumeric and should not contain any special characters or spaces',
      ),
    about: Yup.string().required('About store is required'),
    videoUrl: Yup.string().matches(videoUrlRegex, 'Please enter a valid url'),
  });

  const onSubmit = (values) => {
    if (values?.videoUrl) {
      videoUrlRegex?.test(values?.videoUrl) ? submitStoreDetails(values) : notify(`Please enter a valid url`, 'error');
    } else {
      submitStoreDetails(values);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const submitStoreDetails = async (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('store_logo', values?.logo);
    formData.append('store_banner', values?.banner);
    formData.append('store_name', values?.storeName);
    formData.append('domain_slug', values?.domainName?.replaceAll(/\s/g, '-').toLowerCase());
    formData.append('store_description', values?.about);
    values?.videoUrl && formData.append('store_video_url', values?.videoUrl);

    try {
      const res = await AxiosAll('post', `seller/storeDetail`, formData, token);
      if (res?.statusCode === 200) {
        notify(res?.message, 'success');
        nav(`/policy-details/${id}/${token}`);
        setLoading(false);
      } else {
        notify(res?.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formik?.values?.logo && formik?.values?.banner && formik?.values?.storeName && formik?.values?.about) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, []);

  // check if image has 2446px x 580px
  const checkImageSize = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        if (img.width === 2446 && img.height === 580) {
          formik.setFieldValue('banner', file);
          // submitBanner(file)
          setBannerImage(file);
        } else {
          notify('Image size should be 2446px x 580px', 'error');
        }
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
    // getUserData();
  };

  useEffect(() => {
    if (count <= 0 && sellerData?.user?.stripe_sub_is_first_time !== 1) {
      nav(
        `/subscription/${sellerData?.user?.id}/${sellerData?.user_token}?isNew=${sellerData?.user?.stripe_sub_is_first_time}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, sellerData]);

  return (
    <>
      <ProgressBar value={45} />
      {registeredUser !== 1 ? (
        <div className="verifyyouremail">
          <div>You are already registered!</div>
          <div className="text-para">You will be redirected to subscription page in {count} seconds</div>
        </div>
      ) : (
        <div className="storedetails">
          <form onSubmit={formik.handleSubmit}>
            <div className="text-heading">Let’s set up your store</div>
            <div className="text-para">This information will display to customers </div>
            <div>
              <p className="logo-text marleft0">Store Logo (135px x 135px)</p>

              <div className="file-section">
                <label htmlFor="files" className="drag-label">
                  <div className="store-img-row">
                    <div className="upload-text">
                      <input
                        id="files"
                        className="input-type-file-upload"
                        type="file"
                        name="logo"
                        accept="image/png,image/jpeg,image/jpg"
                        onChange={(event) => {
                          if (event.target.files.length > 0) {
                            // submitLogo( event.currentTarget.files[0])
                            formik.setFieldValue('logo', event.currentTarget.files[0]);
                            setLogoImage(event.currentTarget.files[0]);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        onClick={(e) => {
                          e.currentTarget.value = null;
                          formik.setFieldValue('logo', '');
                        }}
                      />

                      {logoImage || sellerData?.user?.store?.store_logo ? (
                        <img
                          src={logoImage ? URL.createObjectURL(logoImage) : sellerData?.user?.store?.store_logo}
                          className="img"
                          alt="logo"
                        />
                      ) : (
                        <>
                          {' '}
                          <div>
                            <img src={images.upload} className="upload-img" alt="upload" />
                          </div>
                          Upload
                        </>
                      )}
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <div>
              <p className="logo-text marleft0">Store banner (2446px x 580px)</p>
              <div className="drop-down">
                <div className="file-label">
                  <label htmlFor="bannerfiles">
                    <input
                      id="bannerfiles"
                      className="input-type-file-upload"
                      type="file"
                      name="banner"
                      accept="image/png,image/jpeg,image/jpg"
                      onChange={(event) => checkImageSize(event)}
                      onBlur={formik.handleBlur}
                      onClick={(e) => {
                        e.currentTarget.value = null;
                        formik.setFieldValue('banner', '');
                      }}
                    />
                    {bannerImage || sellerData?.user?.store?.store_banner ? (
                      <img
                        src={bannerImage ? URL.createObjectURL(bannerImage) : sellerData?.user?.store?.store_banner}
                        alt="banner"
                      />
                    ) : (
                      <>
                        <img src={images.upload} className="upload-img" alt="upload" />
                        <p className="select-image-text">
                          Drag and drop banner image or
                          <u> click here</u> to select files{' '}
                        </p>
                      </>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="mrgbottom">
              <div className="label-text">Store name</div>
              <InputTypeOutline
                name="storeName"
                id="storeName"
                type="text"
                placeholder="Store Name"
                onBlur={formik.handleBlur}
                value={formik.values.storeName}
                onChange={formik.handleChange}
                error={formik.touched.storeName && formik.errors.storeName}
              />
            </div>
            <div className="mrgbottom">
              <div className="tooltip-domain">
                <div className="label-text">Domain name</div>
                <Tooltip title="Once you have entered the domain name, it cannot be altered at a later time.">
                  <InfoIcon />
                </Tooltip>
              </div>
              <InputTypeOutline
                name="domainName"
                id="domainName"
                type="text"
                placeholder="Domain Name"
                staticText=".sellstream.com"
                disabled={sellerData?.user?.stripe_sub_is_first_time !== 1}
                showStaticText
                showTooltip
                tooltipTitle="Once you have entered the domain name, it cannot be altered at a later time."
                onBlur={formik.handleBlur}
                value={formik.values.domainName}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const formattedValue = inputValue.replace(/\s/g, '').toLowerCase();

                  formik.handleChange('domainName')(formattedValue);
                }}
                error={formik.touched.domainName && formik.errors.domainName}
              />
            </div>
            <div className="mrgbottom">
              <div className="label-text">About store</div>
              <TextArea
                name="about"
                id="about"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.about}
                onChange={formik.handleChange}
                error={formik.touched.about && formik.errors.about}
              />
            </div>
            <div className="">
              <div className="label-text">Video url (optional)</div>
              <InputTypeOutline
                name="videoUrl"
                id="videoUrl"
                type="url"
                onBlur={formik.handleBlur}
                value={formik.values.videoUrl}
                onChange={formik.handleChange}
                error={formik.touched.videoUrl && formik.errors.videoUrl}
              />
            </div>
            <div className="btn-section">
              <div>
                <LightBtn title="Back" onClick={() => nav(`/verify-email/${id}/${token}`)} />
              </div>
              <div>
                <DarkBtn
                  loading={loading}
                  title="Continue"
                  disable={
                    !(
                      formik?.values?.storeName &&
                      formik?.values?.about &&
                      formik?.values?.domainName &&
                      (logoImage || formik?.values?.logo) &&
                      (bannerImage || formik?.values?.banner)
                    ) || loading
                  }
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default StoreDetails;
