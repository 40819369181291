import { images } from '../assets';

const cardImageMap = {
  visa: images.visa,
  mastercard: images.mastercard,
  amex: images.americanExpress,
  discover: images.discover,
  jcb: images.jcb,
  diners: images.dinner_club,
  unionpay: images.union_pay,
};

const defaultImage = images.card;

export const cardTypeImage = (cardBrand, height, width) => {
  const brandName = cardBrand.toLowerCase().replace(/\s+/g, '');
  const imageUrl = cardImageMap[brandName] || defaultImage;
  return (
    <img
      height={height || 120}
      width={width || 200}
      loading="lazy"
      alt={cardBrand}
      src={imageUrl}
      className={`${brandName === 'amex' ? 'amex-img' : ''} mastercard`}
    />
  );
};
