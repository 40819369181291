import LockIcon from '@mui/icons-material/Lock';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { stripkey } from '../../core/constants';
import { useWindowSize } from '../../hooks/window';
import { MAX_DESKTOP_4K } from '../../models/breakpoints.model';

const CheckoutForm = ({ setPayCardID, buttonText, btnClass, addCardLoading, formik }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(addCardLoading || false);
  const { width } = useWindowSize();
  const isLarge = (width || 0) > MAX_DESKTOP_4K;

  const handleSubmit = async (event) => {
    setLoading(true);
    setErrorMessage('');
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    setPayCardID && setPayCardID(paymentMethod?.id);
    setTimeout(() => setLoading(false), 10000);

    setErrorMessage(error?.message);
    error && setLoading(false);
  };
  const appearance = {
    style: {
      base: {
        fontSize: '1rem',
        color: '#000',
        border: '1px solid #D9D9D9',
        backgroundColor: '#FFFCFC',
        borderRadius: '20px',
      },
      invalid: {
        color: '#F5445E',
        iconColor: '#F5445E',
        border: '1px solid #F5445E',
      },
    },
    hidePostalCode: true,
  };
  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={appearance} />
      <div className="font12px input-error-message">{errorMessage}</div>
      {loading ? (
        <div className="btn-trial-center">
          <Button disabled={loading} className={`trial-btn ${btnClass}`} style={{ marginTop: '22px' }}>
            {loading ? 'Loading...' : buttonText || 'Add'}
          </Button>
        </div>
      ) : (
        <div className="btn-trial-center">
          <Button className={`trial-btn ${btnClass}`} type="submit" disabled={!stripe || !elements || loading}>
            <LockIcon />
            {loading ? 'Loading...' : buttonText || 'Add'}
          </Button>
        </div>
      )}
    </form>
  );
};

const stripePromise = loadStripe(stripkey);

const PaymentMethodStripForm = ({ setPayCardID, buttonText, btnClass, loading }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        setPayCardID={setPayCardID}
        buttonText={buttonText}
        btnClass={btnClass}
        addCardLoading={loading}
        // theme={appearance}
      />
    </Elements>
  );
};

export default PaymentMethodStripForm;
