import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../../../assets';
import { BlueBtn } from '../../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../../components/universal/Buttons/LightBtn';
import DropDown from '../../../../components/universal/DropDown';
import TextArea from '../../../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../../../components/universal/InputType/inputTypeOutline';
import { UploadDraggableContainer } from '../../../../components/universal/UploadDraggableContainer';
import { UploadSingleImage } from '../../../../components/universal/UploadImage';
import BannerCard from '../../../../components/universal/WelcomeCard';
import { notify } from '../../../../core/constants';
import { AxiosAll } from '../../../../services/axiosAll';
import { Loader } from '../../../../components/Skeleton/Loader';

export const AddBanner = () => {
  const route = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [metaImage, setMetaImage] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [links, setLinks] = useState([]);
  const [banners, setBanners] = useState({
    desktopBanner: '',
    tabletBanner: '',
    mobileBanner: '',
  });

  const searchParams = new URLSearchParams(window.location.search);
  const bannerId = searchParams?.get('id');
  const { userDetails } = useSelector((state) => state.loginReducer);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    description: '',
    buttonLabel: '',
    buttonLink: '0',
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    BannerImage: '',
  };

  const validationSchema = Yup.object({
    BannerImage: Yup.string().required('Banner image is required'),
    // description: Yup.string().required('Description is required'),
    // buttonLabel: Yup.string().required('Label is required'),
    // buttonLink: Yup.string().required('Link is required'),
    // metaTitle: Yup.string(),
    // metaDescription: Yup.string(),
    // metaKeywords: Yup.string(),
  });

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values?.title);
    formData.append('sub_title', values?.description);
    formData.append('button_label', values?.buttonLabel);
    formData.append('button_link', parseInt(values?.buttonLink, 10));
    formData.append('seo_meta_title', values?.metaTitle);
    formData.append('seo_meta_description', values?.metaDescription);
    formData.append('seo_meta_keywords', values?.metaKeywords);
    formData.append('seller_section_id', bannerId || 0);
    formData.append('seo_meta_image', metaImage);
    formData.append('banner_image', bannerImage);
    formData.append('banner_image_web', banners?.desktopBanner);
    formData.append('banner_image_tablet', banners?.tabletBanner);
    formData.append('banner_image_mobile', banners?.mobileBanner);

    setLoading(true);

    const endPoint = id
      ? `seller/sellerSections/${id}?section_type=banner_with_multiple_image&_method=PUT`
      : 'seller/sellerSections?section_type=banner_with_multiple_image';

    const response = await AxiosAll('post', endPoint, formData);
    if (response.statusCode === 200) {
      notify(response.message, 'success');
      route(`/onboarding/site-editor/banners/${response?.data?.id}`);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const getBannerDetails = async () => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `seller/sellerSections/${id}?section_type=banner_with_multiple_image&type=banner`,
      null,
    );
    if (response.statusCode === 200) {
      const bannerData = response.data;
      formik.setFieldValue('title', bannerData?.title || '');
      formik.setFieldValue('description', bannerData?.sub_title || '');
      formik.setFieldValue('buttonLabel', bannerData?.button_label || '');
      formik.setFieldValue('buttonLink', bannerData?.button_link || '');
      formik.setFieldValue('metaTitle', bannerData?.seo_meta_title || '');
      formik.setFieldValue('metaDescription', bannerData?.seo_meta_description || '');
      formik.setFieldValue('metaKeywords', bannerData?.seo_meta_keywords || '');
      formik.setFieldValue('BannerImage', bannerData?.banner_image || '');

      setMetaImage(bannerData?.seo_meta_image || '');
      setBannerImage(bannerData?.banner_image || '');
      setBanners({
        desktopBanner: bannerData?.banner_image_web || '',
        tabletBanner: bannerData?.banner_image_tablet || '',
        mobileBanner: bannerData?.banner_image_mobile || '',
      });
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const getDropdowns = async () => {
    const types = ['links'];

    const response = await AxiosAll(
      'get',
      `dropdowns?type[]=${types.join('&type[]=')}&seller_id=${userDetails?.user?.id}`,
    );
    if (response.statusCode === 200) {
      setLinks(response?.data?.links);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getBannerDetails();
    }
    getDropdowns();
  }, []);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  useEffect(() => {
    formik.setFieldValue('BannerImage', bannerImage || '');
  }, [bannerImage]);

  return (
    <div className="banner-page">
      <Loader open={loading} />
      <BannerCard title="Promo Banners" para={para} backgroundImageURL={images.dynamicProductsImg} />
      <form onSubmit={formik.handleSubmit}>
        <div className="main-section container-27inch">
          <BlueBtn
            title="Return"
            customClass="return-btn"
            onClick={() => route(bannerId ? `/onboarding/site-editor/banners/${bannerId}` : `/onboarding/site-editor`)}
          />
          <div className="trending-detail">
            <div className="search-engine-section">
              <Typography variant="h4">Section Details</Typography>
              <Typography paragraph className="description">
                These details will populate the featured products section on your website.
              </Typography>
              <div className="input-title">
                <div className="meta-info">
                  <div className="meta-title">
                    <Typography>Banner Title</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="title"
                      value={formik?.values?.title}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.title && formik?.errors?.title}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Description</Typography>
                    <TextArea
                      placeholder="Enter"
                      name="description"
                      value={formik?.values?.description}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.description && formik?.errors?.description}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Label</Typography>
                    <InputTypeOutline
                      placeholder="Enter"
                      name="buttonLabel"
                      value={formik?.values?.buttonLabel}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.buttonLabel && formik?.errors?.buttonLabel}
                    />
                  </div>
                  <div className="meta-des">
                    <Typography>Button Link</Typography>
                    <DropDown
                      placholder="Select"
                      name="buttonLink"
                      handleChange={(e) => formik.setFieldValue('buttonLink', e.target.value)}
                      value={formik.values.buttonLink}
                      option={links}
                      touched={formik.touched.buttonLink}
                      error={formik.errors.buttonLink}
                      blur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="search-engine-section">
            <Typography variant="h4">Search Engine Optimisation</Typography>
            <Typography paragraph className="description">
              Product SEO is the practice of optimising elements of product to provide search engines with enough
              relevant content to get the product found in search engines.
            </Typography>

            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Meta Title</Typography>
                  <InputTypeOutline
                    placeholder="Enter"
                    name="metaTitle"
                    value={formik?.values?.metaTitle}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaTitle && formik?.errors?.metaTitle}
                  />
                </div>
                <div className="meta-des">
                  <Typography>Meta Description</Typography>
                  <TextArea
                    placeholder="Enter"
                    name="metaDescription"
                    value={formik?.values?.metaDescription}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaDescription && formik?.errors?.metaDescription}
                  />
                </div>
                <div className="meta-keywords">
                  <Typography>Meta Keywords</Typography>
                  <InputTypeOutline
                    placeholder="Enter"
                    name="metaKeywords"
                    value={formik?.values?.metaKeywords}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.metaKeywords && formik?.errors?.metaKeywords}
                  />
                </div>
                <div className="banner-single-upload">
                  <UploadSingleImage
                    title="Meta Image"
                    description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                    image={metaImage}
                    setImage={setMetaImage}
                    imageSize={2}
                  />
                </div>
              </div>
            </div>
          </div>

          <UploadSingleImage
            title="Banner Image"
            description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
            image={bannerImage}
            setImage={setBannerImage}
            imageSize={2}
          />
          {formik?.touched?.BannerImage && <div className="input-error-message">{formik?.errors?.BannerImage}</div>}
          <UploadDraggableContainer
            dec={para}
            imagePreview={images.sitePreview}
            imageTrash={images.trashBtn}
            banners={banners}
            setBanners={setBanners}
            imageSize={2}
          />

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn
                title="Cancel"
                onClick={() =>
                  route(bannerId ? `/onboarding/site-editor/banners/${bannerId}` : `/onboarding/site-editor`)
                }
              />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
