import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Blackbtn from '../../components/universal/Buttons/BlackBtn';

export const NotFound = () => {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const nav = useNavigate();
  useEffect(() => {
    if (userDetails.token) {
      if (userDetails?.user?.userType === 1) {
        nav('admin/user-registration');
      } else {
        nav('onboarding/dashboard');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="notfound">
      <h1>Oops, page not found</h1>
      <p>We are very sorry for the inconvenience. It looks like you're trying to</p>
      <p>access a page the has been deleted or never even existed.</p>
      <div className="btn-section">
        <Blackbtn
          onClick={() =>
            nav(
              userDetails.token
                ? userDetails?.user?.userType === 1
                  ? 'admin/user-registration'
                  : 'onboarding/dashboard'
                : 'login',
            )
          }
          title="BACK TO HOMEPAGE"
        />
      </div>
    </div>
  );
};
