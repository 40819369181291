import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import { Empty } from '../../../components/universal/Empty';
import { ImgCard } from '../../../components/SiteEditorTable/imgCard';
import { images } from '../../../assets';

export const UserTable = ({ TableColumn, Row, page, rowsPerPage, handleOrderDetail, name, financeReport }) => {
  const funcToAddPrice = (item) => {
    let price = 0;

    item?.map((e, index) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      price += e?.price;
    });

    return price.toFixed(2);
  };
  const nav = useNavigate();
  return (
    <TableContainer component={Paper}>
      {console.log('rows', Row)}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {TableColumn?.map((col, index) => {
              return col != 'User Name' && <TableCell key={index}>{col} </TableCell>;
            })}
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {Row?.length > 0 ? (
            Row?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
              return (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row?.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.name}
                  </TableCell>{' '}
                  {/* <TableCell component="th" scope="row">
                    {row?.user_name}
                  </TableCell> */}
                  <TableCell component="th" scope="row">
                    {row?.phone_number}
                  </TableCell>{' '}
                  <TableCell align="center">
                    <ImgCard tableImg={row?.profile_picture || images.defaultUser} isImageTrue />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.is_active === 1 ? 'Acive' : 'UnActive'}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <tr>
              <td colSpan="5">
                <Empty message="Your orders will show in this table" />
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
