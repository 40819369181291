import { Container } from '@mui/material';
import React from 'react';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';

export const Help = () => {
  return (
    <div className="help-page">
      <Container className="all-container-27inch">
        <div className="order-heading">Help</div>
        <div className="para-section">
          <div className="help-para">
            We have curated a selection of tutorial videos to help you navigate the platform, as well as a downloadable
            user manual to help.
          </div>
          <DarkBtn title="User manual" />
        </div>
        <div className="box-container">
          <div className="video-container">
            <iframe title="How to view orders" className="video-box" src="https://www.youtube.com/embed/ByKAShkXu90" />
            <p>How to view orders</p>
          </div>

          <div className="video-container">
            <iframe
              title="How to edit a product"
              className="video-box"
              src="https://www.youtube.com/embed/UacgLYebkJ4"
            />
            <p>How to edit a product</p>
          </div>
          <div className="video-container">
            <iframe
              title="How to view a single product"
              className="video-box"
              src="https://www.youtube.com/embed/9uH355Zf24I"
            />
            <p>How to view a single product</p>
          </div>
          <div className="video-container">
            <iframe
              title="How to add a single a product"
              className="video-box"
              src="https://www.youtube.com/embed/FYQlFTO9Y1o"
            />
            <p>How to add a single a product</p>
          </div>
        </div>
      </Container>
    </div>
  );
};
