import React, { useEffect, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import LightBtn from '../../Buttons/LightBtn';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const EditTagSelection = ({ getSelectedTags, tagKeywords }) => {
  const [tags, setTags] = React.useState([]);

  useEffect(() => {
    setTags(tagKeywords);
  }, [tagKeywords]);

  const [tagValue, setTagValue] = useState('');

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
    setTagValue('');
  };

  useEffect(() => {
    // transform tags to "tag1, tag2, tag3"
    const tagsString = tags?.map((tag) => tag.text)?.join(', ');
    getSelectedTags(tagsString);
  }, [tags]);

  return (
    <>
      <div className="MultiSelect">
        <div>
          <ReactTags
            tags={tags}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            inputFieldPosition="top"
            allowDragDrop={false}
            autocomplete
            autofocus={false}
            handleInputChange={(e) => setTagValue(e)}
            inputValue={tagValue}
            placeholder="Add tags"
          />
        </div>
      </div>
      <LightBtn
        title="Add"
        customClass={`${tagValue !== '' ? 'singleproductbtndrk' : ''}`}
        disabled={tagValue === ''}
        onClick={() => {
          if (tagValue) {
            const tag = {
              id: tagValue,
              text: tagValue,
            };
            handleAddition(tag);
          }
        }}
        setTagValue=""
      />
    </>
  );
};

export default EditTagSelection;
