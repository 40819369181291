/* eslint-disable no-debugger */
/* eslint-disable no-shadow */
import { Dialog, DialogActions, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { ProductSectionCard } from '../../../components/ProductSection/ProductSectionCard';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import TextArea from '../../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import ProductsCard from '../../../components/universal/ProductsCard';
import { UploadDraggableContainer } from '../../../components/universal/UploadDraggableContainer';
import { UploadSingleImage } from '../../../components/universal/UploadImage';
import BannerCard from '../../../components/universal/WelcomeCard';
import { formats, modules, notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import Editor from '../../../components/universal/InputType/Editor';
import { Loader } from '../../../components/Skeleton/Loader';

export const AddProductCollection = () => {
  const route = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [existingProducts, setExistingProducts] = useState([]);
  const [coverImage, setCoverImage] = useState('');
  const [featuredImage, setFeaturedImage] = useState('');
  const [metaImage, setMetaImage] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [product, setProduct] = useState([]);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  const [banners, setBanners] = useState({
    desktopBanner: '',
    tabletBanner: '',
    mobileBanner: '',
  });

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    description: '',
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    metaTitle: Yup.string(),
    metaDescription: Yup.string(),
    metaKeywords: Yup.string(),
  });

  const onSubmit = async (values) => {
    if (!coverImage) {
      notify('Please upload cover image', 'error');
    } else if (!featuredImage) {
      notify('Please upload featured image', 'error');
    } else if (productList?.length === 0) {
      notify('Please select at least one product', 'error');
    } else if (!bannerImage) {
      notify('Please upload banner image', 'error');
    } else {
      const formData = new FormData();
      formData.append('title', values?.title);
      formData.append('description', values?.description);
      formData.append('cover_image', coverImage);
      formData.append('featured_image', featuredImage);
      formData.append('seo_meta_title', values?.metaTitle);
      formData.append('seo_meta_description', values?.metaDescription);
      formData.append('seo_meta_keywords', values?.metaKeywords);
      formData.append('seo_meta_image', metaImage);
      formData.append('banner_image', bannerImage);
      formData.append('banner_image_web', banners?.desktopBanner);
      formData.append('banner_image_tablet', banners?.tabletBanner);
      formData.append('banner_image_mobile', banners?.mobileBanner);
      if (productList.length > 0) {
        for (let i = 0; i < productList.length; i++) {
          formData.append(`products[${i}]`, productList[i]?.id);
        }
      } else {
        formData.append(`products`, '');
      }

      setLoading(true);

      const endPoint = id ? `seller/shop-collections/${id}?_method=PUT` : 'seller/shop-collections';

      const response = await AxiosAll('post', endPoint, formData);
      if (response.statusCode === 200) {
        notify(response.message, 'success');
        route('/onboarding/site-editor/product-collection');
        setLoading(false);
      } else {
        notify(response.message, 'error');
        setLoading(false);
      }
    }
  };

  const getProducts = async () => {
    const response = await AxiosAll('get', `seller/products?&search=${search || ''}`, null);
    if (response?.statusCode === 200) {
      const responseData = response?.data?.data;
      // notify(response.message, 'success');

      setProduct(responseData);
    } else {
      // notify(response.message, 'error');
    }
  };

  const getCollectionDetails = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/shop-collections/${id}`, null);
    if (response.statusCode === 200) {
      const collectionData = response.data;
      formik.setFieldValue('title', collectionData?.title || '');
      formik.setFieldValue('description', collectionData?.description || '');
      // formik.setFieldValue('isTrending', collectionData?.is_trending || '');
      // formik.setFieldValue('isFeatured', collectionData?.is_featured || '');
      formik.setFieldValue('metaTitle', collectionData?.seo_meta_title || '');
      formik.setFieldValue('metaDescription', collectionData?.seo_meta_description || '');
      formik.setFieldValue('metaKeywords', collectionData?.seo_meta_keywords || '');
      setMetaImage(collectionData?.seo_meta_image || '');
      setBannerImage(collectionData?.banner_image || '');
      setBanners({
        desktopBanner: collectionData?.banner_image_web || '',
        tabletBanner: collectionData?.banner_image_tablet || '',
        mobileBanner: collectionData?.banner_image_mobile || '',
      });
      setCoverImage(collectionData?.cover_image || '');
      setFeaturedImage(collectionData?.featured_image || '');
      const selectedId = collectionData?.shop_collection_product?.map((item) => item?.id);
      setExistingProducts(selectedId || []);
      setSelectedProduct((prev) => [...prev, ...selectedId]);
      setProductList(collectionData?.shop_collection_product || []);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    if (e?.target?.value) {
      setSearchText(e?.target?.value);
    } else {
      setSearchText('');
    }
  };

  useEffect(() => {
    if (search !== '') {
      getProducts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  const handleDelete = (productId) => {
    const filteredProduct = productList.filter((item) => item?.id !== productId);
    const filteredProductIds = selectedProduct.filter((item) => item !== productId);
    setSelectedProduct(filteredProductIds);
    setExistingProducts(filteredProductIds);
    setProductList(filteredProduct);
    setOpenDelete(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getCollectionDetails();
    }
  }, []);

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...productList];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);

    setProductList(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  useEffect(() => {
    if (productList?.length) {
      setProduct([]);
    }
  }, [productList]);

  return (
    <div className="site-product-section">
      <Loader open={loading} />
      <BannerCard title="Product Collection" para={para} backgroundImageURL={images.dynamicProductsImg} />
      <div className="main-section container-27inch">
        <BlueBtn
          title="Return"
          customClass="return-btn"
          onClick={() => route('/onboarding/site-editor/product-collection')}
        />
        <div className="trending-detail">
          <div className="search-engine-section">
            <Typography variant="h4">Section Details</Typography>
            <Typography paragraph className="description">
              These details will populate the featured products section on your website.
            </Typography>
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Enter"
                    name="title"
                    value={formik?.values?.title}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.title && formik?.errors?.title}
                  />
                </div>
                <div className="meta-des">
                  <Typography>Description</Typography>
                  <div className="product-section-editor">
                    <Editor
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      placeholder="Description"
                      name="description"
                      value={formik.values.description}
                      error={formik.touched.description && formik.errors.description}
                      onChange={(e) => {
                        formik.setFieldValue('description', e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UploadSingleImage
          title="Cover Image"
          description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
          image={coverImage}
          setImage={setCoverImage}
          imageSize={2}
        />
        <UploadSingleImage
          title="Featured Image"
          description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
          image={featuredImage}
          setImage={setFeaturedImage}
          imageSize={2}
        />

        <div className="padding-left">
          <div className="add-product">
            <Typography>Add Products</Typography>
          </div>
          <div className="product-gallery">
            <Autocomplete
              options={product?.map((prediction) => prediction?.name)}
              noOptionsText="No Products Found"
              inputValue={searchText}
              onInputChange={handleSearch}
              renderInput={(params) => <TextField {...params} label="Add Custom Products" variant="outlined" />}
              renderOption={(option) => <div>{option}</div>}
              getOptionSelected={(option, value) => option === value}
              filterSelectedOptions={true}
              onChange={(event, value) => {
                if (value) {
                  const selectedPrediction = product?.find((prediction) => prediction?.name === value);

                  if (selectedPrediction) {
                    const isDuplicate = productList?.some((product) => product?.id === selectedPrediction.id);

                    if (isDuplicate) {
                      notify('This product has already been added', 'error');
                      return;
                    }
                    const updatedTopPicks = [...productList, selectedPrediction];

                    setProductList(updatedTopPicks);
                  }
                }
              }}
            />{' '}
            {productList?.map((productImg, index) => {
              return (
                <div className="img-container" key={productImg?.id}>
                  <label className="label-file" htmlFor={`myCheckbox${index}`}>
                    <ProductsCard
                      dragStart={(e) => handleDragStart(e, index)}
                      dragOver={(e) => handleDragOver(e, index)}
                      dragEnd={() => setDraggedOverIndex(null)}
                      dropCard={(e) => handleDrop(e, index)}
                      image={productImg?.cover_image?.image}
                      name={productImg?.name}
                      copyUrl={productImg?.banner_image}
                      id={productImg?.id}
                      isImages
                    />
                  </label>
                  {/* <Checkbox
                        className="checkbox-style"
                        {...Label}
                        id={`myCheckbox${index}`}
                        checkedIcon={<CheckCircleIcon />}
                        checked={!!selectedProductIds?.ids?.includes(productImg?.id)}
                        value={productImg?.id}
                        onClick={(e) => handleCheckbox(productImg?.id, e)}
                        icon={<RadioButtonUncheckedIcon />}
                      /> */}

                  {productList?.length > 0 ? (
                    <DeleteIcon
                      onClick={() => {
                        setOpenDelete(true);
                        setDeleteId(productImg?.id);
                      }}
                    />
                  ) : null}
                </div>
              );
            })}
            <Dialog
              open={openDelete}
              onClose={() => {
                setOpenDelete(false);
                setDeleteId(null);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="delete-pop-up"
            >
              <div className="delete-container">
                <Typography variant="h2">Delete</Typography>
                <Typography paragraph>Are you sure you want to delete this product?</Typography>
              </div>
              <DialogActions>
                <BlueBtn title="Yes" onClick={() => handleDelete(deleteId)} />
                <LightBtn
                  title="No"
                  onClick={() => {
                    setOpenDelete(false);
                    setDeleteId(null);
                  }}
                />
              </DialogActions>
            </Dialog>
            {/* <CardPagination
                page={page}
                noOfPages={productImages?.last_page}
                handleChange={(e, value) => setPage(value)}
                rowsPerPage={productImages?.per_page}
              /> */}
          </div>
          {formik?.touched?.product && formik?.errors?.product && (
            <div className="input-error-message">{formik?.errors?.product}</div>
          )}
        </div>

        <div className="search-engine-section">
          <Typography variant="h4">Search Engine Optimisation</Typography>
          <Typography paragraph className="description">
            Product SEO is the practice of optimising elements of product to provide search engines with enough relevant
            content to get the product found in search engines.
          </Typography>

          <div className="input-title">
            <div className="meta-info">
              <div className="meta-title">
                <Typography>Meta Title</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="metaTitle"
                  value={formik?.values?.metaTitle}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.metaTitle && formik?.errors?.metaTitle}
                />
              </div>
              <div className="meta-des">
                <Typography>Meta Description</Typography>
                <TextArea
                  placeholder="Enter"
                  name="metaDescription"
                  value={formik?.values?.metaDescription}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.metaDescription && formik?.errors?.metaDescription}
                />
              </div>
              <div className="meta-keywords">
                <Typography>Meta Keywords</Typography>
                <TextArea
                  placeholder="Enter"
                  name="metaKeywords"
                  value={formik?.values?.metaKeywords}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.metaKeywords && formik?.errors?.metaKeywords}
                />
              </div>
              <div className="singleUpload">
                <UploadSingleImage
                  title="Meta Image"
                  description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
                  image={metaImage}
                  setImage={setMetaImage}
                  imageSize={2}
                />
              </div>
            </div>
          </div>
        </div>
        <UploadSingleImage
          title="Banner Image"
          description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
          image={bannerImage}
          setImage={setBannerImage}
          imageSize={2}
        />

        <UploadDraggableContainer
          dec={para}
          imagePreview={images.sitePreview}
          imageTrash={images.trashBtn}
          banners={banners}
          setBanners={setBanners}
          imageSize={2}
        />

        <div className="buttons">
          <div className="buttons-position">
            <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor/product-collection')} />
            <BlueBtn
              title="Save"
              type="submit"
              onClick={() => formik.handleSubmit()}
              loading={loading}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
