// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import Pagination from '@material-ui/lab/Pagination';
import { useEffect } from 'react';

const CardPagination = ({ page, noOfPages, handleChange, rowsPerPage }) => {
  useEffect(() => {
    window?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [page]);

  return (
    <div className="pagination">
      {/* <div>Items per page :{rowsPerPage}</div> */}

      <Pagination count={noOfPages} page={page} onChange={handleChange} defaultPage={1} size="small" />
    </div>
  );
};

export default CardPagination;
