import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import BannerCard from '../../../components/universal/WelcomeCard';
import { AxiosAll } from '../../../services/axiosAll';
import { notify } from '../../../core/constants';
import { ShippingTable } from '../../../components/SiteEditorTable/ComonTable/ShippingTable';
import AddCourier from '../../../components/dialogs/AddCourier';
import { Loader } from '../../../components/Skeleton/Loader';

export const ShippingList = () => {
  const route = useNavigate();
  const [ShippingSerivceList, setShippingList] = useState([]);
  const [loading, setloading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);

  const [total, setTotal] = useState(0);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getShippingList = async () => {
    setloading(true);
    const response = await AxiosAll('get', 'seller/shippingCouriers?per_page=20', null);
    if (response.statusCode === 200) {
      setShippingList(response.data.data);
      setTotal(response.data.total);
      setloading(false);
    } else {
      notify(response.message, 'error');
      setloading(false);
    }
  };

  useEffect(() => {
    getShippingList();
  }, []);

  const AddNewAddCourier = async (value, id) => {
    // setisLoading(true);
    const response = await AxiosAll(
      id === 0 ? 'post' : 'put',
      id === 0 ? 'seller/shippingCouriers' : `seller/shippingCouriers/${id}`,
      {
        name: value,
        status: 1,
      },
      null,
    );
    if (response.statusCode === 200) {
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }

    getShippingList();
  };

  const EditStatus = async (value, data) => {
    // setisLoading(true);
    const response = await AxiosAll(
      'put',
      `seller/shippingCouriers/${data?.id}`,
      {
        name: data?.name,
        status: value,
      },
      null,
    );

    getShippingList();
  };
  return (
    <div className="shipping">
      <Loader open={loading} />
      <BannerCard title="Shipping" para={para} smallCard />
      <div className="container-27inch">
        <div className="shipping-buttons">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route(-1)} />
          <AddCourier AddNewAddCourier={AddNewAddCourier} customClass="shipping-pop" />
        </div>
        {loading ? (
          'Loading...'
        ) : (
          <div className="site-table">
            <ShippingTable
              ShippingList={ShippingSerivceList}
              getShippingList={getShippingList}
              AddNewAddCourier={AddNewAddCourier}
              EditStatus={EditStatus}
            />
          </div>
        )}
      </div>
    </div>
  );
};
