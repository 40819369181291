import Skeleton from '@mui/material/Skeleton';

export default function ProductSkeleton({ type }) {
  return (
    <div className="productskeleton">
      {/* <div className='titletext'>
                <Skeleton variant="text" className='maintitle' />
            </div> */}
      {type === 2 ? (
        <div className="searchbtnsection">
          <div className="btnsection">
            <Skeleton variant="rectangular" className="search" />
            <Skeleton variant="rectangular" className="btn" />
          </div>
          <Skeleton variant="rectangular" className="btn" />
        </div>
      ) : (
        <div className="boxsection">
          <div className="productimgbox">
            <Skeleton variant="rectangular" className="gboxes" />
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="text" className="price" />
          </div>
          <div className="productimgbox">
            <Skeleton variant="rectangular" className="gboxes" />
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="text" className="price" />
          </div>
          <div className="productimgbox">
            <Skeleton variant="rectangular" className="gboxes" />
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="text" className="price" />
          </div>
          <div className="productimgbox">
            <Skeleton variant="rectangular" className="gboxes" />
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="text" className="price" />
          </div>
          <div className="productimgbox">
            <Skeleton variant="rectangular" className="gboxes" />
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="text" className="price" />
          </div>
          <div className="productimgbox">
            <Skeleton variant="rectangular" className="gboxes" />
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="text" className="price" />
          </div>
          <div className="productimgbox">
            <Skeleton variant="rectangular" className="gboxes" />
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="text" className="price" />
          </div>
          <div className="productimgbox">
            <Skeleton variant="rectangular" className="gboxes" />
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="text" className="price" />
          </div>
        </div>
      )}
    </div>
  );
}
