import { images } from '../../../assets';

export const Empty = ({ message, type }) => {
  return (
    <div className="empty-img">
      <img src={type === 2 ? images?.verify : images?.emptyBasket} alt="verify" />
      <div className="chart-text">{message || 'No data available'}</div>
    </div>
  );
};
