import { Typography } from '@mui/material';
import React from 'react';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import TextArea from '../../universal/InputType/TextArea';

export const SignupLetterInput = ({
  newsTitle,
  newsDes,
  inputTitle,
  inputPlaceholder,
  desTitle,
  desPlaceholder,
  callActionTitle,
  callActionPlaceholder,
  buttonLabel,
  buttonLabelPlaceholder,
  formik,
}) => {
  return (
    <div className="Signup-Letter-section">
      <Typography variant="h4">{newsTitle}</Typography>
      <Typography paragraph className="description">
        {newsDes}
      </Typography>

      <div className="input-title">
        <div className="input-info">
          <div className="input-section">
            <Typography>{inputTitle}</Typography>
            <InputTypeOutline
              placeholder={inputPlaceholder}
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && formik.errors.title}
            />
          </div>
          <div className="des-section">
            <Typography>{desTitle}</Typography>
            <TextArea
              placeholder={desPlaceholder}
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && formik.errors.description}
            />
          </div>
          <div className="call-action">
            <Typography>{callActionTitle}</Typography>
            <InputTypeOutline
              placeholder={callActionPlaceholder}
              name="action"
              value={formik.values.action}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.action && formik.errors.action}
            />
          </div>
          <div className="button-label">
            <Typography>{buttonLabel}</Typography>
            <InputTypeOutline
              placeholder={buttonLabelPlaceholder}
              name="label"
              value={formik.values.label}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.label && formik.errors.label}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
