import { Checkbox, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { images } from '../../assets';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import ProgressBar from '../../components/universal/ProgressBar';
import { notify } from '../../core/constants';
import { AxiosAll } from '../../services/axiosAll';

const BusinessProductDetails = () => {
  const [userDetails, setUserDetails] = useState('');
  const [sellerData, setSellerData] = useState({});
  const [registeredUser, setRegisteredUser] = useState(1);
  const [count, setCount] = useState(5);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const nav = useNavigate();
  const { id } = useParams();
  const { token } = useParams();

  const getUserData = async () => {
    try {
      const res = await AxiosAll('get', 'seller-profile', null, token);
      const user = res?.data?.user;
      setUserDetails(user);
      setSellerData(res?.data);
      setRegisteredUser(user?.stripe_sub_is_first_time);
      if (res?.data?.user?.stripe_sub_is_first_time !== 1) {
        const timer = setInterval(() => {
          setCount((prev) => prev - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    setLoading(true);
    try {
      const res = await AxiosAll('get', `dropdowns?type[]=seller_onboarding_sections`, null, `${token}`);
      if (res?.statusCode === 200) {
        setCategories(res?.data?.seller_onboarding_sections);
        const allIds = res?.data?.seller_onboarding_sections?.map((item) => item?.id);
        setSelectedIds(allIds);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategorySelection = async (data) => {
    const index = selectedIds.indexOf(data?.id);
    if (index > -1) {
      selectedIds.splice(index, 1);
      setSelectedIds([...selectedIds]);
    } else {
      setSelectedIds([...selectedIds, data?.id]);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const res = await AxiosAll('post', `seller/sitePreferences`, { preferences: selectedIds }, `${token}`);
      if (res?.statusCode === 200) {
        notify(res?.message, 'success');
        nav(
          userDetails?.stripe_sub_is_first_time === 2
            ? `/subscription/${id}/${token}?isNew=${userDetails?.stripe_sub_is_first_time}`
            : `/subscription/${id}/${token}`,
        );
        setSubmitting(false);
      } else {
        notify(res?.message, 'error');
        setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (count <= 0 && sellerData?.user?.stripe_sub_is_first_time !== 1) {
      nav(
        `/subscription/${sellerData?.user?.id}/${sellerData?.user_token}?isNew=${sellerData?.user?.stripe_sub_is_first_time}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, sellerData]);

  useEffect(() => {
    getUserData();
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ProgressBar value={85} />
      {registeredUser !== 1 ? (
        <div className="verifyyouremail">
          <div>You are already registered!</div>
          <div className="text-para">You will be redirected to subscription page in {count} seconds</div>
        </div>
      ) : (
        <div className="business-product-details">
          <div className="left-arrow-new">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              src={images.arrowBack}
              alt="arrow"
              onClick={() => nav(`/business-details/${id}/${token}`)}
              className="arrow-back"
            />
            {/* <WestIcon onClick={() => nav(`/business-details/${id}/${token}`)} /> */}
            <div className="main-heading">What do you want to add to your website?</div>
            <div />
          </div>
          <div className="recommended-para">Recommended for you</div>
          <div className="product-img-section">
            <div className="img-section">
              {loading
                ? [...Array(12).keys()].map((item, index) => {
                    return (
                      <div key={index} className="img-container">
                        <Skeleton variant="rectangular" width={210} height={118} className="skeleton-img" />
                      </div>
                    );
                  })
                : categories?.map((item, index) => {
                    return (
                      <div key={index} className="img-container">
                        <div className="productscard">
                          <img src={images.blueBgImg} className="product-img " alt="product" />
                          <p className="product-heading">{item?.name}</p>
                          <p className="product-para">{item?.description}</p>
                        </div>
                        <Checkbox
                          checked={selectedIds.includes(item?.id)}
                          className="checkbox-style"
                          onClick={() => handleCategorySelection(item)}
                        />
                      </div>
                    );
                  })}
            </div>
            <div className="btn-section">
              <div>
                <LightBtn title="Back" type="button" onClick={() => nav(`/business-details/${id}/${token}`)} />
              </div>
              <div>
                <DarkBtn
                  title="Continue"
                  loading={submitting}
                  onClick={handleSubmit}
                  disable={selectedIds.length === 0 || submitting}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BusinessProductDetails;
