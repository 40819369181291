import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { InputLabel } from '@mui/material';
import BlackBtn from '../../components/universal/Buttons/BlackBtn';
import ExclusiveBlogs from '../../components/universal/ExclusiveBlogs';
import InputType from '../../components/universal/InputType';
import { notify } from '../../core/constants';
import { updateGuestUser } from '../../redux/actions/guestUser';
import { addLogin } from '../../redux/actions/login';
import { AxiosAll } from '../../services/axiosAll';

const Login = () => {
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const [Loading, setLoading] = React.useState(false);
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const searchParams = new URLSearchParams(window?.location?.search);
  const dispatch = useDispatch();
  const nav = useNavigate();

  const loginCall = async (data) => {
    try {
      const response = await AxiosAll('post', `seller/login`, data);
      if (response.statusCode === 200) {
        if (response?.data?.user?.is_email_verified) {
          formik.handleReset();
          if (response?.data?.user?.type == 1) {
            nav('/admin/user-registration');
            notify(response.message, 'success');
            dispatch(
              addLogin({
                token: response?.data?.user_token,
                user: response?.data?.user,
              }),
            );
            dispatch(
              updateGuestUser({
                user: response?.data?.user,
                userToken: response?.data?.user_token,
              }),
            );
            setLoading(false);
          } else if (
            response?.data?.user?.is_profile_completed === 0 ||
            (response?.data?.user?.stripe_sub_status !== 'active' &&
              response?.data.user.stripe_sub_status !== 'trialing')
          ) {
            incompleteProfileNav(response?.data);
            setLoading(false);
          } else if (
            searchParams?.get('token') ||
            (response?.data?.user?.isProSeller && !response?.data?.user?.isProSellerProfileComplete)
          ) {
            nav(`/store-details/${response?.data?.user?.id}/${response?.data?.user_token}`);
            setLoading(false);
          } else {
            nav('/onboarding/dashboard');
            notify(response.message, 'success');
            dispatch(
              addLogin({
                token: response?.data?.user_token,
                user: response?.data?.user,
              }),
            );

            dispatch(
              updateGuestUser({
                user: response?.data?.user,
                userToken: response?.data?.user_token,
              }),
            );
            setLoading(false);
          }
        } else if (!response?.data?.user?.is_email_verified) {
          // resendVerification(
          //   response?.result?.user?.id,
          //   response?.result?.user?.email,
          //   response?.result?.userToken
          // );
          notify(response?.message, 'error');
          nav(`/verify-email/${response?.data?.user?.id}/${response?.data?.user_token}`);
        }
      } else {
        notify(response?.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      // notify(error?.response?.message, "error");
    }
  };

  const incompleteProfileNav = async (data) => {
    console.log('data.user.is_profile_completed', data.user.is_profile_completed);
    console.log('data.user.stripe_sub_status', data.user.is_profile_completed, data.user.stripe_sub_status);
    switch (
      data.user.is_profile_completed === 0 ||
      (data.user.stripe_sub_status !== 'active' && data.user.stripe_sub_status !== 'trialing')
    ) {
      case data?.user?.profile_completed_step === 1:
        nav(`/store-details/${data?.user?.id}/${data?.user_token}`);
        break;
      case data?.user?.profile_completed_step === 2:
        nav(`/policy-details/${data?.user?.id}/${data?.user_token}`);
        break;
      case data?.user?.profile_completed_step === 3:
        nav(`/business-details/${data?.user?.id}/${data?.user_token}`);
        break;
      case data?.user?.profile_completed_step === 4:
        nav(`/business-product-details/${data?.user?.id}/${data?.user_token}`);
        break;
      case data?.user?.profile_completed_step === 5:
        nav(`/subscription/${data?.user?.id}/${data?.user_token}?isNew=${data?.user?.stripe_sub_is_first_time}`);
        break;

      default:
        nav('/onboarding/dashboard');
        break;
    }
  };

  // const resendVerification = async (id, emailId, token) => {
  //   try {
  //     const res = await AxiosAll("post", `user/resend-link?`, { email: emailId }, `${token}`);
  //     notify(res.data.message, "success");
  //     nav(`/verify-email/${id}/${token}`);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // formik start
  const initialValues = {
    email: '',
    password: '',
  };
  const validationSchema = Yup.object({
    email: Yup.string().required('E-mail address is required.').email('Enter valid email address'),
    password: Yup.string().required('Password is required.'),
  });
  const onSubmit = (values) => {
    setLoading(true);
    loginCall(
      searchParams?.get('token')
        ? {
            email: values.email,
            password: values.password,
            deviceToken: guestDetails.token,
            deviceType: 'web',
            // guest_user_id: userData?.user?.id,
            proSellerToken: searchParams?.get('token'),
          }
        : {
            email: values.email,
            password: values.password,
            device_token: guestDetails.token,
            device_type: 'web',
            // guest_user_id: userData?.user?.id,
          },
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="loginpage">
      <div className="login-full-container">
        <div className="main-heading">Login</div>
        <div className="login-para">Welcome back! Let's get you signed in</div>
      </div>
      <form onSubmit={formik.handleSubmit} className="form-box box-border">
        <div className="inner-box">
          <InputLabel className="input-lable-text">Email</InputLabel>
          <InputType
            name="email"
            id="email"
            type="email"
            placeholder="Email Address"
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="inner-box">
          <InputLabel className="input-lable-text">Password</InputLabel>
          <InputType
            password
            name="password"
            id="password"
            type={isPasswordShow ? 'password' : 'text'}
            placeholder="Password"
            onBlur={formik.handleBlur}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && formik.errors.password}
            isPasswordShow={isPasswordShow}
            setIsPasswordShow={setIsPasswordShow}
          />
        </div>
        <div className="inner-box" style={{ textAlign: 'center' }}>
          <BlackBtn type="submit" loading={Loading} disabled={Loading} title="Login" />

          <Link to="/forgot-password" className="forgot-password">
            <span>Forget Password?</span>
          </Link>
        </div>
      </form>

      {/* <ExclusiveBlogs /> */}
    </div>
  );
};

export default Login;
