import { Container } from '@mui/material';
import React from 'react';
import CancelBtn from '../../components/universal/Buttons/CancelBtn';
import DeliveredBtn from '../../components/universal/Buttons/DeliveredBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import OutOfStock from '../../components/universal/Buttons/OutOfStock';
import PendingBtn from '../../components/universal/Buttons/PendingBtn';
import DashboardChart from '../../components/universal/DashboardChart';
import DatePicker from '../../components/universal/DatePicker';
import DropDown from '../../components/universal/DropDown';
import CopyIcon from '../../components/universal/Icons/CopyIcon';
import DeleteIcons from '../../components/universal/Icons/DeleteIcons';
import EditIcons from '../../components/universal/Icons/EditIcons';
import OnOffSwitch from '../../components/universal/OnOffSwitch';
import SearchBar from '../../components/universal/SearchBar';
import sweatAlertBox from '../../components/universal/SweetAlertBox';

const Home = () => {
  return (
    <>
      <Container>
        {/* <DarkBtn /> */}
        {/* <LightBtn /> */}
        {/* <BlackBtn /> */}
        {/* <UnpublishBtn /> */}
        <OutOfStock />
        <PendingBtn />
        <CancelBtn />
        <DeliveredBtn />
        <SearchBar />
        {/* <HideShowIcon /> */}
        <CopyIcon />
        <DeleteIcons />
        <EditIcons />
        <DropDown />
        <OnOffSwitch />
        {/* <TagSelection /> */}
        <DatePicker />

        <LightBtn
          title="Cancel Subscription"
          className="cancel-subscription"
          onClick={
            () =>
              sweatAlertBox(
                'Cancel Subscription',
                'You are sure you want to cancel this product from your subscription?',
                'Confirm',
                'Cancel',
              )
            // setIdToDel(Detail?.subscription?.subscriptionId)
          }
        />
      </Container>
      <DashboardChart />
    </>
  );
};

export default Home;
