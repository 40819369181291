import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { images } from '../../../assets';
import DashboardSkeleton from '../../../components/Skeleton/DashboardSkeleton';
import WelcomeAdminDialog from '../../../components/dialogs/WelcomeAdminDashboard';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import DashboardCharts from '../../../components/universal/DashboardChart_v2';
import { Empty } from '../../../components/universal/Empty';
import MainHeading from '../../../components/universal/Headings/MainHeading';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { deleteLogin } from '../../../redux/actions/login';
import { addUser, deleteUser } from '../../../redux/actions/user';
import { AxiosAll } from '../../../services/axiosAll';

const Dashboard = () => {
  const { userData } = useSelector((state) => state.guestUserReducer);

  const { userDetails } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const [userinfo, setuserinfo] = useState({});
  const [deshboardDetail, setDeshboardDetail] = useState();
  const [chart, setChart] = useState();
  const [Analyticschart, setAnalyticsChart] = useState();
  const [showChart, setshowChart] = useState(false);
  const categories = chart?.sales_per_month_line_chart?.grossSale?.map((e) => e.key);
  const orderMonths = chart?.order_quantity_per_month?.map((e) => e.name);
  const ordersINMonth = chart?.order_quantity_per_month?.map((e) => e.data);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadinginfo, setloadinginfo] = useState(true);
  const [loadingChart, setloadingChart] = useState(true);
  const [loadingAnalyticsChart, setloadingAnalyticsChart] = useState(true);
  const query = window.location.search;
  const urlParam = new URLSearchParams(query);
  const first = urlParam.get('first');
  const nav = useNavigate();
  const location = useLocation();

  const barChartData = orderMonths?.map((e, i) => {
    return {
      name: e,
      data: ordersINMonth[i],
    };
  });
  // console.log(barChartData);
  const months = Analyticschart?.product_page_visit?.map((e) => e.name);
  const pageView = Analyticschart?.product_page_visit?.map((e) => e.data);
  const barChartProductViewData = months?.map((e, i) => {
    return {
      name: e,
      data: pageView[i],
    };
  });
  const email = urlParam.get('email');

  useEffect(() => {
    if (userDetails?.token && email && email !== userData?.user?.email) {
      dispatch(deleteLogin());
      dispatch(deleteUser());
      nav('/login');
    }
    // eslint-disable-next-line
  }, []);

  const userDetailsCall = async () => {
    try {
      const res = await AxiosAll('get', 'seller/profile', null, userDetails?.token);
      const user = res?.data;
      if (res?.data?.is_email_verified) {
        setuserinfo(res?.data);
        if (res?.data?.id) {
          getDeshboard(res?.data?.id);
          getChart();
          getAnalyticChart();
        }
        dispatch(addUser({ user: res?.data }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDeshboard = async (id) => {
    const response = await AxiosAll(
      'get',
      'seller/dashboard?types=let_complete_your_store_data,summary_and_listing',
      null,
      userDetails?.token,
    );

    setDeshboardDetail(response.data);
    setloadinginfo(false);
  };

  const getChart = async (id) => {
    const responce = await AxiosAll(
      'get',
      'seller/dashboard?types=sales_per_month_line_chart,order_quantity_per_month',
      null,
      userDetails?.token,
    );
    setChart(responce.data);
    setloadingChart(false);
  };

  const getAnalyticChart = async () => {
    const responce = await AxiosAll(
      'get',
      'seller/dashboard?types=website_visit,product_page_visit',
      null,
      userDetails?.token,
    );
    setTimeout(() => {
      setshowChart(true);
    }, 1000);
    setAnalyticsChart(responce.data);
    setloadingAnalyticsChart(false);
  };

  useEffect(() => {
    userDetailsCall();
  }, [location.pathname]);

  useEffect(() => {
    // eslint-disable-next-line no-debugger
    if (first === '1') {
      setDialogOpen(true);
    }
  }, [window.location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="seller-dashboard">
      <WelcomeAdminDialog dialogOpen={dialogOpen} />
      <BannerCard
        title={`Welcome ${first === '1' ? '' : 'back'}, ${userData?.user?.name?.split(' ')?.slice(0, -1)?.join(' ')}`}
        para="Here you can configure your website using our preset website sections, at any time
      you can view your work by clicking on the ‘view website’."
        smallCard
      />
      <Container className="all-container-27inch">
        {loadinginfo ? (
          <DashboardSkeleton type={1} />
        ) : (
          <>
            <div className="heading-row">
              <a
                href={`https://${userData?.user?.store?.domain_slug}.dev.sell-stream.skunktest.work`}
                target="_blank"
                rel="noreferrer"
              >
                <MainHeading title="View your website" />
              </a>
              <div className="usericon">
                <img
                  src={userData?.user?.store?.store_logo ? userData?.user?.store?.store_logo : images?.defaultUser}
                  className="store-img"
                  alt="store"
                />
              </div>
            </div>
            <div className="complete-section your-store-new">
              <div className="store-heading weight-600">Let's complete your store</div>
              <div className="store-row">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="step-one-text">Step one</div>
                      <div className="box-title">Add bank account</div>
                      {deshboardDetail?.let_complete_your_store_data?.isBankAccountAdded ? (
                        <img src={images?.completeIcon} className="completed-icon" alt="complete" />
                      ) : (
                        <DarkBtn
                          onClick={() => nav('/onboarding/my-profile', { state: { tab: 1 } })}
                          title="Complete"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="step-one-text">Step two </div>
                      <div className="box-title">Configure your site</div>
                      {deshboardDetail?.let_complete_your_store_data?.isStoreCofigured ? (
                        <img src={images.completeIcon} className="completed-icon" alt="complete" />
                      ) : (
                        <DarkBtn
                          onClick={() => nav('/onboarding/my-profile', { state: { tab: 0 } })}
                          title="Complete"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="step-one-text">Step three</div>
                      <div className="box-title">List your first Item</div>
                      {deshboardDetail?.let_complete_your_store_data?.isListedProducts ? (
                        <img src={images.completeIcon} className="completed-icon" alt="complete" />
                      ) : (
                        <DarkBtn onClick={() => nav('/onboarding/add-single-product')} title="Complete" />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="step-one-text"> Step four </div>
                      <div className="box-title">Optimise for SEO</div>
                      {deshboardDetail?.let_complete_your_store_data?.IsSEOCompleted ? (
                        <img src={images.completeIcon} className="completed-icon" alt="complete" />
                      ) : (
                        <DarkBtn title="Complete" onClick={() => nav('/onboarding/site-editor')} />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="complete-section summery-section">
              <div className="store-heading summery-eading-new weight-600">Summary</div>
              <div className="store-row">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading price">
                        {deshboardDetail?.summary_and_listing?.totalCustomer || 0}
                      </div>
                      <div className="box-title">Total Customers</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading price">
                        {' '}
                        {deshboardDetail?.summary_and_listing?.orderProcessing}
                      </div>
                      <div className="box-title">Processing Orders</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading price">{deshboardDetail?.summary_and_listing?.noOfOrders}</div>
                      <div className="box-title">Total Orders</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading price">
                        £ {deshboardDetail?.summary_and_listing?.orderItemPriceSum}
                      </div>
                      <div className="box-title">Total Revenue</div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )}

        <div className="graph-section">
          <Grid container spacing={2}>
            {loadingChart ? (
              <DashboardSkeleton type={2} />
            ) : (
              <>
                {' '}
                <Grid item xs={12} sm={6} className="firstgraph-color">
                  <div className="grid-box-n graph-n">
                    <div className="text-view">
                      <div className="store-heading weight-600">Sales per month</div>
                      <Link to="/onboarding/financial-reports">
                        {' '}
                        <div className="box-title textunderline">View all</div>
                      </Link>
                    </div>
                    {chart ? (
                      <DashboardCharts
                        name="Sales per month"
                        data={chart?.sales_per_month_line_chart?.grossSale?.map((e) => e.value)}
                        categories={categories}
                        type="line"
                      />
                    ) : (
                      <Empty message="Product sales will show here" />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className="secondgraph-color">
                  <div
                    className={
                      deshboardDetail?.summary_and_listing?.noOfOrders ? 'grid-box-n graph-n' : 'grid-box-n graph-bar'
                    }
                  >
                    <div className="text-view">
                      <div className="store-heading weight-600">Order quantity per month</div>
                      <Link to="/onboarding/orders">
                        <div className="box-title textunderline">View all</div>
                      </Link>
                    </div>
                    {barChartData?.length > 0 ? (
                      <DashboardCharts barChartData={barChartData} categories={categories} type="bar" />
                    ) : (
                      <Empty type={2} message="Quantity of orders will show here" />
                    )}
                  </div>
                </Grid>
              </>
            )}
            {loadingAnalyticsChart ? (
              <DashboardSkeleton type={2} />
            ) : (
              <>
                <Grid item xs={12} sm={6} className="firstgraph-color">
                  <div className="grid-box-n graph-n">
                    <div className="text-view">
                      <div className="store-heading weight-600">Website Visits</div>
                      <Link to="/onboarding/financial-reports">
                        {' '}
                        <div className="box-title textunderline">View all</div>
                      </Link>
                    </div>
                    {Analyticschart?.website_visit ? null : <Empty message="Website Visits will show here" />}
                    <DashboardCharts
                      name="Website Visits"
                      data={Analyticschart?.website_visit?.map((e) => e.value)}
                      categories={categories}
                      type="line"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className="secondgraph-color">
                  <div
                    className={
                      deshboardDetail?.summary_and_listing?.noOfOrders
                        ? 'grid-box-n graph-n-page-visit'
                        : 'grid-box-n graph-bar'
                    }
                  >
                    <div className="text-view">
                      <div className="store-heading weight-600">Product Page Visits</div>
                      <Link to="/onboarding/orders">
                        <div className="box-title textunderline">View all</div>
                      </Link>
                    </div>
                    {/* {console.log('data', Analyticschart?.product_page_visit)}
                    {console.log('barChartProductViewData', barChartProductViewData)} */}
                    {Analyticschart?.product_page_visit?.length > 0 && showChart ? (
                      <DashboardCharts
                        barChartData={Analyticschart?.product_page_visit}
                        categories={categories}
                        type="bar"
                      />
                    ) : (
                      <Empty type={2} message="Product Page Visits will show here" />
                    )}
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <div className="grid-box-n">
                <div className="store-heading marginbott">Listings</div>
                <div className="text-view-repat" style={{ borderBottom: '2px solid #D8D4CF' }}>
                  <div className="text-view">
                    <img src={images.lock} alt="lock" className="lock-img" />
                    <div className="box-title">Active listings</div>
                  </div>
                  <div className="store-heading sidepadding">
                    {deshboardDetail?.summary_and_listing?.inStockProducts}
                  </div>
                </div>
                <div className="text-view-repat outofstock-text">
                  <div className="text-view">
                    <HourglassEmptyIcon />
                    <div className="box-title">Out of stock</div>
                  </div>
                  <div className="store-heading sidepadding">
                    {deshboardDetail?.summary_and_listing?.outOfStockProducts}
                  </div>
                </div>
              </div>
            </Grid>
            {deshboardDetail?.let_complete_your_store_data?.isBankAccountAdded == 0 ? (
              <Grid item xs={12} sm={6}>
                <div className="grid-box-bank">
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <div className="bank-heading">Add your bank account</div>
                      <div className="bankpara"> Let's add your bank account so you can get paid for your sales. </div>
                      <DarkBtn
                        onClick={() => nav('/onboarding/my-profile', { state: { tab: 1 } })}
                        title="View details"
                      />{' '}
                    </Grid>
                    <Grid item xs={4}>
                      <img src={images.dashboardSide} className="bankdetailsimg" alt="bank details" />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
