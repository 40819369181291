import React, { useState } from 'react';

const FileUpload = ({ onChange, forId, id, accept, title }) => {
  const [file, setFile] = useState(null);

  return (
    <div className="fileuploadsection">
      <div className="file-section">
        <button type="button" className="attach-btn">
          <label htmlFor={forId || 'files'}>
            {title} <span className="plusicon">+</span>
          </label>
        </button>
        <input
          id={id || 'files'}
          className="input-type-file-upload"
          type="file"
          accept={accept}
          onChange={(e) => {
            setFile(e.target.files[0]);
            onChange(e);
          }}
        />
      </div>
    </div>
  );
};

export default FileUpload;
