const TextArea = ({ placeholder, name, value, onBlur, onChange, error, props, topClass }) => {
  return (
    <div className={`${topClass} main-textarea`}>
      <textarea
        className="input-textarea"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      {error && <div className="input-error-message minus-margin">{error}</div>}
    </div>
  );
};

export default TextArea;
