import { Container, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import DropDown from '../../../components/universal/DropDown';
import CodeInputBox from '../../../components/universal/InputType/CodeInputBox';
import { AxiosAll } from '../../../services/axiosAll';
import { notify } from '../../../core/constants';

const BulkUpload = () => {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [tag, setTag] = useState();
  const [tagValue, setTagValue] = useState('266');
  const [tagColor, setTagColor] = useState();
  const [tagChildren, setTagChildren] = useState();
  const [tagValueChildren, setTagValueChildren] = useState();
  const [masterCategory, setMasterCategory] = useState([]);
  const [mainCategory, setMainCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedMasterCategory, setSelectedMasterCategory] = useState('0');
  const [masterCategoryArr, setMasterCategoryArr] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState('0');
  const [selectedSubCategory, setSelectedSubCategory] = useState('0');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [shippingTypeOptions, setShippingTypeOptions] = useState([]);
  const [shippinType, setShippingType] = useState('0');
  const [productConditionType, setProducConstiontType] = useState('0');
  const [productType, setProductType] = useState('0');

  // const [shippingType, setShippingType] = useState('0');

  const [tagChildrenSize, setTagChildrenSize] = useState();
  const [tagChildrenSizeValue, setTagChildrenSizeValue] = useState();

  const [color, setColor] = useState();
  const [colorValue, setColorValue] = useState('0');

  const [badge, setBadges] = useState();
  const [badgeValue, setBadgesValue] = useState('0');

  const [countrie, setCountries] = useState();
  const [countrieValue, setCountriesValue] = useState('0');

  const [couriers, setCouriers] = useState();
  const [couriersValue, setCourierValues] = useState('0');

  const [courierClass, setCourierClasses] = useState();
  const [couriersClassValue, setCourierClassValues] = useState('0');

  const [processingTime, setProcessingTime] = useState();
  const [processingTimeValue, setProcessingTimeValue] = useState('0');
  const hiddenFileInput = React.useRef(null);

  const [productConditionValue, setProductConditionValue] = useState('');
  const navigate = useNavigate();
  const getDropdowns = async () => {
    const types = [
      'categories',
      'shipping_types',
      'product_types',
      'badges',
      'shipping_processing_times',
      'product_conditions',
      'shipping_countries',
      'shipping_couriers',
      'sizes',
      'colors',
      'shipping_types',
    ];

    const response = await AxiosAll(
      'get',
      `dropdowns?type[]=${types.join('&type[]=')}&seller_id=${userDetails?.user?.id}`,
    );

    setMasterCategory(response?.data?.categories?.filter((e) => e?.childrens?.length > 0));
    setProductTypeOptions(response?.data?.product_types);
    setShippingTypeOptions(response?.data?.shipping_types);
    setConditionOptions(response?.data?.product_conditions);
    setBadges(response?.data?.badges);
    setCountries(response?.data?.shipping_countries);
    setProcessingTime(response?.data?.shipping_processing_times);
    setCouriers(response?.data?.shipping_couriers);
    setColor(response?.data?.colors);
    setTagChildrenSize(response?.data?.sizes);
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  const uploadCSV = async (fileUploaded) => {
    if (fileUploaded) {
      const formData = new FormData();
      formData.append('csv_file', fileUploaded);

      try {
        const response = await AxiosAll('post', `seller/productBulkUpload`, formData);
        if (response?.statusCode === 200) {
          navigate('/onboarding/products');
        } else {
          notify(response.message, 'error');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUserMenual = () => {
    window.open('https://prod.goethical.com/doc/dashboard-user-manual.pdf', '_blank');
  };

  useEffect(() => {
    const mainCategoryData = masterCategory?.find((item) => item.id === parseInt(selectedMasterCategory, 10));
    setMainCategory(mainCategoryData?.childrens?.filter((e) => e?.childrens?.length > 0));
  }, [selectedMasterCategory, masterCategory]);

  useEffect(() => {
    const subCategoryData = mainCategory?.find((item) => item.id === parseInt(selectedMainCategory, 10));
    setSubCategory(subCategoryData?.childrens);
  }, [selectedMainCategory, mainCategory]);

  const handleTagChange = (e) => {
    setSelectedMasterCategory(e.target.value);
    setSelectedMainCategory('0');
    setMasterCategoryArr((prev) => [...prev, e.target.value]);
  };

  const handlemainTagChildrenChange = (e) => {
    setSelectedMainCategory(e.target.value);
    setSelectedSubCategory('0');
  };
  const handleTagChildrenChange = (e) => {
    const { value } = e.target;
    setSelectedSubCategory(value);
  };
  const handleshippineChange = (e) => {
    setShippingType(e.target.value);
  };

  const handleColorChange = (e) => {
    const { value } = e.target;
    setColorValue(value);
  };
  const handleBadgeChange = (e) => {
    const { value } = e.target;
    setBadgesValue(value);
  };

  const handleCountrieChange = (e) => {
    const { value } = e.target;
    setCountriesValue(value);
  };

  const handleProcessingTimeChange = (e) => {
    const { value } = e.target;
    setProcessingTimeValue(value);
  };

  const handleCourierValuesChange = (e) => {
    const { value } = e.target;
    setCourierValues(value);
  };

  const handleCourierClassChange = (e) => {
    const { value } = e.target;
    setCourierClassValues(value);
  };

  const handleTagSizeChange = (e) => {
    const { value } = e.target;
    setTagChildrenSizeValue(value);
  };

  const handleProductConditionChange = (e) => {
    const { value } = e.target;
    setProducConstiontType(value);
  };

  const handleProductTypeChange = (e) => {
    const { value } = e.target;
    setProductType(value);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    if (event.target.files[0]) {
      const fileUploaded = event.target.files[0];
      uploadCSV(fileUploaded);
    }
  };
  useEffect(() => {
    const TagChildrenData = tag?.filter((e) => e.id === tagValue);
    const tagChildrendataResult = TagChildrenData ? TagChildrenData[0]?.children : [];
    setTagColor(tagChildrendataResult[0]?.isColorAvailable);
    setTagChildren(tagChildrendataResult);
    const tagChildrendataResultValue = tagChildrendataResult[0]?.id;
    setTagValueChildren(tagChildrendataResultValue);
  }, [tagValue, tag]);

  useEffect(() => {
    if (setTagValueChildren && tag && tagValue && tagValueChildren) {
      const tagSizeData = tagChildren?.filter((e) => e.id === tagValueChildren);
      const tegsizeResult = tagSizeData[0]?.tagSizes ? tagSizeData[0]?.tagSizes : [];
      setTagChildrenSize(tegsizeResult);
      const courierClassResultValue = tegsizeResult[0]?.id;
      setTagChildrenSizeValue(courierClassResultValue);
    }
  }, [tagChildren, tag, tagValue, tagValueChildren]);

  useEffect(() => {
    if (couriersValue !== '0' && couriers?.length) {
      const courierClassData = couriers?.filter((e) => e.id === couriersValue);
      const courierClassResult = courierClassData ? courierClassData[0]?.courier_classes : [];
      setCourierClasses(courierClassResult);
      const courierClassResultValue = courierClassResult[0]?.id;
      setCourierClassValues(courierClassResultValue);
    }
  }, [couriersValue, couriers]);

  return (
    <div className="bulkuploadscreen">
      <Container className="all-container-27inch">
        <div className="bulk-upload-heading">Bulk upload (CSV)</div>
        <div className="bulk-para">
          <p>
            {' '}
            Before continuing please download our{' '}
            <span
              tabIndex={0}
              role="button"
              onClick={() =>
                window.open(
                  `https://api.dev.sell-stream.skunktest.work/assets/sample_files/bulk_upload_sample.csv`,
                  '_blank',
                )
              }
            >
              CSV template
            </span>{' '}
            and{' '}
            <span tabIndex={0} role="button" onClick={handleUserMenual}>
              user manual
            </span>
            . In particular, careful attention needs to be paid to mandatory fields and fixed data options, such as
            category, colour and size.
          </p>
        </div>
        <div className="bulk-para">
          <p>
            Images will need to be separately uploaded and in the{' '}
            <span tabIndex={0} role="button" onClick={() => navigate('/onboarding/product-images')}>
              Product Images
            </span>{' '}
            page and referenced in your CSV.
          </p>
        </div>
        <div className="bulk-para">
          <p>
            For technical support, please contact{' '}
            <span tabIndex={0} role="button" onClick={() => window.top.open('mailto:supportgoethical@gmai.com')}>
              support@sellstream.com
            </span>
          </p>
        </div>
        <div className="box-container">
          <div className="btn-section">
            <LightBtn
              title="Download CSV template"
              onClick={() =>
                window.open(
                  `https://api.dev.sell-stream.skunktest.work/assets/sample_files/bulk_upload_sample.csv`,
                  '_blank',
                )
              }
            />
            <DarkBtn for="files" title="Upload CSV" onClick={handleClick} />
            <input
              type="file"
              ref={hiddenFileInput}
              accept=".csv"
              onChange={handleChange}
              style={{ display: 'none' }}
            />
          </div>

          <div className="bulkbody-container">
            <div className="product-heading">Finding your product codes</div>
            <div className="bulk-para">
              <p>
                {' '}
                When adding product categories, sizes and colours you need to refernece codes defined in our system. Use
                the searches below to find the relevant codes for your products.{' '}
              </p>
            </div>
            <div className="form-heading">Category & Size</div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search product category codes</div>
                <div className="dropdown-section">
                  <DropDown option={masterCategory} handleChange={handleTagChange} value={selectedMasterCategory} />
                  {mainCategory?.length > 0 && (
                    <DropDown
                      option={mainCategory}
                      handleChange={handlemainTagChildrenChange}
                      value={`${selectedMainCategory}`}
                    />
                  )}
                  {subCategory?.length > 0 && (
                    <DropDown
                      option={subCategory}
                      handleChange={handleTagChildrenChange}
                      value={`${selectedSubCategory}`}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={selectedSubCategory} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Sizes</div>

                <DropDown
                  option={tagChildrenSize}
                  handleChange={handleTagSizeChange}
                  value={`${tagChildrenSizeValue}`}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={tagChildrenSizeValue} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="form-heading">Colour</div>
                <div className="search">Search colours</div>
                <div className="dropdown-section-color">
                  <DropDown
                    placholder="Product Colour"
                    option={color}
                    handleChange={handleColorChange}
                    value={colorValue}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={colorValue} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Product condition</div>
                <DropDown
                  placholder="Product condition"
                  option={conditionOptions}
                  handleChange={handleProductConditionChange}
                  value={productConditionType}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox productCondition code={productConditionType} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Product Value</div>
                <DropDown
                  placholder="Product Value"
                  option={badge}
                  handleChange={handleBadgeChange}
                  value={`${badgeValue}`}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={badgeValue} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Product Type</div>
                <DropDown
                  placholder="Product Type"
                  option={productTypeOptions}
                  handleChange={handleProductTypeChange}
                  value={`${productType}`}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={productType} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Dispatching from</div>
                <DropDown
                  placholder="Country you are dispatching from"
                  option={countrie}
                  handleChange={handleCountrieChange}
                  value={countrieValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={countrieValue} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Dispatching Type</div>
                <DropDown
                  placholder="Search Dispatching Type"
                  option={shippingTypeOptions}
                  handleChange={handleshippineChange}
                  value={shippinType}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={shippinType} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Processing time</div>
                <DropDown
                  placholder="Select Processig Time"
                  option={processingTime}
                  handleChange={handleProcessingTimeChange}
                  value={processingTimeValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={processingTimeValue} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Delivery courier</div>
                <DropDown
                  placholder="Select Delivery Courier"
                  option={couriers}
                  handleChange={handleCourierValuesChange}
                  value={couriersValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={couriersValue} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Delivery class</div>
                <DropDown
                  placholder=" Select Delivery Class"
                  option={courierClass}
                  handleChange={handleCourierClassChange}
                  value={couriersClassValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={couriersClassValue} />
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BulkUpload;
