import { DELETE_GUEST_USER, SET_GUEST_USER, UPDATE_GUEST_USER } from '../constant';

export const addGuestUser = (userData) => {
  return { type: SET_GUEST_USER, data: userData };
};

export const updateGuestUser = (userData) => {
  return { type: UPDATE_GUEST_USER, data: userData };
};

export const deleteGuestUser = () => {
  return { type: DELETE_GUEST_USER, data: {} };
};
