import { DialogActions, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { images } from '../../../assets';
import { AxiosAll } from '../../../services/axiosAll';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import CardPagination from '../../universal/CardPagination';
import SubHeading from '../../universal/Headings/SubHeading';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import ProductsCard from '../../universal/ProductsCard';
import UploadImage from '../UploadImage';

const WelcomeAdminDialog = ({ getSelectedImage }) => {
  const [copyText, setCopyText] = useState('Copy image link to clipboard');
  const [open, setOpen] = useState(false);
  const [gallery, setGallery] = useState({});
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState('');
  const [uploadImage, setUploadImage] = useState([]);
  const [imageSize, setImageSize] = useState();

  const { userData } = useSelector((state) => state.guestUserReducer);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage('');
  };

  const getGallery = async () => {
    const response = await AxiosAll(
      'get',
      `seller/galleryImages?search=${search || ''}&page=${page}&per_page=20`,
      null,
      userData?.userToken,
    );
    setGallery(response.data);
  };

  const UploadMultiImage = async () => {
    const formData = new FormData();
    for (let index = 0; index < uploadImage.length; index++) {
      const element = uploadImage[index];
      formData.append(`images[${index}][name]`, element?.editName ? element?.editName : element?.name);
      formData.append(`images[${index}][alt_tag]`, element?.editName ? element?.editName : element?.name);
      formData.append(`images[${index}][file]`, element);

      // eslint-disable-next-line no-await-in-loop
      const response = await AxiosAll('post', 'seller/galleryImages', formData, userData?.userToken);
      if (response?.statusCode === 200) {
        setGallery(response?.data);
      }
    }
    getGallery();
    setUploadImage([]);
  };

  useEffect(() => {
    const result = uploadImage.reduce((sum, item) => {
      // eslint-disable-next-line no-multi-assign, no-param-reassign
      const newLocal = (sum += item.size);
      return newLocal;
    }, 0);
    setImageSize(result);
  }, [uploadImage]);

  useEffect(() => {
    getGallery();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);
  // console.log('fhirgrg', gallery);
  return (
    // <div>
    <>
      {' '}
      <u tabIndex={0} role="button" onClick={handleClickOpen} className="cursorpointer">
        click here{' '}
      </u>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="medialibrary"
      >
        <DialogContent>
          <SubHeading title="Select from media library" />
          <div className="btn-section">
            <InputTypeOutline
              placeholder="Search image"
              name="imageSearch"
              type="text"
              onChange={(e) => {
                setPage(1);
                setSearchText(e.target.value);
              }}
            />
            <UploadImage
              uploadImage={uploadImage}
              setUploadImage={setUploadImage}
              UploadMultiImage={UploadMultiImage}
              imageSize={imageSize}
            />
          </div>
          {gallery?.data?.map((item, index) => {
            const imageFormat = item?.url?.split('.')?.pop();
            return (
              <div className="img-container" key={index}>
                <div key={index}>
                  <input
                    type="radio"
                    name="check"
                    id={`myCheckbox${index}`}
                    value={item.id}
                    onChange={(e) => setSelectedImage(e.target.value)}
                  />
                  <label className="label-file" htmlFor={`myCheckbox${index}`}>
                    <ProductsCard
                      // name={`${item?.name} (${imageFormat})`}
                      image={item?.image}
                    />
                  </label>
                </div>
                <div className="product-text-section">
                  <div className="product-text">{item?.name}</div>
                  {/* {type === 'add_product' ? null : ( */}
                  <Tooltip
                    title={copyText}
                    placement="right"
                    onClick={() => {
                      // eslint-disable-next-line no-undef
                      navigator.clipboard.writeText(item?.image);
                      setCopyText('Copied to clipboard');
                      setTimeout(() => {
                        setCopyText('Copy image link to clipboard');
                      }, 2000);
                    }}
                  >
                    <img src={images.copy} className="copy-icon_new" alt="copy" />
                  </Tooltip>
                  {/* )}  */}
                </div>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <CardPagination
            page={page}
            noOfPages={gallery?.last_page}
            handleChange={(e, value) => setPage(value)}
            rowsPerPage={gallery?.perPage}
          />
          <div className="lastbtn">
            <LightBtn title="Cancel" onClick={handleClose} />
            <DarkBtn
              title="Add"
              onClick={() => {
                if (selectedImage) {
                  const data = gallery?.data?.find((item) => {
                    if (item.id === parseInt(selectedImage, 10)) {
                      return item;
                    }
                    return null;
                  });
                  getSelectedImage(data);
                  handleClose();
                  setSelectedImage('');
                }
              }}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
    // </div>
  );
};

export default WelcomeAdminDialog;
