// import LightBtn from '../../components/universal/Buttons/LightBtn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import InputTypeOutline from '../../components/universal/InputType/inputTypeOutline';
import ProgressBar from '../../components/universal/ProgressBar';
import { baseUrl, notify } from '../../core/constants';
import { store } from '../../redux/store';
import { axiosAll, AxiosAll } from '../../services/axiosAll';

const CreateAccount = () => {
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] = React.useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);

  const nav = useNavigate();

  const params = useParams();

  // const [ProsellerInfo, setProsellerInfo] = useState({});

  // const getProSellerDetails = async () => {
  //   const response = await axiosAll({
  //     method: "get",
  //     url: `v2/pro_seller_requests-by-token/${params?.token}`,
  //     params: "",
  //   });
  //   if (response?.statusCode === 200) {
  //     setProsellerInfo(response?.result);
  //     formik?.setFieldValue(
  //       "name",
  //       response?.result?.proSeller?.firstName +
  //         " " +
  //         response?.result?.proSeller?.lastName
  //     );
  //     formik?.setFieldValue("email", response?.result?.proSeller?.email);
  //     formik?.setFieldValue("password", "");
  //   }
  //   // setLoading(false);
  // };
  // useEffect(() => {
  //   getProSellerDetails();
  // }, [params?.token]);

  // const CreatePassword = async (data, token) => {
  //   setLoading(true);
  //   try {
  //     const res = await AxiosAll("post", `user/createPassword?`, data, token);
  //     if (res.data.status === "Error") {
  //       setLoading(false);
  //       notify(res.data.message, "error");
  //     } else {
  //       setLoading(false);
  //       notify(res.data.message, "success");
  //     }
  //   } catch (error) {
  //     setLoading(false);

  //     console.log(error);
  //   }
  // };

  // const CreateProSeller = async () => {
  //   const state = store.getState();
  //   const { guestDetails } = state.guestReducer;
  //   try {
  //     const res = await axios.put(
  //       `${baseUrl}pro_seller_requests/${params?.token}/status?deviceType=3&deviceToken=${guestDetails?.token}`,
  //       { status: 2 }
  //     );
  //     if (res.data.status === "Error") {
  //       setLoading(false);
  //       notify(res.data.message, "error");
  //     } else {
  //       setLoading(false);
  //       notify(res.data.message, "success");
  //     }
  //   } catch (error) {
  //     setLoading(false);

  //     console.log(error);
  //   }
  // };

  const CreteAccountSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await AxiosAll(
        'post',
        'seller/register',
        {
          email: data?.email,
          password: data?.password,
        },
        null,
      );
      if (res?.statusCode === 200) {
        notify(res?.message, 'success');
        nav(`/verify-email/${res?.data?.user?.id}/${res?.data?.user_token}`);
        setLoading(false);
      } else {
        notify(res?.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // formik start
  const initialValues = {
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Enter valid email address')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid email address',
      )
      .required('Email is required')
      .trim(),
    confirmEmail: Yup.string()
      .required('Re-enter the e-mail address.')
      .email('Enter valid email address')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid email address',
      )
      .oneOf([Yup.ref('email'), null], 'E-mail address must match'),
    password: Yup.string().required('Password is required.'),
    confirmPassword: Yup.string()
      .required('Re-enter the password')
      .oneOf([Yup.ref('password'), null], 'Password must match'),
  });

  const onSubmit = (values) => {
    if (
      !(
        values.password.length > 7 &&
        pattern.test(values.password) &&
        (pattern2.test(values.password) || pattterNo.test(values?.password))
      )
    ) {
      notify('Please fill the password correctly', 'error');
    } else {
      CreteAccountSubmit(
        params?.token
          ? {
              email: values?.email,
              proSellerToken: params?.token,
            }
          : {
              email: values?.email,
              password: values?.password,
            },
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (
      formik?.values?.email &&
      formik?.values?.password &&
      formik?.values?.confirmEmail &&
      formik?.values?.confirmPassword
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [formik?.values]);

  let pattern = /^(?=.*[A-Z])/;
  let pattern2 = /(?=.*[-+_!@#$%^&*., ?])/;
  let pattterNo = /^(?=.*[0-9])/;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <ProgressBar value={15} />
      <div className="createaccount">
        <div className="text-heading">Create your account</div>
        <div className="text-para">Let's start by creating an account</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mrgbottom">
            <div className="label-name">Email</div>
            <InputTypeOutline
              name="email"
              id="email"
              type="name"
              placeholder="Email address"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && formik.errors.email}
            />
          </div>
          <div className="mrgbottom">
            <div className="label-name">Re-Enter Email</div>
            <InputTypeOutline
              name="confirmEmail"
              id="confirmEmail"
              type="email"
              placeholder="Re-Enter Email address"
              onBlur={formik.handleBlur}
              value={formik.values.confirmEmail}
              onChange={formik.handleChange}
              error={formik.touched.confirmEmail && formik.errors.confirmEmail}
            />
          </div>

          <div className="mrgbottom">
            <div className="label-name">Password</div>
            <InputTypeOutline
              password
              name="password"
              id="password"
              type={isPasswordShow ? 'password' : 'text'}
              placeholder="Password"
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && formik.errors.password}
              isPasswordShow={isPasswordShow}
              setIsPasswordShow={setIsPasswordShow}
            />
          </div>
          <div className="mrgbottom">
            <div className="label-name">Re-Enter Password</div>
            <InputTypeOutline
              password
              name="confirmPassword"
              id="confirmPassword"
              type={isConfirmPasswordShow ? 'password' : 'text'}
              placeholder="Re-Enter Password"
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && formik.errors.confirmPassword}
              isPasswordShow={isConfirmPasswordShow}
              setIsPasswordShow={setIsConfirmPasswordShow}
            />
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckCircleIcon />}
                  icon={<RadioButtonUncheckedIcon />}
                  checked={formik?.values.password.length > 7}
                />
              }
              disabled={!(formik?.values.password.length > 7)}
              label="Must be at least 8 character long"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckCircleIcon />}
                  icon={<RadioButtonUncheckedIcon />}
                  checked={!!(pattern2.test(formik?.values.password) || pattterNo.test(formik?.values?.password))}
                />
              }
              disabled={!(pattern2.test(formik?.values.password) || pattterNo.test(formik?.values?.password))}
              label="Contains at least one number or symbol"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckCircleIcon />}
                  icon={<RadioButtonUncheckedIcon />}
                  checked={!!pattern.test(formik?.values.password)}
                />
              }
              disabled={!pattern.test(formik?.values.password)}
              label="Have one uppercase letter"
            />
          </FormGroup>
          <div className="btn-end">
            <DarkBtn title="Continue" loading={loading} type="submit" disable={disableButton || loading} />
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateAccount;
