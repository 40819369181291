import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { ColorsTable } from '../../../components/SiteEditorTable/ComonTable/colorsTable';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import CardPagination from '../../../components/universal/CardPagination';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import SearchBar from '../../../components/universal/SearchBar';
import { images } from '../../../assets';
import { Loader } from '../../../components/Skeleton/Loader';

export const ColorList = () => {
  const route = useNavigate();
  const [loading, setLoading] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [itemData, setItemData] = useState({});
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getItemList = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/colors?per_page=${limit}&page=${page}&search=${search || ''}`, null);
    if (response.statusCode === 200) {
      setItemList(response?.data?.data);
      setItemData(response?.data);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
    setPage(1);
  };

  useEffect(() => {
    if (page > itemData?.last_page) {
      setPage(itemData?.last_page);
    }
  }, [itemData?.last_page, page]);

  useEffect(() => {
    getItemList();
  }, [page, limit, search]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  return (
    <div className="site-color-list">
      <Loader open={loading} />

      <BannerCard title="Colours" para={para} smallCard backgroundImageURL={images.latestArrivalImg} />
      <div className="container-27inch">
        <div className="color-btn">
          <div className="internal-color-btn">
            <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
            <BlueBtn
              title="Add New Colour"
              onClick={() => route('/onboarding/site-editor/add-colors')}
              customClass="collection-btn"
            />
          </div>
          <SearchBar handleChange={handleSearch} value={searchText} />
        </div>
        <div className="site-table">
          <ColorsTable itemList={itemList} getItemList={getItemList} />
        </div>
        {itemData?.total >= 12 && (
          <CardPagination
            noOfPages={itemData?.last_page}
            page={page}
            handleChange={handlePagination}
            rowsPerPage={limit}
          />
        )}
      </div>
    </div>
  );
};
