/* eslint-disable import/named */
import { Navigate, Route, Routes } from 'react-router';
import SellerSideBar from '../components/SellerSide/SellerSideBar';
import BrandWithCause from '../pages/Admin/BrandWithCause';
import Categories from '../pages/Admin/Categories';
import FeaturedSection from '../pages/Admin/FeaturedSection';
import { EnhancedTable as PopularCategory } from '../pages/Admin/PopularCategory';
import SellerAdmin from '../pages/Admin/SellerAdmin';
import BusinessDetails from '../pages/BusinessDetails';
import CreateAccount from '../pages/CreateAccount';
import Home from '../pages/Home';
import PolicyDetails from '../pages/PolicyDetails';
import ProSellerRegistration from '../pages/ProSellerRegistration';
import ResetPassword from '../pages/ResetPassword';
import Seller from '../pages/Seller';
import Dashboard from '../pages/Seller/Dashboard';
import EditSingleProduct from '../pages/Seller/EditSingleProduct';
import FinancialReports from '../pages/Seller/FinancialReports';
import { MyProfile } from '../pages/Seller/MyProfile';
import Orders from '../pages/Seller/Orders';
import ProductImages from '../pages/Seller/ProductImages';
import Products from '../pages/Seller/Products';
import SellerOrderDetails from '../pages/Seller/SellerOrderDetails';

import { AddThemeBaseContant } from '../pages/AddThemeBaseContant';
import { CategoriesHeader } from '../pages/Categories';
import { DynamicProduct } from '../pages/DynamicProduct';
import ErrorCase from '../pages/Error';
import { LatestArrival } from '../pages/LatestArrival';
import { NewsLetter } from '../pages/NewsLetter';
import { ProductBadgesList } from '../pages/ProductBadges';
import { AddBlogs } from '../pages/Seller/AddBlog';
import { AddBlogSection } from '../pages/Seller/AddBlogSection';
import { AddColors } from '../pages/Seller/AddColor';
import { AddCustomProducts } from '../pages/Seller/AddCustomProducts';
import { AddFeatureProducts } from '../pages/Seller/AddFeatureProducts';
import { AddInstagramSection } from '../pages/Seller/AddInstagramSection';
import { AddLinks } from '../pages/Seller/AddLink';
import { AddPopularProducts } from '../pages/Seller/AddPopularProducts';
import { AddProductBadges } from '../pages/Seller/AddProductBadges';
import { AddProductCollection } from '../pages/Seller/AddProductCollection';
import { AddProductCollectionSection } from '../pages/Seller/AddProductCollectionSection';
import { AddSizes } from '../pages/Seller/AddSize';
import { AddTestimonials } from '../pages/Seller/AddTestimonial';
import { AddTestimonialSection } from '../pages/Seller/AddTestimonialSection';
import { AddBanner } from '../pages/Seller/BannerSection/AddBanner';
import { BannerList } from '../pages/Seller/BannerSection/BannerList';
import { BlogList } from '../pages/Seller/BlogList';
import BulkUpload from '../pages/Seller/BulkUpload';
import CancelSubscription from '../pages/Seller/CancelSubscription';
import { ColorList } from '../pages/Seller/ColorList';
import { AddDynamicBlockBanner } from '../pages/Seller/CommonBanners/DynamicBlockBanner/AddDynamicBlockBanner';
import { DynamicBlockBannerList } from '../pages/Seller/CommonBanners/DynamicBlockBanner/DynamicBlockBannerList';
import { AddFeatureProductBanners } from '../pages/Seller/CommonBanners/FeatureProductBanner/AddFeatureProductBanner';
import { FeatureProductBannerList } from '../pages/Seller/CommonBanners/FeatureProductBanner/FeatureProductBannerList';
import { AddLatestArrivalBanner } from '../pages/Seller/CommonBanners/LatestArrivalBanner/AddLatestArrivalBannner';
import { LatestArrivalBannerList } from '../pages/Seller/CommonBanners/LatestArrivalBanner/LatestArrivalBannerList';
import { ProductBanners } from '../pages/Seller/CommonBanners/ProductBanner/AddProductBanners';
import { ProductBannerList } from '../pages/Seller/CommonBanners/ProductBanner/ProductBannerList';
import { SellerBanners } from '../pages/Seller/CommonBanners/SellerBanner/AddSellerBanners';
import { SellerBannerList } from '../pages/Seller/CommonBanners/SellerBanner/SellerBannerList';
import { AddThemBaseBannner } from '../pages/Seller/CommonBanners/ThemBaseBannner/AddThemBaseBannner';
import { ThemBaseBannnerList } from '../pages/Seller/CommonBanners/ThemBaseBannner/ThemBaseBannnerList';
import { TrendingBanners } from '../pages/Seller/CommonBanners/TrendingBanner/AddTrendingBanners';
import { TrendingBannerList } from '../pages/Seller/CommonBanners/TrendingBanner/TrendingBannerList';
import { CountryList } from '../pages/Seller/CountryLIst';
import { Feedbacks } from '../pages/Seller/Feedbacks';
import { Help } from '../pages/Seller/Help';
import { Instagram } from '../pages/Seller/Instagram';
import { Links } from '../pages/Seller/Links';
import NewAddSingleProduct from '../pages/Seller/NewAddSingleProduct';
import OrderInvoice from '../pages/Seller/OrderInvoice';
import { ProcessingTime } from '../pages/Seller/ProcessingTime';
import { ProductCollection } from '../pages/Seller/ProductCollection';
import { RightImageLeftContent } from '../pages/Seller/RightImageLeftContent';
import { ShopByValue } from '../pages/Seller/ShopByValue';
import { ShippingClassList } from '../pages/Seller/ShoppingClassList';
import { ShippingList } from '../pages/Seller/ShoppingList';
import { SizeList } from '../pages/Seller/SizeList';
import { Testimonials } from '../pages/Seller/Tesimonials';
import { SignupLetter } from '../pages/SingupLetter';
import { SiteEditor } from '../pages/SiteEditor';
import StoreDetails from '../pages/StoreDetails/indes';
import StripVerification from '../pages/StripVerification';
import { SubCategoriesAdd } from '../pages/SubCategories';
import { SubChildCategorieAdd } from '../pages/SubChildCategories';
import { ThemeBanner } from '../pages/ThemeBanner';
import { TopBanner } from '../pages/TopBanner';
import { TrendingProduct } from '../pages/TrendingProduct';
import Users from '../pages/User/UserList';
import VerifyYourEmail from '../pages/VerifyYourEmail';
import ViewDetails from '../pages/ViewDetails';
import Welcome from '../pages/Welcome';
import { WebsiteFooter } from '../pages/websiteFooter';

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/onboarding/dashboard" replace />} />
      <Route path="*" element={<Dashboard />} />
      <Route path="invalid-store" element={<ErrorCase />} />
      <Route path="login" element={<Navigate to="/onboarding/dashboard" replace />} />
      <Route path="onboarding" element={<SellerSideBar />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="help" element={<Help />} />
        <Route path="home" element={<Home />} />
        <Route path="seller" element={<Seller />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="create-account" element={<CreateAccount />} />
        <Route path="verify-email" element={<VerifyYourEmail />} />
        <Route path="store-details" element={<StoreDetails />} />
        <Route path="welcome" element={<Welcome />} />
        <Route path="policy-details" element={<PolicyDetails />} />
        <Route path="business-details" element={<BusinessDetails />} />
        <Route path="pro-seller-registration" element={<ProSellerRegistration />} />
        <Route path="categories" element={<Categories />} />
        <Route path="view-details" element={<ViewDetails />} />
        <Route path="popular-category" element={<PopularCategory />} />
        <Route path="brand-with-cause" element={<BrandWithCause />} />
        <Route path="products" element={<Products />} />
        <Route path="product-images" element={<ProductImages />} />
        <Route path="seller-admin" element={<SellerAdmin />} />
        <Route path="featured-section" element={<FeaturedSection />} />
        {/* <Route path="add-single-product" element={<AddSingleProduct />} /> */}
        <Route path="add-single-product" element={<NewAddSingleProduct />} />
        <Route path="bulk-upload" element={<BulkUpload />} />
        <Route path="seller/edit-product/:title/:id" element={<EditSingleProduct />} />
        <Route path="orders" element={<Orders />} />
        <Route path="users" element={<Users />} />

        <Route path="seller/order/details/:id" element={<SellerOrderDetails />} />
        <Route path="seller/view-finance-detail/reports/:id" element={<SellerOrderDetails />} />
        <Route path="my-profile" element={<MyProfile />} />
        <Route path="my-profile/subscription" element={<CancelSubscription />} />

        <Route path="financial-reports" element={<FinancialReports />} />
        <Route path="site-editor/theme-base-content" element={<AddThemeBaseContant />} />
        <Route path="site-editor/theme-base-content/:id" element={<AddThemeBaseContant />} />

        {/* new page route */}
        <Route path="site-editor" element={<SiteEditor />} />
        {/* small messed up in this pages */}
        <Route path="site-editor/theme-banner" element={<ThemeBanner />} />
        <Route path="site-editor/shop-by-value" element={<ShopByValue />} />
        <Route path="site-editor/shop-by-value/:id" element={<ShopByValue />} />

        <Route path="site-editor/top-bar" element={<TopBanner />} />
        <Route path="site-editor/top-bar/:id" element={<TopBanner />} />

        {/* <Route path="site-editor/product-section" element={<ProductSection />} /> */}
        <Route path="site-editor/feedbacks" element={<Feedbacks />} />
        <Route path="site-editor/shippings" element={<ShippingList />} />
        <Route path="site-editor/shippings/processing-time" element={<ProcessingTime />} />
        <Route path="site-editor/shippings/countries" element={<CountryList />} />
        <Route path="site-editor/shippings/classes/:id" element={<ShippingClassList />} />

        <Route path="site-editor/blogs-list" element={<BlogList />} />
        <Route path="site-editor/add-blogs" element={<AddBlogs />} />
        <Route path="site-editor/edit-blog/:id" element={<AddBlogs />} />

        <Route path="site-editor/sizes" element={<SizeList />} />
        <Route path="site-editor/add-sizes" element={<AddSizes />} />
        <Route path="site-editor/edit-size/:id" element={<AddSizes />} />

        <Route path="site-editor/colors" element={<ColorList />} />
        <Route path="site-editor/add-colors" element={<AddColors />} />
        <Route path="site-editor/edit-color/:id" element={<AddColors />} />

        <Route path="site-editor/latest-arrival" element={<LatestArrival />} />
        <Route path="site-editor/latest-arrival/:id" element={<LatestArrival />} />

        <Route path="site-editor/dynamic-content-block" element={<DynamicProduct />} />
        <Route path="site-editor/dynamic-content-block/:id" element={<DynamicProduct />} />
        {/* <Route path="site-editor/tell-your-story" element={<TellYourStory />} /> */}

        <Route path="site-editor/newsletter-related-blog-ideas-and-personalised-tips" element={<NewsLetter />} />
        <Route path="site-editor/newsletter-related-blog-ideas-and-personalised-tips/:id" element={<NewsLetter />} />

        <Route path="site-editor/signup-to-newsletter" element={<SignupLetter />} />
        <Route path="site-editor/signup-to-newsletter/:id" element={<SignupLetter />} />

        <Route path="site-editor/footer" element={<WebsiteFooter />} />
        <Route path="site-editor/footer/:id" element={<WebsiteFooter />} />

        <Route path="site-editor/feature-products" element={<AddFeatureProducts />} />
        <Route path="site-editor/popular-products" element={<AddPopularProducts />} />

        <Route path="site-editor/trending-product" element={<TrendingProduct />} />
        <Route path="site-editor/trending-product/:id" element={<TrendingProduct />} />

        <Route path="site-editor/product-collection" element={<ProductCollection />} />
        <Route path="site-editor/add-product-collection" element={<AddProductCollection />} />
        <Route path="site-editor/product-collection/:id" element={<AddProductCollection />} />

        <Route path="site-editor/product-badges" element={<ProductBadgesList />} />
        <Route path="site-editor/add-product-badges" element={<AddProductBadges />} />
        <Route path="site-editor/add-product-badges/:id" element={<AddProductBadges />} />

        {/* <Route path="site-editor/banners" element={<BannerList />} /> */}
        <Route path="site-editor/banners/:id" element={<BannerList />} />
        <Route path="site-editor/banner-with-multiple-image" element={<AddBanner />} />
        <Route path="site-editor/banner-with-multiple-image/:id" element={<AddBanner />} />

        <Route path="site-editor/blogs" element={<AddBlogSection />} />
        <Route path="site-editor/blogs/:id" element={<AddBlogSection />} />

        {/* <Route path="site-editor/instagram-post" element={<AddInstagramSection />} />
        <Route path="site-editor/instagram-post/:id" element={<AddInstagramSection />} /> */}

        <Route path="site-editor/shop-collection" element={<AddProductCollectionSection />} />
        <Route path="site-editor/shop-collection/:id" element={<AddProductCollectionSection />} />

        <Route path="site-editor/testimonial" element={<AddTestimonialSection />} />
        <Route path="site-editor/testimonial/:id" element={<AddTestimonialSection />} />

        <Route path="site-editor/right-image-left-content" element={<RightImageLeftContent />} />
        <Route path="site-editor/right-image-left-content/:id" element={<RightImageLeftContent />} />

        {/* <Route path="site-editor/instagram" element={<Instagram />} /> */}

        <Route path="site-editor/testimonial-list" element={<Testimonials />} />
        <Route path="site-editor/add-testimonial" element={<AddTestimonials />} />
        <Route path="site-editor/edit-testimonial/:id" element={<AddTestimonials />} />

        <Route path="site-editor/links" element={<Links />} />
        <Route path="site-editor/add-links" element={<AddLinks />} />
        <Route path="site-editor/edit-link/:id" element={<AddLinks />} />

        <Route path="site-editor/custom-products" element={<AddCustomProducts />} />
        <Route path="site-editor/custom-products/:id" element={<AddCustomProducts />} />

        <Route path="site-editor/categories-header" element={<CategoriesHeader />} />
        <Route path="site-editor/sub-categories-add/:id" element={<SubCategoriesAdd />} />
        <Route path="site-editor/sub-categories-child-add/:id" element={<SubChildCategorieAdd />} />

        {/* Common Banners Section */}

        <Route path="site-editor/seller_banners" element={<SellerBannerList />} />
        <Route path="site-editor/add_seller_banners" element={<SellerBanners />} />
        <Route path="site-editor/edit_seller_banners/:id" element={<SellerBanners />} />

        <Route path="site-editor/feature-product-banners" element={<FeatureProductBannerList />} />
        <Route path="site-editor/add-feature-product-banners" element={<AddFeatureProductBanners />} />
        <Route path="site-editor/edit-feature-product-banners/:id" element={<AddFeatureProductBanners />} />

        <Route path="site-editor/theme-based-content-banners" element={<ThemBaseBannnerList />} />
        <Route path="site-editor/add-theme-based-content-banners" element={<AddThemBaseBannner />} />
        <Route path="site-editor/edit-theme-based-content-banners/:id" element={<AddThemBaseBannner />} />

        <Route path="site-editor/latest-arrival-banners" element={<LatestArrivalBannerList />} />
        <Route path="site-editor/add-latest-arrival-banners" element={<AddLatestArrivalBanner />} />
        <Route path="site-editor/edit-latest-arrival-banners/:id" element={<AddLatestArrivalBanner />} />

        <Route path="site-editor/dynamic-block-banners" element={<DynamicBlockBannerList />} />
        <Route path="site-editor/add-dynamic-block-banners" element={<AddDynamicBlockBanner />} />
        <Route path="site-editor/edit-dynamic-block-banners/:id" element={<AddDynamicBlockBanner />} />

        <Route path="site-editor/seller-banners" element={<SellerBannerList />} />
        <Route path="site-editor/add-seller-banners" element={<SellerBanners />} />
        <Route path="site-editor/edit-seller-banners/:id" element={<SellerBanners />} />

        <Route path="site-editor/product-banners" element={<ProductBannerList />} />
        <Route path="site-editor/add-product-banners" element={<ProductBanners />} />
        <Route path="site-editor/edit-product-banners/:id" element={<ProductBanners />} />

        <Route path="site-editor/trending-banners" element={<TrendingBannerList />} />
        <Route path="site-editor/add-trending-banners" element={<TrendingBanners />} />
        <Route path="site-editor/edit-trending-banners/:id" element={<TrendingBanners />} />
      </Route>
      <Route path="onboarding/invoice" element={<OrderInvoice />} />
      <Route path="stripe-verification" element={<StripVerification />} />

      {/* <Route path="*" element={<NotFound />} /> */}
      <Route path="*" element={<Navigate to="/onboarding/dashboard" replace />} />
    </Routes>
  );
};

export default AppRoutes;
