import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import TextArea from '../../../components/universal/InputType/TextArea';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import { UploadSingleImage } from '../../../components/universal/UploadImage';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { Loader } from '../../../components/Skeleton/Loader';

export const AddBlogs = () => {
  const route = useNavigate();

  const [blogImage, setBlogImage] = useState('');
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const initialValues = {
    title: '',
    description: '',
    link: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    link: Yup.string().url('Link is not valid').required('Link is required'),
  });

  const getBlogDetails = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/blogs/${id}`, null);
    if (response?.statusCode === 200) {
      formik.setFieldValue('title', response?.data?.title || '');
      formik.setFieldValue('description', response?.data?.description || '');
      formik.setFieldValue('link', response?.data?.link || '');
      setBlogImage(response?.data?.image || '');
      setLoading(false);
    } else {
      notify(response?.message, 'error');
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    if (!blogImage) {
      notify('Please upload image', 'error');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('title', values?.title);
    formData.append('description', values?.description);
    formData.append('link', values?.link);
    formData.append('image', blogImage);

    const endPoint = id ? `seller/blogs/${id}` : 'seller/blogs';

    const response = await AxiosAll('post', endPoint, formData);
    if (response?.statusCode === 200) {
      notify(response?.message, 'success');
      route('/onboarding/site-editor/blogs-list');
      setLoading(false);
    } else {
      notify(response?.message, 'error');
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (id) {
      getBlogDetails();
    }
  }, []);

  const error = document?.querySelector('.input-error-message');
  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);

  return (
    <div className="latest-arrivals 2">
      <Loader open={loading} />
      <BannerCard title="Blog" para={para} backgroundImageURL={images.latestArrivalImg} smallCard />
      <div className="main-section container-27inch">
        <div className="section-editor">
          <BlueBtn
            title="Return"
            customClass="return-btn"
            onClick={() => route('/onboarding/site-editor/blogs-list')}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <UploadSingleImage
            title="Cover Image"
            description="Color is used to express style and communicate meaning. With dynamic color, Material puts personal color preferences and individual needs at the forefront of systematic color application."
            image={blogImage}
            setImage={setBlogImage}
            imageSize={2}
          />

          <div className="search-engine-section">
            <div className="input-title">
              <div className="meta-info">
                <div className="meta-title">
                  <Typography>Title</Typography>
                  <InputTypeOutline
                    placeholder="Title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && formik.errors.title}
                  />
                </div>
                <div className="meta-des">
                  <Typography>Description</Typography>
                  <TextArea
                    placeholder="Description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && formik.errors.description}
                  />
                </div>
                <div className="meta-title">
                  <Typography>Link</Typography>
                  <InputTypeOutline
                    placeholder="Link"
                    name="link"
                    value={formik.values.link}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.link && formik.errors.link}
                  />
                </div>
                {/* <div className="meta-des">
                  <Typography>Status</Typography>
                  <div className="radiobtn-section">
                    <RadioGroup
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.status && formik.errors.status}
                    >
                      <FormControlLabel value="1" label="Active" control={<Radio />} />
                      <FormControlLabel value="0" label="Inactive" control={<Radio />} />
                    </RadioGroup>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="buttons">
            <div className="buttons-position">
              <LightBtn title="Cancel" onClick={() => route('/onboarding/site-editor/blogs-list')} />
              <BlueBtn title="Save" type="submit" loading={loading} disabled={loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
