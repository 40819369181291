import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import BannerCard from '../../../components/universal/WelcomeCard';
import { AxiosAll } from '../../../services/axiosAll';
import { notify } from '../../../core/constants';
import { ShippingTable } from '../../../components/SiteEditorTable/ComonTable/ShippingTable';
import AddCourierClass from '../../../components/dialogs/AddCourierClass';
import { TimeTable } from '../../../components/SiteEditorTable/ComonTable/TimeTable';
import AddTime from '../../../components/dialogs/AddTime';
import { Loader } from '../../../components/Skeleton/Loader';

export const ProcessingTime = () => {
  const route = useNavigate();
  const [ShippingList, setShippingList] = useState([]);
  const [loading, setloading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);

  const [total, setTotal] = useState(0);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getTimeList = async () => {
    setloading(true);
    const response = await AxiosAll('get', `seller/shippingProcessingTimes?per_page=20`, null);
    if (response.statusCode === 200) {
      setShippingList(response.data.data);
      setTotal(response.data.total);
      setloading(false);
    } else {
      notify(response.message, 'error');
      setloading(false);
    }
  };

  useEffect(() => {
    getTimeList();
  }, []);

  const AddProcessingTime = async (value) => {
    // eslint-disable-next-line no-unused-vars
    const response = await AxiosAll(
      value.id === 0 ? 'post' : 'put',
      value.id === 0 ? 'seller/shippingProcessingTimes' : `seller/shippingProcessingTimes/${value.id}`,
      value,
      null,
    );
    if (response.statusCode === 200) {
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }
    getTimeList();
  };

  const EditStatus = async (value, data) => {
    // setisLoading(true);
    const response = await AxiosAll(
      'put',
      `seller/shippingProcessingTimes/${data.id}`,
      {
        processing_time: data?.processing_time,
        min_time: data?.min_time,
        max_time: data?.max_time,
        average_time: data?.average_time,
        status: value,
      },
      null,
    );
    getTimeList();
  };
  return (
    <div className="processing-time">
      <Loader open={loading} />
      <BannerCard title="Processing Time" para={para} smallCard />
      <div className="container-27inch">
        <div className="processing-buttons">
          <BlueBtn title="Return" customClass="return-btn" onClick={() => route(-1)} />
          <AddTime AddProcessingTime={AddProcessingTime} />
        </div>
        {loading ? (
          'Loading...'
        ) : (
          <div className="site-table">
            <TimeTable
              isclass
              ShippingList={ShippingList}
              getShippingList={getTimeList}
              AddProcessingTime={AddProcessingTime}
              EditStatus={EditStatus}
            />
          </div>
        )}
      </div>
    </div>
  );
};
