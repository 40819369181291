export const AddIconBtn = ({ onPress, iconImg, iconColor, iconClass }) => {
  return (
    <div className={`add-btn ${iconClass}`} style={{ background: iconColor }}>
      <button
        onClick={onPress}
        type="button"
        style={{
          background: 'transparent',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
        }}
      >
        <img src={iconImg} alt={iconImg} />
      </button>
    </div>
  );
};
