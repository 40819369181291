import { Dialog, DialogActions, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { images } from '../../../assets';
import { CircleBtnIcon } from '../../siteEitor/circleBtnIcon';
import { AddIconBtn } from '../AddBtn';
// eslint-disable-next-line import/no-named-as-default
// import CategoryAdd from '../../dialogs/CategoryAdd';
import { PopUpBeauty } from '../../PopUpContainer/BeautiPopUp';
import { TopPicksPopUp } from '../../PopUpContainer/TopPicksPopUp';
import SearchMetaSection from '../../SearchEngineSection';
import CategoryAdd from '../../dialogs/CategoryAdd';
import { Position } from '../../PopUpContainer/Position';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import LightBtn from '../../universal/Buttons/LightBtn';

export const CategoryCard = ({
  data,
  deleteCategory,
  AddCategory,
  getMainCategories,
  setStep,
  dragStart,
  dragOver,
  dragEnd,
  dropCard,
  key,
}) => {
  const route = useNavigate();
  const query = window.location.search;
  const urlParam = new URLSearchParams(query);
  const child = urlParam.get('child');
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();

  return (
    <div
      className="category-card"
      key={key}
      draggable
      onDragStart={dragStart}
      onDragOver={dragOver}
      onDragEnd={dragEnd}
      onDrop={dropCard}
    >
      <Typography>{data?.name}</Typography>
      <div className="icon-btn desktop">
        {child === '2' ? null : (
          <AddIconBtn
            iconColor="#2559C9"
            iconImg={images.plusIcon}
            onPress={() => {
              route(
                child === '1'
                  ? `/onboarding/site-editor/sub-categories-child-add/${data?.id}?child=2`
                  : `/onboarding/site-editor/sub-categories-add/${data?.id}?child=1`,
              );
            }}
          />
        )}
        <CategoryAdd AddCategory={AddCategory} type="edit" name={data?.name} id={data?.id} />
        {child == null ? (
          <PopUpBeauty
            data={data}
            getMainCategories={getMainCategories}
            setStep={setStep}
            id={data?.id}
            topPicks={data?.top_picks}
          />
        ) : (
          ''
        )}
        {child == null ? (
          <Position
            data={data}
            getMainCategories={getMainCategories}
            setStep={setStep}
            id={data?.id}
            topPicks={data?.top_picks}
          />
        ) : (
          ''
        )}
        <TopPicksPopUp setStep={setStep} data={data} name={data?.name} id={data?.id} />

        <SearchMetaSection setStep={setStep} name={data?.name} data={data} id={data?.id} />

        <CircleBtnIcon
          iconColor="#2F2B23"
          onPress={() => {
            setOpenDelete(true);
            setDeleteId(data?.id);
          }}
        >
          <DeleteIcon />
        </CircleBtnIcon>
      </div>
      <Dialog
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
          setDeleteId('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="delete-pop-up"
      >
        <div className="delete-container">
          <Typography variant="h2">Delete</Typography>
          <Typography paragraph>Are you sure you want to delete?</Typography>
        </div>
        <DialogActions>
          <BlueBtn title="Yes" onClick={() => deleteCategory(deleteId)} />
          <LightBtn title="No" onClick={() => setOpenDelete(false)} />
        </DialogActions>
      </Dialog>

      <div className="icon-btn mobile">
        <div className="first-options">
          {child === '2' ? null : (
            <AddIconBtn
              iconColor="#2559C9"
              iconImg={images.plusIcon}
              onPress={() => {
                route(
                  child === '1'
                    ? `/onboarding/site-editor/sub-categories-child-add/${data?.id}?child=2`
                    : `/onboarding/site-editor/sub-categories-add/${data?.id}?child=1`,
                );
              }}
            />
          )}
          <CategoryAdd AddCategory={AddCategory} type="edit" name={data?.name} id={data?.id} />
          <CircleBtnIcon
            iconColor="#2F2B23"
            onPress={() => {
              setOpenDelete(true);
              setDeleteId(data?.id);
            }}
          >
            <DeleteIcon />
          </CircleBtnIcon>
        </div>

        <div className="second-options">
          {child == null ? (
            <PopUpBeauty
              data={data}
              getMainCategories={getMainCategories}
              setStep={setStep}
              id={data?.id}
              topPicks={data?.top_picks}
            />
          ) : (
            ''
          )}

          {child == null ? (
            <Position
              data={data}
              getMainCategories={getMainCategories}
              setStep={setStep}
              id={data?.id}
              topPicks={data?.top_picks}
            />
          ) : (
            ''
          )}
          <TopPicksPopUp setStep={setStep} data={data} name={data?.name} id={data?.id} />

          <SearchMetaSection setStep={setStep} name={data?.name} data={data} id={data?.id} />
        </div>
      </div>
    </div>
  );
};
