import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';

const DropDown = ({
  name,
  handleChange,
  value,
  option,
  touched,
  blur,
  placholder,
  showTooltip,
  tooltipTitle,
  enablePlaceholder,
  error,
  labelKey,
  idKey,
  disabled,
  customClass,
}) => {
  return (
    <div className={`dropdown ${customClass}`}>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            name={name}
            onChange={handleChange}
            disabled={disabled}
            id="demo-simple-select"
            value={value}
            className="select-field setupselectbox"
            onBlur={blur}
            displayEmpty
          >
            {placholder ? (
              // eslint-disable-next-line no-unneeded-ternary
              <MenuItem value={0} disabled={!enablePlaceholder}>
                {placholder}
              </MenuItem>
            ) : null}
            {option &&
              option?.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={idKey ? item[idKey] : item?.id ? item?.id : item?.value ? item?.value : item[idKey]}
                    disabled={item?.inSubsection ? item?.inSubsection : false}
                  >
                    {labelKey
                      ? item[labelKey]
                      : item?.title
                      ? item?.title
                      : item?.slug
                      ? item?.slug
                      : item?.name
                      ? item?.name
                      : item?.squareTitle
                      ? item?.squareTitle
                      : item?.color
                      ? item?.color
                      : item?.processing_time
                      ? item?.processing_time
                      : item?.courierName
                      ? item?.courierName
                      : item?.courierClassName
                      ? item?.courierClassName
                      : item?.size
                      ? item?.size
                      : item?.section_name
                      ? item?.section_name
                      : item?.country
                      ? item?.country
                      : item[labelKey]}
                  </MenuItem>
                );
              })}
          </Select>
          {/* {showTooltip && (
            <Tooltip title={tooltipTitle || 'Tooltip'}>
              <InfoIcon />
            </Tooltip>
          )} */}
        </FormControl>
        {touched && error && <div className="input-error-message">{error}</div>}
        {value === 'Yes' && touched && error && <div className="input-error-message">{error}</div>}
      </Box>
    </div>
  );
};

export default DropDown;
