import { Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ReactQuill from 'react-quill';
import * as React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import 'react-quill/dist/quill.snow.css';
import TextArea from '../../universal/InputType/TextArea';
import { images } from '../../../assets';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import { UploadDraggableContainer } from '../../universal/UploadDraggableContainer';
import { AxiosAll } from '../../../services/axiosAll';
import Editor, { CustomToolbar } from '../../universal/InputType/Editor';
import { formats, modules, notify } from '../../../core/constants';

export const TopPicksPopUp = ({ name, id, data, setStep }) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [banners, setBanners] = React.useState({
    desktopBanner: '',
    tabletBanner: '',
    mobileBanner: '',
  });

  const initialValues = {
    title: '',
    description: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setStep(true);
    setOpen(false);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('banner_title', values?.title);
    formData.append('banner_description', values?.description);
    formData.append('name', name);
    formData.append('status', 1);
    formData.append('banner_image', banners?.desktopBanner);
    formData.append('banner_image_tablet', banners?.tabletBanner);
    formData.append('banner_image_mobile', banners?.mobileBanner);

    const response = await AxiosAll('post', `seller/categories/${id}?_method=PUT`, formData);
    if (response?.statusCode === 200) {
      notify(response?.message, 'success');
      setStep(true);
      setOpen(false);
      setLoading(false);
    } else {
      notify(response?.message, 'error');
      setOpen(true);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  React.useEffect(() => {
    if (data?.banner_title !== '' || data?.banner_description !== '') {
      formik.setFieldValue('title', data?.banner_title);
      formik.setFieldValue('description', data?.banner_description);
      setBanners({
        desktopBanner: data?.banner_image || '',
        tabletBanner: data?.banner_image_tablet || '',
        mobileBanner: data?.banner_image_mobile || '',
      });
    }
  }, [data]);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';
  const error = document?.querySelector('.input-error-message');
  React.useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [error]);
  return (
    <div className="btn-width">
      {data?.banner_title !== '' || data?.banner_description !== '' ? (
        <BlueBtn title="Edit Banner" customClass="create-menu" onClick={handleClickOpen} />
      ) : (
        <BlueBtn title="Add Banner" customClass="create-menu" onClick={handleClickOpen} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="top-picks"
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="main-picks-container">
            <div className="meta-info">
              <div className="meta-title">
                <Typography>Title</Typography>
                <InputTypeOutline
                  placeholder="Enter"
                  name="title"
                  value={formik?.values?.title}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.title && formik?.errors?.title}
                />
              </div>

              <div className="meta-des">
                <Typography>Description</Typography>
                <div className="main-textarea">
                  <Editor
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    placeholder="Description"
                    name="description"
                    value={formik.values.description}
                    error={formik.touched.description && formik.errors.description}
                    onChange={(e) => {
                      formik.setFieldValue('description', e);
                    }}
                  />
                </div>
              </div>
            </div>

            <UploadDraggableContainer
              dec={para}
              imagePreview={images.sitePreview}
              imageTrash={images.trashBtn}
              banners={banners}
              setBanners={setBanners}
              imageSize={2}
            />
          </div>
          <DialogActions className="bottom-btn">
            <LightBtn title="Cancel" onClick={handleClose} />
            <BlueBtn title="Save" loading={loading} type="submit" />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
