export const ImgCard = ({ isImageTrue, tableImg }) => {
  const isImage = isImageTrue || false;
  return !isImage ? (
    <div className="img-card" />
  ) : (
    <div className="table-img">
      <img src={tableImg} alt="" />
    </div>
  );
};
