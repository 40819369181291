import Cropper from 'react-cropper';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import { memo } from 'react';

const CustomCropper = ({ image, setCropper, cropperRef }) => {
  return (
    <Cropper
      src={URL.createObjectURL(image)}
      initialAspectRatio={16 / 9}
      guides={false}
      background={false}
      responsive
      viewMode={1}
      zoomable={false}
      // crop={onCrop}
      onInitialized={(instance) => {
        setCropper(instance);
      }}
      ref={cropperRef}
    />
  );
};

export default memo(CustomCropper);
