import { DELETE_CART, SET_CART, UPDATE_CART } from '../constant';

const initialState = {
  count: '0',
};

// eslint-disable-next-line default-param-last
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART:
      return { count: action.data };
    case DELETE_CART:
      return { count: 0 };
    case UPDATE_CART:
      return { count: action.data };
    default:
      return state;
  }
};

export default cartReducer;
