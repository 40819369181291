import { Dialog, DialogActions, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { images } from '../../../assets';
import { notify, truncateText } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import { RadioButtonsGroup } from '../RadioBtnTable';
import { TableActionBtn } from '../TableActionBtn';
import { ImgCard } from '../imgCard';

export const BannerTable = ({ list, getList, setBannerList }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [itemId, setItemId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [draggedOverIndex, setDraggedOverIndex] = React.useState(null);

  const { id } = useParams();
  const handleDelete = async (bannerId) => {
    setLoading(true);
    const response = await AxiosAll('delete', `seller/deleteBanner/${bannerId}`, null);
    if (response?.statusCode === 200) {
      notify(response?.message, 'success');
      getList && getList();
      setLoading(false);
    } else {
      notify(response?.message, 'error');
      setLoading(false);
    }
    handleClose();
  };

  const handleDrop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...list];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setBannerList(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };
  const handleClickOpen = (bannerId) => {
    setOpen(true);
    setItemId(bannerId);
  };

  const handleClose = () => {
    setOpen(false);
    setItemId(null);
  };

  const handleChange = async (e, bannerId, key) => {
    const { value } = e.target;

    const response = await AxiosAll(
      'put',
      `seller/sellerSections/${bannerId}?section_type=banner_with_multiple_image&_method=PUT`,
      { [key]: parseInt(value, 10) },
    );
    if (response?.statusCode === 200) {
      notify(response?.message, 'success');
      getList && getList();
    } else {
      notify(response?.message, 'error');
    }
  };

  return (
    <TableContainer component={Paper} className="main-table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Banner Image</TableCell>
            <TableCell align="left">Banner Name</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, index) => {
            return (
              <TableRow
                key={row?.id}
                draggable
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => handleDragOver(e, index)}
                onDragEnd={() => setDraggedOverIndex(null)}
                onDrop={(e) => handleDrop(e, index)}
              >
                <TableCell align="left">
                  <ImgCard tableImg={row?.banner_image || images.placeholder} isImageTrue />
                </TableCell>
                <TableCell align="left">{row?.title}</TableCell>
                <TableCell align="left">
                  <RadioButtonsGroup
                    name="status"
                    value={row?.status}
                    onChange={(e) => handleChange(e, row?.id, 'status')}
                  />
                </TableCell>
                <TableCell align="left">
                  <TableActionBtn
                    onEdit={() => navigate(`/onboarding/site-editor/banner-with-multiple-image/${row?.id}?id=${id}`)}
                    onDelete={() => handleClickOpen(row?.id)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="delete-pop-up"
          >
            <div className="delete-container">
              <Typography variant="h2">Delete</Typography>
              <Typography paragraph>Are you sure you want to delete this banner?</Typography>
            </div>
            <DialogActions>
              <BlueBtn title="Yes" loading={loading} disabled={loading} onClick={() => handleDelete(itemId)} />
              <LightBtn title="No" onClick={handleClose} />
            </DialogActions>
          </Dialog>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
