import Multiselect from 'multiselect-react-dropdown';

const MultiSelect = ({ badges, getMetadata }) => {
  return (
    <Multiselect
      options={badges}
      selectionLimit={4}
      onSelect={(selectedList, selectedItem) => {
        const selectedOptionString = selectedList?.map((option) => {
          return {
            category: 1,
            type: 5,
            content: option.id,
          };
        });
        getMetadata(selectedOptionString);
      }}
      onRemove={(selectedList, selectedItem) => {
        const selectedOptionString = selectedList?.map((option) => {
          return {
            category: 1,
            type: 5,
            content: option.id,
          };
        });
        getMetadata(selectedOptionString);
      }}
      displayValue="name"
    />
  );
};

export default MultiSelect;
