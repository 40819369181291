/* eslint-disable jsx-a11y/anchor-is-valid */
import PanoramaFishEyeOutlinedIcon from '@mui/icons-material/PanoramaFishEyeOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import DropDown from '../../../components/universal/DropDown';
import Pagination from '../../../components/universal/Pagination';
import { notify, UserRegistrationOption } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const UserRegistration = () => {
  const [proSellerList, setProSeller] = useState();
  const [paginationCount, setCount] = useState();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [optionValue, setOption] = useState('1');

  const getProSeller = async (pageData) => {
    const param = {
      page: pageData,
    };
    const responce = await axiosAll({
      method: 'get',
      url: `v2/pro_seller_requests`,
      params: param,
    });
    setProSeller(responce?.result?.data);
    setRowsPerPage(responce?.result?.data?.length);
    setCount(responce?.result?.total);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setOption(value);
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    if (name === 'allSelect') {
      const AllChecked = proSellerList?.map((proSeller) => {
        return { ...proSeller, isChecked: checked };
      });
      setProSeller(AllChecked);
    } else {
      const CheckUser = proSellerList?.map((proSeller) =>
        proSeller.id === name ? { ...proSeller, isChecked: checked } : proSeller,
      );
      setProSeller(CheckUser);
    }
  };

  const handleApply = async () => {
    for (let index = 0; index < proSellerList?.length; index++) {
      if (proSellerList[index]?.isChecked) {
        if (optionValue === '2') {
          const responce = axiosAll({
            method: 'delete',
            url: `v2/pro_seller_requests/${proSellerList[index]?.id}`,
          });
          if (responce?.statusCode === 200) {
            notify(responce?.message, 'success');
            getProSeller(page);
          }
        } else if (optionValue === '3') {
          const params = {
            isApp: 1,
            isWeb: 1,
            url: `${window.location.origin}/welcome-page`,
          };

          const responce = axiosAll({
            method: 'put',
            url: `v2/pro_seller_requests/${proSellerList[index]?.id}`,
            data: params,
          });
          if (responce?.statusCode === 200) {
            notify(responce?.message, 'success');
            getProSeller(page);
          }
        } else if (optionValue === '4') {
          const params = {
            isApp: 0,
            isWeb: 0,
            url: `${window.location.origin}/welcome-page`,
          };
          const responce = axiosAll({
            method: 'put',
            url: `v2/pro_seller_requests/${proSellerList[index]?.id}`,
            data: params,
          });
          if (responce?.statusCode === 200) {
            notify(responce?.message, 'success');
            getProSeller(page);
          }
        } else {
          const params = {
            isApp: 1,
            isWeb: 0,
            url: `${window.location.origin}/welcome-page`,
          };
          const responce = axiosAll({
            method: 'put',
            url: `v2/pro_seller_requests/${proSellerList[index]?.id}`,
            data: params,
          });
          if (responce?.statusCode === 200) {
            notify(responce?.message, 'success');
            getProSeller(page);
          }
        }
      }
    }
  };
  const AllUser = async () => {
    const params = {
      isApp: '',
      isWeb: '',
      status: '',
      page: 1,
    };
    const responce = await axiosAll({
      method: 'get',
      url: `v2/pro_seller_requests`,
      params,
    });
    setProSeller(responce?.result?.data);
    setRowsPerPage(responce?.result?.data?.length);
    setCount(responce?.result?.total);
  };

  const AllUserAllow = async () => {
    const params = {
      isApp: 1,
      isWeb: 1,
      status: 1,
      page: 1,
    };
    const responce = await axiosAll({
      method: 'get',
      url: `v2/pro_seller_requests`,
      params,
    });
    setProSeller(responce?.result?.data);
    setRowsPerPage(responce?.result?.data?.length);
    setCount(responce?.result?.total);
  };

  const AllowUserDashboardAndApp = async () => {
    const params = {
      isApp: 1,
      isWeb: 1,
      status: 2,
      page: 1,
    };
    const responce = await axiosAll({
      method: 'get',
      url: `v2/pro_seller_requests`,
      params,
    });
    setProSeller(responce?.result?.data);
    setRowsPerPage(responce?.result?.data?.length);
    setCount(responce?.result?.total);
  };

  const DanyUser = async () => {
    const params = {
      isApp: 0,
      isWeb: 0,
      status: 1,
      page: 1,
    };
    const responce = await axiosAll({
      method: 'get',
      url: `v2/pro_seller_requests`,
      params,
    });
    setProSeller(responce?.result?.data);
    setRowsPerPage(responce?.result?.data?.length);
    setCount(responce?.result?.total);
  };

  const DanyUserDashboardAccess = async () => {
    const params = {
      isApp: 1,
      isWeb: 0,
      status: 1,
      page: 1,
    };
    const responce = await axiosAll({
      method: 'get',
      url: `v2/pro_seller_requests`,
      params,
    });
    setProSeller(responce?.result?.data);
    setRowsPerPage(responce?.result?.data?.length);
    setCount(responce?.result?.total);
  };

  useEffect(() => {
    getProSeller(page);
  }, [page]);

  return (
    <div className="UserRegistration">
      <div className="entries">
        <h1 className="heading">Entries</h1>
      </div>
      <div className="add-product">
        <div>
          <a tabIndex={0} role="button" className="product" onClick={AllUser}>
            All |
          </a>
          <a tabIndex={0} role="button" className="product" onClick={AllUserAllow}>
            Allow user- access to dashboard and to app(Pending for Vendor Registration) |
          </a>
          <a tabIndex={0} role="button" className="product" onClick={AllowUserDashboardAndApp}>
            Allow user- access to dashboard and to app(Completed) |
          </a>
          <a tabIndex={0} role="button" className="product " onClick={DanyUser}>
            Deny user- No access to dashboard and app |
          </a>
          <a tabIndex={0} role="button" className="product" onClick={DanyUserDashboardAccess}>
            Deny user Dashboard Access- but continue access to app{' '}
          </a>
        </div>
        <div className="register-container">
          <div className="dropdownsection">
            <DropDown option={UserRegistrationOption} handleChange={handleChange} value={optionValue} />
            <LightBtn title=" Apply " onClick={handleApply} />
          </div>
          <div>
            <Pagination
              page={page}
              setPage={setPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              total={paginationCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </div>
        </div>

        <div className="table-row">
          <table>
            <tr className="table-heading">
              <th>
                <input
                  type="checkbox"
                  name="allSelect"
                  onChange={handleCheckbox}
                  checked={proSellerList?.filter((proSeller) => proSeller?.isChecked !== true).length < 1}
                />
              </th>
              <th>Lifecycle Stage</th>
              <th>Data Source</th>
              <th> Your Name</th>
              <th> Seller Shop/Company Name</th>
              <th> Date</th>
              <th>Actions</th>
              <th>Request type</th>
            </tr>
            <tbody>
              {proSellerList?.map((seller, index) => {
                return (
                  <tr className="product-name" key={index}>
                    <td>
                      <div className="icon-section">
                        <input
                          type="checkbox"
                          name={seller.id}
                          onChange={handleCheckbox}
                          checked={seller?.isChecked || false}
                        />
                        <StarOutlineOutlinedIcon className="star-icon" />
                        <PanoramaFishEyeOutlinedIcon className="panorama-icon" />
                      </div>
                    </td>
                    <td>opportunity</td>
                    <td>Website Registration</td>
                    <td>
                      {' '}
                      {seller?.firstName} {'     '}
                      {seller?.lastName}
                    </td>
                    <td> {seller?.companyName}</td>
                    <td>{moment(seller?.createdAt).format('MMMM D, YYYY')}</td>
                    <td className="view-icon">
                      <Link to={`/admin/view-registration/seller/detail/${seller.id}`}>
                        <VisibilityIcon className="icon-visibility" />
                      </Link>
                    </td>
                    <td>{seller?.status ? '	Processed' : 'New Request'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserRegistration;
