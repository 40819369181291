import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { TestimonialsTable } from '../../../components/SiteEditorTable/ComonTable/testimonialsTable';
import { BlueBtn } from '../../../components/universal/Buttons/BlueBtn';
import CardPagination from '../../../components/universal/CardPagination';
import SearchBar from '../../../components/universal/SearchBar';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';
import { Loader } from '../../../components/Skeleton/Loader';

export const Testimonials = () => {
  const route = useNavigate();
  const [loading, setLoading] = useState(false);
  const [testimonialList, setTestimonialList] = useState([]);
  const [testimonialData, setTestimonialData] = useState({});
  const [searchTestimonial, setSearch] = useState();
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getTestimonialList = async () => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `seller/testimonials?per_page=${limit}&page=${page}&search=${searchTestimonial || ''}`,
      null,
    );
    if (response.statusCode === 200) {
      setTestimonialList(response?.data?.data);
      setTestimonialData(response?.data);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
    setPage(1);
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (page > testimonialData?.last_page) {
      setPage(testimonialData?.last_page);
    }
  }, [testimonialData?.last_page, page]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    getTestimonialList();
  }, [page, limit, searchTestimonial]);

  return (
    <div className="testimonials">
      <Loader open={loading} />

      <BannerCard title="Testimonials" para={para} smallCard />
      <div className="container-27inch">
        <div className="testimonial-btn-left">
          <div className="btn-button">
            <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
            <BlueBtn
              title="Add New Testimonial"
              onClick={() => route('/onboarding/site-editor/add-testimonial')}
              customClass="collection-btn"
            />
          </div>
          <SearchBar handleChange={handleSearch} value={searchText} />
        </div>
        <div className="site-table">
          <TestimonialsTable testimonialList={testimonialList} getTestimonialList={getTestimonialList} />
        </div>
        {testimonialData?.total >= 12 && (
          <CardPagination
            noOfPages={testimonialData?.last_page}
            page={page}
            handleChange={handlePagination}
            rowsPerPage={limit}
          />
        )}
      </div>
    </div>
  );
};
