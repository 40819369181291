/* eslint-disable jsx-a11y/label-has-associated-control */
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import { CircleBtnIcon } from '../../siteEitor/circleBtnIcon';
import { images } from '../../../assets';
import LightBtn from '../../universal/Buttons/LightBtn';

const AddTime = ({ type, AddProcessingTime, data, id }) => {
  const [Open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    processing_time: Yup.string().required('Courier Class Name is required'),
    min_time: Yup.number().required('Minimum Time is required').min(1, 'Minimum Time cannot be zero'),
    max_time: Yup.number().required('Maximum Time is required').min(1, 'Maximum Time cannot be zero'),
    average_time: Yup.number().required('Average Time is required').min(1, 'Average Time cannot be zero'),
  });

  const formik = useFormik({
    initialValues: {
      processing_time: data?.processing_time || '',
      min_time: data?.min_time || 0,
      max_time: data?.max_time || 0,
      average_time: data?.average_time || 0,
      status: data?.status || 1,
    },
    validationSchema,
    onSubmit: (values) => {
      AddProcessingTime({
        processing_time: values?.processing_time,
        min_time: values?.min_time,
        max_time: values?.max_time,
        average_time: values?.average_time,
        status: values?.status,
        id: id || 0,
      });

      setOpen(false);
      formik.resetForm();
    },
  });

  return (
    <div className="AddTo">
      {type === 'edit' ? (
        <CircleBtnIcon
          iconClass="edit"
          iconColor="transparent"
          iconImg={images.editBlack}
          onPress={() => setOpen(true)}
        >
          <ModeEditOutlineIcon />
        </CircleBtnIcon>
      ) : (
        <BlueBtn
          onClick={() => {
            setOpen(true);
          }}
          title="Create Processing Time"
          customClass="create-menu"
        />
      )}
      <Dialog
        open={Open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Add-To processing-time-popup"
      >
        <DialogContent>
          <p className="processing-text">{type === 'edit' ? 'Edit' : 'Add'} Time</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="add-time">
              <div>
                <label>Process Time Name (label)</label>
                <InputTypeOutline
                  placeholder="Process Time Name(label)*"
                  name="processing_time"
                  value={formik.values.processing_time}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.processing_time && formik.errors.processing_time}
                />
              </div>
              <div>
                <label>Average Process Time (days)*</label>
                <InputTypeOutline
                  placeholder="Average Process Time ( days)*"
                  name="average_time"
                  type="number"
                  value={formik.values.average_time}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.average_time && formik.errors.average_time}
                />
              </div>
              <div>
                <label>Minimum Process Time (days)*</label>
                <InputTypeOutline
                  placeholder="Minimum Process Time ( days)*"
                  name="min_time"
                  type="number"
                  value={formik.values.min_time}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.min_time && formik.errors.min_time}
                />
              </div>
              <div>
                <label>Maximum Process Time (days)*</label>
                <InputTypeOutline
                  placeholder="Maximum Process Time ( days)*"
                  name="max_time"
                  type="number"
                  value={formik.values.max_time}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.max_time && formik.errors.max_time}
                />
              </div>
              <div className="add-time-btn">
                <BlueBtn title="Yes" type="submit" />
                <LightBtn
                  title="No"
                  onClick={() => {
                    setOpen(false);
                    formik.resetForm();
                  }}
                />
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddTime;
