import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function FinancalReportSkeleton() {
  return (
    <div className="financalreportskeleton">
      <table>
        <tr>
          <td className="ordernumber">
            <Skeleton variant="text" className="number" />
          </td>
          <td className="amount">
            <Skeleton variant="text" className="amountval" />
          </td>
          <td className="totalfee">
            <Skeleton variant="text" className="feeval" />
          </td>
          <td className="netpayout">
            <Skeleton variant="text" className="payoutval" />
          </td>
          <td className="payoutdate">
            <Skeleton variant="text" className="payoutdateval" />
          </td>
          <td className="refund">
            <Skeleton variant="text" className="refundval" />
          </td>
          <td className="acionbtntd">
            <Skeleton variant="rectangular" className="acionbtn" />
          </td>
        </tr>
        <tr>
          <td className="ordernumber">
            <Skeleton variant="text" className="number" />
          </td>
          <td className="amount">
            <Skeleton variant="text" className="amountval" />
          </td>
          <td className="totalfee">
            <Skeleton variant="text" className="feeval" />
          </td>
          <td className="netpayout">
            <Skeleton variant="text" className="payoutval" />
          </td>
          <td className="payoutdate">
            <Skeleton variant="text" className="payoutdateval" />
          </td>
          <td className="refund">
            <Skeleton variant="text" className="refundval" />
          </td>
          <td className="acionbtntd">
            <Skeleton variant="rectangular" className="acionbtn" />
          </td>
        </tr>
        <tr>
          <td className="ordernumber">
            <Skeleton variant="text" className="number" />
          </td>
          <td className="amount">
            <Skeleton variant="text" className="amountval" />
          </td>
          <td className="totalfee">
            <Skeleton variant="text" className="feeval" />
          </td>
          <td className="netpayout">
            <Skeleton variant="text" className="payoutval" />
          </td>
          <td className="payoutdate">
            <Skeleton variant="text" className="payoutdateval" />
          </td>
          <td className="refund">
            <Skeleton variant="text" className="refundval" />
          </td>
          <td className="acionbtntd">
            <Skeleton variant="rectangular" className="acionbtn" />
          </td>
        </tr>
        <tr>
          <td className="ordernumber">
            <Skeleton variant="text" className="number" />
          </td>
          <td className="amount">
            <Skeleton variant="text" className="amountval" />
          </td>
          <td className="totalfee">
            <Skeleton variant="text" className="feeval" />
          </td>
          <td className="netpayout">
            <Skeleton variant="text" className="payoutval" />
          </td>
          <td className="payoutdate">
            <Skeleton variant="text" className="payoutdateval" />
          </td>
          <td className="refund">
            <Skeleton variant="text" className="refundval" />
          </td>
          <td className="acionbtntd">
            <Skeleton variant="rectangular" className="acionbtn" />
          </td>
        </tr>
      </table>
    </div>
  );
}
