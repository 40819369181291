import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddSubsectionDialog from '../../../components/dialogs/AddSubsection';
import AddToDialog from '../../../components/dialogs/AddTo';
import RemoveFromDialog from '../../../components/dialogs/RemoveFrom';
import SellerSkeleton from '../../../components/Skeleton/SellerSkeleton';
import PendingBtn from '../../../components/universal/Buttons/PendingBtn';
import DropDown from '../../../components/universal/DropDown';
import FileUpload from '../../../components/universal/FileUpload';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import TextArea from '../../../components/universal/InputType/TextArea';
import OnOffSwitch from '../../../components/universal/OnOffSwitch';
import Pagination from '../../../components/universal/Pagination';
import SearchBar from '../../../components/universal/SearchBar';
import { notify } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';

const FeaturedSection = () => {
  const [productList, setProductList] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [paginationCount, setCount] = useState();
  const [tag, setTag] = useState();
  const [tagValue, setTagValue] = useState('');
  const [sectionId, setId] = useState(1);
  const [timer, setTimer] = useState(null);
  const [subsection, setSubsection] = useState();
  const [subsectionValue, setSubsectionValue] = useState(1);
  const [subsectionValueById, setSubsectionValueById] = useState();
  const [AddToOption, setAddToOption] = useState([]);
  const [AddToOptionValue, setToAddOptionValue] = useState();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [RemoveToloading, setRemoveToLoading] = useState(false);
  const [removeOption, setRemoveOption] = useState([]);
  const [removeOptionvalue, setRemoveOptionvalue] = useState();
  const [squreImage, setSqureImage] = useState();
  const [landscapeImage, setLandscapeImage] = useState();
  const [visibilitySubsection, setVisibility] = useState(false);
  const [titleRequired, setTitleRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  const [landscapeImageRequired, setLandscapeImageRequired] = useState(false);

  const [SubSectionDetail, setSubSectionDetail] = useState({
    squareTitle: '',
    squareDescription: '',
  });

  const defaultOption = {
    title: 'All',
    value: '',
  };

  const getProduct = async (pageData) => {
    const params = {
      feedtype: 0,
      startprice: '',
      endprice: '',
      sortbyprice: 1,
      tags: tagValue,
      page: pageData,
      search: search || '',
    };
    setLoadingTable(true);
    const response = await axiosAll({
      method: 'get',
      url: `/v2/productSectionProductsAdmin`,
      params,
    });
    setProductList(response?.result?.data);
    // console.log(response?.result);
    setLoadingTable(false);
    setCount(response?.result?.total);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (search?.length > 0) {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          getProduct();
        }, 500),
      );
    } else {
      getProduct();
    }
  };
  const getSubSection = async (sectionDataId) => {
    const params = {
      sectionId: sectionDataId || 1,
    };
    const responce = await axiosAll({
      method: 'get',
      url: `v2/subsections`,
      params,
    });
    setSubsection(responce?.result?.subsections);
    setSubsectionValue(responce?.result?.subsections[0].id);
  };

  const getSubSectionById = async (subsectionData) => {
    const responce = await axiosAll({
      method: 'get',
      url: `v2/subsections/${subsectionData}`,
    });
    setSubsectionValueById(responce?.result?.subsection);
    setVisibility(responce?.result?.subsection?.status === 1);

    setSubSectionDetail({
      ...SubSectionDetail,
      squareTitle: responce?.result?.subsection?.squareTitle,
      squareDescription: responce?.result?.subsection?.squareDescription,
    });
  };

  const getCategerios = async () => {
    const responce = await axiosAll({
      method: 'get',
      url: `v1/tag`,
    });
    responce?.result?.data?.unshift(defaultOption);

    setTag(responce?.result?.data);
  };

  const getsections = async () => {
    const params = {
      cardId: 1,
      tagId: tagValue,
    };
    const responce = await axiosAll({
      method: 'get',
      url: `v2/sections`,
      params,
    });
    setId(responce?.result?.section?.id);
  };

  const handleProductDetail = async (id) => {
    const responce = await axiosAll({
      method: 'get',
      url: `v1/product/${id}`,
    });
    const update = responce?.result?.product?.sectionProducts;
    // eslint-disable-next-line no-restricted-syntax
    for (const sub of subsection) {
      const updateOption = update?.filter((e) => e?.subsectionId === sub?.id);
      if (updateOption?.length > 0) {
        const tempSub = {
          id: sub.id,
          squareTitle: sub?.squareTitle,
          inSubsection: true,
        };
        AddToOption?.push(tempSub);
      } else {
        const tempSubMain = {
          id: sub.id,
          squareTitle: sub?.squareTitle,
          inSubsection: false,
        };
        AddToOption?.push(tempSubMain);
      }
      setToAddOptionValue(AddToOption[0]?.id);
    }
  };

  const handleProductDetailRemove = async (productid) => {
    setRemoveToLoading(true);
    const responce = await axiosAll({
      method: 'get',
      url: `v1/product/${productid}`,
    });
    if (responce?.statusCode === 200) {
      const update = responce?.result?.product?.sectionProducts;
      // eslint-disable-next-line no-restricted-syntax
      for (const sub of subsection) {
        const updateOption = update?.filter((e) => e?.subsectionId === sub?.id);
        if (updateOption?.length > 0) {
          const tempSub = {
            id: sub.id,
            name: sub?.squareTitle,
          };
          // eslint-disable-next-line no-await-in-loop
          await removeOption?.push(tempSub);
        }
      }
      setRemoveToLoading(false);
      await setRemoveOptionvalue(removeOption[0]?.id ? removeOption[0]?.id : '');
    }
  };

  const handleSectionChange = (e) => {
    const { value } = e.target;
    setSubsectionValue(value);
  };

  const updateSubSection = async () => {
    if (
      SubSectionDetail?.squareTitle !== '' &&
      SubSectionDetail?.squareDescription !== '' &&
      (landscapeImage !== '' || subsectionValueById?.landscapeImage !== '')
    ) {
      setTitleRequired(false);
      setDescriptionRequired(false);
      setLandscapeImageRequired(false);
      const formData = new FormData();
      formData.append('squareTitle', SubSectionDetail?.squareTitle ? SubSectionDetail?.squareTitle : '');
      formData.append(
        'squareDescription',
        SubSectionDetail?.squareDescription ? SubSectionDetail?.squareDescription : '',
      );
      formData.append('squareImage', squreImage || '');
      formData.append('landscapeImage', landscapeImage || '');
      formData.append('status', visibilitySubsection === true ? 1 : 0);
      setLoading(true);
      const response = await axiosAll({
        method: 'post',
        url: `v2/subsections/${subsectionValue}`,
        data: formData,
      });
      if (response?.statusCode === 200) {
        notify(response.message, 'success');
        getCategerios();
        //  getSubSection(sectionId);
        getSubSectionById(subsectionValue);
        setLoading(false);
        setSqureImage('');
        setLandscapeImage('');
      } else {
        setLoading(false);
        notify(response.message, 'error');
      }
    } else {
      setTitleRequired(true);
      setDescriptionRequired(true);
      setLandscapeImageRequired(true);
    }
  };

  const handleTagChange = (e) => {
    const { value } = e.target;
    setTagValue(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChange = (e) => {
    setToAddOptionValue(e.target.value);
  };

  useEffect(() => {
    getSubSectionById(subsectionValue);
  }, [subsectionValue]);

  useEffect(() => {
    getSubSection(sectionId);
  }, [sectionId]);

  useEffect(() => {
    getsections(tagValue);
  }, [tagValue]);

  useEffect(() => {
    getProduct(page);
    getCategerios();
  }, [page, tagValue]);

  useEffect(() => {
    getCategerios();
  }, []);

  return (
    <div className="FeaturedSection">
      <div className="subsection">
        <h3>SubSection Details</h3>
        <AddSubsectionDialog
          subsectionValue={sectionId}
          getSubSection={getSubSection}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
      <div className="dropdownsection">
        <label htmlFor="Categories" className="label-text">
          Categories
        </label>
        <DropDown option={tag} handleChange={handleTagChange} value={tagValue} />
      </div>
      <div className="dropdownsection">
        <label htmlFor="SubSection" className="label-text">
          SubSection
        </label>
        <DropDown option={subsection} handleChange={handleSectionChange} value={subsectionValue} />
      </div>
      <div className="img-alignment">
        <div>
          <label htmlFor="Square Image" className="label-text">
            Square Image
          </label>
          <FileUpload
            forId="SqureImage"
            id="SqureImage"
            title="choose file"
            accept="image/png,image/jpeg,image/jpg"
            onChange={(e) => {
              setSqureImage(e.target.files[0]);
            }}
          />
          <div className="product-img">
            {loading ? (
              ' Loading....'
            ) : (
              <img
                src={squreImage ? URL.createObjectURL(squreImage) : subsectionValueById?.squareImage}
                alt="squareImage"
              />
            )}
          </div>
        </div>

        <div className="landscape-section">
          <label htmlFor="Landscape Image" className="label-text">
            Landscape Image
          </label>
          <FileUpload
            forId="LandscapeImage"
            title="choose file"
            id="LandscapeImage"
            accept="image/png,image/jpeg,image/jpg"
            onChange={(e) => {
              setLandscapeImage(e.target.files[0]);
            }}
          />
          <div className="product-img">
            {loading ? (
              ' Loading....'
            ) : (
              <img
                src={landscapeImage ? URL.createObjectURL(landscapeImage) : subsectionValueById?.landscapeImage}
                alt="landscapeImage"
              />
            )}
          </div>
          <div className="input-error-message">
            {subsectionValueById?.landscapeImage === '' && landscapeImage === '' && landscapeImageRequired === true
              ? 'landscape Image is required.'
              : null}
          </div>
        </div>
      </div>
      <div className="dropdownsection">
        <label htmlFor="Square Title" className="label-text">
          Square Title
        </label>
        <InputTypeOutline
          onChange={(e) =>
            setSubSectionDetail({
              ...SubSectionDetail,
              squareTitle: e.target.value,
            })
          }
          name="squareTitle"
          value={SubSectionDetail?.squareTitle}
        />
        <div className="input-error-message">
          {SubSectionDetail?.squareTitle === '' && titleRequired ? 'squre tittle is required.' : null}
        </div>
      </div>
      <div className="dropdownsection">
        <label htmlFor="Square Description" className="label-text">
          Square Description
        </label>
        <TextArea
          onChange={(e) =>
            setSubSectionDetail({
              ...SubSectionDetail,
              squareDescription: e.target.value,
            })
          }
          name="squareDescription"
          value={SubSectionDetail?.squareDescription}
        />
        <div className="input-error-message">
          {SubSectionDetail?.squareDescription === '' && descriptionRequired ? 'squre description is required.' : null}
        </div>
      </div>
      <Grid item xs={12} sm={6} className="switchuifixed">
        <label htmlFor="Visibility" className="label-text">
          Visibility
        </label>
        <OnOffSwitch value={visibilitySubsection} onChange={(e) => setVisibility(e.target.checked)} />
      </Grid>
      <div>
        <PendingBtn title="Update Details" loading={loading} onClick={updateSubSection} />
      </div>

      {/* table section */}
      <div className="tablesection">
        <div className="product-title">
          <h1>Products</h1>
        </div>
        <div className="table-container">
          <div className="searchbarsection">
            <div>
              {productList?.length > 10 ? (
                <Pagination
                  page={page}
                  setPage={setPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  total={paginationCount}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              ) : (
                ''
              )}
            </div>
            <SearchBar handleChange={handleSearch} value={search} />
          </div>
          {loadingTable ? (
            <SellerSkeleton />
          ) : (
            <table>
              <tr className="product-heading">
                <th>Name</th>
                <th>Product Image</th>
                <th>Price</th>
                <th>Seller Name</th>
                <th>Action</th>
              </tr>
              {productList?.length > 0 ? (
                productList?.map((item, index) => {
                  return (
                    <tr key={index} className="product-name">
                      <td>{item?.name}</td>
                      <td>
                        <img src={item?.cover_image?.image} alt="" className="motiimg" />
                      </td>
                      <td> £{item?.price?.toFixed(2)}</td>
                      <td className="seller-name">{item?.product_seller?.name}</td>
                      <td>
                        <div className="addbtn">
                          <AddToDialog
                            setToOptionValue={setToAddOptionValue}
                            setToOption={setAddToOption}
                            handleProductDetail={handleProductDetail}
                            id={item?.id}
                            sectionId={sectionId}
                            setSubsectionValue={setSubsectionValue}
                            AddToOptionValue={AddToOptionValue}
                            AddToOption={AddToOption}
                            handleChange={handleChange}
                          />
                          <RemoveFromDialog
                            sectionId={sectionId}
                            productid={item?.id}
                            removeOption={removeOption}
                            removeOptionvalue={removeOptionvalue}
                            handleProductDetailRemove={handleProductDetailRemove}
                            setOption={setRemoveOption}
                            setOptionvalue={setRemoveOptionvalue}
                            RemoveToloading={RemoveToloading}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td colSpan="5" className="not-gound-td">
                  <div className="not-found">Not data found</div>
                </td>
              )}
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedSection;
