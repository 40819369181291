/* eslint-disable jsx-a11y/label-has-associated-control */
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import { CircleBtnIcon } from '../../siteEitor/circleBtnIcon';
import { images } from '../../../assets';
import LightBtn from '../../universal/Buttons/LightBtn';

const AddCountry = ({ type, AddNewCountry, data, id }) => {
  const [Open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Courier Class Name is required'),
    currency_symbol: Yup.string().required('Currency symbol is required'),
    currency_iso_code: Yup.string().required('Currency iso code is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      currency_symbol: data?.currency_symbol || '',
      currency_iso_code: data?.currency_iso_code || '',
      status: data?.status || 1,
    },
    validationSchema,
    onSubmit: (values) => {
      AddNewCountry({
        name: values?.name,
        currency_symbol: values?.currency_symbol,
        currency_iso_code: values?.currency_iso_code,
        status: values?.status,
        id: id || 0,
      });

      formik.resetForm();

      setOpen(false);
    },
  });

  return (
    <div className="AddTo">
      {type === 'edit' ? (
        <CircleBtnIcon
          iconClass="edit"
          iconColor="transparent"
          iconImg={images.editBlack}
          onPress={() => setOpen(true)}
        >
          <ModeEditOutlineIcon />
        </CircleBtnIcon>
      ) : (
        <BlueBtn
          onClick={() => {
            setOpen(true);
          }}
          title="Create Country"
          customClass="create-menu"
        />
      )}
      <Dialog
        open={Open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Add-To"
      >
        <DialogContent>
          <p style={{ fontSize: '18px' }}>{type === 'edit' ? 'Edit' : 'Add'} Country</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="country-list-popup">
              <div>
                <label>Country Name</label>
                <InputTypeOutline
                  placeholder="Country Name*"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                />
              </div>

              <div>
                <label>Currency Symbol</label>
                <InputTypeOutline
                  placeholder="Currency Symbol"
                  name="currency_symbol"
                  type="text"
                  value={formik.values.currency_symbol}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.currency_symbol && formik.errors.currency_symbol}
                />
              </div>
              <div>
                <label>Currency ISO Code</label>
                <InputTypeOutline
                  placeholder="Currency ISO Code"
                  name="currency_iso_code"
                  type="text"
                  value={formik.values.currency_iso_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.currency_iso_code && formik.errors.currency_iso_code}
                />
              </div>
              <div className="country-btn">
                <BlueBtn title="Yes" type="submit" />
                <LightBtn
                  title="No"
                  onClick={() => {
                    setOpen(false);
                    formik.resetForm();
                  }}
                />
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddCountry;
