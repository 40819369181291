import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import TextArea from '../../components/universal/InputType/TextArea';
import ProgressBar from '../../components/universal/ProgressBar';
import { notify } from '../../core/constants';
import { store } from '../../redux/store';
import { AxiosAll } from '../../services/axiosAll';

const PolicyDetails = () => {
  const nav = useNavigate();
  const state = store.getState();
  const { guestDetails } = state.guestReducer;
  const { id } = useParams();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [sellerData, setSellerData] = useState({});
  const [registeredUser, setRegisteredUser] = useState(1);
  const [count, setCount] = useState(5);
  // const [returnPolicy, setReturnPolicy] = useState();
  // const [refundPolicy, setRefundPolicy] = useState();
  // const [shippingPolicy, setShippingPolicy] = useState();

  const getUserData = async () => {
    try {
      const res = await AxiosAll('get', 'seller-profile', null, token);
      const user = res?.data?.user;
      // var return_policy = res?.data?.user?.metadata.filter((obj) => {
      //   return obj.typeName == "RETURN_POLICY";
      // });
      // var shipping_policy = res?.data?.user?.metadata.filter((obj) => {
      //   return obj.typeName === "SHIPPING_POLICY";
      // });
      // var refund_policy = res?.data?.user?.metadata.filter((obj) => {
      //   return obj.typeName === "REFUND_POLICY";
      // });
      // setReturnPolicy(return_policy[0]);
      // setRefundPolicy(refund_policy[0]);
      // setShippingPolicy(shipping_policy[0]);
      formik?.setFieldValue('returnPolicy', user?.store?.return_policy || '');
      formik?.setFieldValue('refundPolicy', user?.store?.refund_policy || '');
      formik?.setFieldValue('shippingPolicy', user?.store?.shipping_policy || '');
      setSellerData(res?.data);
      setRegisteredUser(user?.stripe_sub_is_first_time);
      if (res?.data?.user?.stripe_sub_is_first_time !== 1) {
        const timer = setInterval(() => {
          setCount((prev) => prev - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  // formik start
  const initialValues = {
    returnPolicy: '',
    refundPolicy: '',
    shippingPolicy: '',
  };
  const onSubmit = async (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('return_policy', values?.returnPolicy);
    formData.append('shipping_policy', values?.shippingPolicy);
    formData.append('refund_policy', values?.refundPolicy);

    try {
      const res = await AxiosAll('post', 'seller/storePolicy', formData, token);
      if (res?.statusCode === 200) {
        notify(res?.message, 'success');
        nav(`/business-details/${id}/${token}`);
        setLoading(false);
      } else {
        notify(res?.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    if (count <= 0 && sellerData?.user?.stripe_sub_is_first_time !== 1) {
      nav(
        `/subscription/${sellerData?.user?.id}/${sellerData?.user_token}?isNew=${sellerData?.user?.stripe_sub_is_first_time}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, sellerData]);

  return (
    <>
      <ProgressBar value={60} />
      {registeredUser !== 1 ? (
        <div className="verifyyouremail">
          <div>You are already registered!</div>
          <div className="text-para">You will be redirected to subscription page in {count} seconds</div>
        </div>
      ) : (
        <div className="policydetails">
          <form onSubmit={formik.handleSubmit}>
            <div className="text-heading">Specify your policies</div>
            <div className="text-para">If you don’t have any you can leave this blank</div>
            <div className="">
              <div className="label-text">Return policy</div>
              <TextArea
                name="returnPolicy"
                id="returnPolicy"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.returnPolicy}
                onChange={formik.handleChange}
              />
            </div>

            <div className="">
              <div className="label-text">Refund policy</div>
              <TextArea
                name="refundPolicy"
                id="refundPolicy"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.refundPolicy}
                onChange={formik.handleChange}
              />
            </div>

            <div className="">
              <div className="label-text">Shipping policy</div>
              <TextArea
                name="shippingPolicy"
                id="shippingPolicy"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.shippingPolicy}
                onChange={formik.handleChange}
              />
            </div>
            <div className="btn-section">
              <div>
                <LightBtn title="Back" onClick={() => nav(`/store-details/${id}/${token}`)} />
              </div>
              <div>
                {/* <DarkBtn /> */}
                <DarkBtn loading={loading} disable={loading} title="Continue" type="submit" />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default PolicyDetails;
