import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ProductBannerTable } from '../../../../../components/SiteEditorTable/ComonTable/productBannerTable';
import { BlueBtn } from '../../../../../components/universal/Buttons/BlueBtn';
import LightBtn from '../../../../../components/universal/Buttons/LightBtn';
import CardPagination from '../../../../../components/universal/CardPagination';
import BannerCard from '../../../../../components/universal/WelcomeCard';
import { notify } from '../../../../../core/constants';
import { AxiosAll } from '../../../../../services/axiosAll';
import { images } from '../../../../../assets';
import { Loader } from '../../../../../components/Skeleton/Loader';

export const ProductBannerList = () => {
  const route = useNavigate();
  const [BannerList, setBannerList] = useState([]);
  const [bannerData, setBannerData] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [loading, setLoading] = useState(false);

  const para =
    'Here you can configure your website using our preset website sections, at any time you can view your work by clicking on the ‘view website’.';

  const getBannerList = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `seller/common-product-banners?per_page=${limit}&page=${page}`, null);
    if (response.statusCode === 200) {
      setBannerList(response?.data?.data);
      setBannerData(response?.data);
      setLoading(false);
    } else {
      notify(response.message, 'error');
      setLoading(false);
    }
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (page > bannerData?.last_page) {
      setPage(bannerData?.last_page);
    }
  }, [bannerData?.last_page, page]);

  useEffect(() => {
    getBannerList();
  }, [page, limit]);

  return (
    <div className="site-product-banner-list">
      <Loader open={loading} />
      <BannerCard title="All Product Banner" para={para} smallCard backgroundImageURL={images.dynamicProductsImg} />
      <div className="container-27inch">
        <BlueBtn title="Return" customClass="return-btn" onClick={() => route('/onboarding/site-editor')} />
        <BlueBtn
          title="Add All Product Banner"
          onClick={() => route('/onboarding/site-editor/add-product-banners')}
          customClass="collection-btn"
        />
        <div className="site-table">
          <ProductBannerTable list={BannerList} getList={getBannerList} />
        </div>
        {bannerData?.total >= 12 && (
          <CardPagination
            noOfPages={bannerData?.last_page}
            page={page}
            handleChange={handlePagination}
            rowsPerPage={limit}
          />
        )}
      </div>
    </div>
  );
};
