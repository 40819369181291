/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import CategoryDropDown from '../../../components/universal/AddProduct/CategoryDropDown';
import EditMultiSelect from '../../../components/universal/EditProduct/EditMultiSelect';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';

export const FilterSection = ({ filterData, setFilterData, filterBtnRef, setValidFilter, errors, setErrors }) => {
  const [masterCategory, setMasterCategory] = useState([]);
  const [mainCategory, setMainCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [masterCategoryArr, setMasterCategoryArr] = useState([]);
  const [selectedMasterCategory, setSelectedMasterCategory] = useState('0');
  const [selectedMainCategory, setSelectedMainCategory] = useState('0');
  const [selectedSubCategory, setSelectedSubCategory] = useState('0');
  //   const [tag, setTag] = useState([]);

  const [isValid, setIsValid] = useState(false);
  const [badges, setBadges] = useState([]);
  const [filteredBadges, setFilteredBadges] = useState([]);
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [metadata, setMetadata] = useState([]);

  const { userDetails } = useSelector((state) => state.loginReducer);

  const initialValues = {
    isTrending: false,
    isPrice: false,
    isEthicalValue: false,
    isCategories: false,
    isSearch: false,
    isFreeDelivery: false,
    isItemsOnSale: false,
    isSortBy: false,
    minimumPrice: 0,
    maximumPrice: 5000,
    search: '',
    sortBy: '1',
  };

  const validationSchema = Yup.object({
    isTrending: Yup.boolean(),
    isPrice: Yup.boolean(),

    isEthicalValue: Yup.boolean(),
    isCategories: Yup.boolean(),
    isSearch: Yup.boolean(),
    // search: Yup.string().when('isSearch', {
    //   is: true,
    //   then: Yup.string().required('Search is required'),
    // }),
    isFreeDelivery: Yup.boolean(),
    isItemsOnSale: Yup.boolean(),
    isSortBy: Yup.boolean(),
    sortBy: Yup.string().when('isSortBy', {
      is: true,
      then: Yup.string().required('Sort By is required'),
    }),
  });

  const onSubmit = async (values) => {
    const data = {
      is_trending: values.isTrending ? 1 : 0,
      is_price: values.isPrice ? 1 : 0,
      is_ethical_value: values.isEthicalValue ? 1 : 0,
      is_categories: values.isCategories ? 1 : 0,
      is_search: values.isSearch ? 1 : 0,
      is_free_delivery: values.isFreeDelivery ? 1 : 0,
      is_items_on_sale: values.isItemsOnSale ? 1 : 0,
      is_sort_by: values.isSortBy ? 1 : 0,
    };

    if (
      !values.isTrending &&
      !values.isPrice &&
      !values.isEthicalValue &&
      !values.isCategories &&
      !values.isSearch &&
      !values.isFreeDelivery &&
      !values.isItemsOnSale &&
      !values.isSortBy
    ) {
      notify('Please select atleast one filter', 'error');
      return;
    }

    if (values.isPrice) {
      data.startprice = values?.minimumPrice?.toFixed(2);
      data.endprice = values?.maximumPrice?.toFixed(2);
    }

    if (values.isCategories) {
      data.category1 = selectedMasterCategory;
      data.category2 = selectedMainCategory;
      data.category3 = selectedSubCategory;
    }

    if (values.isSearch) {
      data.search = values.search;
    }

    if (values.isEthicalValue) {
      data.ethical_value = selectedBadges?.map((item) => item?.id);
    }

    if (values.isSortBy) {
      data.sort_by = parseInt(values.sortBy, 10);
    }

    if (isValid) {
      setFilterData && setFilterData(data);
      setValidFilter && setValidFilter(true);
    } else {
      Object.keys(errors).forEach((key, index) => {
        if (index === 0) {
          notify(errors[key], 'error');
        }
      });
    }
  };

  useEffect(() => {
    if (filterData) {
      formik.setValues({
        isTrending: filterData?.is_trending === 1,
        isPrice: filterData?.is_price === 1,
        minimumPrice: filterData?.startprice ? filterData?.startprice : formik.values?.minimumPrice,
        maximumPrice: filterData?.endprice ? filterData?.endprice : formik.values?.maximumPrice,
        isEthicalValue: filterData?.is_ethical_value === 1,
        isCategories: filterData?.is_categories === 1,
        isSearch: filterData?.is_search === 1,
        search: filterData?.search,
        isFreeDelivery: filterData?.is_free_delivery === 1,
        isItemsOnSale: filterData?.is_items_on_sale === 1,
        isSortBy: filterData?.is_sort_by === 1,
        sortBy: filterData?.sort_by,
      });
      setSelectedMasterCategory(filterData?.category1);
      setSelectedMainCategory(filterData?.category2);
      setSelectedSubCategory(filterData?.category3);
      if (badges?.length > 0) {
        const ethicalValue = filterData?.ethical_value?.map((item) => {
          const badge = badges?.find((b) => b?.id === item);
          return badge;
        });
        setSelectedBadges(ethicalValue);
      }
    }
  }, [filterData, badges]);

  const getDropdowns = async () => {
    const types = ['badges', 'categories'];

    const response = await AxiosAll(
      'get',
      `dropdowns?type[]=${types.join('&type[]=')}&seller_id=${userDetails?.user?.id}`,
    );

    setBadges(response?.data?.badges);
    setMasterCategory(response?.data?.categories);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    getDropdowns();
  }, []);

  useEffect(() => {
    const mainCategoryData = masterCategory?.find((item) => item.id === parseInt(selectedMasterCategory, 10));
    setMainCategory(mainCategoryData?.childrens);
  }, [selectedMasterCategory, masterCategory]);

  useEffect(() => {
    const subCategoryData = mainCategory?.find((item) => item.id === parseInt(selectedMainCategory, 10));
    setSubCategory(subCategoryData?.childrens);
  }, [selectedMainCategory, mainCategory]);

  useEffect(() => {
    if (selectedMasterCategory === 295) {
      const newBadges = badges.map((badge) => {
        if (badge.id === 19 || badge.id === 6) {
          return badge;
        }
        return undefined;
      });
      const FilteredBadges = newBadges?.filter((badge) => {
        return badge !== undefined;
      });
      setFilteredBadges(FilteredBadges);
    } else if (selectedMasterCategory === 44) {
      setFilteredBadges(badges);
    } else {
      setFilteredBadges(badges);
    }
  }, [badges, selectedMasterCategory]);

  useEffect(() => {
    if (formik.values.isPrice && (formik.values.minimumPrice === '' || formik.values.maximumPrice === '')) {
      if (formik.values.minimumPrice === '') {
        setErrors({
          minimumPrice: 'Minimum Price is required',
        });
      } else if (formik.values.maximumPrice === '') {
        setErrors({
          maximumPrice: 'Maximum Price is required',
        });
      }
    } else if (formik.values.isEthicalValue && selectedBadges?.length === 0) {
      setErrors({
        selectedBadges: 'Ethical Value is required',
      });
    } else if (
      formik.values.isCategories &&
      (selectedMasterCategory === '0' || selectedMainCategory === '0' || selectedSubCategory === '0')
    ) {
      if (selectedMasterCategory === '0') {
        setErrors({
          selectedMasterCategory: 'Master Category is required',
        });
      } else if (selectedMainCategory === '0') {
        setErrors({
          selectedMainCategory: 'Main Category is required',
        });
      } else if (selectedSubCategory === '0') {
        setErrors({
          selectedSubCategory: 'Sub Category is required',
        });
      }
    } else if (formik.values.isSearch && formik.values.search === '') {
      setErrors({
        search: 'Search is required',
      });
    } else if (formik.values.isSortBy && formik.values.sortBy === '') {
      setErrors({
        sortBy: 'Sort By is required',
      });
    } else {
      setErrors({});
    }
  }, [
    formik.values.isTrending,
    formik.values.isPrice,
    formik.values.isEthicalValue,
    formik.values.isCategories,
    formik.values.isSearch,
    formik.values.isFreeDelivery,
    formik.values.isItemsOnSale,
    formik.values.isSortBy,
    formik.values.minimumPrice,
    formik.values.maximumPrice,
    formik.values.search,
    formik.values.sortBy,
    selectedBadges,
    selectedMasterCategory,
    selectedMainCategory,
    selectedSubCategory,
    setErrors,
  ]);

  useEffect(() => {
    const newErrors = { ...errors, ...formik.errors };
    setIsValid(Object.keys(newErrors).length === 0);
  }, [errors, formik.errors]);

  return (
    <div className="search-engine-section">
      <div className="input-title">
        <Accordion expanded={false}>
          <AccordionSummary>
            <Typography>Trending</Typography>
            <Checkbox name="isTrending" checked={formik.values.isTrending} onChange={formik.handleChange} />
          </AccordionSummary>
        </Accordion>
        <Accordion expanded={formik.values.isPrice}>
          <AccordionSummary>
            <Typography>Min. Price - Max. Price</Typography>
            <Checkbox name="isPrice" checked={formik.values.isPrice} onChange={formik.handleChange} />
          </AccordionSummary>
          <AccordionDetails>
            <div className="meta-info">
              <div className="meta-title">
                <Typography>Minimum Price</Typography>
                <InputTypeOutline
                  placeholder="minimum price"
                  name="minimumPrice"
                  type="number"
                  value={formik.values.minimumPrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  min="0"
                  onKeyDown={(event) => {
                    if (event.keyCode === 189) {
                      event.preventDefault();
                    }
                  }}
                  error={formik.touched.minimumPrice && formik.errors.minimumPrice}
                />
              </div>
              {formik.values.minimumPrice > formik.values.maximumPrice ? (
                <div className="input-error-message">
                  "Minimum price must be less than or equal to the maximum price"
                </div>
              ) : null}

              <div className="meta-title">
                <Typography>Maximum Price</Typography>
                <InputTypeOutline
                  placeholder="maximum price"
                  name="maximumPrice"
                  type="number"
                  value={formik.values.maximumPrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  min="0"
                  onKeyDown={(event) => {
                    if (event.keyCode === 189) {
                      event.preventDefault();
                    }
                  }}
                  error={formik.touched.maximumPrice && formik.errors.maximumPrice}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={formik.values.isEthicalValue}>
          <AccordionSummary>
            <Typography>Ethical value</Typography>
            <Checkbox name="isEthicalValue" checked={formik.values?.isEthicalValue} onChange={formik.handleChange} />
          </AccordionSummary>
          <AccordionDetails>
            <div className="ethical-dropdown">
              <EditMultiSelect
                name="ethicalValue"
                badges={filteredBadges}
                selectedBadges={selectedBadges}
                setSelectedBadges={setSelectedBadges}
                setMetadata={setMetadata}
                metadata={metadata}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={formik.values.isCategories}>
          <AccordionSummary>
            <Typography>Category</Typography>
            <Checkbox name="isCategories" checked={formik.values.isCategories} onChange={formik.handleChange} />
          </AccordionSummary>
          <AccordionDetails>
            <div className="meta-info">
              <div className="meta-title category-dropdown">
                <div className="categories-drop-down">
                  <CategoryDropDown
                    options={masterCategory}
                    value={selectedMasterCategory}
                    defaultSelect={{
                      name: 'Master category',
                      value: '0',
                    }}
                    onChange={(e) => {
                      setSelectedMasterCategory(e.target.value);
                      setSelectedMainCategory('0');
                      setMasterCategoryArr((prev) => [...prev, e.target.value]);
                    }}
                  />
                </div>
                {mainCategory?.length > 0 && (
                  <div className="categories-drop-down">
                    <CategoryDropDown
                      value={selectedMainCategory}
                      options={mainCategory}
                      defaultSelect={{
                        name: 'Main category',
                        value: '0',
                      }}
                      onChange={(e) => {
                        setSelectedMainCategory(e.target.value);
                        setSelectedSubCategory('0');
                      }}
                    />
                  </div>
                )}
                {subCategory?.length > 0 && (
                  <div className="categories-drop-down">
                    <CategoryDropDown
                      value={selectedSubCategory}
                      options={subCategory}
                      defaultSelect={{
                        name: 'Sub category',
                        value: '0',
                      }}
                      // getSubCategory={getSubCategory}
                      onChange={(e) => {
                        setSelectedSubCategory(e.target.value);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={formik.values.isSearch}>
          <AccordionSummary>
            <Typography>Search</Typography>
            <Checkbox name="isSearch" checked={formik.values.isSearch} onChange={formik.handleChange} />
          </AccordionSummary>
          <AccordionDetails>
            <div className="meta-info">
              <div className="meta-title">
                <Typography>Please Enter Query</Typography>
                <InputTypeOutline
                  placeholder="Enter Query"
                  name="search"
                  value={formik.values.search}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.search && formik.errors.search}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={false}>
          <AccordionSummary>
            <Typography>Free Delivery</Typography>
            <Checkbox name="isFreeDelivery" checked={formik.values.isFreeDelivery} onChange={formik.handleChange} />
          </AccordionSummary>
        </Accordion>
        <Accordion expanded={false}>
          <AccordionSummary>
            <Typography>Items on sale</Typography>
            <Checkbox name="isItemsOnSale" checked={formik.values.isItemsOnSale} onChange={formik.handleChange} />
          </AccordionSummary>
        </Accordion>
        <Accordion expanded={formik.values.isSortBy}>
          <AccordionSummary>
            <Typography>Sort by</Typography>
            <Checkbox name="isSortBy" checked={formik.values.isSortBy} onChange={formik.handleChange} />
          </AccordionSummary>
          <AccordionDetails>
            <div className="meta-info">
              <div className="meta-title">
                <div className="radiobtn-section">
                  <RadioGroup
                    name="sortBy"
                    value={formik.values.sortBy}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sortBy && formik.errors.sortBy}
                  >
                    <FormControlLabel value="1" label="Recent" control={<Radio />} />
                    <FormControlLabel value="2" label="Highest to lowest price" control={<Radio />} />
                    <FormControlLabel value="3" label="Lowest to highest price" control={<Radio />} />
                  </RadioGroup>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="btn-section">
        <input type="submit" onClick={() => formik?.handleSubmit()} ref={filterBtnRef} hidden className="btn" />
      </div>
    </div>
  );
};
