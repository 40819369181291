/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import DehazeIcon from '@mui/icons-material/Dehaze';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import { Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { images } from '../../../assets';
import { buttonDatas } from '../../../components/SellerSide/SellerSideBar/Accordion';
import { useWindowSize } from '../../../hooks/window';
import { MAX_TABLET_WIDTH } from '../../../models/breakpoints.model';
import { deleteLogin } from '../../../redux/actions/login';

export const SellerMobileMenu = () => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_TABLET_WIDTH;
  const [state, setState] = React.useState({
    left: false,
  });
  // const [newdrawer, setNewdrawer] = React.useState({
  //   left: false,
  // });

  const toggleDrawer = (anchor, open) => (event) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }
    setState({ ...state, [anchor]: open });
  };

  // useEffect((anchor)=>{
  //     isMobile && setNewdrawer({ ...state, [anchor]: false });
  // });

  const handleChange = (anchor) => {
    toggleDrawer(anchor, false);
    alert(anchor);
  };

  useEffect(() => {
    !isMobile && state.left && setState({ ...state, left: false });
  }, [isMobile, state]);

  const dispatch = useDispatch();
  const nav = useNavigate();
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Link to="seller">
          <ListItem className="side-bar-padd">
            <ListItemButton onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <ListItemIcon>
                <img src={images.dashboard} className="new-sidebar" />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <div className="main-accordion">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <ListItemIcon>
                <img src={images.siteEditor} className="new-sidebar" />
              </ListItemIcon>
              <ListItemText>Site Editor</ListItemText>
            </AccordionSummary>

            <AccordionDetails>
              <ul>
                {buttonDatas.map((buttonData) => {
                  return (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <ListItem key={buttonData.Section}>
                      {buttonData.dropdown && buttonData.dropdown?.length > 0 ? (
                        <div className="sub-accordion">
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <div
                                className="sub-accordion-text"
                                style={{ display: 'flex', gap: 5, alignItems: 'center' }}
                              >
                                <Typography>{buttonData.Section}</Typography>
                                <KeyboardArrowDownIcon />
                              </div>
                            </AccordionSummary>

                            <AccordionDetails>
                              <ul>
                                {buttonData.dropdown.map((buttonDataList) => {
                                  return (
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                    <ListItemButton
                                      key={buttonDataList}
                                      onClick={toggleDrawer(anchor, false)}
                                      onKeyDown={toggleDrawer(anchor, false)}
                                    >
                                      <Link to={buttonDataList?.Link}>
                                        <ListItemText>{buttonDataList.Section}</ListItemText>
                                      </Link>
                                    </ListItemButton>
                                  );
                                })}
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ) : (
                        <ListItemButton onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                          <Link to={buttonData?.Link}>
                            <ListItemText>{buttonData.Section}</ListItemText>
                          </Link>
                        </ListItemButton>
                      )}
                    </ListItem>
                  );
                })}
              </ul>
            </AccordionDetails>
          </Accordion>
        </div>
        <Divider />
        <Link to="product-images">
          <ListItem className="side-bar-padd">
            <ListItemButton onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <ListItemIcon>
                <img src={images.gallery} className="new-sidebar" />
              </ListItemIcon>
              <ListItemText>Library</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <Link to="products">
          <ListItem className="side-bar-padd">
            <ListItemButton onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <ListItemIcon>
                <img src={images.book} className="new-sidebar" />
              </ListItemIcon>

              <ListItemText>Inventory</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <Link to="orders">
          <ListItem className="side-bar-padd">
            <ListItemButton onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <ListItemIcon>
                <img src={images.basket} className="new-sidebar" />
              </ListItemIcon>

              <ListItemText>Orders</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />

        <Link to="users">
          <ListItem className="side-bar-padd">
            <ListItemButton onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <ListItemIcon>
                <img src={images.user} className="new-sidebar" />
              </ListItemIcon>
              <ListItemText>Users</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <Link to="financial-reports">
          <ListItem className="side-bar-padd">
            <ListItemButton onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <ListItemIcon>
                <img src={images.financials} className="new-sidebar" />
              </ListItemIcon>

              <ListItemText>Reports</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <Link to="my-profile">
          <ListItem className="side-bar-padd">
            <ListItemButton onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <ListItemIcon>
                <img src={images.user} className="new-sidebar" />
              </ListItemIcon>

              <ListItemText>Account</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <Link to="help">
          <ListItem className="side-bar-padd">
            <ListItemButton onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <ListItemIcon>
                <img src={images.user} className="new-sidebar" />
              </ListItemIcon>

              <ListItemText>Help</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <ListItem className="side-bar-padd">
          <div
            tabIndex={0}
            role="button"
            onClick={() => {
              dispatch(deleteLogin());
              //   dispatch(deleteUser());
              nav('/login');
            }}
            className="item2"
          >
            <ListItemButton onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
              <ListItemIcon>
                <img src={images.signOut} className="new-sidebar" />
              </ListItemIcon>

              <ListItemText>Logout</ListItemText>
            </ListItemButton>
          </div>
        </ListItem>
      </List>
      {/* <Divider />
      <List>

          <ListItem>
            <ListItemButton>
              <ListItemText>
                <DarkBtn title='Login'/>
              </ListItemText>
            </ListItemButton>
          </ListItem>

      </List> */}
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {/* <img src={images.menuIcon} /> */}
            <DehazeIcon />
          </Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};
