import React from 'react';
import { Button } from '@mui/material';

const DeliveredBtn = () => {
  return (
    <div className="">
      <Button variant="contained" className="deliveredbtn orderbtn3">
        Delivered
      </Button>
    </div>
  );
};

export default DeliveredBtn;
