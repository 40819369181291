/* eslint-disable no-debugger */
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import { Empty } from '../../components/universal/Empty';
import { toPascalCase } from '../../core/constants';

export const CustomerOrderTable = ({ TableColumn, Row, page, rowsPerPage, handleOrderDetail, name }) => {
  const funcToAddPrice = (item) => {
    let price = 0;

    item?.map((e, index) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      price += e?.total_product_price;
    });

    return price.toFixed(2);
  };
  const nav = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {TableColumn?.map((col, index) => {
              return <TableCell key={index}>{col} </TableCell>;
            })}
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {Row?.length > 0 ? (
            Row?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
              return (
                <TableRow
                  key={row?.orderItemId ? row?.orderItemId : row?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.generated_order_id}
                  </TableCell>
                  {row?.grossAmount ? (
                    <TableCell>{row?.grossAmount.toFixed(2)}</TableCell>
                  ) : (
                    <TableCell>
                      {row?.order_items.map((item) => {
                        return (
                          <>
                            <p>
                              {item?.stock}x{item?.product?.product_name}
                            </p>
                            <div
                              className={`orderid ${
                                item?.status === 2
                                  ? 'pendingbtn'
                                  : item?.status === 3
                                  ? 'deliveredbtn'
                                  : item?.status === 4
                                  ? 'deliveredbtn'
                                  : item?.status === 5
                                  ? 'cancelbtn'
                                  : item?.status === 6
                                  ? 'cancelbtn'
                                  : item?.status === 7
                                  ? 'partialbtn'
                                  : 'cancelbtn'
                              }`}
                            >
                              {toPascalCase(item?.order_status?.slug)}
                            </div>
                          </>
                        );
                      })}
                    </TableCell>
                  )}
                  {/* <TableCell className="date">
                    {row?.total_charge >= 0
                      ? row?.total_charge.toFixed(2)
                      : moment(row?.order_status[0]?.payoutDate).format('M/DD/YY [at] hh:mm A ')}
                  </TableCell> */}
                  {/* <TableCell style={{ paddingLeft: '3vw' }}>
                    {row?.order_status ? `£${funcToAddPrice(row?.order_status)}` : `${row?.netPayable.toFixed(2)}`}
                  </TableCell> */}
                  <TableCell>
                    {row?.order_status?.length
                      ? row?.order_status?.length
                      : moment(row?.created_at).format('M/DD/YY [at] hh:mm a ')}
                  </TableCell>

                  <TableCell>
                    {row?.order_items ? `£${funcToAddPrice(row?.order_items)}` : `${row?.total_amount.toFixed(2)}`}
                  </TableCell>
                  <TableCell>{row?.order_items?.length}</TableCell>

                  <TableCell>
                    <LightBtn
                      onClick={() =>
                        nav(`/onboarding/seller/order/details/${row?.id}`, {
                          state: { id: row?.id, order: 'order' },
                        })
                      }
                      title="View details"
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <tr>
              <td colSpan="5">
                <Empty message="Your orders will show in this table" />
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
