/* eslint-disable jsx-a11y/control-has-associated-label */
import { Container, Grid } from '@mui/material';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import SubHeading from '../../../components/universal/Headings/SubHeading';
import { notify } from '../../../core/constants';
import { AxiosAll, axiosAll } from '../../../services/axiosAll';

const OrderInvoice = () => {
  const [invoice, setInvoice] = useState();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const Order_id = urlParams.get('order_id');

  const handleOrderDetail = async (id) => {
    const responce = await AxiosAll('get', `seller/orders/${id}`, null);
    setInvoice(responce?.data);
  };

  const calculateNetCost = (item) => {
    if (item && item.price_with_no_vat !== undefined && item.stock !== undefined && item.stock !== 0) {
      const netCost = item.price_with_no_vat / item.stock;
      return netCost.toFixed(2);
    }
    return 'N/A';
  };

  const handleInvoiceMAil = async (id) => {
    const response = await AxiosAll('post', `seller/orders-item/send-invoice/${id}`);
    // setInvoice(responce?.result);
    notify(response?.message, 'success');
  };

  // const Invoice_ID = `${Order_id}`;

  // const handleURL = (Invoice_ID) => {
  //   setSearchParma({ ["order_id"]: Invoice_ID });
  // };

  // var data = document.getElementById('salesinvoice');

  // html2canvas(data).then((canvas) => {
  //   var imgWidth = 180;
  //   // var pageHeight = 295;
  //   var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   // var heightLeft = imgHeight;

  //   const contentDataURL = canvas.toDataURL('image/png');
  //   const pdf = new jsPDF('p', 'mm', 'a4');
  //   var position = 0;

  //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  // });

  const exportAsPdf = () => {
    const data = document.getElementById('salesinvoice');
    html2canvas(data)
      .then((canvas) => {
        const imgWidth = 180;
        // var pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        // var heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png');
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF('p', 'mm', 'a4');
        const position = 0;

        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        pdf.save(`order_${invoice?.generated_order_id}.pdf`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleOrderDetail(Order_id);
  }, [Order_id]);

  return (
    <div className="orderinvoice">
      <Container id="pdfinvoice" className="all-container-27inch">
        <div className="btn-section">
          {/* <PendingBtn title="Download pdf"/>
          <PendingBtn title="Email invoice"/> */}
          <DarkBtn title="Download pdf" onClick={exportAsPdf} />
          <DarkBtn onClick={() => handleInvoiceMAil(Order_id)} title="Email invoice" />
        </div>
        <div id="salesinvoice" className="salesinvoice">
          <div className="sales-name-section">
            <div className="sales-name">Sales Invoice</div>
            <div className="sales-name">Invoice #{invoice?.generated_order_id}</div>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <SubHeading title="Shipped To :" />
              {invoice?.order_buyer?.shipping_addess_name},{'  '}
              {invoice?.order_buyer?.shipping_addess_line_one},{invoice?.order_buyer?.shipping_addess_line_two},
              {invoice?.order_buyer?.shipping_addess_city},{invoice?.order_buyer?.shipping_addess_country}
            </Grid>
            <Grid item xs={6} sm={4}>
              <SubHeading title="Billed To :" />
              {invoice?.order_buyer?.billing_addess_name},{'  '}
              {invoice?.order_buyer?.billing_addess_line_one},{invoice?.order_buyer?.billing_addess_line_two},
              {invoice?.order_buyer?.billing_addess_city},{invoice?.order_buyer?.billing_addess_country}
            </Grid>
            <Grid item xs={12} sm={4} className="seller-info">
              {invoice?.order_items?.map((item, index) => {
                if (index === 0) {
                  return (
                    <>
                      <SubHeading title="Seller Info :" />
                      <p className="info">
                        <b>Name: </b> {item?.seller?.name || item?.seller?.b_business_name}
                      </p>
                      <p className="info">
                        <b>Store Name : </b> {item?.seller?.store?.store_name}
                      </p>
                      <p className="info">
                        <b>Email : </b> {item?.seller?.email || item?.seller?.b_business_email}
                      </p>
                      <p className="info">
                        <b>Phone : </b>
                        {item?.seller?.phone_number || item?.seller?.b_phone_number}
                      </p>
                      <p className="info">
                        <b>Reg Vat No : </b>
                        {item?.seller?.b_vat_number}
                      </p>
                      <p className="info">
                        <b>Order Date : </b>
                        {moment(item?.created_at).format('DD-MM-YYYY')}
                      </p>
                    </>
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>

          <div className="table-section">
            <div className="order_items-section">
              <div className="order_items-text">Order Summary</div>
            </div>
            <table>
              <tr>
                <th>Item</th>
                <th>Net Cost</th>
                <th>Quantity</th>
                <th>Item Total</th>
              </tr>

              {invoice?.order_items?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="borderline">{item?.product?.product_name}</td>
                    <td className="borderline">£{calculateNetCost(item)}</td>
                    <td className="borderline">{item?.stock}</td>
                    <td className="borderline">£{item?.price_with_no_vat?.toFixed(2)}</td>
                  </tr>
                );
              })}
              <tr>
                <td />
                <td />
                <td>
                  <b>Subtotal</b>
                </td>
                <td>£{invoice?.sub_total?.toFixed(2)}</td>
              </tr>
              <tr>
                <td />
                <td />
                <td>
                  <b>Shipping</b>
                </td>
                <td>£{invoice?.shipping_total?.toFixed(2)}</td>
              </tr>
              <tr>
                <td />
                <td />
                <td>
                  <b>Gift Wrapping</b>
                </td>
                <td>£{invoice?.gift_price_total?.toFixed(2)}</td>
              </tr>
              <tr>
                <td />
                <td />
                <td>
                  <b>Vat @ 20%</b>
                </td>
                <td>£{invoice?.vat_total?.toFixed(2)}</td>
              </tr>
              <tr>
                <td />
                <td />
                <td>
                  <b>Total</b>
                </td>
                <td>£{invoice?.total_amount?.toFixed(2)}</td>
              </tr>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OrderInvoice;
