import React from 'react';
import { Button } from '@mui/material';
import DisabledBtn from '../DisabledBtn';

const PendingBtn = ({ title, onClick, loading, type }) => {
  return loading ? (
    <DisabledBtn title="Loading..." />
  ) : (
    <Button type={type} onClick={onClick} variant="contained" className="pendingbtn orderbtn3">
      {title || 'pending btn'}
    </Button>
  );
};

export default PendingBtn;
