import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import { CircleBtnIcon } from '../../siteEitor/circleBtnIcon';
import { images } from '../../../assets';
import LightBtn from '../../universal/Buttons/LightBtn';

const AddCourierClass = ({ type, AddNewCourierClass, data, id }) => {
  const [Open, setOpen] = useState(false);
  const [isExpress, setisExpress] = useState(data?.is_express);
  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    courierClassName: Yup.string().required('Courier Class Name is required'),
    shippingTime: Yup.string().required('Courier Shipping Time is required'),
  });

  const formik = useFormik({
    initialValues: {
      courierClassName: data?.name || '',
      shippingTime: data?.shipping_time || '',
      isExpress: data?.is_express || '',
      min_transit_time: data?.min_transit_time || 1,
      max_transit_time: data?.max_transit_time || 2,
      status: data?.status || 1,
    },
    validationSchema,
    onSubmit: (values) => {
      AddNewCourierClass({
        courierClassName: values.courierClassName,
        shippingTime: values.shippingTime,
        isExpress: isExpress ? 1 : 0,
        min_transit_time: values?.min_transit_time || 1,
        max_transit_time: values?.max_transit_time || 2,
        status: values?.status || 1,
        id: id || 0,
      });

      setOpen(false);
      formik.resetForm();
    },
  });

  return (
    <div className="AddTo">
      {type === 'edit' ? (
        <BlueBtn
          onClick={() => {
            setOpen(true);
          }}
          title="Edit"
          customClass="create-menu"
        />
      ) : (
        <BlueBtn
          onClick={() => {
            setOpen(true);
          }}
          title="Create  menu"
          customClass="create-menu"
        />
      )}
      <Dialog
        open={Open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Add-To courier"
      >
        <DialogContent>
          <p className="courier-text">{type === 'edit' ? 'Edit' : 'Add'} Courier Class</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-box">
              <div>
                <InputTypeOutline
                  placeholder="Courier Class Name"
                  name="courierClassName"
                  value={formik.values.courierClassName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.courierClassName && formik.errors.courierClassName}
                />
              </div>
              <div>
                <InputTypeOutline
                  placeholder="Courier Shipping Time"
                  name="shippingTime"
                  type="number"
                  value={formik.values.shippingTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.shippingTime && formik.errors.shippingTime}
                />
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="isExpress"
                    checked={isExpress}
                    onChange={() => setisExpress(!isExpress)}
                  />
                  Class Is Express?
                </label>
              </div>
              <div style={{ marginRight: '10px' }}>
                <BlueBtn title="Yes" type="submit" />
                <LightBtn
                  title="No"
                  onClick={() => {
                    setOpen(false);
                    formik.resetForm();
                  }}
                />
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddCourierClass;
