import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as React from 'react';

import { useNavigate } from 'react-router';
import { images } from '../../../assets';
// eslint-disable-next-line import/order
import { Link } from 'react-router-dom';

export const AccordionMenu = ({ menuName, toggleDrawer, anchor, handleChange, options }) => {
  const route = useNavigate();
  return (
    <div className="sub-accordion">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <div className="sub-accordion-text" style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography>{menuName}</Typography>
            <KeyboardArrowDownIcon />
          </div>
        </AccordionSummary>

        <AccordionDetails>
          <ul>
            {options.map((buttonData) => {
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li key={buttonData.Section} className="item2">
                  {handleChange ? (
                    <Link to={buttonData?.Link} onClick={() => handleChange(anchor, false)}>
                      {buttonData.Section}
                    </Link>
                  ) : (
                    <Link to={buttonData?.Link}>{buttonData.Section}</Link>
                  )}
                </li>
              );
            })}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
