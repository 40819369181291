import React, { useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { DraggableUploadCard } from '../../topBanner/DraggableUploadCard';
import { DraggableCardDelete } from '../../dialogs/DraggableCardDelete';
import { images } from '../../../assets';
import { CircleBtnIcon } from '../../siteEitor/circleBtnIcon';

export const UploadArrowDraggableContainer = ({ dec, imagePreview, imageTrash }) => {
  const [users, setUsers] = useState([
    { id: 1, name: 'Desktop Banner' },
    { id: 2, name: 'Desktop Banner' },
    { id: 3, name: 'Desktop Banner' },
    { id: 4, name: 'Desktop Banner' },
    { id: 5, name: 'Desktop Banner' },
    { id: 6, name: 'Desktop Banner' },
    { id: 7, name: 'Desktop Banner' },
    { id: 8, name: 'Desktop Banner' },
    { id: 9, name: 'Desktop Banner' },
    { id: 10, name: 'Desktop Banner' },
  ]);
  const [loading, setLoading] = useState(false);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  const handleDrop = (e, index) => {
    const draggedUserIndex = Number(e.dataTransfer.getData('text/plain'));
    const newUsers = [...users];

    const draggedUser = newUsers[draggedUserIndex];
    newUsers.splice(draggedUserIndex, 1);
    newUsers.splice(index, 0, draggedUser);

    setUsers(newUsers);
    setDraggedOverIndex(null);
  };

  const handleDelete = (deleteUser) => {
    const newUser = users.filter((user) => user !== deleteUser);
    setUsers(newUser);
  };

  const moveCardUp = (index) => {
    if (index > 0) {
      setLoading(true);
      setTimeout(() => {
        const newUsers = [...users];
        const draggedUser = newUsers[index];
        newUsers.splice(index, 1);
        newUsers.splice(index - 1, 0, draggedUser);
        setUsers(newUsers);
        setLoading(false);
      }, 1000); // Simulating an asynchronous operation with a timeout
    }
  };

  const moveCardDown = (index) => {
    if (index < users.length - 1) {
      setLoading(true);
      setTimeout(() => {
        const newUsers = [...users];
        const draggedUser = newUsers[index];
        newUsers.splice(index, 1);
        newUsers.splice(index + 1, 0, draggedUser);
        setUsers(newUsers);
        setLoading(false);
      }, 1000); // Simulating an asynchronous operation with a timeout
    }
  };

  return (
    <>
      {users.map((user, index) => (
        <div className="drag-container" key={index}>
          <DraggableUploadCard
            keyID={user.id}
            dragStart={(e) => handleDragStart(e, index)}
            dragOver={(e) => handleDragOver(e, index)}
            dragEnd={() => setDraggedOverIndex(null)}
            dropCard={(e) => handleDrop(e, index)}
            cardImg={imagePreview}
            headerTitle={user.name}
            descriptionPara={dec}
            dropDownReq
            imageSize={2}
          />
          <div className="upload-arrow-card">
            <div className="up-down-btn">
              <CircleBtnIcon iconImg={images.upArrow} iconColor="#444746" onPress={() => moveCardUp(index)}>
                <ArrowUpwardIcon />
              </CircleBtnIcon>

              <CircleBtnIcon iconImg={images.downArrow} iconColor="#444746" onPress={() => moveCardDown(index)}>
                <ArrowDownwardIcon />
              </CircleBtnIcon>
            </div>
            <DraggableCardDelete
              iconImage={imageTrash}
              textTitle="Delete"
              textDescription="Are you sure you want to delete this product?"
              onPress={() => handleDelete(user)}
            />
          </div>
        </div>
      ))}
    </>
  );
};
