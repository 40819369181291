import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import ProductImageSkeleton from '../../../components/Skeleton/ProductImageSkeleton';
import UploadImage from '../../../components/dialogs/UploadImage';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import CardPagination from '../../../components/universal/CardPagination';
import { Empty } from '../../../components/universal/Empty';
import ProductsCard from '../../../components/universal/ProductsCard';
import SearchBar from '../../../components/universal/SearchBar';
import sweatAlertBox from '../../../components/universal/SweetAlertBox';
import ToolTip from '../../../components/universal/ToolTip';
import BannerCard from '../../../components/universal/WelcomeCard';
import { notify } from '../../../core/constants';
import { AxiosAll } from '../../../services/axiosAll';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ProductImages = () => {
  const converter = require('number-to-words');
  const [productImages, setProductImages] = useState();
  const [selectedProduct, setSelected] = useState(0);
  const [searchProduct, setSearch] = useState();
  const [searchText, setSearchText] = useState();
  const [bulkDeleteToggle, setBulkDeleteToggle] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(1);
  const [imageSize, setImageSize] = useState();
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [bulkDeleteData, setBulkDeleteData] = useState({
    images_id: [],
  });
  const [uploadImage, setUploadImage] = useState([]);

  const { userData } = useSelector((state) => state.guestUserReducer);

  const handlePagination = (event, value) => {
    setPage(value);
  };

  const getProductImage = async (item) => {
    const response = await AxiosAll(
      'get',
      `seller/galleryImages?search=${item || ''}&page=${page}&per_page=12`,
      null,
      userData?.userToken,
    );
    if (response?.statusCode === 200) {
      setProductImages(response?.data);
      // eslint-disable-next-line no-bitwise
      if ((page > 1) & (response?.data?.data.length === 0)) {
        setPage(page - 1);
      }
    }
    setLoading(false);
  };

  const UploadMultiImage = async () => {
    setIsImageUploading(true);
    const formData = new FormData();
    for (let index = 0; index < uploadImage.length; index++) {
      const element = uploadImage[index];
      formData.append(`images[${index}][name]`, element?.editName ? element?.editName : element?.name);
      formData.append(`images[${index}][alt_tag]`, element?.editName ? element?.editName : element?.name);
      formData.append(`images[${index}][file]`, element);
    }
    const response = await AxiosAll('post', 'seller/galleryImages', formData, userData?.userToken);
    if (response?.statusCode === 200) {
      setProductImages(response?.data);
      setPage(1);
    }
    getProductImage();
    setUploadImage([]);
    setLoading(false);
    setIsImageUploading(false);
  };

  const handleDelete = async (id) => {
    const response = await AxiosAll('delete', `seller/galleryImages/${id}`, null, userData?.userToken);
    if (response?.statusCode === 200) {
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }
    getProductImage();
  };

  const handleBulkDelete = async (id) => {
    const response = await AxiosAll(
      'delete',
      `seller/galleryImagesDeleteMultiple`,
      bulkDeleteData,
      userData?.userToken,
    );
    if (response?.statusCode === 200) {
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }
    setSelected(0);
    setBulkDeleteToggle(false);
    getProductImage();
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const handleCheckbox = (id, e) => {
    const { checked } = e.target;

    if (checked) {
      setSelected((prevState) => {
        return prevState + 1;
      });
      setBulkDeleteData({
        images_id: [...bulkDeleteData.images_id, id],
      });
    } else {
      setSelected((prevState) => {
        return prevState - 1;
      });
      setBulkDeleteData({
        images_id: bulkDeleteData.images_id.filter((item) => item !== id),
      });
    }

    getProductImage(searchProduct);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    const result = uploadImage.reduce((sum, item) => {
      // eslint-disable-next-line no-multi-assign, no-param-reassign
      const newLocal = (sum += item.size);
      return newLocal;
    }, 0);
    setImageSize(result);
  }, [uploadImage]);

  useEffect(() => {
    getProductImage(searchProduct);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProduct, page]);

  return (
    <div className="productimages">
      <div className="product-images-container">
        {/* <Container className="all-container-27inch"> */}
        <BannerCard
          title="Product Images"
          para="This is where you can view and upload photos of your products. When bulk uploading products you need to upload your images here and reference the link in your CSV."
          smallCard
          color="#E0F8F2"
        />
        <Container className="all-container-27inch">
          {/* <MainHeading title="Product Images" />
          <div className="seller-para">
            This is where you can view and upload photos of your products. When
            bulk uploading products you need to upload your images here and
            reference the link in your CSV.
          </div> */}
          {Loading ? (
            <ProductImageSkeleton type={2} />
          ) : (
            <div className={bulkDeleteToggle ? 'flexwidth' : 'search-row'}>
              <div className={bulkDeleteToggle ? 'selected ' : ''}>
                {bulkDeleteToggle ? (
                  `${selectedProduct} selected`
                ) : (
                  <SearchBar handleChange={handleSearch} value={searchText} />
                )}
              </div>
              {productImages?.data?.length > 0 ? (
                <div>
                  {bulkDeleteToggle ? (
                    <>
                      <LightBtn
                        title="Cancel"
                        onClick={() => {
                          setBulkDeleteToggle(false);
                          setSelected(0);
                          setBulkDeleteData({
                            images_id: [],
                          });
                        }}
                      />{' '}
                      <DarkBtn
                        title="Delete "
                        onClick={() => {
                          if (selectedProduct > 0) {
                            sweatAlertBox(
                              'Are You Sure?',
                              `You are about to delete ${converter.toWords(selectedProduct)?.bold()} ${
                                selectedProduct === 1 ? 'image' : 'images'
                              }. You cannot undo this action once confirmed.`,
                              'Confirm',
                              'Cancel',

                              handleBulkDelete,
                            );
                          } else {
                            notify('Please select atleast one image', 'error');
                          }
                        }}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </div>
              ) : null}
              {bulkDeleteToggle === false ? (
                <div className="btn-part">
                  {productImages?.data?.length > 0 ? (
                    <LightBtn title="Bulk delete" onClick={() => setBulkDeleteToggle(true)} />
                  ) : null}
                  {/* <DarkBtn title=" Add Images "  /> */}
                  <UploadImage
                    setPage={setPage}
                    uploadImage={uploadImage}
                    setUploadImage={setUploadImage}
                    UploadMultiImage={UploadMultiImage}
                    imageSize={imageSize}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {Loading || isImageUploading ? (
            <ProductImageSkeleton type={1} />
          ) : (
            <>
              {productImages?.data?.length > 0 ? (
                productImages?.data?.map((productImg, index) => {
                  return (
                    <div className="img-container" key={index}>
                      <ProductsCard
                        image={productImg?.image}
                        name={productImg?.name}
                        copyUrl={productImg?.image}
                        id={productImg?.id}
                        isImages
                      />
                      {bulkDeleteToggle ? (
                        <Checkbox
                          className="checkbox-style"
                          {...label}
                          checkedIcon={<CheckCircleIcon />}
                          checked={!!bulkDeleteData.images_id.includes(productImg?.id)}
                          value={productImg?.id}
                          icon={<RadioButtonUncheckedIcon />}
                          onChange={(e) => handleCheckbox(productImg?.id, e)}
                        />
                      ) : (
                        <ToolTip
                          name={productImg?.name}
                          productId={productImg?.id}
                          handleDelete={handleDelete}
                          id={productImg?.id}
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <Empty message="Uploaded images will be displayed here. Click the Add images button to get started" />
              )}

              {productImages?.total > 12 ? (
                <CardPagination
                  noOfPages={productImages?.last_page}
                  page={page}
                  handleChange={handlePagination}
                  rowsPerPage={12}
                />
              ) : (
                ''
              )}
            </>
          )}
        </Container>
      </div>
    </div>
  );
};

export default ProductImages;
