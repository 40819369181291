import { CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

export const Loader = ({ loading, message, size, color, className }) => {
  return (
    <Backdrop sx={{ zIndex: 9999, color: color || '#fff' }} open={loading} className={` loader ${className} `}>
      <CircularProgress size={size || 100} color="inherit" />
      {message ? <h4 dangerouslySetInnerHTML={{ __html: message }} /> : null}
    </Backdrop>
  );
};
