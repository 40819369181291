/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-debugger */
/* eslint-disable no-unsafe-optional-chaining */
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DialogActions from '@mui/material/DialogActions';
import * as React from 'react';
import { Checkbox, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Label } from 'gestalt';

import { BlueBtn } from '../../universal/Buttons/BlueBtn';
import LightBtn from '../../universal/Buttons/LightBtn';

import { AxiosAll } from '../../../services/axiosAll';
import { Empty } from '../../universal/Empty';

import ProductsCard from '../../universal/ProductsCard';

import { notify } from '../../../core/constants';
import ToolTip from '../../universal/ToolTip';
import { Loader } from '../../Skeleton/Loader';

export const ShopCollectionSorting = ({ id, data, setStep, AddCategory, getMainCategories }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [product, setProduct] = useState([]);
  const [productImages, setProductImages] = useState();
  const [searchText, setSearchText] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  const [shopCollectionList, setShopCollectionList] = useState([]);
  const [TrendingCollectionData, setTrendingCollectionData] = useState([]);
  const [sectionType, setSectionType] = useState(1);

  const handleClickOpen = () => {
    setOpen(true);
  };
  console.log('shopCollectionList', shopCollectionList);
  console.log('sectionType', sectionType);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment === null) {
      setSectionType(1);
    } else {
      setSectionType(newAlignment);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };
  const getShopCollectionList = async () => {
    const response = await AxiosAll('get', `seller/shop-collections`, null);
    if (response?.statusCode === 200) {
      setShopCollectionList(response?.data);
    } else {
      notify(response.message, 'error');
    }
  };

  const getTrendingCollectionList = async () => {
    const response = await AxiosAll('get', `seller/shop-collections?is_trending=1`, null);
    if (response?.statusCode === 200) {
      setTrendingCollectionData(response?.data);
    } else {
      notify(response.message, 'error');
    }
  };

  const handleSaveOrder = async () => {
    const formData = new FormData();
    formData.append(`type`, sectionType === 1 ? 1 : 2);
    if (sectionType === 1) {
      if (shopCollectionList.length > 0) {
        for (let i = 0; i < shopCollectionList.length; i++) {
          formData.append(`shop_collection_ids[${i}]`, shopCollectionList[i]?.id);
        }
      } else {
        formData.append(`shop_collection_ids`, '');
      }
    }
    if (sectionType === 2) {
      if (TrendingCollectionData.length > 0) {
        for (let i = 0; i < TrendingCollectionData.length; i++) {
          formData.append(`shop_collection_ids[${i}]`, TrendingCollectionData[i]?.id);
        }
      } else {
        formData.append(`shop_collection_ids`, '');
      }
    }
    const response = await AxiosAll('post', `seller/shop-collection-ordering`, formData);
    if (response?.statusCode === 200) {
      if (sectionType === 2) {
        notify(response?.message, 'success');
        getTrendingCollectionList();
      } else {
        notify(response?.message, 'success');
        getShopCollectionList();
      }
    } else {
      notify(response.message, 'error');
    }
  };

  useEffect(() => {
    getTrendingCollectionList();
    getShopCollectionList();
  }, [open]);

  const handleDropShop = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...shopCollectionList];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setShopCollectionList(newSections);
    setDraggedOverIndex(null);
  };

  const handleDropTrending = (e, index) => {
    const draggedSectionIndex = Number(e.dataTransfer.getData('text/plain'));
    const newSections = [...TrendingCollectionData];
    const draggedSection = newSections[draggedSectionIndex];
    newSections.splice(draggedSectionIndex, 1);
    newSections.splice(index, 0, draggedSection);
    setTrendingCollectionData(newSections);
    setDraggedOverIndex(null);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  return (
    <div className="btn-width">
      <BlueBtn title="Order Shop Collections" customClass="return-btn" onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="beauty-picks"
      >
        {/* <Loader open={true} message="LoadingMessage" /> */}

        <div className="main-picks-container">
          <div className="add-product">
            <Typography>Manage the Order of Shop Collections</Typography>
          </div>
          <div className="product-list">
            <div className="product-gallery">
              <div className="link-toggle-btn">
                <ToggleButtonGroup
                  value={sectionType}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                  className="logs-toggle"
                >
                  <ToggleButton value={1} className="left-btn">
                    All Shop Collection
                  </ToggleButton>
                  <ToggleButton value={2} className="right-btn">
                    Trending Shop Collection
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              <div className="filter-table">
                {sectionType === 1 && (
                  <>
                    {shopCollectionList?.map((productImg, index) => {
                      return (
                        <div className="img-container" key={productImg?.id}>
                          <label className="label-file" htmlFor={`myCheckbox${index}`}>
                            <ProductsCard
                              dragStart={(e) => handleDragStart(e, index)}
                              dragOver={(e) => handleDragOver(e, index)}
                              dragEnd={() => setDraggedOverIndex(null)}
                              dropCard={(e) => handleDropShop(e, index)}
                              image={productImg?.cover_image}
                              name={productImg?.title}
                              copyUrl={productImg?.cover_image}
                              id={productImg?.id}
                              isImages
                            />
                          </label>
                        </div>
                      );
                    })}
                  </>
                )}
                {sectionType === 2 && (
                  <>
                    {TrendingCollectionData?.map((productImg, index) => {
                      return (
                        <div className="img-container" key={productImg?.id}>
                          <label className="label-file" htmlFor={`myCheckbox${index}`}>
                            <ProductsCard
                              dragStart={(e) => handleDragStart(e, index)}
                              dragOver={(e) => handleDragOver(e, index)}
                              dragEnd={() => setDraggedOverIndex(null)}
                              dropCard={(e) => handleDropTrending(e, index)}
                              image={productImg?.cover_image}
                              name={productImg?.title}
                              copyUrl={productImg?.cover_image}
                              id={productImg?.id}
                              isImages
                            />
                          </label>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <DialogActions className="bottom-btn">
          <LightBtn title="Cancel" onClick={handleClose} />
          <BlueBtn title="Save Order" onClick={handleSaveOrder} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
